// @generated by protobuf-ts 2.8.1 with parameter long_type_number,generate_dependencies
// @generated from protobuf file "auto_reserve/admin/restaurant_image/restaurant_image_service.proto" (package "auto_reserve.admin.restaurant_image", syntax proto3)
// tslint:disable
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * @generated from protobuf message auto_reserve.admin.restaurant_image.DestroyRequest
 */
export interface DestroyRequest {
  /**
   * @generated from protobuf field: int64 id = 1;
   */
  id: number;
}
/**
 * @generated from protobuf message auto_reserve.admin.restaurant_image.DestroyResponse
 */
export interface DestroyResponse {}
// @generated message type with reflection information, may provide speed optimized methods
class DestroyRequest$Type extends MessageType<DestroyRequest> {
  constructor() {
    super("auto_reserve.admin.restaurant_image.DestroyRequest", [
      {
        no: 1,
        name: "id",
        kind: "scalar",
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
    ]);
  }
  create(value?: PartialMessage<DestroyRequest>): DestroyRequest {
    const message = { id: 0 };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<DestroyRequest>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: DestroyRequest,
  ): DestroyRequest {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* int64 id */ 1:
          message.id = reader.int64().toNumber();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: DestroyRequest,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* int64 id = 1; */
    if (message.id !== 0) writer.tag(1, WireType.Varint).int64(message.id);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.admin.restaurant_image.DestroyRequest
 */
export const DestroyRequest = new DestroyRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DestroyResponse$Type extends MessageType<DestroyResponse> {
  constructor() {
    super("auto_reserve.admin.restaurant_image.DestroyResponse", []);
  }
  create(value?: PartialMessage<DestroyResponse>): DestroyResponse {
    const message = {};
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<DestroyResponse>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: DestroyResponse,
  ): DestroyResponse {
    return target ?? this.create();
  }
  internalBinaryWrite(
    message: DestroyResponse,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.admin.restaurant_image.DestroyResponse
 */
export const DestroyResponse = new DestroyResponse$Type();
/**
 * @generated ServiceType for protobuf service auto_reserve.admin.restaurant_image.RestaurantImage
 */
export const RestaurantImage = new ServiceType(
  "auto_reserve.admin.restaurant_image.RestaurantImage",
  [{ name: "Destroy", options: {}, I: DestroyRequest, O: DestroyResponse }],
);
