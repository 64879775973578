// @generated by protobuf-ts 2.8.1 with parameter long_type_number,generate_dependencies
// @generated from protobuf file "auto_reserve/admin/owner_invoice/owner_invoice_resource.proto" (package "auto_reserve.admin.owner_invoice", syntax proto3)
// tslint:disable
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Timestamp } from "../../../google/protobuf/timestamp";
/**
 * @generated from protobuf message auto_reserve.admin.owner_invoice.OwnerResource
 */
export interface OwnerResource {
  /**
   * @generated from protobuf field: int64 id = 1;
   */
  id: number;
  /**
   * @generated from protobuf field: string name = 4;
   */
  name: string;
  /**
   * @generated from protobuf field: string robopay_customer_id = 6;
   */
  robopayCustomerId: string;
  /**
   * @generated from protobuf field: bool has_bank_account_transfer_document = 7;
   */
  hasBankAccountTransferDocument: boolean;
  /**
   * @generated from protobuf field: repeated auto_reserve.admin.owner_invoice.RestaurantResource restaurants = 8;
   */
  restaurants: RestaurantResource[];
}
/**
 * @generated from protobuf message auto_reserve.admin.owner_invoice.RestaurantOrderContractResource
 */
export interface RestaurantOrderContractResource {
  /**
   * @generated from protobuf field: int64 id = 1;
   */
  id: number;
  /**
   * @generated from protobuf field: string memo = 2;
   */
  memo: string;
}
/**
 * @generated from protobuf message auto_reserve.admin.owner_invoice.RestaurantResource
 */
export interface RestaurantResource {
  /**
   * @generated from protobuf field: int64 id = 1;
   */
  id: number;
  /**
   * @generated from protobuf field: string name = 2;
   */
  name: string;
  /**
   * @generated from protobuf field: repeated auto_reserve.admin.owner_invoice.RestaurantOrderContractResource order_contracts = 3;
   */
  orderContracts: RestaurantOrderContractResource[];
}
/**
 * @generated from protobuf message auto_reserve.admin.owner_invoice.OwnerInvoiceResource
 */
export interface OwnerInvoiceResource {
  /**
   * @generated from protobuf field: string id = 1;
   */
  id: string;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp created_at = 2;
   */
  createdAt?: Timestamp;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp updated_at = 3;
   */
  updatedAt?: Timestamp;
  /**
   * @generated from protobuf field: int64 amount = 4;
   */
  amount: number;
  /**
   * @generated from protobuf field: string contents = 5;
   */
  contents: string;
  /**
   * @generated from protobuf field: auto_reserve.admin.owner_invoice.OwnerInvoiceResource.Status status = 6;
   */
  status: OwnerInvoiceResource_Status;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp due = 7;
   */
  due?: Timestamp;
  /**
   * @generated from protobuf field: auto_reserve.admin.owner_invoice.OwnerResource owner = 8;
   */
  owner?: OwnerResource;
}
/**
 * @generated from protobuf enum auto_reserve.admin.owner_invoice.OwnerInvoiceResource.Status
 */
export enum OwnerInvoiceResource_Status {
  /**
   * @generated from protobuf enum value: DRAFT = 0;
   */
  DRAFT = 0,
  /**
   * @generated from protobuf enum value: BILLED = 1;
   */
  BILLED = 1,
  /**
   * @generated from protobuf enum value: PAID = 2;
   */
  PAID = 2,
  /**
   * @generated from protobuf enum value: NOT_BILLED = 3;
   */
  NOT_BILLED = 3,
}
// @generated message type with reflection information, may provide speed optimized methods
class OwnerResource$Type extends MessageType<OwnerResource> {
  constructor() {
    super("auto_reserve.admin.owner_invoice.OwnerResource", [
      {
        no: 1,
        name: "id",
        kind: "scalar",
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
      { no: 4, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
      {
        no: 6,
        name: "robopay_customer_id",
        kind: "scalar",
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 7,
        name: "has_bank_account_transfer_document",
        kind: "scalar",
        T: 8 /*ScalarType.BOOL*/,
      },
      {
        no: 8,
        name: "restaurants",
        kind: "message",
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => RestaurantResource,
      },
    ]);
  }
  create(value?: PartialMessage<OwnerResource>): OwnerResource {
    const message = {
      id: 0,
      name: "",
      robopayCustomerId: "",
      hasBankAccountTransferDocument: false,
      restaurants: [],
    };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<OwnerResource>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: OwnerResource,
  ): OwnerResource {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* int64 id */ 1:
          message.id = reader.int64().toNumber();
          break;
        case /* string name */ 4:
          message.name = reader.string();
          break;
        case /* string robopay_customer_id */ 6:
          message.robopayCustomerId = reader.string();
          break;
        case /* bool has_bank_account_transfer_document */ 7:
          message.hasBankAccountTransferDocument = reader.bool();
          break;
        case /* repeated auto_reserve.admin.owner_invoice.RestaurantResource restaurants */ 8:
          message.restaurants.push(
            RestaurantResource.internalBinaryRead(
              reader,
              reader.uint32(),
              options,
            ),
          );
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: OwnerResource,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* int64 id = 1; */
    if (message.id !== 0) writer.tag(1, WireType.Varint).int64(message.id);
    /* string name = 4; */
    if (message.name !== "")
      writer.tag(4, WireType.LengthDelimited).string(message.name);
    /* string robopay_customer_id = 6; */
    if (message.robopayCustomerId !== "")
      writer.tag(6, WireType.LengthDelimited).string(message.robopayCustomerId);
    /* bool has_bank_account_transfer_document = 7; */
    if (message.hasBankAccountTransferDocument !== false)
      writer
        .tag(7, WireType.Varint)
        .bool(message.hasBankAccountTransferDocument);
    /* repeated auto_reserve.admin.owner_invoice.RestaurantResource restaurants = 8; */
    for (let i = 0; i < message.restaurants.length; i++)
      RestaurantResource.internalBinaryWrite(
        message.restaurants[i],
        writer.tag(8, WireType.LengthDelimited).fork(),
        options,
      ).join();
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.admin.owner_invoice.OwnerResource
 */
export const OwnerResource = new OwnerResource$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RestaurantOrderContractResource$Type extends MessageType<RestaurantOrderContractResource> {
  constructor() {
    super("auto_reserve.admin.owner_invoice.RestaurantOrderContractResource", [
      {
        no: 1,
        name: "id",
        kind: "scalar",
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
      { no: 2, name: "memo", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
    ]);
  }
  create(
    value?: PartialMessage<RestaurantOrderContractResource>,
  ): RestaurantOrderContractResource {
    const message = { id: 0, memo: "" };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<RestaurantOrderContractResource>(
        this,
        message,
        value,
      );
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: RestaurantOrderContractResource,
  ): RestaurantOrderContractResource {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* int64 id */ 1:
          message.id = reader.int64().toNumber();
          break;
        case /* string memo */ 2:
          message.memo = reader.string();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: RestaurantOrderContractResource,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* int64 id = 1; */
    if (message.id !== 0) writer.tag(1, WireType.Varint).int64(message.id);
    /* string memo = 2; */
    if (message.memo !== "")
      writer.tag(2, WireType.LengthDelimited).string(message.memo);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.admin.owner_invoice.RestaurantOrderContractResource
 */
export const RestaurantOrderContractResource =
  new RestaurantOrderContractResource$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RestaurantResource$Type extends MessageType<RestaurantResource> {
  constructor() {
    super("auto_reserve.admin.owner_invoice.RestaurantResource", [
      {
        no: 1,
        name: "id",
        kind: "scalar",
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
      { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
      {
        no: 3,
        name: "order_contracts",
        kind: "message",
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => RestaurantOrderContractResource,
      },
    ]);
  }
  create(value?: PartialMessage<RestaurantResource>): RestaurantResource {
    const message = { id: 0, name: "", orderContracts: [] };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<RestaurantResource>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: RestaurantResource,
  ): RestaurantResource {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* int64 id */ 1:
          message.id = reader.int64().toNumber();
          break;
        case /* string name */ 2:
          message.name = reader.string();
          break;
        case /* repeated auto_reserve.admin.owner_invoice.RestaurantOrderContractResource order_contracts */ 3:
          message.orderContracts.push(
            RestaurantOrderContractResource.internalBinaryRead(
              reader,
              reader.uint32(),
              options,
            ),
          );
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: RestaurantResource,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* int64 id = 1; */
    if (message.id !== 0) writer.tag(1, WireType.Varint).int64(message.id);
    /* string name = 2; */
    if (message.name !== "")
      writer.tag(2, WireType.LengthDelimited).string(message.name);
    /* repeated auto_reserve.admin.owner_invoice.RestaurantOrderContractResource order_contracts = 3; */
    for (let i = 0; i < message.orderContracts.length; i++)
      RestaurantOrderContractResource.internalBinaryWrite(
        message.orderContracts[i],
        writer.tag(3, WireType.LengthDelimited).fork(),
        options,
      ).join();
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.admin.owner_invoice.RestaurantResource
 */
export const RestaurantResource = new RestaurantResource$Type();
// @generated message type with reflection information, may provide speed optimized methods
class OwnerInvoiceResource$Type extends MessageType<OwnerInvoiceResource> {
  constructor() {
    super("auto_reserve.admin.owner_invoice.OwnerInvoiceResource", [
      { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: "created_at", kind: "message", T: () => Timestamp },
      { no: 3, name: "updated_at", kind: "message", T: () => Timestamp },
      {
        no: 4,
        name: "amount",
        kind: "scalar",
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
      { no: 5, name: "contents", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
      {
        no: 6,
        name: "status",
        kind: "enum",
        T: () => [
          "auto_reserve.admin.owner_invoice.OwnerInvoiceResource.Status",
          OwnerInvoiceResource_Status,
        ],
      },
      { no: 7, name: "due", kind: "message", T: () => Timestamp },
      { no: 8, name: "owner", kind: "message", T: () => OwnerResource },
    ]);
  }
  create(value?: PartialMessage<OwnerInvoiceResource>): OwnerInvoiceResource {
    const message = { id: "", amount: 0, contents: "", status: 0 };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<OwnerInvoiceResource>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: OwnerInvoiceResource,
  ): OwnerInvoiceResource {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* string id */ 1:
          message.id = reader.string();
          break;
        case /* google.protobuf.Timestamp created_at */ 2:
          message.createdAt = Timestamp.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.createdAt,
          );
          break;
        case /* google.protobuf.Timestamp updated_at */ 3:
          message.updatedAt = Timestamp.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.updatedAt,
          );
          break;
        case /* int64 amount */ 4:
          message.amount = reader.int64().toNumber();
          break;
        case /* string contents */ 5:
          message.contents = reader.string();
          break;
        case /* auto_reserve.admin.owner_invoice.OwnerInvoiceResource.Status status */ 6:
          message.status = reader.int32();
          break;
        case /* google.protobuf.Timestamp due */ 7:
          message.due = Timestamp.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.due,
          );
          break;
        case /* auto_reserve.admin.owner_invoice.OwnerResource owner */ 8:
          message.owner = OwnerResource.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.owner,
          );
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: OwnerInvoiceResource,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* string id = 1; */
    if (message.id !== "")
      writer.tag(1, WireType.LengthDelimited).string(message.id);
    /* google.protobuf.Timestamp created_at = 2; */
    if (message.createdAt)
      Timestamp.internalBinaryWrite(
        message.createdAt,
        writer.tag(2, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* google.protobuf.Timestamp updated_at = 3; */
    if (message.updatedAt)
      Timestamp.internalBinaryWrite(
        message.updatedAt,
        writer.tag(3, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* int64 amount = 4; */
    if (message.amount !== 0)
      writer.tag(4, WireType.Varint).int64(message.amount);
    /* string contents = 5; */
    if (message.contents !== "")
      writer.tag(5, WireType.LengthDelimited).string(message.contents);
    /* auto_reserve.admin.owner_invoice.OwnerInvoiceResource.Status status = 6; */
    if (message.status !== 0)
      writer.tag(6, WireType.Varint).int32(message.status);
    /* google.protobuf.Timestamp due = 7; */
    if (message.due)
      Timestamp.internalBinaryWrite(
        message.due,
        writer.tag(7, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* auto_reserve.admin.owner_invoice.OwnerResource owner = 8; */
    if (message.owner)
      OwnerResource.internalBinaryWrite(
        message.owner,
        writer.tag(8, WireType.LengthDelimited).fork(),
        options,
      ).join();
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.admin.owner_invoice.OwnerInvoiceResource
 */
export const OwnerInvoiceResource = new OwnerInvoiceResource$Type();
