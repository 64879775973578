// @generated by protobuf-ts 2.8.1 with parameter long_type_number,generate_dependencies
// @generated from protobuf file "auto_reserve/admin/owner_invoice/owner_invoice_service.proto" (package "auto_reserve.admin.owner_invoice", syntax proto3)
// tslint:disable
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { OwnerInvoiceResource_Status } from "./owner_invoice_resource";
import { OwnerInvoiceResource } from "./owner_invoice_resource";
/**
 * @generated from protobuf message auto_reserve.admin.owner_invoice.ListFilters
 */
export interface ListFilters {
  /**
   * @generated from protobuf field: repeated int64 owner_ids = 1;
   */
  ownerIds: number[];
  /**
   * @generated from protobuf field: repeated int64 statuses = 2;
   */
  statuses: number[];
}
/**
 * @generated from protobuf message auto_reserve.admin.owner_invoice.ListRequest
 */
export interface ListRequest {
  /**
   * @generated from protobuf field: int64 page = 1;
   */
  page: number;
  /**
   * @generated from protobuf field: int64 per = 2;
   */
  per: number;
  /**
   * @generated from protobuf field: auto_reserve.admin.owner_invoice.ListFilters filters = 3;
   */
  filters?: ListFilters;
}
/**
 * @generated from protobuf message auto_reserve.admin.owner_invoice.ListResponse
 */
export interface ListResponse {
  /**
   * @generated from protobuf field: repeated auto_reserve.admin.owner_invoice.OwnerInvoiceResource owner_invoices = 1;
   */
  ownerInvoices: OwnerInvoiceResource[];
  /**
   * @generated from protobuf field: int64 total_count = 2;
   */
  totalCount: number;
}
/**
 * @generated from protobuf message auto_reserve.admin.owner_invoice.GetRequest
 */
export interface GetRequest {
  /**
   * @generated from protobuf field: string id = 1;
   */
  id: string;
}
/**
 * @generated from protobuf message auto_reserve.admin.owner_invoice.CreateRequest
 */
export interface CreateRequest {
  /**
   * @generated from protobuf field: auto_reserve.admin.owner_invoice.OwnerInvoiceResource.Status status = 2;
   */
  status: OwnerInvoiceResource_Status;
  /**
   * @generated from protobuf field: string contents = 3;
   */
  contents: string;
  /**
   * @generated from protobuf field: int64 amount = 4;
   */
  amount: number;
  /**
   * @generated from protobuf field: string due = 5;
   */
  due: string;
  /**
   * @generated from protobuf field: int64 owner_id = 6;
   */
  ownerId: number;
}
/**
 * @generated from protobuf message auto_reserve.admin.owner_invoice.UpdateRequest
 */
export interface UpdateRequest {
  /**
   * @generated from protobuf field: string id = 1;
   */
  id: string;
  /**
   * @generated from protobuf field: auto_reserve.admin.owner_invoice.OwnerInvoiceResource.Status status = 2;
   */
  status: OwnerInvoiceResource_Status;
  /**
   * @generated from protobuf field: string contents = 3;
   */
  contents: string;
  /**
   * @generated from protobuf field: int64 amount = 4;
   */
  amount: number;
  /**
   * @generated from protobuf field: string due = 5;
   */
  due: string;
  /**
   * @generated from protobuf field: int64 owner_id = 6;
   */
  ownerId: number;
}
/**
 * @generated from protobuf message auto_reserve.admin.owner_invoice.UpdateStatusRequest
 */
export interface UpdateStatusRequest {
  /**
   * @generated from protobuf field: string id = 1;
   */
  id: string;
  /**
   * @generated from protobuf field: auto_reserve.admin.owner_invoice.OwnerInvoiceResource.Status status = 2;
   */
  status: OwnerInvoiceResource_Status;
}
/**
 * @generated from protobuf message auto_reserve.admin.owner_invoice.UpsertRequest
 */
export interface UpsertRequest {
  /**
   * @generated from protobuf field: repeated string ids = 1;
   */
  ids: string[];
  /**
   * @generated from protobuf field: auto_reserve.admin.owner_invoice.OwnerInvoiceResource.Status status = 2;
   */
  status: OwnerInvoiceResource_Status;
}
/**
 * @generated from protobuf message auto_reserve.admin.owner_invoice.UpsertResponse
 */
export interface UpsertResponse {
  /**
   * @generated from protobuf field: repeated auto_reserve.admin.owner_invoice.OwnerInvoiceResource owner_invoices = 1;
   */
  ownerInvoices: OwnerInvoiceResource[];
}
/**
 * @generated from protobuf message auto_reserve.admin.owner_invoice.DestroyRequest
 */
export interface DestroyRequest {
  /**
   * @generated from protobuf field: string id = 1;
   */
  id: string;
}
/**
 * @generated from protobuf message auto_reserve.admin.owner_invoice.DestroyResponse
 */
export interface DestroyResponse {}
// @generated message type with reflection information, may provide speed optimized methods
class ListFilters$Type extends MessageType<ListFilters> {
  constructor() {
    super("auto_reserve.admin.owner_invoice.ListFilters", [
      {
        no: 1,
        name: "owner_ids",
        kind: "scalar",
        repeat: 1 /*RepeatType.PACKED*/,
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
      {
        no: 2,
        name: "statuses",
        kind: "scalar",
        repeat: 1 /*RepeatType.PACKED*/,
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
    ]);
  }
  create(value?: PartialMessage<ListFilters>): ListFilters {
    const message = { ownerIds: [], statuses: [] };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<ListFilters>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: ListFilters,
  ): ListFilters {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* repeated int64 owner_ids */ 1:
          if (wireType === WireType.LengthDelimited)
            for (let e = reader.int32() + reader.pos; reader.pos < e; )
              message.ownerIds.push(reader.int64().toNumber());
          else message.ownerIds.push(reader.int64().toNumber());
          break;
        case /* repeated int64 statuses */ 2:
          if (wireType === WireType.LengthDelimited)
            for (let e = reader.int32() + reader.pos; reader.pos < e; )
              message.statuses.push(reader.int64().toNumber());
          else message.statuses.push(reader.int64().toNumber());
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: ListFilters,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* repeated int64 owner_ids = 1; */
    if (message.ownerIds.length) {
      writer.tag(1, WireType.LengthDelimited).fork();
      for (let i = 0; i < message.ownerIds.length; i++)
        writer.int64(message.ownerIds[i]);
      writer.join();
    }
    /* repeated int64 statuses = 2; */
    if (message.statuses.length) {
      writer.tag(2, WireType.LengthDelimited).fork();
      for (let i = 0; i < message.statuses.length; i++)
        writer.int64(message.statuses[i]);
      writer.join();
    }
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.admin.owner_invoice.ListFilters
 */
export const ListFilters = new ListFilters$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListRequest$Type extends MessageType<ListRequest> {
  constructor() {
    super("auto_reserve.admin.owner_invoice.ListRequest", [
      {
        no: 1,
        name: "page",
        kind: "scalar",
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
      {
        no: 2,
        name: "per",
        kind: "scalar",
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
      { no: 3, name: "filters", kind: "message", T: () => ListFilters },
    ]);
  }
  create(value?: PartialMessage<ListRequest>): ListRequest {
    const message = { page: 0, per: 0 };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<ListRequest>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: ListRequest,
  ): ListRequest {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* int64 page */ 1:
          message.page = reader.int64().toNumber();
          break;
        case /* int64 per */ 2:
          message.per = reader.int64().toNumber();
          break;
        case /* auto_reserve.admin.owner_invoice.ListFilters filters */ 3:
          message.filters = ListFilters.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.filters,
          );
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: ListRequest,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* int64 page = 1; */
    if (message.page !== 0) writer.tag(1, WireType.Varint).int64(message.page);
    /* int64 per = 2; */
    if (message.per !== 0) writer.tag(2, WireType.Varint).int64(message.per);
    /* auto_reserve.admin.owner_invoice.ListFilters filters = 3; */
    if (message.filters)
      ListFilters.internalBinaryWrite(
        message.filters,
        writer.tag(3, WireType.LengthDelimited).fork(),
        options,
      ).join();
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.admin.owner_invoice.ListRequest
 */
export const ListRequest = new ListRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListResponse$Type extends MessageType<ListResponse> {
  constructor() {
    super("auto_reserve.admin.owner_invoice.ListResponse", [
      {
        no: 1,
        name: "owner_invoices",
        kind: "message",
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => OwnerInvoiceResource,
      },
      {
        no: 2,
        name: "total_count",
        kind: "scalar",
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
    ]);
  }
  create(value?: PartialMessage<ListResponse>): ListResponse {
    const message = { ownerInvoices: [], totalCount: 0 };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<ListResponse>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: ListResponse,
  ): ListResponse {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* repeated auto_reserve.admin.owner_invoice.OwnerInvoiceResource owner_invoices */ 1:
          message.ownerInvoices.push(
            OwnerInvoiceResource.internalBinaryRead(
              reader,
              reader.uint32(),
              options,
            ),
          );
          break;
        case /* int64 total_count */ 2:
          message.totalCount = reader.int64().toNumber();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: ListResponse,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* repeated auto_reserve.admin.owner_invoice.OwnerInvoiceResource owner_invoices = 1; */
    for (let i = 0; i < message.ownerInvoices.length; i++)
      OwnerInvoiceResource.internalBinaryWrite(
        message.ownerInvoices[i],
        writer.tag(1, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* int64 total_count = 2; */
    if (message.totalCount !== 0)
      writer.tag(2, WireType.Varint).int64(message.totalCount);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.admin.owner_invoice.ListResponse
 */
export const ListResponse = new ListResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetRequest$Type extends MessageType<GetRequest> {
  constructor() {
    super("auto_reserve.admin.owner_invoice.GetRequest", [
      { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
    ]);
  }
  create(value?: PartialMessage<GetRequest>): GetRequest {
    const message = { id: "" };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<GetRequest>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: GetRequest,
  ): GetRequest {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* string id */ 1:
          message.id = reader.string();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: GetRequest,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* string id = 1; */
    if (message.id !== "")
      writer.tag(1, WireType.LengthDelimited).string(message.id);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.admin.owner_invoice.GetRequest
 */
export const GetRequest = new GetRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateRequest$Type extends MessageType<CreateRequest> {
  constructor() {
    super("auto_reserve.admin.owner_invoice.CreateRequest", [
      {
        no: 2,
        name: "status",
        kind: "enum",
        T: () => [
          "auto_reserve.admin.owner_invoice.OwnerInvoiceResource.Status",
          OwnerInvoiceResource_Status,
        ],
      },
      { no: 3, name: "contents", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
      {
        no: 4,
        name: "amount",
        kind: "scalar",
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
      { no: 5, name: "due", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
      {
        no: 6,
        name: "owner_id",
        kind: "scalar",
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
    ]);
  }
  create(value?: PartialMessage<CreateRequest>): CreateRequest {
    const message = { status: 0, contents: "", amount: 0, due: "", ownerId: 0 };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<CreateRequest>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: CreateRequest,
  ): CreateRequest {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* auto_reserve.admin.owner_invoice.OwnerInvoiceResource.Status status */ 2:
          message.status = reader.int32();
          break;
        case /* string contents */ 3:
          message.contents = reader.string();
          break;
        case /* int64 amount */ 4:
          message.amount = reader.int64().toNumber();
          break;
        case /* string due */ 5:
          message.due = reader.string();
          break;
        case /* int64 owner_id */ 6:
          message.ownerId = reader.int64().toNumber();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: CreateRequest,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* auto_reserve.admin.owner_invoice.OwnerInvoiceResource.Status status = 2; */
    if (message.status !== 0)
      writer.tag(2, WireType.Varint).int32(message.status);
    /* string contents = 3; */
    if (message.contents !== "")
      writer.tag(3, WireType.LengthDelimited).string(message.contents);
    /* int64 amount = 4; */
    if (message.amount !== 0)
      writer.tag(4, WireType.Varint).int64(message.amount);
    /* string due = 5; */
    if (message.due !== "")
      writer.tag(5, WireType.LengthDelimited).string(message.due);
    /* int64 owner_id = 6; */
    if (message.ownerId !== 0)
      writer.tag(6, WireType.Varint).int64(message.ownerId);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.admin.owner_invoice.CreateRequest
 */
export const CreateRequest = new CreateRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateRequest$Type extends MessageType<UpdateRequest> {
  constructor() {
    super("auto_reserve.admin.owner_invoice.UpdateRequest", [
      { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: "status",
        kind: "enum",
        T: () => [
          "auto_reserve.admin.owner_invoice.OwnerInvoiceResource.Status",
          OwnerInvoiceResource_Status,
        ],
      },
      { no: 3, name: "contents", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
      {
        no: 4,
        name: "amount",
        kind: "scalar",
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
      { no: 5, name: "due", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
      {
        no: 6,
        name: "owner_id",
        kind: "scalar",
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
    ]);
  }
  create(value?: PartialMessage<UpdateRequest>): UpdateRequest {
    const message = {
      id: "",
      status: 0,
      contents: "",
      amount: 0,
      due: "",
      ownerId: 0,
    };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<UpdateRequest>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: UpdateRequest,
  ): UpdateRequest {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* string id */ 1:
          message.id = reader.string();
          break;
        case /* auto_reserve.admin.owner_invoice.OwnerInvoiceResource.Status status */ 2:
          message.status = reader.int32();
          break;
        case /* string contents */ 3:
          message.contents = reader.string();
          break;
        case /* int64 amount */ 4:
          message.amount = reader.int64().toNumber();
          break;
        case /* string due */ 5:
          message.due = reader.string();
          break;
        case /* int64 owner_id */ 6:
          message.ownerId = reader.int64().toNumber();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: UpdateRequest,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* string id = 1; */
    if (message.id !== "")
      writer.tag(1, WireType.LengthDelimited).string(message.id);
    /* auto_reserve.admin.owner_invoice.OwnerInvoiceResource.Status status = 2; */
    if (message.status !== 0)
      writer.tag(2, WireType.Varint).int32(message.status);
    /* string contents = 3; */
    if (message.contents !== "")
      writer.tag(3, WireType.LengthDelimited).string(message.contents);
    /* int64 amount = 4; */
    if (message.amount !== 0)
      writer.tag(4, WireType.Varint).int64(message.amount);
    /* string due = 5; */
    if (message.due !== "")
      writer.tag(5, WireType.LengthDelimited).string(message.due);
    /* int64 owner_id = 6; */
    if (message.ownerId !== 0)
      writer.tag(6, WireType.Varint).int64(message.ownerId);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.admin.owner_invoice.UpdateRequest
 */
export const UpdateRequest = new UpdateRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateStatusRequest$Type extends MessageType<UpdateStatusRequest> {
  constructor() {
    super("auto_reserve.admin.owner_invoice.UpdateStatusRequest", [
      { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: "status",
        kind: "enum",
        T: () => [
          "auto_reserve.admin.owner_invoice.OwnerInvoiceResource.Status",
          OwnerInvoiceResource_Status,
        ],
      },
    ]);
  }
  create(value?: PartialMessage<UpdateStatusRequest>): UpdateStatusRequest {
    const message = { id: "", status: 0 };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<UpdateStatusRequest>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: UpdateStatusRequest,
  ): UpdateStatusRequest {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* string id */ 1:
          message.id = reader.string();
          break;
        case /* auto_reserve.admin.owner_invoice.OwnerInvoiceResource.Status status */ 2:
          message.status = reader.int32();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: UpdateStatusRequest,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* string id = 1; */
    if (message.id !== "")
      writer.tag(1, WireType.LengthDelimited).string(message.id);
    /* auto_reserve.admin.owner_invoice.OwnerInvoiceResource.Status status = 2; */
    if (message.status !== 0)
      writer.tag(2, WireType.Varint).int32(message.status);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.admin.owner_invoice.UpdateStatusRequest
 */
export const UpdateStatusRequest = new UpdateStatusRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpsertRequest$Type extends MessageType<UpsertRequest> {
  constructor() {
    super("auto_reserve.admin.owner_invoice.UpsertRequest", [
      {
        no: 1,
        name: "ids",
        kind: "scalar",
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: "status",
        kind: "enum",
        T: () => [
          "auto_reserve.admin.owner_invoice.OwnerInvoiceResource.Status",
          OwnerInvoiceResource_Status,
        ],
      },
    ]);
  }
  create(value?: PartialMessage<UpsertRequest>): UpsertRequest {
    const message = { ids: [], status: 0 };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<UpsertRequest>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: UpsertRequest,
  ): UpsertRequest {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* repeated string ids */ 1:
          message.ids.push(reader.string());
          break;
        case /* auto_reserve.admin.owner_invoice.OwnerInvoiceResource.Status status */ 2:
          message.status = reader.int32();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: UpsertRequest,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* repeated string ids = 1; */
    for (let i = 0; i < message.ids.length; i++)
      writer.tag(1, WireType.LengthDelimited).string(message.ids[i]);
    /* auto_reserve.admin.owner_invoice.OwnerInvoiceResource.Status status = 2; */
    if (message.status !== 0)
      writer.tag(2, WireType.Varint).int32(message.status);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.admin.owner_invoice.UpsertRequest
 */
export const UpsertRequest = new UpsertRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpsertResponse$Type extends MessageType<UpsertResponse> {
  constructor() {
    super("auto_reserve.admin.owner_invoice.UpsertResponse", [
      {
        no: 1,
        name: "owner_invoices",
        kind: "message",
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => OwnerInvoiceResource,
      },
    ]);
  }
  create(value?: PartialMessage<UpsertResponse>): UpsertResponse {
    const message = { ownerInvoices: [] };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<UpsertResponse>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: UpsertResponse,
  ): UpsertResponse {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* repeated auto_reserve.admin.owner_invoice.OwnerInvoiceResource owner_invoices */ 1:
          message.ownerInvoices.push(
            OwnerInvoiceResource.internalBinaryRead(
              reader,
              reader.uint32(),
              options,
            ),
          );
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: UpsertResponse,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* repeated auto_reserve.admin.owner_invoice.OwnerInvoiceResource owner_invoices = 1; */
    for (let i = 0; i < message.ownerInvoices.length; i++)
      OwnerInvoiceResource.internalBinaryWrite(
        message.ownerInvoices[i],
        writer.tag(1, WireType.LengthDelimited).fork(),
        options,
      ).join();
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.admin.owner_invoice.UpsertResponse
 */
export const UpsertResponse = new UpsertResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DestroyRequest$Type extends MessageType<DestroyRequest> {
  constructor() {
    super("auto_reserve.admin.owner_invoice.DestroyRequest", [
      { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
    ]);
  }
  create(value?: PartialMessage<DestroyRequest>): DestroyRequest {
    const message = { id: "" };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<DestroyRequest>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: DestroyRequest,
  ): DestroyRequest {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* string id */ 1:
          message.id = reader.string();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: DestroyRequest,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* string id = 1; */
    if (message.id !== "")
      writer.tag(1, WireType.LengthDelimited).string(message.id);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.admin.owner_invoice.DestroyRequest
 */
export const DestroyRequest = new DestroyRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DestroyResponse$Type extends MessageType<DestroyResponse> {
  constructor() {
    super("auto_reserve.admin.owner_invoice.DestroyResponse", []);
  }
  create(value?: PartialMessage<DestroyResponse>): DestroyResponse {
    const message = {};
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<DestroyResponse>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: DestroyResponse,
  ): DestroyResponse {
    return target ?? this.create();
  }
  internalBinaryWrite(
    message: DestroyResponse,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.admin.owner_invoice.DestroyResponse
 */
export const DestroyResponse = new DestroyResponse$Type();
/**
 * @generated ServiceType for protobuf service auto_reserve.admin.owner_invoice.OwnerInvoice
 */
export const OwnerInvoice = new ServiceType(
  "auto_reserve.admin.owner_invoice.OwnerInvoice",
  [
    { name: "List", options: {}, I: ListRequest, O: ListResponse },
    { name: "Get", options: {}, I: GetRequest, O: OwnerInvoiceResource },
    { name: "Create", options: {}, I: CreateRequest, O: OwnerInvoiceResource },
    { name: "Update", options: {}, I: UpdateRequest, O: OwnerInvoiceResource },
    {
      name: "UpdateStatus",
      options: {},
      I: UpdateStatusRequest,
      O: OwnerInvoiceResource,
    },
    { name: "Upsert", options: {}, I: UpsertRequest, O: UpsertResponse },
    { name: "Destroy", options: {}, I: DestroyRequest, O: DestroyResponse },
  ],
);
