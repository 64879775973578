// @generated by protobuf-ts 2.8.1 with parameter long_type_number,generate_dependencies
// @generated from protobuf file "auto_reserve/admin/broadcast_message/broadcast_message_resource.proto" (package "auto_reserve.admin.broadcast_message", syntax proto3)
// tslint:disable
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { MessageContentResource } from "../message_content/message_content_resource";
import { Timestamp } from "../../../google/protobuf/timestamp";
/**
 * @generated from protobuf message auto_reserve.admin.broadcast_message.SenderResource
 */
export interface SenderResource {
  /**
   * @generated from protobuf field: int64 id = 1;
   */
  id: number;
  /**
   * @generated from protobuf field: string type = 2;
   */
  type: string;
  /**
   * @generated from protobuf field: string name = 3;
   */
  name: string;
}
/**
 * @generated from protobuf message auto_reserve.admin.broadcast_message.BroadcastMessageResource
 */
export interface BroadcastMessageResource {
  /**
   * @generated from protobuf field: string id = 1;
   */
  id: string;
  /**
   * @generated from protobuf field: auto_reserve.admin.broadcast_message.BroadcastMessageResource.Status status = 2;
   */
  status: BroadcastMessageResource_Status;
  /**
   * @generated from protobuf field: auto_reserve.admin.broadcast_message.SenderResource sender = 3;
   */
  sender?: SenderResource;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp delivery_at = 4;
   */
  deliveryAt?: Timestamp;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp started_at = 5;
   */
  startedAt?: Timestamp;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp finished_at = 6;
   */
  finishedAt?: Timestamp;
  /**
   * @generated from protobuf field: int64 number_of_sent = 7;
   */
  numberOfSent: number;
  /**
   * @generated from protobuf field: int64 success_count = 8;
   */
  successCount: number;
  /**
   * @generated from protobuf field: int64 failure_count = 9;
   */
  failureCount: number;
  /**
   * @generated from protobuf field: bool test_delivery = 10;
   */
  testDelivery: boolean;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp created_at = 11;
   */
  createdAt?: Timestamp;
  /**
   * @generated from protobuf field: repeated auto_reserve.admin.message_content.MessageContentResource message_contents = 12;
   */
  messageContents: MessageContentResource[];
  /**
   * @generated from protobuf field: repeated string prefecture_ids = 13;
   */
  prefectureIds: string[];
  /**
   * @generated from protobuf field: string subject = 14;
   */
  subject: string;
  /**
   * @generated from protobuf field: bool use_csv = 15;
   */
  useCsv: boolean;
}
/**
 * @generated from protobuf enum auto_reserve.admin.broadcast_message.BroadcastMessageResource.Status
 */
export enum BroadcastMessageResource_Status {
  /**
   * @generated from protobuf enum value: PENDING = 0;
   */
  PENDING = 0,
  /**
   * @generated from protobuf enum value: IN_PROGRESS = 1;
   */
  IN_PROGRESS = 1,
  /**
   * @generated from protobuf enum value: SUCCEEDED = 2;
   */
  SUCCEEDED = 2,
  /**
   * @generated from protobuf enum value: FAILED = 3;
   */
  FAILED = 3,
  /**
   * @generated from protobuf enum value: PARTIALLY_FAILED = 4;
   */
  PARTIALLY_FAILED = 4,
  /**
   * @generated from protobuf enum value: CANCELLED = 5;
   */
  CANCELLED = 5,
}
// @generated message type with reflection information, may provide speed optimized methods
class SenderResource$Type extends MessageType<SenderResource> {
  constructor() {
    super("auto_reserve.admin.broadcast_message.SenderResource", [
      {
        no: 1,
        name: "id",
        kind: "scalar",
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
      { no: 2, name: "type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
    ]);
  }
  create(value?: PartialMessage<SenderResource>): SenderResource {
    const message = { id: 0, type: "", name: "" };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<SenderResource>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: SenderResource,
  ): SenderResource {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* int64 id */ 1:
          message.id = reader.int64().toNumber();
          break;
        case /* string type */ 2:
          message.type = reader.string();
          break;
        case /* string name */ 3:
          message.name = reader.string();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: SenderResource,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* int64 id = 1; */
    if (message.id !== 0) writer.tag(1, WireType.Varint).int64(message.id);
    /* string type = 2; */
    if (message.type !== "")
      writer.tag(2, WireType.LengthDelimited).string(message.type);
    /* string name = 3; */
    if (message.name !== "")
      writer.tag(3, WireType.LengthDelimited).string(message.name);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.admin.broadcast_message.SenderResource
 */
export const SenderResource = new SenderResource$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BroadcastMessageResource$Type extends MessageType<BroadcastMessageResource> {
  constructor() {
    super("auto_reserve.admin.broadcast_message.BroadcastMessageResource", [
      { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: "status",
        kind: "enum",
        T: () => [
          "auto_reserve.admin.broadcast_message.BroadcastMessageResource.Status",
          BroadcastMessageResource_Status,
        ],
      },
      { no: 3, name: "sender", kind: "message", T: () => SenderResource },
      { no: 4, name: "delivery_at", kind: "message", T: () => Timestamp },
      { no: 5, name: "started_at", kind: "message", T: () => Timestamp },
      { no: 6, name: "finished_at", kind: "message", T: () => Timestamp },
      {
        no: 7,
        name: "number_of_sent",
        kind: "scalar",
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
      {
        no: 8,
        name: "success_count",
        kind: "scalar",
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
      {
        no: 9,
        name: "failure_count",
        kind: "scalar",
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
      {
        no: 10,
        name: "test_delivery",
        kind: "scalar",
        T: 8 /*ScalarType.BOOL*/,
      },
      { no: 11, name: "created_at", kind: "message", T: () => Timestamp },
      {
        no: 12,
        name: "message_contents",
        kind: "message",
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => MessageContentResource,
      },
      {
        no: 13,
        name: "prefecture_ids",
        kind: "scalar",
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 14, name: "subject", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
      { no: 15, name: "use_csv", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
    ]);
  }
  create(
    value?: PartialMessage<BroadcastMessageResource>,
  ): BroadcastMessageResource {
    const message = {
      id: "",
      status: 0,
      numberOfSent: 0,
      successCount: 0,
      failureCount: 0,
      testDelivery: false,
      messageContents: [],
      prefectureIds: [],
      subject: "",
      useCsv: false,
    };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<BroadcastMessageResource>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: BroadcastMessageResource,
  ): BroadcastMessageResource {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* string id */ 1:
          message.id = reader.string();
          break;
        case /* auto_reserve.admin.broadcast_message.BroadcastMessageResource.Status status */ 2:
          message.status = reader.int32();
          break;
        case /* auto_reserve.admin.broadcast_message.SenderResource sender */ 3:
          message.sender = SenderResource.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.sender,
          );
          break;
        case /* google.protobuf.Timestamp delivery_at */ 4:
          message.deliveryAt = Timestamp.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.deliveryAt,
          );
          break;
        case /* google.protobuf.Timestamp started_at */ 5:
          message.startedAt = Timestamp.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.startedAt,
          );
          break;
        case /* google.protobuf.Timestamp finished_at */ 6:
          message.finishedAt = Timestamp.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.finishedAt,
          );
          break;
        case /* int64 number_of_sent */ 7:
          message.numberOfSent = reader.int64().toNumber();
          break;
        case /* int64 success_count */ 8:
          message.successCount = reader.int64().toNumber();
          break;
        case /* int64 failure_count */ 9:
          message.failureCount = reader.int64().toNumber();
          break;
        case /* bool test_delivery */ 10:
          message.testDelivery = reader.bool();
          break;
        case /* google.protobuf.Timestamp created_at */ 11:
          message.createdAt = Timestamp.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.createdAt,
          );
          break;
        case /* repeated auto_reserve.admin.message_content.MessageContentResource message_contents */ 12:
          message.messageContents.push(
            MessageContentResource.internalBinaryRead(
              reader,
              reader.uint32(),
              options,
            ),
          );
          break;
        case /* repeated string prefecture_ids */ 13:
          message.prefectureIds.push(reader.string());
          break;
        case /* string subject */ 14:
          message.subject = reader.string();
          break;
        case /* bool use_csv */ 15:
          message.useCsv = reader.bool();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: BroadcastMessageResource,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* string id = 1; */
    if (message.id !== "")
      writer.tag(1, WireType.LengthDelimited).string(message.id);
    /* auto_reserve.admin.broadcast_message.BroadcastMessageResource.Status status = 2; */
    if (message.status !== 0)
      writer.tag(2, WireType.Varint).int32(message.status);
    /* auto_reserve.admin.broadcast_message.SenderResource sender = 3; */
    if (message.sender)
      SenderResource.internalBinaryWrite(
        message.sender,
        writer.tag(3, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* google.protobuf.Timestamp delivery_at = 4; */
    if (message.deliveryAt)
      Timestamp.internalBinaryWrite(
        message.deliveryAt,
        writer.tag(4, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* google.protobuf.Timestamp started_at = 5; */
    if (message.startedAt)
      Timestamp.internalBinaryWrite(
        message.startedAt,
        writer.tag(5, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* google.protobuf.Timestamp finished_at = 6; */
    if (message.finishedAt)
      Timestamp.internalBinaryWrite(
        message.finishedAt,
        writer.tag(6, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* int64 number_of_sent = 7; */
    if (message.numberOfSent !== 0)
      writer.tag(7, WireType.Varint).int64(message.numberOfSent);
    /* int64 success_count = 8; */
    if (message.successCount !== 0)
      writer.tag(8, WireType.Varint).int64(message.successCount);
    /* int64 failure_count = 9; */
    if (message.failureCount !== 0)
      writer.tag(9, WireType.Varint).int64(message.failureCount);
    /* bool test_delivery = 10; */
    if (message.testDelivery !== false)
      writer.tag(10, WireType.Varint).bool(message.testDelivery);
    /* google.protobuf.Timestamp created_at = 11; */
    if (message.createdAt)
      Timestamp.internalBinaryWrite(
        message.createdAt,
        writer.tag(11, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* repeated auto_reserve.admin.message_content.MessageContentResource message_contents = 12; */
    for (let i = 0; i < message.messageContents.length; i++)
      MessageContentResource.internalBinaryWrite(
        message.messageContents[i],
        writer.tag(12, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* repeated string prefecture_ids = 13; */
    for (let i = 0; i < message.prefectureIds.length; i++)
      writer.tag(13, WireType.LengthDelimited).string(message.prefectureIds[i]);
    /* string subject = 14; */
    if (message.subject !== "")
      writer.tag(14, WireType.LengthDelimited).string(message.subject);
    /* bool use_csv = 15; */
    if (message.useCsv !== false)
      writer.tag(15, WireType.Varint).bool(message.useCsv);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.admin.broadcast_message.BroadcastMessageResource
 */
export const BroadcastMessageResource = new BroadcastMessageResource$Type();
