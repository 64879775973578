// @generated by protobuf-ts 2.8.1 with parameter long_type_number,generate_dependencies
// @generated from protobuf file "auto_reserve/admin/broadcast_message/broadcast_message_service.proto" (package "auto_reserve.admin.broadcast_message", syntax proto3)
// tslint:disable
import { Empty } from "../../../google/protobuf/empty";
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { BroadcastMessageResource } from "./broadcast_message_resource";
/**
 * @generated from protobuf message auto_reserve.admin.broadcast_message.ListRequest
 */
export interface ListRequest {
  /**
   * @generated from protobuf field: int64 page = 1;
   */
  page: number;
  /**
   * @generated from protobuf field: int64 per = 2;
   */
  per: number;
}
/**
 * @generated from protobuf message auto_reserve.admin.broadcast_message.ListResponse
 */
export interface ListResponse {
  /**
   * @generated from protobuf field: repeated auto_reserve.admin.broadcast_message.BroadcastMessageResource broadcast_messages = 1;
   */
  broadcastMessages: BroadcastMessageResource[];
  /**
   * @generated from protobuf field: int64 total_count = 2;
   */
  totalCount: number;
  /**
   * @generated from protobuf field: int64 current_page = 3;
   */
  currentPage: number;
  /**
   * @generated from protobuf field: int64 total_pages = 4;
   */
  totalPages: number;
}
/**
 * @generated from protobuf message auto_reserve.admin.broadcast_message.GetRequest
 */
export interface GetRequest {
  /**
   * @generated from protobuf field: string id = 1;
   */
  id: string;
}
/**
 * @generated from protobuf message auto_reserve.admin.broadcast_message.CreateRequest
 */
export interface CreateRequest {
  /**
   * @generated from protobuf field: string delivery_at = 1;
   */
  deliveryAt: string;
  /**
   * @generated from protobuf field: repeated auto_reserve.admin.broadcast_message.Contents contents = 2;
   */
  contents: Contents[];
  /**
   * @generated from protobuf field: bool test_delivery = 3;
   */
  testDelivery: boolean;
  /**
   * @generated from protobuf field: repeated string prefecture_ids = 4;
   */
  prefectureIds: string[];
  /**
   * @generated from protobuf field: string subject = 5;
   */
  subject: string;
  /**
   * @generated from protobuf field: bool use_csv = 6;
   */
  useCsv: boolean;
  /**
   * @generated from protobuf field: bytes user_ids = 7;
   */
  userIds: Uint8Array;
}
/**
 * @generated from protobuf message auto_reserve.admin.broadcast_message.UpdateRequest
 */
export interface UpdateRequest {
  /**
   * @generated from protobuf field: string id = 1;
   */
  id: string;
  /**
   * @generated from protobuf field: string delivery_at = 2;
   */
  deliveryAt: string;
  /**
   * @generated from protobuf field: repeated auto_reserve.admin.broadcast_message.Contents contents = 3;
   */
  contents: Contents[];
  /**
   * @generated from protobuf field: bool test_delivery = 4;
   */
  testDelivery: boolean;
  /**
   * @generated from protobuf field: repeated string prefecture_ids = 5;
   */
  prefectureIds: string[];
  /**
   * @generated from protobuf field: string subject = 6;
   */
  subject: string;
  /**
   * @generated from protobuf field: bool use_csv = 7;
   */
  useCsv: boolean;
  /**
   * @generated from protobuf field: bytes user_ids = 8;
   */
  userIds: Uint8Array;
}
/**
 * @generated from protobuf message auto_reserve.admin.broadcast_message.DestroyRequest
 */
export interface DestroyRequest {
  /**
   * @generated from protobuf field: string id = 1;
   */
  id: string;
}
/**
 * @generated from protobuf message auto_reserve.admin.broadcast_message.PreviewRequest
 */
export interface PreviewRequest {
  /**
   * @generated from protobuf field: string delivery_at = 1;
   */
  deliveryAt: string;
  /**
   * @generated from protobuf field: repeated auto_reserve.admin.broadcast_message.Contents contents = 2;
   */
  contents: Contents[];
}
/**
 * @generated from protobuf message auto_reserve.admin.broadcast_message.NumberOfCustomersRequest
 */
export interface NumberOfCustomersRequest {
  /**
   * @generated from protobuf field: bool test_delivery = 1;
   */
  testDelivery: boolean;
  /**
   * @generated from protobuf field: repeated string prefecture_ids = 2;
   */
  prefectureIds: string[];
}
/**
 * @generated from protobuf message auto_reserve.admin.broadcast_message.PreviewResponse
 */
export interface PreviewResponse {
  /**
   * @generated from protobuf field: string title = 1;
   */
  title: string;
  /**
   * @generated from protobuf field: string body_html = 2;
   */
  bodyHtml: string;
}
/**
 * @generated from protobuf message auto_reserve.admin.broadcast_message.NumberOfCustomersResponse
 */
export interface NumberOfCustomersResponse {
  /**
   * @generated from protobuf field: int64 number_of_customers = 1;
   */
  numberOfCustomers: number;
}
/**
 * @generated from protobuf message auto_reserve.admin.broadcast_message.ContentsButton
 */
export interface ContentsButton {
  /**
   * @generated from protobuf field: string text = 1;
   */
  text: string;
  /**
   * @generated from protobuf field: string url = 2;
   */
  url: string;
}
/**
 * @generated from protobuf message auto_reserve.admin.broadcast_message.Contents
 */
export interface Contents {
  /**
   * @generated from protobuf field: string body = 1;
   */
  body: string;
  /**
   * @generated from protobuf field: string image = 2;
   */
  image: string;
  /**
   * @generated from protobuf field: auto_reserve.admin.broadcast_message.ContentsButton button = 3;
   */
  button?: ContentsButton;
}
// @generated message type with reflection information, may provide speed optimized methods
class ListRequest$Type extends MessageType<ListRequest> {
  constructor() {
    super("auto_reserve.admin.broadcast_message.ListRequest", [
      {
        no: 1,
        name: "page",
        kind: "scalar",
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
      {
        no: 2,
        name: "per",
        kind: "scalar",
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
    ]);
  }
  create(value?: PartialMessage<ListRequest>): ListRequest {
    const message = { page: 0, per: 0 };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<ListRequest>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: ListRequest,
  ): ListRequest {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* int64 page */ 1:
          message.page = reader.int64().toNumber();
          break;
        case /* int64 per */ 2:
          message.per = reader.int64().toNumber();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: ListRequest,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* int64 page = 1; */
    if (message.page !== 0) writer.tag(1, WireType.Varint).int64(message.page);
    /* int64 per = 2; */
    if (message.per !== 0) writer.tag(2, WireType.Varint).int64(message.per);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.admin.broadcast_message.ListRequest
 */
export const ListRequest = new ListRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListResponse$Type extends MessageType<ListResponse> {
  constructor() {
    super("auto_reserve.admin.broadcast_message.ListResponse", [
      {
        no: 1,
        name: "broadcast_messages",
        kind: "message",
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => BroadcastMessageResource,
      },
      {
        no: 2,
        name: "total_count",
        kind: "scalar",
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
      {
        no: 3,
        name: "current_page",
        kind: "scalar",
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
      {
        no: 4,
        name: "total_pages",
        kind: "scalar",
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
    ]);
  }
  create(value?: PartialMessage<ListResponse>): ListResponse {
    const message = {
      broadcastMessages: [],
      totalCount: 0,
      currentPage: 0,
      totalPages: 0,
    };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<ListResponse>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: ListResponse,
  ): ListResponse {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* repeated auto_reserve.admin.broadcast_message.BroadcastMessageResource broadcast_messages */ 1:
          message.broadcastMessages.push(
            BroadcastMessageResource.internalBinaryRead(
              reader,
              reader.uint32(),
              options,
            ),
          );
          break;
        case /* int64 total_count */ 2:
          message.totalCount = reader.int64().toNumber();
          break;
        case /* int64 current_page */ 3:
          message.currentPage = reader.int64().toNumber();
          break;
        case /* int64 total_pages */ 4:
          message.totalPages = reader.int64().toNumber();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: ListResponse,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* repeated auto_reserve.admin.broadcast_message.BroadcastMessageResource broadcast_messages = 1; */
    for (let i = 0; i < message.broadcastMessages.length; i++)
      BroadcastMessageResource.internalBinaryWrite(
        message.broadcastMessages[i],
        writer.tag(1, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* int64 total_count = 2; */
    if (message.totalCount !== 0)
      writer.tag(2, WireType.Varint).int64(message.totalCount);
    /* int64 current_page = 3; */
    if (message.currentPage !== 0)
      writer.tag(3, WireType.Varint).int64(message.currentPage);
    /* int64 total_pages = 4; */
    if (message.totalPages !== 0)
      writer.tag(4, WireType.Varint).int64(message.totalPages);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.admin.broadcast_message.ListResponse
 */
export const ListResponse = new ListResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetRequest$Type extends MessageType<GetRequest> {
  constructor() {
    super("auto_reserve.admin.broadcast_message.GetRequest", [
      { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
    ]);
  }
  create(value?: PartialMessage<GetRequest>): GetRequest {
    const message = { id: "" };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<GetRequest>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: GetRequest,
  ): GetRequest {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* string id */ 1:
          message.id = reader.string();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: GetRequest,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* string id = 1; */
    if (message.id !== "")
      writer.tag(1, WireType.LengthDelimited).string(message.id);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.admin.broadcast_message.GetRequest
 */
export const GetRequest = new GetRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateRequest$Type extends MessageType<CreateRequest> {
  constructor() {
    super("auto_reserve.admin.broadcast_message.CreateRequest", [
      {
        no: 1,
        name: "delivery_at",
        kind: "scalar",
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: "contents",
        kind: "message",
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => Contents,
      },
      {
        no: 3,
        name: "test_delivery",
        kind: "scalar",
        T: 8 /*ScalarType.BOOL*/,
      },
      {
        no: 4,
        name: "prefecture_ids",
        kind: "scalar",
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 5, name: "subject", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
      { no: 6, name: "use_csv", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
      { no: 7, name: "user_ids", kind: "scalar", T: 12 /*ScalarType.BYTES*/ },
    ]);
  }
  create(value?: PartialMessage<CreateRequest>): CreateRequest {
    const message = {
      deliveryAt: "",
      contents: [],
      testDelivery: false,
      prefectureIds: [],
      subject: "",
      useCsv: false,
      userIds: new Uint8Array(0),
    };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<CreateRequest>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: CreateRequest,
  ): CreateRequest {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* string delivery_at */ 1:
          message.deliveryAt = reader.string();
          break;
        case /* repeated auto_reserve.admin.broadcast_message.Contents contents */ 2:
          message.contents.push(
            Contents.internalBinaryRead(reader, reader.uint32(), options),
          );
          break;
        case /* bool test_delivery */ 3:
          message.testDelivery = reader.bool();
          break;
        case /* repeated string prefecture_ids */ 4:
          message.prefectureIds.push(reader.string());
          break;
        case /* string subject */ 5:
          message.subject = reader.string();
          break;
        case /* bool use_csv */ 6:
          message.useCsv = reader.bool();
          break;
        case /* bytes user_ids */ 7:
          message.userIds = reader.bytes();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: CreateRequest,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* string delivery_at = 1; */
    if (message.deliveryAt !== "")
      writer.tag(1, WireType.LengthDelimited).string(message.deliveryAt);
    /* repeated auto_reserve.admin.broadcast_message.Contents contents = 2; */
    for (let i = 0; i < message.contents.length; i++)
      Contents.internalBinaryWrite(
        message.contents[i],
        writer.tag(2, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* bool test_delivery = 3; */
    if (message.testDelivery !== false)
      writer.tag(3, WireType.Varint).bool(message.testDelivery);
    /* repeated string prefecture_ids = 4; */
    for (let i = 0; i < message.prefectureIds.length; i++)
      writer.tag(4, WireType.LengthDelimited).string(message.prefectureIds[i]);
    /* string subject = 5; */
    if (message.subject !== "")
      writer.tag(5, WireType.LengthDelimited).string(message.subject);
    /* bool use_csv = 6; */
    if (message.useCsv !== false)
      writer.tag(6, WireType.Varint).bool(message.useCsv);
    /* bytes user_ids = 7; */
    if (message.userIds.length)
      writer.tag(7, WireType.LengthDelimited).bytes(message.userIds);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.admin.broadcast_message.CreateRequest
 */
export const CreateRequest = new CreateRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateRequest$Type extends MessageType<UpdateRequest> {
  constructor() {
    super("auto_reserve.admin.broadcast_message.UpdateRequest", [
      { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: "delivery_at",
        kind: "scalar",
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 3,
        name: "contents",
        kind: "message",
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => Contents,
      },
      {
        no: 4,
        name: "test_delivery",
        kind: "scalar",
        T: 8 /*ScalarType.BOOL*/,
      },
      {
        no: 5,
        name: "prefecture_ids",
        kind: "scalar",
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 6, name: "subject", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
      { no: 7, name: "use_csv", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
      { no: 8, name: "user_ids", kind: "scalar", T: 12 /*ScalarType.BYTES*/ },
    ]);
  }
  create(value?: PartialMessage<UpdateRequest>): UpdateRequest {
    const message = {
      id: "",
      deliveryAt: "",
      contents: [],
      testDelivery: false,
      prefectureIds: [],
      subject: "",
      useCsv: false,
      userIds: new Uint8Array(0),
    };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<UpdateRequest>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: UpdateRequest,
  ): UpdateRequest {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* string id */ 1:
          message.id = reader.string();
          break;
        case /* string delivery_at */ 2:
          message.deliveryAt = reader.string();
          break;
        case /* repeated auto_reserve.admin.broadcast_message.Contents contents */ 3:
          message.contents.push(
            Contents.internalBinaryRead(reader, reader.uint32(), options),
          );
          break;
        case /* bool test_delivery */ 4:
          message.testDelivery = reader.bool();
          break;
        case /* repeated string prefecture_ids */ 5:
          message.prefectureIds.push(reader.string());
          break;
        case /* string subject */ 6:
          message.subject = reader.string();
          break;
        case /* bool use_csv */ 7:
          message.useCsv = reader.bool();
          break;
        case /* bytes user_ids */ 8:
          message.userIds = reader.bytes();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: UpdateRequest,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* string id = 1; */
    if (message.id !== "")
      writer.tag(1, WireType.LengthDelimited).string(message.id);
    /* string delivery_at = 2; */
    if (message.deliveryAt !== "")
      writer.tag(2, WireType.LengthDelimited).string(message.deliveryAt);
    /* repeated auto_reserve.admin.broadcast_message.Contents contents = 3; */
    for (let i = 0; i < message.contents.length; i++)
      Contents.internalBinaryWrite(
        message.contents[i],
        writer.tag(3, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* bool test_delivery = 4; */
    if (message.testDelivery !== false)
      writer.tag(4, WireType.Varint).bool(message.testDelivery);
    /* repeated string prefecture_ids = 5; */
    for (let i = 0; i < message.prefectureIds.length; i++)
      writer.tag(5, WireType.LengthDelimited).string(message.prefectureIds[i]);
    /* string subject = 6; */
    if (message.subject !== "")
      writer.tag(6, WireType.LengthDelimited).string(message.subject);
    /* bool use_csv = 7; */
    if (message.useCsv !== false)
      writer.tag(7, WireType.Varint).bool(message.useCsv);
    /* bytes user_ids = 8; */
    if (message.userIds.length)
      writer.tag(8, WireType.LengthDelimited).bytes(message.userIds);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.admin.broadcast_message.UpdateRequest
 */
export const UpdateRequest = new UpdateRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DestroyRequest$Type extends MessageType<DestroyRequest> {
  constructor() {
    super("auto_reserve.admin.broadcast_message.DestroyRequest", [
      { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
    ]);
  }
  create(value?: PartialMessage<DestroyRequest>): DestroyRequest {
    const message = { id: "" };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<DestroyRequest>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: DestroyRequest,
  ): DestroyRequest {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* string id */ 1:
          message.id = reader.string();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: DestroyRequest,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* string id = 1; */
    if (message.id !== "")
      writer.tag(1, WireType.LengthDelimited).string(message.id);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.admin.broadcast_message.DestroyRequest
 */
export const DestroyRequest = new DestroyRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PreviewRequest$Type extends MessageType<PreviewRequest> {
  constructor() {
    super("auto_reserve.admin.broadcast_message.PreviewRequest", [
      {
        no: 1,
        name: "delivery_at",
        kind: "scalar",
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: "contents",
        kind: "message",
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => Contents,
      },
    ]);
  }
  create(value?: PartialMessage<PreviewRequest>): PreviewRequest {
    const message = { deliveryAt: "", contents: [] };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<PreviewRequest>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: PreviewRequest,
  ): PreviewRequest {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* string delivery_at */ 1:
          message.deliveryAt = reader.string();
          break;
        case /* repeated auto_reserve.admin.broadcast_message.Contents contents */ 2:
          message.contents.push(
            Contents.internalBinaryRead(reader, reader.uint32(), options),
          );
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: PreviewRequest,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* string delivery_at = 1; */
    if (message.deliveryAt !== "")
      writer.tag(1, WireType.LengthDelimited).string(message.deliveryAt);
    /* repeated auto_reserve.admin.broadcast_message.Contents contents = 2; */
    for (let i = 0; i < message.contents.length; i++)
      Contents.internalBinaryWrite(
        message.contents[i],
        writer.tag(2, WireType.LengthDelimited).fork(),
        options,
      ).join();
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.admin.broadcast_message.PreviewRequest
 */
export const PreviewRequest = new PreviewRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class NumberOfCustomersRequest$Type extends MessageType<NumberOfCustomersRequest> {
  constructor() {
    super("auto_reserve.admin.broadcast_message.NumberOfCustomersRequest", [
      {
        no: 1,
        name: "test_delivery",
        kind: "scalar",
        T: 8 /*ScalarType.BOOL*/,
      },
      {
        no: 2,
        name: "prefecture_ids",
        kind: "scalar",
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
  create(
    value?: PartialMessage<NumberOfCustomersRequest>,
  ): NumberOfCustomersRequest {
    const message = { testDelivery: false, prefectureIds: [] };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<NumberOfCustomersRequest>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: NumberOfCustomersRequest,
  ): NumberOfCustomersRequest {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* bool test_delivery */ 1:
          message.testDelivery = reader.bool();
          break;
        case /* repeated string prefecture_ids */ 2:
          message.prefectureIds.push(reader.string());
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: NumberOfCustomersRequest,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* bool test_delivery = 1; */
    if (message.testDelivery !== false)
      writer.tag(1, WireType.Varint).bool(message.testDelivery);
    /* repeated string prefecture_ids = 2; */
    for (let i = 0; i < message.prefectureIds.length; i++)
      writer.tag(2, WireType.LengthDelimited).string(message.prefectureIds[i]);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.admin.broadcast_message.NumberOfCustomersRequest
 */
export const NumberOfCustomersRequest = new NumberOfCustomersRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PreviewResponse$Type extends MessageType<PreviewResponse> {
  constructor() {
    super("auto_reserve.admin.broadcast_message.PreviewResponse", [
      { no: 1, name: "title", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: "body_html", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
    ]);
  }
  create(value?: PartialMessage<PreviewResponse>): PreviewResponse {
    const message = { title: "", bodyHtml: "" };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<PreviewResponse>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: PreviewResponse,
  ): PreviewResponse {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* string title */ 1:
          message.title = reader.string();
          break;
        case /* string body_html */ 2:
          message.bodyHtml = reader.string();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: PreviewResponse,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* string title = 1; */
    if (message.title !== "")
      writer.tag(1, WireType.LengthDelimited).string(message.title);
    /* string body_html = 2; */
    if (message.bodyHtml !== "")
      writer.tag(2, WireType.LengthDelimited).string(message.bodyHtml);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.admin.broadcast_message.PreviewResponse
 */
export const PreviewResponse = new PreviewResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class NumberOfCustomersResponse$Type extends MessageType<NumberOfCustomersResponse> {
  constructor() {
    super("auto_reserve.admin.broadcast_message.NumberOfCustomersResponse", [
      {
        no: 1,
        name: "number_of_customers",
        kind: "scalar",
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
    ]);
  }
  create(
    value?: PartialMessage<NumberOfCustomersResponse>,
  ): NumberOfCustomersResponse {
    const message = { numberOfCustomers: 0 };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<NumberOfCustomersResponse>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: NumberOfCustomersResponse,
  ): NumberOfCustomersResponse {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* int64 number_of_customers */ 1:
          message.numberOfCustomers = reader.int64().toNumber();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: NumberOfCustomersResponse,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* int64 number_of_customers = 1; */
    if (message.numberOfCustomers !== 0)
      writer.tag(1, WireType.Varint).int64(message.numberOfCustomers);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.admin.broadcast_message.NumberOfCustomersResponse
 */
export const NumberOfCustomersResponse = new NumberOfCustomersResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ContentsButton$Type extends MessageType<ContentsButton> {
  constructor() {
    super("auto_reserve.admin.broadcast_message.ContentsButton", [
      { no: 1, name: "text", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: "url", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
    ]);
  }
  create(value?: PartialMessage<ContentsButton>): ContentsButton {
    const message = { text: "", url: "" };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<ContentsButton>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: ContentsButton,
  ): ContentsButton {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* string text */ 1:
          message.text = reader.string();
          break;
        case /* string url */ 2:
          message.url = reader.string();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: ContentsButton,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* string text = 1; */
    if (message.text !== "")
      writer.tag(1, WireType.LengthDelimited).string(message.text);
    /* string url = 2; */
    if (message.url !== "")
      writer.tag(2, WireType.LengthDelimited).string(message.url);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.admin.broadcast_message.ContentsButton
 */
export const ContentsButton = new ContentsButton$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Contents$Type extends MessageType<Contents> {
  constructor() {
    super("auto_reserve.admin.broadcast_message.Contents", [
      { no: 1, name: "body", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: "image", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: "button", kind: "message", T: () => ContentsButton },
    ]);
  }
  create(value?: PartialMessage<Contents>): Contents {
    const message = { body: "", image: "" };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<Contents>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: Contents,
  ): Contents {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* string body */ 1:
          message.body = reader.string();
          break;
        case /* string image */ 2:
          message.image = reader.string();
          break;
        case /* auto_reserve.admin.broadcast_message.ContentsButton button */ 3:
          message.button = ContentsButton.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.button,
          );
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: Contents,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* string body = 1; */
    if (message.body !== "")
      writer.tag(1, WireType.LengthDelimited).string(message.body);
    /* string image = 2; */
    if (message.image !== "")
      writer.tag(2, WireType.LengthDelimited).string(message.image);
    /* auto_reserve.admin.broadcast_message.ContentsButton button = 3; */
    if (message.button)
      ContentsButton.internalBinaryWrite(
        message.button,
        writer.tag(3, WireType.LengthDelimited).fork(),
        options,
      ).join();
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.admin.broadcast_message.Contents
 */
export const Contents = new Contents$Type();
/**
 * @generated ServiceType for protobuf service auto_reserve.admin.broadcast_message.BroadcastMessage
 */
export const BroadcastMessage = new ServiceType(
  "auto_reserve.admin.broadcast_message.BroadcastMessage",
  [
    { name: "List", options: {}, I: ListRequest, O: ListResponse },
    { name: "Get", options: {}, I: GetRequest, O: BroadcastMessageResource },
    {
      name: "Create",
      options: {},
      I: CreateRequest,
      O: BroadcastMessageResource,
    },
    {
      name: "Update",
      options: {},
      I: UpdateRequest,
      O: BroadcastMessageResource,
    },
    { name: "Destroy", options: {}, I: DestroyRequest, O: Empty },
    { name: "Preview", options: {}, I: PreviewRequest, O: PreviewResponse },
    {
      name: "NumberOfCustomers",
      options: {},
      I: NumberOfCustomersRequest,
      O: NumberOfCustomersResponse,
    },
  ],
);
