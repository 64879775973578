import { ReplaceAll } from '../types/utils'
import { initDayjs } from './dayjs'

let locale: SupportedLocale | undefined

export type SupportedLocale =
  | 'ja'
  | 'en'
  | 'ko'
  | 'zh-CN'
  | 'zh-TW'
  | 'zh-HK'
  | 'th'
export type ApiResponseLocale = Lowercase<ReplaceAll<SupportedLocale, '-', '_'>>

/* eslint-disable ar-i18n/require-translation-ja */
export const localeData: {
  [key in SupportedLocale]: {
    label: string
    subdomain?: string | null
  }
} = {
  ja: {
    label: '日本語',
    subdomain: null,
  },
  en: { label: 'English', subdomain: null },
  ko: {
    label: '한국어',
    subdomain: null,
  },
  'zh-CN': { label: '简体中文', subdomain: 'cn' },
  'zh-TW': {
    label: '繁體中文（台灣）',
    subdomain: 'tw',
  },
  'zh-HK': {
    label: '繁體中文（香港）',
    subdomain: 'hk',
  },
  th: {
    label: 'ภาษาไทย',
  },
} as const

export const supportedLocales = Object.keys(localeData) as SupportedLocale[]

/* eslint-enable ar-i18n/require-translation-ja */
export function getLocale() {
  if (locale === undefined) {
    throw new Error('You must call initLocale() before calling getLocale()')
  }
  return locale
}

export function getApiResponseLocale() {
  return getLocale().replace('-', '_').toLowerCase() as ApiResponseLocale
}

// modules/i18nのinitI18nにって間接的に呼び出される
export function initLocale(value: SupportedLocale) {
  locale = value
  initDayjs(locale)
}

export function getStripeLocale() {
  const locale = getLocale()
  if (locale === 'zh-CN') return 'zh'
  return locale
}

export function getCountry() {
  const [, country] = getLocale().split('-')
  return country || 'JP'
}

export function isZh() {
  return getLocale().startsWith('zh')
}

export function isJa() {
  return getLocale() === 'ja'
}
