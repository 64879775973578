import React from 'react'
import PageHeader from 'components/Shared/PageHeader'
import { Button } from '@mui/material'
import { useNavigate } from 'react-router'
import DataGrid from 'components/Shared/DataGrid'
import dayjs from 'dayjs'
import { getFeeStatusName } from 'models/RestaurantReservationBookContract'
import { restaurantService } from 'models/Restaurant'

export function RestaurantsReservationBookContracts({
  restaurantId,
}: {
  restaurantId?: string
}) {
  const navigate = useNavigate()
  const { data } = restaurantService.useGetReservationBookContracts({
    id: +restaurantId!,
  })

  return (
    <PageHeader
      title="予約台帳"
      extra={[
        <Button
          key="new"
          variant="contained"
          onClick={() =>
            navigate(
              `/restaurants/${restaurantId}/restaurant_reservation_book_contracts/new`
            )
          }
        >
          作成
        </Button>,
      ]}
    >
      <DataGrid
        data={data?.restaurantReservationBookContracts}
        // TODO: totalCountがない
        // totalCount={data?.totalCount ?? 0}
        columns={[
          {
            key: 'action',
            render: (restaurantReservationBookContract) => (
              <Button
                variant="outlined"
                onClick={() =>
                  navigate(
                    `/restaurant_reservation_book_contracts/${restaurantReservationBookContract.id}/edit`
                  )
                }
              >
                編集
              </Button>
            ),
          },
          {
            title: '期間',
            key: 'term',
            render: (restaurantReservationBookContract) =>
              `${
                restaurantReservationBookContract.startDate !== undefined
                  ? dayjs
                      .unix(restaurantReservationBookContract.startDate.seconds)
                      .format('YYYY/MM/DD')
                  : ''
              }~${
                restaurantReservationBookContract.endDate !== undefined
                  ? dayjs
                      .unix(restaurantReservationBookContract.endDate.seconds)
                      .format('YYYY/MM/DD')
                  : ''
              }`,
          },
          {
            title: '契約タイプ',
            key: 'feeStatus',
            render: (restaurantReservationBookContract) =>
              `${getFeeStatusName(
                restaurantReservationBookContract.feeStatus
              )}`,
          },
          {
            title: '月額費用',
            key: 'monthlyFee',
            render: (restaurantReservationBookContract) =>
              `${restaurantReservationBookContract.monthlyFee}円`,
          },
          {
            title: 'ランチ送客手数料',
            key: 'lunchCommissionFee',
            render: (restaurantReservationBookContract) =>
              `${restaurantReservationBookContract.lunchCommissionFee}円`,
          },
          {
            title: 'ディナー送客手数料',
            key: 'dinnerCommissionFee',
            render: (restaurantReservationBookContract) =>
              `${restaurantReservationBookContract.dinnerCommissionFee}円`,
          },
        ]}
        loading={data === undefined}
        rowKey={(restaurantReservationBookContract) =>
          `reservationBookContract${restaurantReservationBookContract.id}`
        }
        sx={{ minHeight: 400 }}
      />
    </PageHeader>
  )
}
