import invariant from 'tiny-invariant'

interface AuthConfig {
  onError: (error: unknown) => void
  useToken: () => string | null
}

let authConfig: AuthConfig | undefined
export function initAuthConfig(config: AuthConfig) {
  authConfig = config
}

export function onError(error: unknown) {
  invariant(
    authConfig !== undefined,
    'You must initialize config with initAuthConfig() before using onError()'
  )
  return authConfig.onError(error)
}

export function useToken() {
  invariant(
    authConfig !== undefined,
    'You must initialize config with initAuthConfig() before using useToken()'
  )
  return authConfig.useToken()
}
