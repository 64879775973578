import type { AspidaClient, BasicHeaders } from 'aspida'
import { dataToURLString } from 'aspida'
import type { Methods as Methods_19hlt8y } from './prefectures'
import type { Methods as Methods_1hgdzd1 } from './report/balance/activities'
import type { Methods as Methods_1qyu6m7 } from './report/balance/csvs/_reporting_category_name@string'
import type { Methods as Methods_ym6yx2 } from './restaurant/order_contracts'
import type { Methods as Methods_oc8r4s } from './restaurant/reservation_book_contracts'
import type { Methods as Methods_1c5cl52 } from './restaurants'

const api = <T>({ baseURL, fetch }: AspidaClient<T>) => {
  const prefix = (
    baseURL === undefined ? 'http://localhost:3001' : baseURL
  ).replace(/\/$/, '')
  const PATH0 = '/prefectures'
  const PATH1 = '/report/balance/activities'
  const PATH2 = '/report/balance/csvs'
  const PATH3 = '/restaurant/order_contracts'
  const PATH4 = '/restaurant/reservation_book_contracts'
  const PATH5 = '/restaurants'
  const GET = 'GET'
  const POST = 'POST'

  return {
    prefectures: {
      /**
       * @returns A list of prefectures
       */
      get: (
        option?:
          | {
              query?: Methods_19hlt8y['get']['query'] | undefined
              config?: T | undefined
            }
          | undefined
      ) =>
        fetch<
          Methods_19hlt8y['get']['resBody'],
          BasicHeaders,
          Methods_19hlt8y['get']['status']
        >(prefix, PATH0, GET, option).json(),
      /**
       * @returns A list of prefectures
       */
      $get: (
        option?:
          | {
              query?: Methods_19hlt8y['get']['query'] | undefined
              config?: T | undefined
            }
          | undefined
      ) =>
        fetch<
          Methods_19hlt8y['get']['resBody'],
          BasicHeaders,
          Methods_19hlt8y['get']['status']
        >(prefix, PATH0, GET, option)
          .json()
          .then((r) => r.body),
      $path: (
        option?:
          | {
              method?: 'get' | undefined
              query: Methods_19hlt8y['get']['query']
            }
          | undefined
      ) =>
        `${prefix}${PATH0}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
    },
    report: {
      balance: {
        activities: {
          /**
           * @returns Successful operation
           */
          get: (option: {
            query: Methods_1hgdzd1['get']['query']
            config?: T | undefined
          }) =>
            fetch<
              Methods_1hgdzd1['get']['resBody'],
              BasicHeaders,
              Methods_1hgdzd1['get']['status']
            >(prefix, PATH1, GET, option).json(),
          /**
           * @returns Successful operation
           */
          $get: (option: {
            query: Methods_1hgdzd1['get']['query']
            config?: T | undefined
          }) =>
            fetch<
              Methods_1hgdzd1['get']['resBody'],
              BasicHeaders,
              Methods_1hgdzd1['get']['status']
            >(prefix, PATH1, GET, option)
              .json()
              .then((r) => r.body),
          $path: (
            option?:
              | {
                  method?: 'get' | undefined
                  query: Methods_1hgdzd1['get']['query']
                }
              | undefined
          ) =>
            `${prefix}${PATH1}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
        },
        csvs: {
          _reporting_category_name: (val3: string) => {
            const prefix3 = `${PATH2}/${val3}`

            return {
              /**
               * @returns CSVファイルが正常にダウンロードされました。
               */
              get: (option: {
                query: Methods_1qyu6m7['get']['query']
                config?: T | undefined
              }) =>
                fetch<
                  Methods_1qyu6m7['get']['resBody'],
                  BasicHeaders,
                  Methods_1qyu6m7['get']['status']
                >(prefix, prefix3, GET, option).blob(),
              /**
               * @returns CSVファイルが正常にダウンロードされました。
               */
              $get: (option: {
                query: Methods_1qyu6m7['get']['query']
                config?: T | undefined
              }) =>
                fetch<
                  Methods_1qyu6m7['get']['resBody'],
                  BasicHeaders,
                  Methods_1qyu6m7['get']['status']
                >(prefix, prefix3, GET, option)
                  .blob()
                  .then((r) => r.body),
              $path: (
                option?:
                  | {
                      method?: 'get' | undefined
                      query: Methods_1qyu6m7['get']['query']
                    }
                  | undefined
              ) =>
                `${prefix}${prefix3}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
            }
          },
        },
      },
    },
    restaurant: {
      order_contracts: {
        get: (option?: { config?: T | undefined } | undefined) =>
          fetch<
            Methods_ym6yx2['get']['resBody'],
            BasicHeaders,
            Methods_ym6yx2['get']['status']
          >(prefix, PATH3, GET, option).json(),
        $get: (option?: { config?: T | undefined } | undefined) =>
          fetch<
            Methods_ym6yx2['get']['resBody'],
            BasicHeaders,
            Methods_ym6yx2['get']['status']
          >(prefix, PATH3, GET, option)
            .json()
            .then((r) => r.body),
        post: (option: {
          body: Methods_ym6yx2['post']['reqBody']
          config?: T | undefined
        }) =>
          fetch<
            Methods_ym6yx2['post']['resBody'],
            BasicHeaders,
            Methods_ym6yx2['post']['status']
          >(prefix, PATH3, POST, option).json(),
        $post: (option: {
          body: Methods_ym6yx2['post']['reqBody']
          config?: T | undefined
        }) =>
          fetch<
            Methods_ym6yx2['post']['resBody'],
            BasicHeaders,
            Methods_ym6yx2['post']['status']
          >(prefix, PATH3, POST, option)
            .json()
            .then((r) => r.body),
        $path: () => `${prefix}${PATH3}`,
      },
      reservation_book_contracts: {
        get: (option?: { config?: T | undefined } | undefined) =>
          fetch<
            Methods_oc8r4s['get']['resBody'],
            BasicHeaders,
            Methods_oc8r4s['get']['status']
          >(prefix, PATH4, GET, option).json(),
        $get: (option?: { config?: T | undefined } | undefined) =>
          fetch<
            Methods_oc8r4s['get']['resBody'],
            BasicHeaders,
            Methods_oc8r4s['get']['status']
          >(prefix, PATH4, GET, option)
            .json()
            .then((r) => r.body),
        post: (option: {
          body: Methods_oc8r4s['post']['reqBody']
          config?: T | undefined
        }) =>
          fetch<
            Methods_oc8r4s['post']['resBody'],
            BasicHeaders,
            Methods_oc8r4s['post']['status']
          >(prefix, PATH4, POST, option).json(),
        $post: (option: {
          body: Methods_oc8r4s['post']['reqBody']
          config?: T | undefined
        }) =>
          fetch<
            Methods_oc8r4s['post']['resBody'],
            BasicHeaders,
            Methods_oc8r4s['post']['status']
          >(prefix, PATH4, POST, option)
            .json()
            .then((r) => r.body),
        $path: () => `${prefix}${PATH4}`,
      },
    },
    restaurants: {
      /**
       * @returns A list of restaurants
       */
      get: (
        option?:
          | {
              query?: Methods_1c5cl52['get']['query'] | undefined
              config?: T | undefined
            }
          | undefined
      ) =>
        fetch<
          Methods_1c5cl52['get']['resBody'],
          BasicHeaders,
          Methods_1c5cl52['get']['status']
        >(prefix, PATH5, GET, option).json(),
      /**
       * @returns A list of restaurants
       */
      $get: (
        option?:
          | {
              query?: Methods_1c5cl52['get']['query'] | undefined
              config?: T | undefined
            }
          | undefined
      ) =>
        fetch<
          Methods_1c5cl52['get']['resBody'],
          BasicHeaders,
          Methods_1c5cl52['get']['status']
        >(prefix, PATH5, GET, option)
          .json()
          .then((r) => r.body),
      $path: (
        option?:
          | {
              method?: 'get' | undefined
              query: Methods_1c5cl52['get']['query']
            }
          | undefined
      ) =>
        `${prefix}${PATH5}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
    },
  }
}

export type ApiInstance = ReturnType<typeof api>
export default api
