// @generated by protobuf-ts 2.8.1 with parameter long_type_number,generate_dependencies
// @generated from protobuf file "auto_reserve/admin/owner/owner_resource.proto" (package "auto_reserve.admin.owner", syntax proto3)
// tslint:disable
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Timestamp } from "../../../google/protobuf/timestamp";
/**
 * @generated from protobuf message auto_reserve.admin.owner.OwnerResource
 */
export interface OwnerResource {
  /**
   * @generated from protobuf field: int64 id = 1;
   */
  id: number;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp created_at = 2;
   */
  createdAt?: Timestamp;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp updated_at = 3;
   */
  updatedAt?: Timestamp;
  /**
   * @generated from protobuf field: string name = 4;
   */
  name: string;
  /**
   * @generated from protobuf field: string username = 5;
   */
  username: string;
  /**
   * @generated from protobuf field: string robopay_customer_id = 6;
   */
  robopayCustomerId: string;
  /**
   * @generated from protobuf field: bool has_bank_account_transfer_document = 7;
   */
  hasBankAccountTransferDocument: boolean;
  /**
   * @generated from protobuf field: auto_reserve.admin.owner.OwnerResource.PayoutIntervalType payout_interval_type = 8;
   */
  payoutIntervalType: OwnerResource_PayoutIntervalType;
}
/**
 * @generated from protobuf enum auto_reserve.admin.owner.OwnerResource.PayoutIntervalType
 */
export enum OwnerResource_PayoutIntervalType {
  /**
   * @generated from protobuf enum value: MONTH = 0;
   */
  MONTH = 0,
  /**
   * @generated from protobuf enum value: TWO_WEEKS = 1;
   */
  TWO_WEEKS = 1,
}
// @generated message type with reflection information, may provide speed optimized methods
class OwnerResource$Type extends MessageType<OwnerResource> {
  constructor() {
    super("auto_reserve.admin.owner.OwnerResource", [
      {
        no: 1,
        name: "id",
        kind: "scalar",
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
      { no: 2, name: "created_at", kind: "message", T: () => Timestamp },
      { no: 3, name: "updated_at", kind: "message", T: () => Timestamp },
      { no: 4, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
      { no: 5, name: "username", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
      {
        no: 6,
        name: "robopay_customer_id",
        kind: "scalar",
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 7,
        name: "has_bank_account_transfer_document",
        kind: "scalar",
        T: 8 /*ScalarType.BOOL*/,
      },
      {
        no: 8,
        name: "payout_interval_type",
        kind: "enum",
        T: () => [
          "auto_reserve.admin.owner.OwnerResource.PayoutIntervalType",
          OwnerResource_PayoutIntervalType,
        ],
      },
    ]);
  }
  create(value?: PartialMessage<OwnerResource>): OwnerResource {
    const message = {
      id: 0,
      name: "",
      username: "",
      robopayCustomerId: "",
      hasBankAccountTransferDocument: false,
      payoutIntervalType: 0,
    };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<OwnerResource>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: OwnerResource,
  ): OwnerResource {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* int64 id */ 1:
          message.id = reader.int64().toNumber();
          break;
        case /* google.protobuf.Timestamp created_at */ 2:
          message.createdAt = Timestamp.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.createdAt,
          );
          break;
        case /* google.protobuf.Timestamp updated_at */ 3:
          message.updatedAt = Timestamp.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.updatedAt,
          );
          break;
        case /* string name */ 4:
          message.name = reader.string();
          break;
        case /* string username */ 5:
          message.username = reader.string();
          break;
        case /* string robopay_customer_id */ 6:
          message.robopayCustomerId = reader.string();
          break;
        case /* bool has_bank_account_transfer_document */ 7:
          message.hasBankAccountTransferDocument = reader.bool();
          break;
        case /* auto_reserve.admin.owner.OwnerResource.PayoutIntervalType payout_interval_type */ 8:
          message.payoutIntervalType = reader.int32();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: OwnerResource,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* int64 id = 1; */
    if (message.id !== 0) writer.tag(1, WireType.Varint).int64(message.id);
    /* google.protobuf.Timestamp created_at = 2; */
    if (message.createdAt)
      Timestamp.internalBinaryWrite(
        message.createdAt,
        writer.tag(2, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* google.protobuf.Timestamp updated_at = 3; */
    if (message.updatedAt)
      Timestamp.internalBinaryWrite(
        message.updatedAt,
        writer.tag(3, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* string name = 4; */
    if (message.name !== "")
      writer.tag(4, WireType.LengthDelimited).string(message.name);
    /* string username = 5; */
    if (message.username !== "")
      writer.tag(5, WireType.LengthDelimited).string(message.username);
    /* string robopay_customer_id = 6; */
    if (message.robopayCustomerId !== "")
      writer.tag(6, WireType.LengthDelimited).string(message.robopayCustomerId);
    /* bool has_bank_account_transfer_document = 7; */
    if (message.hasBankAccountTransferDocument !== false)
      writer
        .tag(7, WireType.Varint)
        .bool(message.hasBankAccountTransferDocument);
    /* auto_reserve.admin.owner.OwnerResource.PayoutIntervalType payout_interval_type = 8; */
    if (message.payoutIntervalType !== 0)
      writer.tag(8, WireType.Varint).int32(message.payoutIntervalType);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.admin.owner.OwnerResource
 */
export const OwnerResource = new OwnerResource$Type();
