import React, { useState } from 'react'
import { View } from 'react-native'
import { useMedia, FilterState } from 'models/Medium'
import { MediumList } from 'components/Medium/List'
import PageHeader from 'components/Shared/PageHeader'

export default function AffiliateMedia() {
  const [filters, setFilters] = useState<FilterState>({
    statuses: undefined,
  })

  const { media, mutate } = useMedia({
    status:
      filters.statuses !== undefined ? { value: filters.statuses } : undefined,
  })

  return (
    <View>
      <PageHeader title="メディア一覧">
        <MediumList
          media={media}
          onMediumUpdated={mutate}
          filters={filters}
          setFilters={setFilters}
        />
      </PageHeader>
    </View>
  )
}
