// @generated by protobuf-ts 2.8.1 with parameter long_type_number,generate_dependencies
// @generated from protobuf file "auto_reserve/admin/restaurant_image/restaurant_image_service.proto" (package "auto_reserve.admin.restaurant_image", syntax proto3)
// tslint:disable
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { RestaurantImage } from "./restaurant_image_service";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { DestroyResponse } from "./restaurant_image_service";
import type { DestroyRequest } from "./restaurant_image_service";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service auto_reserve.admin.restaurant_image.RestaurantImage
 */
export interface IRestaurantImageClient {
  /**
   * @generated from protobuf rpc: Destroy(auto_reserve.admin.restaurant_image.DestroyRequest) returns (auto_reserve.admin.restaurant_image.DestroyResponse);
   */
  destroy(
    input: DestroyRequest,
    options?: RpcOptions,
  ): UnaryCall<DestroyRequest, DestroyResponse>;
}
/**
 * @generated from protobuf service auto_reserve.admin.restaurant_image.RestaurantImage
 */
export class RestaurantImageClient
  implements IRestaurantImageClient, ServiceInfo
{
  typeName = RestaurantImage.typeName;
  methods = RestaurantImage.methods;
  options = RestaurantImage.options;
  constructor(private readonly _transport: RpcTransport) {}
  /**
   * @generated from protobuf rpc: Destroy(auto_reserve.admin.restaurant_image.DestroyRequest) returns (auto_reserve.admin.restaurant_image.DestroyResponse);
   */
  destroy(
    input: DestroyRequest,
    options?: RpcOptions,
  ): UnaryCall<DestroyRequest, DestroyResponse> {
    const method = this.methods[0],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<DestroyRequest, DestroyResponse>(
      "unary",
      this._transport,
      method,
      opt,
      input,
    );
  }
}
