// @generated by protobuf-ts 2.8.1 with parameter long_type_number,generate_dependencies
// @generated from protobuf file "auto_reserve/admin/message_content/message_content_resource.proto" (package "auto_reserve.admin.message_content", syntax proto3)
// tslint:disable
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Timestamp } from "../../../google/protobuf/timestamp";
/**
 * @generated from protobuf message auto_reserve.admin.message_content.ImageResource
 */
export interface ImageResource {
  /**
   * @generated from protobuf field: string url = 1;
   */
  url: string;
  /**
   * @generated from protobuf field: string url_resized = 2;
   */
  urlResized: string;
}
/**
 * @generated from protobuf message auto_reserve.admin.message_content.ButtonResource
 */
export interface ButtonResource {
  /**
   * @generated from protobuf field: string text = 1;
   */
  text: string;
  /**
   * @generated from protobuf field: string url = 2;
   */
  url: string;
}
/**
 * @generated from protobuf message auto_reserve.admin.message_content.MessageContentResource
 */
export interface MessageContentResource {
  /**
   * @generated from protobuf field: string id = 1;
   */
  id: string;
  /**
   * @generated from protobuf field: string body = 2;
   */
  body: string;
  /**
   * @generated from protobuf field: auto_reserve.admin.message_content.ImageResource image = 3;
   */
  image?: ImageResource;
  /**
   * @generated from protobuf field: auto_reserve.admin.message_content.ButtonResource button = 4;
   */
  button?: ButtonResource;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp created_at = 5;
   */
  createdAt?: Timestamp;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp updated_at = 6;
   */
  updatedAt?: Timestamp;
}
// @generated message type with reflection information, may provide speed optimized methods
class ImageResource$Type extends MessageType<ImageResource> {
  constructor() {
    super("auto_reserve.admin.message_content.ImageResource", [
      { no: 1, name: "url", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: "url_resized",
        kind: "scalar",
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
  create(value?: PartialMessage<ImageResource>): ImageResource {
    const message = { url: "", urlResized: "" };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<ImageResource>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: ImageResource,
  ): ImageResource {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* string url */ 1:
          message.url = reader.string();
          break;
        case /* string url_resized */ 2:
          message.urlResized = reader.string();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: ImageResource,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* string url = 1; */
    if (message.url !== "")
      writer.tag(1, WireType.LengthDelimited).string(message.url);
    /* string url_resized = 2; */
    if (message.urlResized !== "")
      writer.tag(2, WireType.LengthDelimited).string(message.urlResized);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.admin.message_content.ImageResource
 */
export const ImageResource = new ImageResource$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ButtonResource$Type extends MessageType<ButtonResource> {
  constructor() {
    super("auto_reserve.admin.message_content.ButtonResource", [
      { no: 1, name: "text", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: "url", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
    ]);
  }
  create(value?: PartialMessage<ButtonResource>): ButtonResource {
    const message = { text: "", url: "" };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<ButtonResource>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: ButtonResource,
  ): ButtonResource {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* string text */ 1:
          message.text = reader.string();
          break;
        case /* string url */ 2:
          message.url = reader.string();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: ButtonResource,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* string text = 1; */
    if (message.text !== "")
      writer.tag(1, WireType.LengthDelimited).string(message.text);
    /* string url = 2; */
    if (message.url !== "")
      writer.tag(2, WireType.LengthDelimited).string(message.url);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.admin.message_content.ButtonResource
 */
export const ButtonResource = new ButtonResource$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MessageContentResource$Type extends MessageType<MessageContentResource> {
  constructor() {
    super("auto_reserve.admin.message_content.MessageContentResource", [
      { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: "body", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: "image", kind: "message", T: () => ImageResource },
      { no: 4, name: "button", kind: "message", T: () => ButtonResource },
      { no: 5, name: "created_at", kind: "message", T: () => Timestamp },
      { no: 6, name: "updated_at", kind: "message", T: () => Timestamp },
    ]);
  }
  create(
    value?: PartialMessage<MessageContentResource>,
  ): MessageContentResource {
    const message = { id: "", body: "" };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<MessageContentResource>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: MessageContentResource,
  ): MessageContentResource {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* string id */ 1:
          message.id = reader.string();
          break;
        case /* string body */ 2:
          message.body = reader.string();
          break;
        case /* auto_reserve.admin.message_content.ImageResource image */ 3:
          message.image = ImageResource.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.image,
          );
          break;
        case /* auto_reserve.admin.message_content.ButtonResource button */ 4:
          message.button = ButtonResource.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.button,
          );
          break;
        case /* google.protobuf.Timestamp created_at */ 5:
          message.createdAt = Timestamp.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.createdAt,
          );
          break;
        case /* google.protobuf.Timestamp updated_at */ 6:
          message.updatedAt = Timestamp.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.updatedAt,
          );
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: MessageContentResource,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* string id = 1; */
    if (message.id !== "")
      writer.tag(1, WireType.LengthDelimited).string(message.id);
    /* string body = 2; */
    if (message.body !== "")
      writer.tag(2, WireType.LengthDelimited).string(message.body);
    /* auto_reserve.admin.message_content.ImageResource image = 3; */
    if (message.image)
      ImageResource.internalBinaryWrite(
        message.image,
        writer.tag(3, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* auto_reserve.admin.message_content.ButtonResource button = 4; */
    if (message.button)
      ButtonResource.internalBinaryWrite(
        message.button,
        writer.tag(4, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* google.protobuf.Timestamp created_at = 5; */
    if (message.createdAt)
      Timestamp.internalBinaryWrite(
        message.createdAt,
        writer.tag(5, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* google.protobuf.Timestamp updated_at = 6; */
    if (message.updatedAt)
      Timestamp.internalBinaryWrite(
        message.updatedAt,
        writer.tag(6, WireType.LengthDelimited).fork(),
        options,
      ).join();
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.admin.message_content.MessageContentResource
 */
export const MessageContentResource = new MessageContentResource$Type();
