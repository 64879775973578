// @generated by protobuf-ts 2.8.1 with parameter long_type_number,generate_dependencies
// @generated from protobuf file "auto_reserve/admin/user/user_resource.proto" (package "auto_reserve.admin.user", syntax proto3)
// tslint:disable
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Timestamp } from "../../../google/protobuf/timestamp";
/**
 * @generated from protobuf message auto_reserve.admin.user.InstagramUserResource
 */
export interface InstagramUserResource {
  /**
   * @generated from protobuf field: string id = 1;
   */
  id: string;
  /**
   * @generated from protobuf field: string instagram_id = 2;
   */
  instagramId: string;
  /**
   * @generated from protobuf field: string instagram_username = 3;
   */
  instagramUsername: string;
  /**
   * @generated from protobuf field: optional int64 instagram_followers_count = 4;
   */
  instagramFollowersCount?: number;
  /**
   * @generated from protobuf field: optional int64 instagram_posts_count = 5;
   */
  instagramPostsCount?: number;
  /**
   * @generated from protobuf field: bool sync_enabled = 6;
   */
  syncEnabled: boolean;
}
/**
 * @generated from protobuf message auto_reserve.admin.user.SerializedImage
 */
export interface SerializedImage {
  /**
   * @generated from protobuf field: string xl = 1;
   */
  xl: string;
  /**
   * @generated from protobuf field: string lg = 2;
   */
  lg: string;
  /**
   * @generated from protobuf field: string md = 3;
   */
  md: string;
  /**
   * @generated from protobuf field: string sm = 4;
   */
  sm: string;
}
/**
 * @generated from protobuf message auto_reserve.admin.user.UserResource
 */
export interface UserResource {
  /**
   * @generated from protobuf field: int64 id = 1;
   */
  id: number;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp created_at = 2;
   */
  createdAt?: Timestamp;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp updated_at = 3;
   */
  updatedAt?: Timestamp;
  /**
   * @generated from protobuf field: string name = 4;
   */
  name: string;
  /**
   * @generated from protobuf field: optional string email = 5;
   */
  email?: string;
  /**
   * @generated from protobuf field: string username = 6;
   */
  username: string;
  /**
   * @generated from protobuf field: optional string about = 7;
   */
  about?: string;
  /**
   * @generated from protobuf field: optional string phone_number = 8;
   */
  phoneNumber?: string;
  /**
   * @generated from protobuf field: auto_reserve.admin.user.UserResource.Role role = 9;
   */
  role: UserResource_Role;
  /**
   * @generated from protobuf field: auto_reserve.admin.user.UserResource.Status status = 10;
   */
  status: UserResource_Status;
  /**
   * @generated from protobuf field: auto_reserve.admin.user.InstagramUserResource instagram_user = 11;
   */
  instagramUser?: InstagramUserResource;
  /**
   * @generated from protobuf field: bool is_foodie = 12;
   */
  isFoodie: boolean;
  /**
   * @generated from protobuf field: auto_reserve.admin.user.SerializedImage serialized_image = 13;
   */
  serializedImage?: SerializedImage;
}
/**
 * @generated from protobuf enum auto_reserve.admin.user.UserResource.Role
 */
export enum UserResource_Role {
  /**
   * @generated from protobuf enum value: GENERAL = 0;
   */
  GENERAL = 0,
  /**
   * @generated from protobuf enum value: ADMIN = 1;
   */
  ADMIN = 1,
  /**
   * @generated from protobuf enum value: DEVELOPER = 2;
   */
  DEVELOPER = 2,
  /**
   * @generated from protobuf enum value: CS = 3;
   */
  CS = 3,
  /**
   * @generated from protobuf enum value: INTERNAL = 4;
   */
  INTERNAL = 4,
}
/**
 * @generated from protobuf enum auto_reserve.admin.user.UserResource.Status
 */
export enum UserResource_Status {
  /**
   * @generated from protobuf enum value: NORMAL = 0;
   */
  NORMAL = 0,
  /**
   * @generated from protobuf enum value: SUSPENDED = 1;
   */
  SUSPENDED = 1,
  /**
   * @generated from protobuf enum value: DISCARDED = 2;
   */
  DISCARDED = 2,
}
// @generated message type with reflection information, may provide speed optimized methods
class InstagramUserResource$Type extends MessageType<InstagramUserResource> {
  constructor() {
    super("auto_reserve.admin.user.InstagramUserResource", [
      { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: "instagram_id",
        kind: "scalar",
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 3,
        name: "instagram_username",
        kind: "scalar",
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 4,
        name: "instagram_followers_count",
        kind: "scalar",
        opt: true,
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
      {
        no: 5,
        name: "instagram_posts_count",
        kind: "scalar",
        opt: true,
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
      { no: 6, name: "sync_enabled", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
    ]);
  }
  create(value?: PartialMessage<InstagramUserResource>): InstagramUserResource {
    const message = {
      id: "",
      instagramId: "",
      instagramUsername: "",
      syncEnabled: false,
    };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<InstagramUserResource>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: InstagramUserResource,
  ): InstagramUserResource {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* string id */ 1:
          message.id = reader.string();
          break;
        case /* string instagram_id */ 2:
          message.instagramId = reader.string();
          break;
        case /* string instagram_username */ 3:
          message.instagramUsername = reader.string();
          break;
        case /* optional int64 instagram_followers_count */ 4:
          message.instagramFollowersCount = reader.int64().toNumber();
          break;
        case /* optional int64 instagram_posts_count */ 5:
          message.instagramPostsCount = reader.int64().toNumber();
          break;
        case /* bool sync_enabled */ 6:
          message.syncEnabled = reader.bool();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: InstagramUserResource,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* string id = 1; */
    if (message.id !== "")
      writer.tag(1, WireType.LengthDelimited).string(message.id);
    /* string instagram_id = 2; */
    if (message.instagramId !== "")
      writer.tag(2, WireType.LengthDelimited).string(message.instagramId);
    /* string instagram_username = 3; */
    if (message.instagramUsername !== "")
      writer.tag(3, WireType.LengthDelimited).string(message.instagramUsername);
    /* optional int64 instagram_followers_count = 4; */
    if (message.instagramFollowersCount !== undefined)
      writer.tag(4, WireType.Varint).int64(message.instagramFollowersCount);
    /* optional int64 instagram_posts_count = 5; */
    if (message.instagramPostsCount !== undefined)
      writer.tag(5, WireType.Varint).int64(message.instagramPostsCount);
    /* bool sync_enabled = 6; */
    if (message.syncEnabled !== false)
      writer.tag(6, WireType.Varint).bool(message.syncEnabled);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.admin.user.InstagramUserResource
 */
export const InstagramUserResource = new InstagramUserResource$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SerializedImage$Type extends MessageType<SerializedImage> {
  constructor() {
    super("auto_reserve.admin.user.SerializedImage", [
      { no: 1, name: "xl", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: "lg", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: "md", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
      { no: 4, name: "sm", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
    ]);
  }
  create(value?: PartialMessage<SerializedImage>): SerializedImage {
    const message = { xl: "", lg: "", md: "", sm: "" };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<SerializedImage>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: SerializedImage,
  ): SerializedImage {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* string xl */ 1:
          message.xl = reader.string();
          break;
        case /* string lg */ 2:
          message.lg = reader.string();
          break;
        case /* string md */ 3:
          message.md = reader.string();
          break;
        case /* string sm */ 4:
          message.sm = reader.string();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: SerializedImage,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* string xl = 1; */
    if (message.xl !== "")
      writer.tag(1, WireType.LengthDelimited).string(message.xl);
    /* string lg = 2; */
    if (message.lg !== "")
      writer.tag(2, WireType.LengthDelimited).string(message.lg);
    /* string md = 3; */
    if (message.md !== "")
      writer.tag(3, WireType.LengthDelimited).string(message.md);
    /* string sm = 4; */
    if (message.sm !== "")
      writer.tag(4, WireType.LengthDelimited).string(message.sm);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.admin.user.SerializedImage
 */
export const SerializedImage = new SerializedImage$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UserResource$Type extends MessageType<UserResource> {
  constructor() {
    super("auto_reserve.admin.user.UserResource", [
      {
        no: 1,
        name: "id",
        kind: "scalar",
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
      { no: 2, name: "created_at", kind: "message", T: () => Timestamp },
      { no: 3, name: "updated_at", kind: "message", T: () => Timestamp },
      { no: 4, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
      {
        no: 5,
        name: "email",
        kind: "scalar",
        opt: true,
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 6, name: "username", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
      {
        no: 7,
        name: "about",
        kind: "scalar",
        opt: true,
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 8,
        name: "phone_number",
        kind: "scalar",
        opt: true,
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 9,
        name: "role",
        kind: "enum",
        T: () => [
          "auto_reserve.admin.user.UserResource.Role",
          UserResource_Role,
        ],
      },
      {
        no: 10,
        name: "status",
        kind: "enum",
        T: () => [
          "auto_reserve.admin.user.UserResource.Status",
          UserResource_Status,
        ],
      },
      {
        no: 11,
        name: "instagram_user",
        kind: "message",
        T: () => InstagramUserResource,
      },
      { no: 12, name: "is_foodie", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
      {
        no: 13,
        name: "serialized_image",
        kind: "message",
        T: () => SerializedImage,
      },
    ]);
  }
  create(value?: PartialMessage<UserResource>): UserResource {
    const message = {
      id: 0,
      name: "",
      username: "",
      role: 0,
      status: 0,
      isFoodie: false,
    };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<UserResource>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: UserResource,
  ): UserResource {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* int64 id */ 1:
          message.id = reader.int64().toNumber();
          break;
        case /* google.protobuf.Timestamp created_at */ 2:
          message.createdAt = Timestamp.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.createdAt,
          );
          break;
        case /* google.protobuf.Timestamp updated_at */ 3:
          message.updatedAt = Timestamp.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.updatedAt,
          );
          break;
        case /* string name */ 4:
          message.name = reader.string();
          break;
        case /* optional string email */ 5:
          message.email = reader.string();
          break;
        case /* string username */ 6:
          message.username = reader.string();
          break;
        case /* optional string about */ 7:
          message.about = reader.string();
          break;
        case /* optional string phone_number */ 8:
          message.phoneNumber = reader.string();
          break;
        case /* auto_reserve.admin.user.UserResource.Role role */ 9:
          message.role = reader.int32();
          break;
        case /* auto_reserve.admin.user.UserResource.Status status */ 10:
          message.status = reader.int32();
          break;
        case /* auto_reserve.admin.user.InstagramUserResource instagram_user */ 11:
          message.instagramUser = InstagramUserResource.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.instagramUser,
          );
          break;
        case /* bool is_foodie */ 12:
          message.isFoodie = reader.bool();
          break;
        case /* auto_reserve.admin.user.SerializedImage serialized_image */ 13:
          message.serializedImage = SerializedImage.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.serializedImage,
          );
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: UserResource,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* int64 id = 1; */
    if (message.id !== 0) writer.tag(1, WireType.Varint).int64(message.id);
    /* google.protobuf.Timestamp created_at = 2; */
    if (message.createdAt)
      Timestamp.internalBinaryWrite(
        message.createdAt,
        writer.tag(2, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* google.protobuf.Timestamp updated_at = 3; */
    if (message.updatedAt)
      Timestamp.internalBinaryWrite(
        message.updatedAt,
        writer.tag(3, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* string name = 4; */
    if (message.name !== "")
      writer.tag(4, WireType.LengthDelimited).string(message.name);
    /* optional string email = 5; */
    if (message.email !== undefined)
      writer.tag(5, WireType.LengthDelimited).string(message.email);
    /* string username = 6; */
    if (message.username !== "")
      writer.tag(6, WireType.LengthDelimited).string(message.username);
    /* optional string about = 7; */
    if (message.about !== undefined)
      writer.tag(7, WireType.LengthDelimited).string(message.about);
    /* optional string phone_number = 8; */
    if (message.phoneNumber !== undefined)
      writer.tag(8, WireType.LengthDelimited).string(message.phoneNumber);
    /* auto_reserve.admin.user.UserResource.Role role = 9; */
    if (message.role !== 0) writer.tag(9, WireType.Varint).int32(message.role);
    /* auto_reserve.admin.user.UserResource.Status status = 10; */
    if (message.status !== 0)
      writer.tag(10, WireType.Varint).int32(message.status);
    /* auto_reserve.admin.user.InstagramUserResource instagram_user = 11; */
    if (message.instagramUser)
      InstagramUserResource.internalBinaryWrite(
        message.instagramUser,
        writer.tag(11, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* bool is_foodie = 12; */
    if (message.isFoodie !== false)
      writer.tag(12, WireType.Varint).bool(message.isFoodie);
    /* auto_reserve.admin.user.SerializedImage serialized_image = 13; */
    if (message.serializedImage)
      SerializedImage.internalBinaryWrite(
        message.serializedImage,
        writer.tag(13, WireType.LengthDelimited).fork(),
        options,
      ).join();
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.admin.user.UserResource
 */
export const UserResource = new UserResource$Type();
