// @generated by protobuf-ts 2.8.1 with parameter long_type_number,generate_dependencies
// @generated from protobuf file "auto_reserve/admin/review/review_service.proto" (package "auto_reserve.admin.review", syntax proto3)
// tslint:disable
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { Review } from "./review_service";
import type { ImportResponse } from "./review_service";
import type { ImportRequest } from "./review_service";
import type { DestroyResponse } from "./review_service";
import type { DestroyRequest } from "./review_service";
import type { UpdateRequest } from "./review_service";
import type { ReviewResource } from "./review_resource";
import type { GetRequest } from "./review_service";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { ListResponse } from "./review_service";
import type { ListRequest } from "./review_service";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service auto_reserve.admin.review.Review
 */
export interface IReviewClient {
  /**
   * @generated from protobuf rpc: List(auto_reserve.admin.review.ListRequest) returns (auto_reserve.admin.review.ListResponse);
   */
  list(
    input: ListRequest,
    options?: RpcOptions,
  ): UnaryCall<ListRequest, ListResponse>;
  /**
   * @generated from protobuf rpc: Get(auto_reserve.admin.review.GetRequest) returns (auto_reserve.admin.review.ReviewResource);
   */
  get(
    input: GetRequest,
    options?: RpcOptions,
  ): UnaryCall<GetRequest, ReviewResource>;
  /**
   * @generated from protobuf rpc: Update(auto_reserve.admin.review.UpdateRequest) returns (auto_reserve.admin.review.ReviewResource);
   */
  update(
    input: UpdateRequest,
    options?: RpcOptions,
  ): UnaryCall<UpdateRequest, ReviewResource>;
  /**
   * @generated from protobuf rpc: Destroy(auto_reserve.admin.review.DestroyRequest) returns (auto_reserve.admin.review.DestroyResponse);
   */
  destroy(
    input: DestroyRequest,
    options?: RpcOptions,
  ): UnaryCall<DestroyRequest, DestroyResponse>;
  /**
   * @generated from protobuf rpc: Import(auto_reserve.admin.review.ImportRequest) returns (auto_reserve.admin.review.ImportResponse);
   */
  import(
    input: ImportRequest,
    options?: RpcOptions,
  ): UnaryCall<ImportRequest, ImportResponse>;
}
/**
 * @generated from protobuf service auto_reserve.admin.review.Review
 */
export class ReviewClient implements IReviewClient, ServiceInfo {
  typeName = Review.typeName;
  methods = Review.methods;
  options = Review.options;
  constructor(private readonly _transport: RpcTransport) {}
  /**
   * @generated from protobuf rpc: List(auto_reserve.admin.review.ListRequest) returns (auto_reserve.admin.review.ListResponse);
   */
  list(
    input: ListRequest,
    options?: RpcOptions,
  ): UnaryCall<ListRequest, ListResponse> {
    const method = this.methods[0],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<ListRequest, ListResponse>(
      "unary",
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: Get(auto_reserve.admin.review.GetRequest) returns (auto_reserve.admin.review.ReviewResource);
   */
  get(
    input: GetRequest,
    options?: RpcOptions,
  ): UnaryCall<GetRequest, ReviewResource> {
    const method = this.methods[1],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<GetRequest, ReviewResource>(
      "unary",
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: Update(auto_reserve.admin.review.UpdateRequest) returns (auto_reserve.admin.review.ReviewResource);
   */
  update(
    input: UpdateRequest,
    options?: RpcOptions,
  ): UnaryCall<UpdateRequest, ReviewResource> {
    const method = this.methods[2],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<UpdateRequest, ReviewResource>(
      "unary",
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: Destroy(auto_reserve.admin.review.DestroyRequest) returns (auto_reserve.admin.review.DestroyResponse);
   */
  destroy(
    input: DestroyRequest,
    options?: RpcOptions,
  ): UnaryCall<DestroyRequest, DestroyResponse> {
    const method = this.methods[3],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<DestroyRequest, DestroyResponse>(
      "unary",
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: Import(auto_reserve.admin.review.ImportRequest) returns (auto_reserve.admin.review.ImportResponse);
   */
  import(
    input: ImportRequest,
    options?: RpcOptions,
  ): UnaryCall<ImportRequest, ImportResponse> {
    const method = this.methods[4],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<ImportRequest, ImportResponse>(
      "unary",
      this._transport,
      method,
      opt,
      input,
    );
  }
}
