import React, { useState } from 'react'
import { ownerInvoiceService } from 'models/OwnerInvoice'
import { useSearchParams } from 'react-router-dom'
import { Box, Button } from '@mui/material'
import PageHeader from 'components/Shared/PageHeader'
import { usePagination } from 'components/Shared/DataGrid'
import { FilterState, OwnerInvoiceList } from 'components/OwnerInvoice/List'

export default function OwnersInvoices() {
  const [searchParams] = useSearchParams()
  const paramsOwnerIds = searchParams.get('owner_ids')
  const ownerIds =
    paramsOwnerIds === null ? [] : paramsOwnerIds.split(',').map((id) => +id)

  const pagination = usePagination()
  const [filters, setFilters] = useState<FilterState>({
    ownerIds,
    statuses: [],
  })

  const { data } = ownerInvoiceService.useList({
    page: pagination.page,
    per: pagination.perPage,
    filters,
  })

  return (
    <Box>
      <PageHeader
        title="請求一覧"
        extra={[
          <Button key="edit" variant="contained" href="/owner_invoices/new">
            作成
          </Button>,
        ]}
      >
        <OwnerInvoiceList
          ownerInvoices={data?.ownerInvoices}
          totalCount={data?.totalCount}
          filters={filters}
          setFilters={setFilters}
          pagination={pagination}
        />
      </PageHeader>
    </Box>
  )
}
