import axios, { AxiosResponse } from 'axios'
import Qs from 'qs'
import Config from 'constants/Config'

export const headers = {
  Accept: 'application/vnd.autoreserve.com+json; version=1',
  'Content-Type': 'application/json',
  'X-ForR-Platform': 'web',
}

const instance = axios.create({
  baseURL: Config.apiUrl,
  headers,
})

instance.interceptors.request.use((config) => {
  config.paramsSerializer = {
    encode(value) {
      return Qs.parse(value)
    },
    serialize(params) {
      return Qs.stringify(params, {
        arrayFormat: 'brackets',
        encode: true,
        skipNulls: true,
      })
    },
  }
  return config
})

export function setHeader(auth: { token: string | null }) {
  if (auth.token != null) {
    instance.defaults.headers.common.Authorization = `Token token=${auth.token}`
    instance.defaults.headers.common['X-AR-Token'] = auth.token
  } else {
    delete instance.defaults.headers.common.Authorization
    delete instance.defaults.headers.common['X-AR-Token']
  }
}

type ResponseWrapper<T> = Promise<{
  response?: AxiosResponse<T>
  error?: unknown
}>

export async function wrapResponse<T>(
  promise: Promise<AxiosResponse<T>>
): ResponseWrapper<T> {
  return await promise
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export const { isAxiosError } = axios

export default instance
