export const Breakpoints = {
  // Extra small devices (phones)
  xs: 0,
  // Small devices (tablets)
  sm: 450,
  // Medium devices (desktops)
  md: 960,
  // Large devices (large desktops)
  lg: 1200,
}
