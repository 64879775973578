import { Button } from '@mui/material'
import { BaseTableRows, BasicTableCell } from 'components/Sales/BaseTableRows'
import type { Report } from 'models/Report'
import React from 'react'

type PartialCaptureReversal = Report['partial_capture_reversal']

const SUB_CATEGORY_ROW_SPAN = 3
const AR_ROW_SPAN = SUB_CATEGORY_ROW_SPAN * 1
const TOTAL_ROW_SPAN = SUB_CATEGORY_ROW_SPAN
const REPORTING_CATEGORY_ROW_SPAN = AR_ROW_SPAN + TOTAL_ROW_SPAN

export function PartialCaptureReversalTableRows({
  data,
  onClickReportingCategory,
}: {
  data: PartialCaptureReversal
  onClickReportingCategory: () => void
}) {
  return (
    <>
      <>
        <BaseTableRows
          reportingCategoryCell={
            <BasicTableCell rowSpan={REPORTING_CATEGORY_ROW_SPAN}>
              <Button variant="text" onClick={onClickReportingCategory}>
                一部キャプチャの差戻し
              </Button>
            </BasicTableCell>
          }
          categoryCell={
            <BasicTableCell rowSpan={AR_ROW_SPAN}>AR</BasicTableCell>
          }
          subCategoryCell={
            <BasicTableCell rowSpan={SUB_CATEGORY_ROW_SPAN}>
              予約キャンセル料
            </BasicTableCell>
          }
          items={[
            {
              label: '件数',
              value: data.ar?.reservation_cancel_fee?.transaction_count,
            },
            {
              label: '総額',
              value: data.ar?.reservation_cancel_fee?.total_amount,
              type: 'currency',
            },
            {
              label: '手数料返金',
              value: data.ar?.reservation_cancel_fee?.total_fee_reversal,
              type: 'currency',
            },
          ]}
        />
      </>

      <>
        <BaseTableRows
          categoryCell={
            <BasicTableCell rowSpan={TOTAL_ROW_SPAN} colSpan={2}>
              合計
            </BasicTableCell>
          }
          items={[
            {
              label: '件数',
              value: data.total.transaction_count,
            },
            {
              label: '総額',
              value: data.total.total_amount,
              type: 'currency',
            },
            {
              label: '手数料返金',
              value: data.total.total_fee_reversal,
              type: 'currency',
            },
          ]}
        />
      </>
    </>
  )
}
