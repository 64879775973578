// @generated by protobuf-ts 2.8.1 with parameter long_type_number,generate_dependencies
// @generated from protobuf file "auto_reserve/admin/user/user_service.proto" (package "auto_reserve.admin.user", syntax proto3)
// tslint:disable
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { UserResource } from "./user_resource";
import { StringValue } from "../../../google/protobuf/wrappers";
/**
 * @generated from protobuf message auto_reserve.admin.user.ListFilters
 */
export interface ListFilters {
  /**
   * @generated from protobuf field: google.protobuf.StringValue email = 1;
   */
  email?: StringValue;
  /**
   * @generated from protobuf field: google.protobuf.StringValue phone_number = 2;
   */
  phoneNumber?: StringValue;
}
/**
 * @generated from protobuf message auto_reserve.admin.user.ListRequest
 */
export interface ListRequest {
  /**
   * @generated from protobuf field: int64 page = 1;
   */
  page: number;
  /**
   * @generated from protobuf field: int64 per = 2;
   */
  per: number;
  /**
   * @generated from protobuf field: auto_reserve.admin.user.ListFilters filters = 3;
   */
  filters?: ListFilters;
}
/**
 * @generated from protobuf message auto_reserve.admin.user.ListResponse
 */
export interface ListResponse {
  /**
   * @generated from protobuf field: repeated auto_reserve.admin.user.UserResource users = 1;
   */
  users: UserResource[];
  /**
   * @generated from protobuf field: int64 total_count = 2;
   */
  totalCount: number;
}
/**
 * @generated from protobuf message auto_reserve.admin.user.GetRequest
 */
export interface GetRequest {
  /**
   * @generated from protobuf field: int64 id = 1;
   */
  id: number;
}
// @generated message type with reflection information, may provide speed optimized methods
class ListFilters$Type extends MessageType<ListFilters> {
  constructor() {
    super("auto_reserve.admin.user.ListFilters", [
      { no: 1, name: "email", kind: "message", T: () => StringValue },
      { no: 2, name: "phone_number", kind: "message", T: () => StringValue },
    ]);
  }
  create(value?: PartialMessage<ListFilters>): ListFilters {
    const message = {};
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<ListFilters>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: ListFilters,
  ): ListFilters {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* google.protobuf.StringValue email */ 1:
          message.email = StringValue.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.email,
          );
          break;
        case /* google.protobuf.StringValue phone_number */ 2:
          message.phoneNumber = StringValue.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.phoneNumber,
          );
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: ListFilters,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* google.protobuf.StringValue email = 1; */
    if (message.email)
      StringValue.internalBinaryWrite(
        message.email,
        writer.tag(1, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* google.protobuf.StringValue phone_number = 2; */
    if (message.phoneNumber)
      StringValue.internalBinaryWrite(
        message.phoneNumber,
        writer.tag(2, WireType.LengthDelimited).fork(),
        options,
      ).join();
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.admin.user.ListFilters
 */
export const ListFilters = new ListFilters$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListRequest$Type extends MessageType<ListRequest> {
  constructor() {
    super("auto_reserve.admin.user.ListRequest", [
      {
        no: 1,
        name: "page",
        kind: "scalar",
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
      {
        no: 2,
        name: "per",
        kind: "scalar",
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
      { no: 3, name: "filters", kind: "message", T: () => ListFilters },
    ]);
  }
  create(value?: PartialMessage<ListRequest>): ListRequest {
    const message = { page: 0, per: 0 };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<ListRequest>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: ListRequest,
  ): ListRequest {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* int64 page */ 1:
          message.page = reader.int64().toNumber();
          break;
        case /* int64 per */ 2:
          message.per = reader.int64().toNumber();
          break;
        case /* auto_reserve.admin.user.ListFilters filters */ 3:
          message.filters = ListFilters.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.filters,
          );
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: ListRequest,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* int64 page = 1; */
    if (message.page !== 0) writer.tag(1, WireType.Varint).int64(message.page);
    /* int64 per = 2; */
    if (message.per !== 0) writer.tag(2, WireType.Varint).int64(message.per);
    /* auto_reserve.admin.user.ListFilters filters = 3; */
    if (message.filters)
      ListFilters.internalBinaryWrite(
        message.filters,
        writer.tag(3, WireType.LengthDelimited).fork(),
        options,
      ).join();
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.admin.user.ListRequest
 */
export const ListRequest = new ListRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListResponse$Type extends MessageType<ListResponse> {
  constructor() {
    super("auto_reserve.admin.user.ListResponse", [
      {
        no: 1,
        name: "users",
        kind: "message",
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => UserResource,
      },
      {
        no: 2,
        name: "total_count",
        kind: "scalar",
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
    ]);
  }
  create(value?: PartialMessage<ListResponse>): ListResponse {
    const message = { users: [], totalCount: 0 };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<ListResponse>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: ListResponse,
  ): ListResponse {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* repeated auto_reserve.admin.user.UserResource users */ 1:
          message.users.push(
            UserResource.internalBinaryRead(reader, reader.uint32(), options),
          );
          break;
        case /* int64 total_count */ 2:
          message.totalCount = reader.int64().toNumber();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: ListResponse,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* repeated auto_reserve.admin.user.UserResource users = 1; */
    for (let i = 0; i < message.users.length; i++)
      UserResource.internalBinaryWrite(
        message.users[i],
        writer.tag(1, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* int64 total_count = 2; */
    if (message.totalCount !== 0)
      writer.tag(2, WireType.Varint).int64(message.totalCount);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.admin.user.ListResponse
 */
export const ListResponse = new ListResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetRequest$Type extends MessageType<GetRequest> {
  constructor() {
    super("auto_reserve.admin.user.GetRequest", [
      {
        no: 1,
        name: "id",
        kind: "scalar",
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
    ]);
  }
  create(value?: PartialMessage<GetRequest>): GetRequest {
    const message = { id: 0 };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<GetRequest>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: GetRequest,
  ): GetRequest {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* int64 id */ 1:
          message.id = reader.int64().toNumber();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: GetRequest,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* int64 id = 1; */
    if (message.id !== 0) writer.tag(1, WireType.Varint).int64(message.id);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.admin.user.GetRequest
 */
export const GetRequest = new GetRequest$Type();
/**
 * @generated ServiceType for protobuf service auto_reserve.admin.user.User
 */
export const User = new ServiceType("auto_reserve.admin.user.User", [
  { name: "List", options: {}, I: ListRequest, O: ListResponse },
  { name: "Get", options: {}, I: GetRequest, O: UserResource },
]);
