import React, { useState } from 'react'
import { View } from 'react-native'
import { useParams, useNavigate } from 'react-router'
import { getStatusName, ownerInvoiceService } from 'models/OwnerInvoice'
import dayjs from 'dayjs'
import { onError, useToken } from 'models/Auth'
import Breadcrumb from 'components/Shared/Breadcrumb'
import PageHeader from 'components/Shared/PageHeader'
import { Box, Button, CircularProgress } from '@mui/material'
import { DescriptionItem, Descriptions } from 'components/Shared/Descriptions'
import ConfirmModal from 'components/Shared/ConfirmModal'
import Link from 'components/Shared/Link'
import { displayToastInfo } from 'components/Shared/Toast'

export default function OwnerInvoice() {
  const token = useToken()
  const navigate = useNavigate()
  const { ownerInvoiceId } = useParams<{ ownerInvoiceId: string }>()
  const [isConfirmationDeleteModalOpen, setIsConfirmationDeleteModalOpen] =
    useState(false)

  const { data: ownerInvoice } = ownerInvoiceService.useGet({
    id: ownerInvoiceId!,
  })

  const owner = ownerInvoice?.owner

  if (ownerInvoice === undefined || owner === undefined)
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <CircularProgress />
      </Box>
    )

  const onConfirm = async () => {
    const { error } = ownerInvoiceService.destroy(token, {
      id: ownerInvoiceId!,
    })

    if (error != null) {
      onError(error)
      return
    }

    displayToastInfo('削除しました')

    navigate('/owner_invoices')
  }

  return (
    <>
      <View>
        <Breadcrumb
          routes={[
            {
              path: '/owner_invoices',
              breadcrumbName: '請求一覧',
            },
            {
              path: `/owner_invoices/${ownerInvoice.id}`,
              breadcrumbName: `${ownerInvoice.id}`,
            },
          ]}
        />
        <PageHeader
          title="請求詳細"
          sx={{ mt: 4 }}
          extra={[
            <Button
              key="edit"
              variant="outlined"
              onClick={() =>
                navigate(`/owner_invoices/${ownerInvoice.id}/edit`)
              }
            >
              編集
            </Button>,
            <Button
              key="destroy"
              variant="contained"
              onClick={() => setIsConfirmationDeleteModalOpen(true)}
              color="delete"
            >
              削除
            </Button>,
          ]}
        >
          <Descriptions>
            <DescriptionItem label="ID">{ownerInvoice.id}</DescriptionItem>
            <DescriptionItem label="オーナー">
              <Link to={`/owners/${owner.id}`}>{owner.name}</Link>
            </DescriptionItem>
            <DescriptionItem label="支払い期日">
              {ownerInvoice.due !== undefined
                ? dayjs.unix(ownerInvoice.due.seconds).format('YYYY/MM/DD')
                : ''}
            </DescriptionItem>
            <DescriptionItem label="ステータス">
              {getStatusName(ownerInvoice.status)}
            </DescriptionItem>
            <DescriptionItem label="金額">
              {ownerInvoice.amount}
            </DescriptionItem>
            <DescriptionItem label="内容" sx={{ whiteSpace: 'pre-wrap' }}>
              {ownerInvoice.contents}
            </DescriptionItem>
          </Descriptions>
        </PageHeader>
      </View>
      <ConfirmModal
        type="delete"
        isVisible={isConfirmationDeleteModalOpen}
        title="削除します"
        onClose={() => setIsConfirmationDeleteModalOpen(false)}
        onConfirm={onConfirm}
      />
    </>
  )
}
