import React from 'react'

const UNINITIALIZED = Object.freeze({})

export function useStable<T>(getInitialValue: () => T): T {
  const ref = React.useRef<T | typeof UNINITIALIZED>(UNINITIALIZED)
  if (ref.current === UNINITIALIZED) {
    ref.current = getInitialValue()
  }
  return ref.current as T
}
