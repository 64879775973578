import {
  createAspidaSWRMutationParameters,
  createAspidaSWRParameters,
} from '@hello-ai/ar_shared/src/modules/aspida-swr'
import { useToken } from 'models/Auth'
import client from 'modules/api'
import { setHeader } from 'modules/axios'
import useSWR, { useSWRMutation } from 'modules/swr'

type ReportBalanceActivities = NonNullable<
  ReturnType<typeof useReportBalanceActivities>['data']
>

export type Report = ReportBalanceActivities['report']

export function useReportBalanceActivities(query: {
  year: number
  month: number
}) {
  const token = useToken()
  setHeader({ token })
  return useSWR(
    ...createAspidaSWRParameters(client.report.balance.activities, {
      query: {
        year: query.year.toString(),
        month: query.month.toString().padStart(2, '0'),
      },
    })
  )
}

export function useReportBalanceCsvs(reportingCategory: string) {
  const token = useToken()
  setHeader({ token })
  const [url, fetcher] = createAspidaSWRMutationParameters(
    client.report.balance.csvs._reporting_category_name(reportingCategory)
  )
  return useSWRMutation(
    url,
    (
      key: Parameters<typeof fetcher>[0],
      { arg }: { arg: { year: number; month: number } }
    ) => {
      return fetcher(key, {
        arg: {
          ...arg,
          query: {
            year: arg.year.toString(),
            month: arg.month.toString().padStart(2, '0'),
          },
        },
      })
    }
  )
}
