import dayjs from '@hello-ai/ar_shared/src/modules/dayjs'
import { Timestamp } from '@hello-ai/proto/src/gen/google/protobuf/timestamp'
import { Button, Typography } from '@mui/material'
import DataGrid, { usePagination } from 'components/Shared/DataGrid'
import { broadcastMessageService } from 'models/BroadcastMessage'
import React from 'react'
import { useNavigate } from 'react-router'
import { getStatusDisplayLabel, getStatusTextStyle } from './Table'

export default function BroadcastMessageList() {
  const navigate = useNavigate()
  const pagination = usePagination()
  const { data } = broadcastMessageService.useList({
    page: pagination.page,
    per: pagination.perPage,
  })

  return (
    <DataGrid
      loading={data === undefined}
      data={data?.broadcastMessages}
      totalCount={data?.totalCount}
      rowKey={(record) => record.id}
      columns={[
        {
          key: 'action',
          render: (broadcastMessage) => (
            <Button
              variant="outlined"
              onClick={() =>
                navigate(`/broadcast_messages/${broadcastMessage.id}`)
              }
            >
              詳細
            </Button>
          ),
        },
        {
          title: '配信日時',
          key: 'delivery_at',
          render: (broadcastMessage) =>
            broadcastMessage.deliveryAt &&
            dayjs(Timestamp.toDate(broadcastMessage.deliveryAt)).format(
              'YYYY年M月D日(ddd) HH:mm'
            ),
        },
        {
          title: '件名',
          key: 'subject',
          render: (broadcastMessage) => broadcastMessage.subject ?? '',
        },
        {
          title: '配信人数',
          key: 'number_of_sent',
          render: (broadcastMessage) => broadcastMessage.numberOfSent ?? '--',
        },
        {
          title: 'ステータス',
          key: 'status',
          render: (broadcastMessage) => (
            <Typography style={getStatusTextStyle(broadcastMessage.status)}>
              {getStatusDisplayLabel(broadcastMessage.status)}
            </Typography>
          ),
        },
      ]}
      pagination={pagination}
      sx={{
        minHeight: 400,
      }}
    />
  )
}
