import React from 'react'
import { View, ActivityIndicator, StyleProp, ViewStyle } from 'react-native'
import { Colors } from '../constants/Colors'

interface LoadingProps {
  style?: StyleProp<ViewStyle>
}

function Loading({ style }: LoadingProps) {
  return (
    <View
      style={[
        { flex: 1, justifyContent: 'center', alignItems: 'center' },
        style,
      ]}
      testID="loading"
    >
      <ActivityIndicator size="small" color={Colors.primary} />
    </View>
  )
}

const MemoizedLoading = React.memo(Loading)

export { MemoizedLoading as Loading }
