// @generated by protobuf-ts 2.8.1 with parameter long_type_number,generate_dependencies
// @generated from protobuf file "auto_reserve/admin/restaurant_reservation_book_contract/restaurant_reservation_book_contract_service.proto" (package "auto_reserve.admin.restaurant_reservation_book_contract", syntax proto3)
// tslint:disable
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { RestaurantReservationBookContract } from "./restaurant_reservation_book_contract_service";
import type { UpdateRequest } from "./restaurant_reservation_book_contract_service";
import type { CreateRequest } from "./restaurant_reservation_book_contract_service";
import type { RestaurantReservationBookContractResource } from "./restaurant_reservation_book_contract_resource";
import type { GetRequest } from "./restaurant_reservation_book_contract_service";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { ListResponse } from "./restaurant_reservation_book_contract_service";
import type { ListRequest } from "./restaurant_reservation_book_contract_service";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service auto_reserve.admin.restaurant_reservation_book_contract.RestaurantReservationBookContract
 */
export interface IRestaurantReservationBookContractClient {
  /**
   * @generated from protobuf rpc: List(auto_reserve.admin.restaurant_reservation_book_contract.ListRequest) returns (auto_reserve.admin.restaurant_reservation_book_contract.ListResponse);
   */
  list(
    input: ListRequest,
    options?: RpcOptions,
  ): UnaryCall<ListRequest, ListResponse>;
  /**
   * @generated from protobuf rpc: Get(auto_reserve.admin.restaurant_reservation_book_contract.GetRequest) returns (auto_reserve.admin.restaurant_reservation_book_contract.RestaurantReservationBookContractResource);
   */
  get(
    input: GetRequest,
    options?: RpcOptions,
  ): UnaryCall<GetRequest, RestaurantReservationBookContractResource>;
  /**
   * @generated from protobuf rpc: Create(auto_reserve.admin.restaurant_reservation_book_contract.CreateRequest) returns (auto_reserve.admin.restaurant_reservation_book_contract.RestaurantReservationBookContractResource);
   */
  create(
    input: CreateRequest,
    options?: RpcOptions,
  ): UnaryCall<CreateRequest, RestaurantReservationBookContractResource>;
  /**
   * @generated from protobuf rpc: Update(auto_reserve.admin.restaurant_reservation_book_contract.UpdateRequest) returns (auto_reserve.admin.restaurant_reservation_book_contract.RestaurantReservationBookContractResource);
   */
  update(
    input: UpdateRequest,
    options?: RpcOptions,
  ): UnaryCall<UpdateRequest, RestaurantReservationBookContractResource>;
}
/**
 * @generated from protobuf service auto_reserve.admin.restaurant_reservation_book_contract.RestaurantReservationBookContract
 */
export class RestaurantReservationBookContractClient
  implements IRestaurantReservationBookContractClient, ServiceInfo
{
  typeName = RestaurantReservationBookContract.typeName;
  methods = RestaurantReservationBookContract.methods;
  options = RestaurantReservationBookContract.options;
  constructor(private readonly _transport: RpcTransport) {}
  /**
   * @generated from protobuf rpc: List(auto_reserve.admin.restaurant_reservation_book_contract.ListRequest) returns (auto_reserve.admin.restaurant_reservation_book_contract.ListResponse);
   */
  list(
    input: ListRequest,
    options?: RpcOptions,
  ): UnaryCall<ListRequest, ListResponse> {
    const method = this.methods[0],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<ListRequest, ListResponse>(
      "unary",
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: Get(auto_reserve.admin.restaurant_reservation_book_contract.GetRequest) returns (auto_reserve.admin.restaurant_reservation_book_contract.RestaurantReservationBookContractResource);
   */
  get(
    input: GetRequest,
    options?: RpcOptions,
  ): UnaryCall<GetRequest, RestaurantReservationBookContractResource> {
    const method = this.methods[1],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      GetRequest,
      RestaurantReservationBookContractResource
    >("unary", this._transport, method, opt, input);
  }
  /**
   * @generated from protobuf rpc: Create(auto_reserve.admin.restaurant_reservation_book_contract.CreateRequest) returns (auto_reserve.admin.restaurant_reservation_book_contract.RestaurantReservationBookContractResource);
   */
  create(
    input: CreateRequest,
    options?: RpcOptions,
  ): UnaryCall<CreateRequest, RestaurantReservationBookContractResource> {
    const method = this.methods[2],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      CreateRequest,
      RestaurantReservationBookContractResource
    >("unary", this._transport, method, opt, input);
  }
  /**
   * @generated from protobuf rpc: Update(auto_reserve.admin.restaurant_reservation_book_contract.UpdateRequest) returns (auto_reserve.admin.restaurant_reservation_book_contract.RestaurantReservationBookContractResource);
   */
  update(
    input: UpdateRequest,
    options?: RpcOptions,
  ): UnaryCall<UpdateRequest, RestaurantReservationBookContractResource> {
    const method = this.methods[3],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      UpdateRequest,
      RestaurantReservationBookContractResource
    >("unary", this._transport, method, opt, input);
  }
}
