// @generated by protobuf-ts 2.8.1 with parameter long_type_number,generate_dependencies
// @generated from protobuf file "auto_reserve/admin/owner/owner_service.proto" (package "auto_reserve.admin.owner", syntax proto3)
// tslint:disable
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { RestaurantResource } from "../restaurant/restaurant_resource";
import { OwnerResource } from "./owner_resource";
/**
 * @generated from protobuf message auto_reserve.admin.owner.AllRequest
 */
export interface AllRequest {}
/**
 * @generated from protobuf message auto_reserve.admin.owner.AllResponse
 */
export interface AllResponse {
  /**
   * @generated from protobuf field: repeated auto_reserve.admin.owner.OwnerResource owners = 1;
   */
  owners: OwnerResource[];
}
/**
 * @generated from protobuf message auto_reserve.admin.owner.ListRequest
 */
export interface ListRequest {
  /**
   * @generated from protobuf field: int64 page = 1;
   */
  page: number;
  /**
   * @generated from protobuf field: int64 per = 2;
   */
  per: number;
}
/**
 * @generated from protobuf message auto_reserve.admin.owner.ListResponse
 */
export interface ListResponse {
  /**
   * @generated from protobuf field: repeated auto_reserve.admin.owner.OwnerResource owners = 1;
   */
  owners: OwnerResource[];
  /**
   * @generated from protobuf field: int64 total_count = 2;
   */
  totalCount: number;
}
/**
 * @generated from protobuf message auto_reserve.admin.owner.GetRequest
 */
export interface GetRequest {
  /**
   * @generated from protobuf field: int64 id = 1;
   */
  id: number;
}
/**
 * @generated from protobuf message auto_reserve.admin.owner.GetRestaurantsResponse
 */
export interface GetRestaurantsResponse {
  /**
   * @generated from protobuf field: int64 id = 1;
   */
  id: number;
  /**
   * @generated from protobuf field: repeated auto_reserve.admin.restaurant.RestaurantResource restaurants = 2;
   */
  restaurants: RestaurantResource[];
}
// @generated message type with reflection information, may provide speed optimized methods
class AllRequest$Type extends MessageType<AllRequest> {
  constructor() {
    super("auto_reserve.admin.owner.AllRequest", []);
  }
  create(value?: PartialMessage<AllRequest>): AllRequest {
    const message = {};
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<AllRequest>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: AllRequest,
  ): AllRequest {
    return target ?? this.create();
  }
  internalBinaryWrite(
    message: AllRequest,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.admin.owner.AllRequest
 */
export const AllRequest = new AllRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AllResponse$Type extends MessageType<AllResponse> {
  constructor() {
    super("auto_reserve.admin.owner.AllResponse", [
      {
        no: 1,
        name: "owners",
        kind: "message",
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => OwnerResource,
      },
    ]);
  }
  create(value?: PartialMessage<AllResponse>): AllResponse {
    const message = { owners: [] };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<AllResponse>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: AllResponse,
  ): AllResponse {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* repeated auto_reserve.admin.owner.OwnerResource owners */ 1:
          message.owners.push(
            OwnerResource.internalBinaryRead(reader, reader.uint32(), options),
          );
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: AllResponse,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* repeated auto_reserve.admin.owner.OwnerResource owners = 1; */
    for (let i = 0; i < message.owners.length; i++)
      OwnerResource.internalBinaryWrite(
        message.owners[i],
        writer.tag(1, WireType.LengthDelimited).fork(),
        options,
      ).join();
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.admin.owner.AllResponse
 */
export const AllResponse = new AllResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListRequest$Type extends MessageType<ListRequest> {
  constructor() {
    super("auto_reserve.admin.owner.ListRequest", [
      {
        no: 1,
        name: "page",
        kind: "scalar",
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
      {
        no: 2,
        name: "per",
        kind: "scalar",
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
    ]);
  }
  create(value?: PartialMessage<ListRequest>): ListRequest {
    const message = { page: 0, per: 0 };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<ListRequest>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: ListRequest,
  ): ListRequest {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* int64 page */ 1:
          message.page = reader.int64().toNumber();
          break;
        case /* int64 per */ 2:
          message.per = reader.int64().toNumber();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: ListRequest,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* int64 page = 1; */
    if (message.page !== 0) writer.tag(1, WireType.Varint).int64(message.page);
    /* int64 per = 2; */
    if (message.per !== 0) writer.tag(2, WireType.Varint).int64(message.per);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.admin.owner.ListRequest
 */
export const ListRequest = new ListRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListResponse$Type extends MessageType<ListResponse> {
  constructor() {
    super("auto_reserve.admin.owner.ListResponse", [
      {
        no: 1,
        name: "owners",
        kind: "message",
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => OwnerResource,
      },
      {
        no: 2,
        name: "total_count",
        kind: "scalar",
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
    ]);
  }
  create(value?: PartialMessage<ListResponse>): ListResponse {
    const message = { owners: [], totalCount: 0 };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<ListResponse>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: ListResponse,
  ): ListResponse {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* repeated auto_reserve.admin.owner.OwnerResource owners */ 1:
          message.owners.push(
            OwnerResource.internalBinaryRead(reader, reader.uint32(), options),
          );
          break;
        case /* int64 total_count */ 2:
          message.totalCount = reader.int64().toNumber();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: ListResponse,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* repeated auto_reserve.admin.owner.OwnerResource owners = 1; */
    for (let i = 0; i < message.owners.length; i++)
      OwnerResource.internalBinaryWrite(
        message.owners[i],
        writer.tag(1, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* int64 total_count = 2; */
    if (message.totalCount !== 0)
      writer.tag(2, WireType.Varint).int64(message.totalCount);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.admin.owner.ListResponse
 */
export const ListResponse = new ListResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetRequest$Type extends MessageType<GetRequest> {
  constructor() {
    super("auto_reserve.admin.owner.GetRequest", [
      {
        no: 1,
        name: "id",
        kind: "scalar",
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
    ]);
  }
  create(value?: PartialMessage<GetRequest>): GetRequest {
    const message = { id: 0 };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<GetRequest>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: GetRequest,
  ): GetRequest {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* int64 id */ 1:
          message.id = reader.int64().toNumber();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: GetRequest,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* int64 id = 1; */
    if (message.id !== 0) writer.tag(1, WireType.Varint).int64(message.id);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.admin.owner.GetRequest
 */
export const GetRequest = new GetRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetRestaurantsResponse$Type extends MessageType<GetRestaurantsResponse> {
  constructor() {
    super("auto_reserve.admin.owner.GetRestaurantsResponse", [
      {
        no: 1,
        name: "id",
        kind: "scalar",
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
      {
        no: 2,
        name: "restaurants",
        kind: "message",
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => RestaurantResource,
      },
    ]);
  }
  create(
    value?: PartialMessage<GetRestaurantsResponse>,
  ): GetRestaurantsResponse {
    const message = { id: 0, restaurants: [] };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<GetRestaurantsResponse>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: GetRestaurantsResponse,
  ): GetRestaurantsResponse {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* int64 id */ 1:
          message.id = reader.int64().toNumber();
          break;
        case /* repeated auto_reserve.admin.restaurant.RestaurantResource restaurants */ 2:
          message.restaurants.push(
            RestaurantResource.internalBinaryRead(
              reader,
              reader.uint32(),
              options,
            ),
          );
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: GetRestaurantsResponse,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* int64 id = 1; */
    if (message.id !== 0) writer.tag(1, WireType.Varint).int64(message.id);
    /* repeated auto_reserve.admin.restaurant.RestaurantResource restaurants = 2; */
    for (let i = 0; i < message.restaurants.length; i++)
      RestaurantResource.internalBinaryWrite(
        message.restaurants[i],
        writer.tag(2, WireType.LengthDelimited).fork(),
        options,
      ).join();
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.admin.owner.GetRestaurantsResponse
 */
export const GetRestaurantsResponse = new GetRestaurantsResponse$Type();
/**
 * @generated ServiceType for protobuf service auto_reserve.admin.owner.Owner
 */
export const Owner = new ServiceType("auto_reserve.admin.owner.Owner", [
  { name: "All", options: {}, I: AllRequest, O: AllResponse },
  { name: "List", options: {}, I: ListRequest, O: ListResponse },
  { name: "Get", options: {}, I: GetRequest, O: OwnerResource },
  {
    name: "GetRestaurants",
    options: {},
    I: GetRequest,
    O: GetRestaurantsResponse,
  },
]);
