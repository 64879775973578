import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import dayjs from '@hello-ai/ar_shared/src/modules/dayjs'
import {
  OwnerInvoiceResource,
  OwnerInvoiceResource_Status,
} from '@hello-ai/proto/src/gen/auto_reserve/admin/owner_invoice/owner_invoice_resource'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Link,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import { FilterAction } from 'components/Filter/FilterAction'
import { FilterStatus } from 'components/Filter/FilterStatus'
import ConfirmModal from 'components/Shared/ConfirmModal'
import DataGrid, { usePagination } from 'components/Shared/DataGrid'
import { displayToastInfo } from 'components/Shared/Toast'
import { onError, useToken } from 'models/Auth'
import { ownerService } from 'models/Owner'
import { getStatusName, ownerInvoiceService } from 'models/OwnerInvoice'
import React, { Fragment, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { z } from 'zod'

const STATUSES = [
  OwnerInvoiceResource_Status.DRAFT,
  OwnerInvoiceResource_Status.BILLED,
  OwnerInvoiceResource_Status.PAID,
  OwnerInvoiceResource_Status.NOT_BILLED,
].map((status) => ({ value: status, text: getStatusName(status) }))

export interface FilterState {
  ownerIds: number[]
  statuses: number[]
}

function FilterOwner({
  onFilter,
  defaultValue,
}: {
  onFilter: (value: number[]) => void
  defaultValue: number[]
}) {
  const [newSelectedStatus, setNewSelectedStatus] = useState(defaultValue)
  const { data } = ownerService.useAll({})
  const [searchOwnerName, setSearchOwnerName] = useState('')

  const options = useMemo(() => {
    if (data === undefined) return null
    if (searchOwnerName.length === 0) return data.owners
    return data.owners.filter((owner) => owner.name.includes(searchOwnerName))
  }, [data, searchOwnerName])

  if (options == null)
    return (
      <Box>
        <CircularProgress />
      </Box>
    )

  return (
    <FormGroup sx={{ p: 2 }}>
      <TextField
        size="small"
        variant="outlined"
        onChange={(event) => setSearchOwnerName(event.target.value)}
        value={searchOwnerName}
        helperText={
          options.length === 0 && '条件に合うオーナーが見つかりません'
        }
        error={options.length === 0}
      />
      <Box sx={{ maxHeight: 400, overflowY: 'auto' }}>
        {options.map((option) => {
          const checked = newSelectedStatus.includes(option.id)

          return (
            <FormControlLabel
              key={option.id}
              control={<Checkbox />}
              label={option.name}
              checked={checked}
              onChange={(_, newChecked) => {
                if (newChecked === checked) return
                setNewSelectedStatus((prev) => {
                  if (newChecked) {
                    return [...prev, option.id]
                  } else {
                    return prev.filter(
                      (selectedStatus) => selectedStatus !== option.id
                    )
                  }
                })
              }}
            />
          )
        })}
      </Box>
      <FilterAction
        onReset={() => {
          setNewSelectedStatus(defaultValue)
        }}
        onSubmit={() => {
          onFilter(newSelectedStatus)
        }}
      />
    </FormGroup>
  )
}

const schema = z.object({
  ids: z.array(z.string()),
  status: z.number(),
})

export function OwnerInvoiceList({
  ownerInvoices,
  totalCount,
  pagination,
  filters,
  setFilters,
}: {
  ownerInvoices: OwnerInvoiceResource[] | undefined
  totalCount: number | undefined
  pagination: ReturnType<typeof usePagination>
  filters: FilterState
  setFilters: React.Dispatch<React.SetStateAction<FilterState>>
}) {
  const token = useToken()
  const [isVisibleConfirmModal, setIsVisibleConfirmModal] = useState(false)

  const { data } = ownerService.useAll({})

  const updateStatus = (
    ownerInvoice: OwnerInvoiceResource,
    value: OwnerInvoiceResource_Status
  ) => {
    const params = {
      id: ownerInvoice.id,
      status: value,
    }

    const { error } = ownerInvoiceService.updateStatus(token, params)

    if (error != null) {
      onError(error)
      return
    }

    displayToastInfo('更新しました')
  }

  const form = useForm<z.infer<typeof schema>>({
    mode: 'onChange',
    resolver: zodResolver(schema),
    defaultValues: {
      ids: [],
      status: OwnerInvoiceResource_Status.DRAFT,
    },
  })
  const selectedOwnerInvoices = form.watch('ids')

  const onConfirmBatchUpdate = async () => {
    const values = form.getValues()

    const { error } = ownerInvoiceService.upsert(token, values)

    if (error != null) {
      onError(error)
      return
    }
    displayToastInfo('一括更新しました')
    window.location.reload()
  }

  return (
    <>
      {selectedOwnerInvoices.length > 0 && (
        <Box
          sx={{
            backgroundColor: Colors.bgBlack,
            p: 2,
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'flex-end',
            columnGap: 2,
          }}
          component="form"
          onSubmit={form.handleSubmit(() => setIsVisibleConfirmModal(true))}
        >
          <Controller
            control={form.control}
            name="status"
            render={({ field, fieldState }) => {
              const { error } = fieldState
              return (
                <TextField
                  {...field}
                  value={`${field.value}`}
                  onChange={(event) => {
                    field.onChange(parseInt(event.target.value))
                  }}
                  select
                  label="ステータス"
                  helperText={error?.message}
                  error={error !== undefined && error.message !== ''}
                  margin="none"
                >
                  {STATUSES.map((item) => (
                    <MenuItem key={item.value} value={`${item.value}`}>
                      {item.text}
                    </MenuItem>
                  ))}
                </TextField>
              )
            }}
          />
          <Button type="submit" variant="contained">
            一括更新
          </Button>
        </Box>
      )}
      <ConfirmModal
        type="warning"
        isVisible={isVisibleConfirmModal}
        title="一括更新を行います"
        onClose={() => setIsVisibleConfirmModal(false)}
        onConfirm={onConfirmBatchUpdate}
      />
      <DataGrid
        sx={{ width: '100%', height: 'calc(100vh - 300px)' }}
        data={ownerInvoices}
        totalCount={totalCount}
        rowKey={(ownerInvoice) => `ownerInvoice-${ownerInvoice.id}`}
        loading={data === undefined}
        pagination={pagination}
        filterState={filters}
        onChangeFilterValue={(key, newValue) => {
          setFilters((prev) => {
            // FIXME: #6274 を参考にimmutable updaterに修正してください
            prev[key] = newValue
            return { ...prev }
          })
        }}
        columns={[
          {
            key: 'action',
            title: (
              <Checkbox
                checked={selectedOwnerInvoices.length > 0}
                indeterminate={
                  selectedOwnerInvoices.length > 0 &&
                  selectedOwnerInvoices.length < (ownerInvoices?.length ?? 0)
                }
                onChange={(_, checked) => {
                  if (ownerInvoices === undefined) return
                  if (checked) {
                    form.setValue(
                      'ids',
                      ownerInvoices.map((item) => item.id)
                    )
                  } else {
                    form.setValue('ids', [])
                  }
                }}
              />
            ),
            render: (ownerInvoice) => {
              const existIndex = selectedOwnerInvoices.indexOf(ownerInvoice.id)
              return (
                <Box>
                  <Checkbox
                    checked={existIndex >= 0}
                    onChange={(_, checked) => {
                      if (checked && existIndex >= 0) return
                      if (checked) {
                        form.setValue('ids', [
                          ...selectedOwnerInvoices,
                          ownerInvoice.id,
                        ])
                      } else {
                        const clone = [...selectedOwnerInvoices]
                        clone.splice(existIndex, 1)
                        form.setValue('ids', clone)
                      }
                    }}
                  />
                  <Button
                    href={`/owner_invoices/${ownerInvoice.id}`}
                    variant="outlined"
                  >
                    詳細
                  </Button>
                </Box>
              )
            },
          },
          {
            key: 'due',
            title: '支払い期日',
            render: (ownerInvoice) => {
              const due = ownerInvoice.due
              if (due === undefined) return null
              return dayjs.unix(due.seconds).format('YYYY/MM/DD')
            },
          },
          {
            key: 'status',
            title: 'ステータス',
            render: (ownerInvoice) => {
              return (
                <Select
                  size="small"
                  margin="none"
                  defaultValue={ownerInvoice.status}
                  onChange={(event) =>
                    updateStatus(
                      ownerInvoice,
                      typeof event.target.value === 'string'
                        ? parseInt(event.target.value)
                        : event.target.value
                    )
                  }
                >
                  {STATUSES.map((status) => (
                    <MenuItem
                      key={`status-${status.value}`}
                      value={status.value}
                    >
                      {status.text}
                    </MenuItem>
                  ))}
                </Select>
              )
            },
            filter: {
              key: 'statuses',
              FilterContent: FilterStatus,
            },
          },
          {
            key: 'owner',
            title: 'オーナー',
            render: (ownerInvoice) => {
              const owner = ownerInvoice.owner
              if (owner === undefined) return null
              return <Link href={`/owners/${owner.id}`}>{owner.name}</Link>
            },
            filter: {
              key: 'ownerIds',
              FilterContent: FilterOwner,
            },
          },
          {
            key: 'robopay_customer_id',
            title: 'ロボペイ口座振替顧客番号',
            render: (ownerInvoice) => {
              const owner = ownerInvoice.owner

              if (owner === undefined) return null

              return owner.robopayCustomerId
            },
          },
          {
            key: 'has_bank_account_transfer_document',
            title: '口座振替書類',
            render: (ownerInvoice) => {
              const owner = ownerInvoice.owner

              if (owner === undefined) return null
              return owner.hasBankAccountTransferDocument ? '取得済' : '未取得'
            },
          },
          {
            key: 'amount',
            title: '金額',
            render: (ownerInvoice) => ownerInvoice.amount.toLocaleString(),
          },
          {
            key: 'contents',
            title: '内容',
            render: (ownerInvoice) => ownerInvoice.contents,
          },
          {
            key: 'memos',
            title: 'メモ',
            render: (ownerInvoice) => {
              const restaurants = ownerInvoice.owner?.restaurants

              if (restaurants === undefined) return null

              return (
                <Box sx={{ whiteSpace: 'pre-wrap' }}>
                  {restaurants.map((restaurant) => {
                    if (restaurant.orderContracts.length === 0) return null
                    return (
                      <Fragment key={restaurant.id}>
                        <Typography variant="subtitle1">
                          {restaurant.name}
                        </Typography>
                        <Typography variant="body1">
                          {restaurant.orderContracts
                            .map((orderContract) => orderContract.memo)
                            .join('\n')}
                        </Typography>
                      </Fragment>
                    )
                  })}
                </Box>
              )
            },
          },
        ]}
      />
    </>
  )
}
