import React from 'react'
import { Text as RNText, StyleSheet, TextProps } from 'react-native'
import { Colors } from '../constants/Colors'
import { useConfig } from './Config'

export const lineHeightMultiplier = 1.5

const styles = StyleSheet.create({
  text: {
    color: Colors.black,
  },
})

export type { TextProps }

export const Text = React.forwardRef<
  RNText,
  React.PropsWithChildren<TextProps>
>(
  (
    { style, ...props }: React.PropsWithChildren<TextProps>,
    ref?: React.Ref<RNText>
  ) => {
    const config = useConfig()
    const fontSize = StyleSheet.flatten(style)?.fontSize ?? config.Text.fontSize
    const lineHeight = fontSize * lineHeightMultiplier

    return (
      <RNText
        ref={ref}
        {...props}
        style={[
          styles.text,
          {
            fontSize: config.Text.fontSize,
            lineHeight,
          },
          config.Text.fontWeight !== undefined && {
            fontWeight: config.Text.fontWeight,
          },
          config.Text.fontFamily !== undefined && {
            fontFamily: config.Text.fontFamily,
          },
          style,
        ]}
      />
    )
  }
)
