import { Button } from '@mui/material'
import { BaseTableRows, BasicTableCell } from 'components/Sales/BaseTableRows'
import type { Report } from 'models/Report'
import React from 'react'

type Refund = Report['refund']
type RefundFailure = Report['refund_failure']

const SUB_CATEGORY_ROW_SPAN = 2
const AR_ROW_SPAN = SUB_CATEGORY_ROW_SPAN * 3
const FOR_R_ROW_SPAN = SUB_CATEGORY_ROW_SPAN * 4
const TOTAL_ROW_SPAN = SUB_CATEGORY_ROW_SPAN
const REPORTING_CATEGORY_ROW_SPAN =
  AR_ROW_SPAN + FOR_R_ROW_SPAN + TOTAL_ROW_SPAN

export function RefundTableRows({
  name,
  data,
  onClickReportingCategory,
}: {
  name: string
  data: Refund | RefundFailure
  onClickReportingCategory: () => void
}) {
  return (
    <>
      <>
        <BaseTableRows
          reportingCategoryCell={
            <BasicTableCell rowSpan={REPORTING_CATEGORY_ROW_SPAN}>
              <Button variant="text" onClick={onClickReportingCategory}>
                {name}
              </Button>
            </BasicTableCell>
          }
          categoryCell={
            <BasicTableCell rowSpan={AR_ROW_SPAN}>AR</BasicTableCell>
          }
          subCategoryCell={
            <BasicTableCell rowSpan={SUB_CATEGORY_ROW_SPAN}>
              予約手数料
            </BasicTableCell>
          }
          items={[
            {
              label: '件数',
              value: data.ar?.reservation_fee?.transaction_count,
            },
            {
              label: '総額',
              value: data.ar?.reservation_fee?.total_amount,
              type: 'currency',
            },
          ]}
        />

        <BaseTableRows
          subCategoryCell={
            <BasicTableCell rowSpan={SUB_CATEGORY_ROW_SPAN}>
              予約事前確認手数料
            </BasicTableCell>
          }
          items={[
            {
              label: '件数',
              value: data.ar?.reservation_reconfirm_fee?.transaction_count,
            },
            {
              label: '総額',
              value: data.ar?.reservation_reconfirm_fee?.total_amount,
              type: 'currency',
            },
          ]}
        />

        <BaseTableRows
          subCategoryCell={
            <BasicTableCell rowSpan={SUB_CATEGORY_ROW_SPAN}>
              予約キャンセル料
            </BasicTableCell>
          }
          items={[
            {
              label: '件数',
              value: data.ar?.reservation_cancel_fee?.transaction_count,
            },
            {
              label: '総額',
              value: data.ar?.reservation_cancel_fee?.total_amount,
              type: 'currency',
            },
          ]}
        />
      </>

      <>
        <BaseTableRows
          categoryCell={
            <BasicTableCell rowSpan={FOR_R_ROW_SPAN}>forR</BasicTableCell>
          }
          subCategoryCell={
            <BasicTableCell rowSpan={SUB_CATEGORY_ROW_SPAN}>
              セルフオーダー
            </BasicTableCell>
          }
          items={[
            {
              label: '件数',
              value: data.rest?.self_order?.transaction_count,
            },
            {
              label: '総額',
              value: data.rest?.self_order?.total_amount,
              type: 'currency',
            },
          ]}
        />

        <BaseTableRows
          subCategoryCell={
            <BasicTableCell rowSpan={SUB_CATEGORY_ROW_SPAN}>
              お取り寄せ
            </BasicTableCell>
          }
          items={[
            {
              label: '件数',
              value: data.rest?.onlinestore_order?.transaction_count,
            },
            {
              label: '総額',
              value: data.rest?.onlinestore_order?.total_amount,
              type: 'currency',
            },
          ]}
        />

        <BaseTableRows
          subCategoryCell={
            <BasicTableCell rowSpan={SUB_CATEGORY_ROW_SPAN}>
              テイクアウト
            </BasicTableCell>
          }
          items={[
            {
              label: '件数',
              value: data.rest?.takeout?.transaction_count,
            },
            {
              label: '総額',
              value: data.rest?.takeout?.total_amount,
              type: 'currency',
            },
          ]}
        />

        <BaseTableRows
          subCategoryCell={
            <BasicTableCell rowSpan={SUB_CATEGORY_ROW_SPAN}>
              月額利用料
            </BasicTableCell>
          }
          items={[
            {
              label: '件数',
              value: data.rest?.monthly_fee?.transaction_count,
            },
            {
              label: '総額',
              value: data.rest?.monthly_fee?.total_amount,
              type: 'currency',
            },
          ]}
        />
      </>

      <>
        <BaseTableRows
          categoryCell={
            <BasicTableCell rowSpan={TOTAL_ROW_SPAN} colSpan={2}>
              合計
            </BasicTableCell>
          }
          items={[
            {
              label: '件数',
              value: data.total.transaction_count,
            },
            {
              label: '総額',
              value: data.total.total_amount,
              type: 'currency',
            },
          ]}
        />
      </>
    </>
  )
}
