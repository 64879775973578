// @generated by protobuf-ts 2.8.1 with parameter long_type_number,generate_dependencies
// @generated from protobuf file "auto_reserve/admin/medium/medium_service.proto" (package "auto_reserve.admin.medium", syntax proto3)
// tslint:disable
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { Medium } from "./medium_service";
import type { DeleteRequest } from "./medium_service";
import type { UpdateRequest } from "./medium_service";
import type { MediumResource } from "./medium_resource";
import type { GetRequest } from "./medium_service";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { ListResponse } from "./medium_service";
import type { ListRequest } from "./medium_service";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service auto_reserve.admin.medium.Medium
 */
export interface IMediumClient {
  /**
   * @generated from protobuf rpc: List(auto_reserve.admin.medium.ListRequest) returns (auto_reserve.admin.medium.ListResponse);
   */
  list(
    input: ListRequest,
    options?: RpcOptions,
  ): UnaryCall<ListRequest, ListResponse>;
  /**
   * @generated from protobuf rpc: Get(auto_reserve.admin.medium.GetRequest) returns (auto_reserve.admin.medium.MediumResource);
   */
  get(
    input: GetRequest,
    options?: RpcOptions,
  ): UnaryCall<GetRequest, MediumResource>;
  /**
   * @generated from protobuf rpc: Update(auto_reserve.admin.medium.UpdateRequest) returns (auto_reserve.admin.medium.MediumResource);
   */
  update(
    input: UpdateRequest,
    options?: RpcOptions,
  ): UnaryCall<UpdateRequest, MediumResource>;
/**
 * @generated from protobuf rpc: Delete(auto_reserve.admin.medium.DeleteRequest) returns (auto_reserve.admin.medium.MediumResource);
 */
  delete(
    input: DeleteRequest,
    options?: RpcOptions,
  ): UnaryCall<DeleteRequest, MediumResource>;
}
/**
 * @generated from protobuf service auto_reserve.admin.medium.Medium
 */
export class MediumClient implements IMediumClient, ServiceInfo {
  typeName = Medium.typeName;
  methods = Medium.methods;
  options = Medium.options;
  constructor(private readonly _transport: RpcTransport) {}
  /**
   * @generated from protobuf rpc: List(auto_reserve.admin.medium.ListRequest) returns (auto_reserve.admin.medium.ListResponse);
   */
  list(
    input: ListRequest,
    options?: RpcOptions,
  ): UnaryCall<ListRequest, ListResponse> {
    const method = this.methods[0],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<ListRequest, ListResponse>(
      "unary",
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: Get(auto_reserve.admin.medium.GetRequest) returns (auto_reserve.admin.medium.MediumResource);
   */
  get(
    input: GetRequest,
    options?: RpcOptions,
  ): UnaryCall<GetRequest, MediumResource> {
    const method = this.methods[1],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<GetRequest, MediumResource>(
      "unary",
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: Update(auto_reserve.admin.medium.UpdateRequest) returns (auto_reserve.admin.medium.MediumResource);
   */
  update(
    input: UpdateRequest,
    options?: RpcOptions,
  ): UnaryCall<UpdateRequest, MediumResource> {
    const method = this.methods[2],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<UpdateRequest, MediumResource>(
      "unary",
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: Delete(auto_reserve.admin.medium.DeleteRequest) returns (auto_reserve.admin.medium.MediumResource);
   */
  delete(
    input: DeleteRequest,
    options?: RpcOptions,
  ): UnaryCall<DeleteRequest, MediumResource> {
    const method = this.methods[3],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<DeleteRequest, MediumResource>(
      "unary",
      this._transport,
      method,
      opt,
      input,
    );
  }
}
