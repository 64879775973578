import dayjs from '@hello-ai/ar_shared/src/modules/dayjs'
import React, { ComponentProps, useMemo } from 'react'
import { ScrollView, TextProps, TouchableOpacity, View } from 'react-native'

import { Pagination } from '@hello-ai/ar_shared/src/components/Pagination'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import {
  BroadcastMessageItem,
  BroadcastMessageStatus,
} from '@hello-ai/ar_shared/src/types/ForR/BroadcastMessage'
import { BroadcastMessageResource_Status } from '@hello-ai/proto/src/gen/auto_reserve/admin/broadcast_message/broadcast_message_resource'

import { t } from '@hello-ai/ar_shared/src/modules/i18n'

const tableRowBorderStyle = {
  borderColor: Colors.border,
  borderBottomWidth: 0.5,
}

function Table({ children }: React.PropsWithChildren<unknown>) {
  return (
    <View
      style={{
        backgroundColor: 'white',
        borderRadius: 9,
        minHeight: '80%',
        position: 'relative',
        flex: 1,
      }}
    >
      {children}
    </View>
  )
}

function Header({ children }: React.PropsWithChildren<unknown>) {
  return (
    <View
      style={[
        {
          flexDirection: 'row',
          paddingHorizontal: 20,
        },
        tableRowBorderStyle,
      ]}
    >
      {children}
    </View>
  )
}

function Body({ children }: React.PropsWithChildren<unknown>) {
  return <ScrollView style={{ paddingHorizontal: 20 }}>{children}</ScrollView>
}

function Footer({ children }: React.PropsWithChildren<unknown>) {
  return (
    <View
      style={{
        paddingVertical: 24,
      }}
    >
      {children}
    </View>
  )
}

function Row({
  children,
  onPress,
}: React.PropsWithChildren<{ onPress: () => void }>) {
  return (
    <TouchableOpacity
      style={{
        flexDirection: 'row',
        borderColor: Colors.border,
        borderBottomWidth: 0.5,
      }}
      onPress={onPress}
    >
      {children}
    </TouchableOpacity>
  )
}

function Cell({
  flex,
  children,
  TextProps,
}: {
  flex: number
  children: string
  TextProps?: TextProps
}) {
  return (
    <View
      style={{
        flexDirection: 'row',
        paddingVertical: 8,
        paddingHorizontal: 10,
        alignItems: 'center',
        flex,
      }}
    >
      <Text {...TextProps}>{children}</Text>
    </View>
  )
}
type CellProps = Parameters<typeof Cell>[0]

const HeaderTextStyle: TextProps['style'] = {
  fontWeight: '600',
  fontSize: 14,
}

export const getStatusTextStyle = (
  status: BroadcastMessageStatus | BroadcastMessageResource_Status
) => {
  switch (status) {
    case 'pending':
    case 'in_progress':
    case BroadcastMessageResource_Status.PENDING:
    case BroadcastMessageResource_Status.IN_PROGRESS:
      return {
        color: Colors.accent,
      }
    case 'succeeded':
    case BroadcastMessageResource_Status.SUCCEEDED:
      return {
        color: Colors.primary,
      }
    case 'partially_failed':
    case 'failed':
    case BroadcastMessageResource_Status.PARTIALLY_FAILED:
    case BroadcastMessageResource_Status.FAILED:
      return {
        color: Colors.caution,
      }
    default:
      return undefined
  }
}

export const getStatusDisplayLabel = (
  status: BroadcastMessageStatus | BroadcastMessageResource_Status
): string => {
  switch (status) {
    case 'pending':
    case BroadcastMessageResource_Status.PENDING:
      return t('配信予約中')
    case 'in_progress':
    case BroadcastMessageResource_Status.IN_PROGRESS:
      return t('配信中')
    case 'succeeded':
    case BroadcastMessageResource_Status.SUCCEEDED:
      return t('配信完了')
    case 'partially_failed':
    case BroadcastMessageResource_Status.PARTIALLY_FAILED:
      return t('一部配信エラー')
    case 'failed':
    case BroadcastMessageResource_Status.FAILED:
      return t('エラー')
    default:
      return ''
  }
}

function StatusCell({
  children,
  ...other
}: { children: BroadcastMessageStatus } & Omit<CellProps, 'children'>) {
  const textStyle: TextProps['style'] = useMemo(
    () => getStatusTextStyle(children),
    [children]
  )
  const displayStatus: string = useMemo(
    () => getStatusDisplayLabel(children),
    [children]
  )

  return (
    <Cell {...other} TextProps={{ style: textStyle }}>
      {displayStatus}
    </Cell>
  )
}

interface BroadcastTableProps {
  broadcastItems: BroadcastMessageItem[]
  onPressItem: (item: BroadcastMessageItem) => void
  PaginationProps: ComponentProps<typeof Pagination>
}

export default function BroadcastTable({
  broadcastItems,
  onPressItem,
  PaginationProps,
}: BroadcastTableProps) {
  return (
    <Table>
      <Header>
        <Cell flex={1} TextProps={{ style: HeaderTextStyle }}>
          {t('配信日時')}
        </Cell>
        <Cell flex={2} TextProps={{ style: HeaderTextStyle }}>
          {t('メッセージ')}
        </Cell>
        <Cell flex={1} TextProps={{ style: HeaderTextStyle }}>
          {t('配信人数')}
        </Cell>
        <Cell flex={1} TextProps={{ style: HeaderTextStyle }}>
          {t('ステータス')}
        </Cell>
      </Header>
      <Body>
        {broadcastItems?.map((item, index) => (
          <Row
            key={index}
            onPress={() => {
              onPressItem(item)
            }}
          >
            <Cell flex={1}>
              {dayjs(item.delivery_at).tz().format('YYYY/MM/DD HH:mm')}
            </Cell>
            <Cell flex={2} TextProps={{ numberOfLines: 2 }}>
              {item.message_contents.find((i) => i.image == null)?.body ?? ''}
            </Cell>
            <Cell flex={1}>{`${item.number_of_sent ?? '--'}`}</Cell>
            <StatusCell flex={1}>{item.status}</StatusCell>
          </Row>
        ))}
      </Body>
      <Footer>
        <Pagination {...PaginationProps} />
      </Footer>
    </Table>
  )
}
