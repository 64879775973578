import { Button } from '@mui/material'
import DataGrid from 'components/Shared/DataGrid'
import PageHeader from 'components/Shared/PageHeader'
import { useRestaurantOwnershipVerificationRequests } from 'models/Owner'
import React from 'react'
import { useNavigate } from 'react-router'

export default function RestaurantOwnershipVerificationRequests() {
  const navigate = useNavigate()

  const { data } = useRestaurantOwnershipVerificationRequests()

  return (
    <PageHeader title="営業許可証申請一覧">
      <DataGrid
        data={data?.restaurant_ownership_verification_requests}
        loading={data == null}
        rowKey={(record) => record.id}
        columns={[
          {
            key: 'action',
            render: (record) => (
              <Button
                variant="outlined"
                onClick={() => {
                  navigate(
                    `/restaurant_ownership_verification_requests/${record.id}`
                  )
                }}
              >
                詳細
              </Button>
            ),
          },
          {
            title: 'オーナー名',
            key: 'owner.name',
            render: (record) => record.owner.name,
          },
          {
            title: '許可されたいレストラン名',
            key: 'restaurant.name',
            render: (record) => record.restaurant.name,
          },
          {
            title: '営業許可証',
            key: 'has_business_license',
            render: (record) => (record.has_business_license ? 'あり' : 'なし'),
          },
          {
            title: '認証コード',
            key: 'has_verification_code',
            render: (record) => (record.has_business_license ? 'あり' : 'なし'),
          },
        ]}
        sx={{ minHeight: 400 }}
      />
    </PageHeader>
  )
}
