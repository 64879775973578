import {
  ReviewResource,
  ReviewResource_Status,
} from '@hello-ai/proto/src/gen/auto_reserve/admin/review/review_resource'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  TextareaAutosize,
} from '@mui/material'
import Breadcrumb from 'components/Shared/Breadcrumb'
import PageHeader from 'components/Shared/PageHeader'
import { displayToastInfo } from 'components/Shared/Toast'
import { onError, useToken } from 'models/Auth'
import { getStatusName, reviewClient, reviewService } from 'models/Review'
import { callRpc, rpcOptions } from 'modules/rpc'
import { oneDecimalRefine, parseFloatOrKeepOriginal } from 'modules/zod'
import React, { useCallback } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router'
import { z } from 'zod'

const schema = z.object({
  status: z.number(),
  content: z.string(),
  rating: z
    .number()
    .min(0)
    .max(5)
    .optional()
    .refine(oneDecimalRefine)
    .or(z.literal('')),
  ratingFood: z
    .number()
    .min(0)
    .max(5)
    .optional()
    .refine(oneDecimalRefine)
    .or(z.literal('')),
  ratingService: z
    .number()
    .min(0)
    .max(5)
    .optional()
    .refine(oneDecimalRefine)
    .or(z.literal('')),
  ratingAtmosphere: z
    .number()
    .min(0)
    .max(5)
    .optional()
    .refine(oneDecimalRefine)
    .or(z.literal('')),
  ratingCostPerformance: z
    .number()
    .min(0)
    .max(5)
    .optional()
    .refine(oneDecimalRefine)
    .or(z.literal('')),
})

function ReviewEditForm({
  review,
  reviewId,
}: {
  reviewId?: string
  review?: ReviewResource
}) {
  const navigate = useNavigate()
  const token = useToken()

  const onFinish = useCallback(
    async (values: z.TypeOf<typeof schema>) => {
      if (reviewId === undefined) return

      const params = {
        id: +reviewId,
        status: values.status,
        content: values.content,
        rating:
          values.rating !== undefined && values.rating !== ''
            ? { value: values.rating }
            : undefined,
        ratingFood:
          values.ratingFood !== undefined && values.ratingFood !== ''
            ? { value: values.ratingFood }
            : undefined,
        ratingService:
          values.ratingService !== undefined && values.ratingService !== ''
            ? { value: values.ratingService }
            : undefined,
        ratingAtmosphere:
          values.ratingAtmosphere !== undefined &&
          values.ratingAtmosphere !== ''
            ? { value: values.ratingAtmosphere }
            : undefined,
        ratingCostPerformance:
          values.ratingCostPerformance !== undefined &&
          values.ratingCostPerformance !== ''
            ? { value: values.ratingCostPerformance }
            : undefined,
      }

      const { error } = await callRpc(
        reviewClient.update(params, rpcOptions({ token }))
      )
      if (error != null) {
        onError(error)
        return
      }

      displayToastInfo('更新しました')

      navigate(`/reviews/${reviewId}`)
    },
    [reviewId, token, navigate]
  )

  const form = useForm({
    mode: 'onChange',
    resolver: zodResolver(schema),
    defaultValues: {
      status: review?.status ?? ReviewResource_Status.JUDGING,
      content: review?.content ?? '',
      rating: review?.rating?.value,
      ratingFood: review?.ratingFood?.value,
      ratingService: review?.ratingService?.value,
      ratingAtmosphere: review?.ratingAtmosphere?.value,
      ratingCostPerformance: review?.ratingCostPerformance?.value,
    },
  })

  return (
    <Box
      component="form"
      sx={{ display: 'flex', flexDirection: 'column', rowGap: 2 }}
      onSubmit={form.handleSubmit(onFinish)}
    >
      <Controller
        control={form.control}
        name="status"
        render={({ field, fieldState }) => {
          const { error } = fieldState
          return (
            <FormControl
              error={error?.message != null && !error.message}
              required
            >
              <FormLabel>ステータス</FormLabel>
              <RadioGroup
                name={field.name}
                onChange={(e) => {
                  const value = Number(e.target.value)
                  if (!isNaN(value)) {
                    field.onChange(value)
                  }
                }}
                value={field.value === undefined ? '' : field.value}
                sx={{ display: 'flex', flexDirection: 'row' }}
              >
                <FormControlLabel
                  value={ReviewResource_Status.JUDGING}
                  control={<Radio />}
                  label={getStatusName(ReviewResource_Status.JUDGING)}
                />
                <FormControlLabel
                  value={ReviewResource_Status.PUBLISHED}
                  control={<Radio />}
                  label={getStatusName(ReviewResource_Status.PUBLISHED)}
                />
                <FormControlLabel
                  value={ReviewResource_Status.UNPUBLISHED}
                  control={<Radio />}
                  label={getStatusName(ReviewResource_Status.UNPUBLISHED)}
                />
                <FormControlLabel
                  value={ReviewResource_Status.ANONYMOUS}
                  control={<Radio />}
                  label={getStatusName(ReviewResource_Status.ANONYMOUS)}
                />
              </RadioGroup>
              <FormHelperText>{error?.message}</FormHelperText>
            </FormControl>
          )
        }}
      />

      <Controller
        control={form.control}
        name="content"
        render={({ field, fieldState }) => {
          const { error } = fieldState
          return (
            <FormControl
              error={error?.message != null && !error.message}
              sx={{ width: '100%' }}
            >
              <FormLabel sx={{ marginBottom: '8px' }}>内容</FormLabel>
              <TextareaAutosize
                {...field}
                minRows={8}
                maxRows={12}
                style={{ width: '100%', padding: '8px', borderRadius: '3px' }}
              />
              <FormHelperText>{error?.message}</FormHelperText>
            </FormControl>
          )
        }}
      />

      <Controller
        control={form.control}
        name="rating"
        render={({ field, fieldState }) => {
          const { error } = fieldState
          return (
            <TextField
              {...field}
              label="総合評価"
              type="number"
              helperText={error?.message}
              error={error?.message != null && !error.message}
              onChange={(e) =>
                field.onChange(parseFloatOrKeepOriginal(e.target.value))
              }
              inputProps={{ step: 0.1, min: 1.0, max: 5.0, precision: 1 }}
            />
          )
        }}
      />

      <Controller
        control={form.control}
        name="ratingFood"
        render={({ field, fieldState }) => {
          const { error } = fieldState
          return (
            <TextField
              {...field}
              label="料理評価"
              type="number"
              helperText={error?.message}
              error={error?.message != null && !error.message}
              onChange={(e) =>
                field.onChange(parseFloatOrKeepOriginal(e.target.value))
              }
              inputProps={{ step: 0.1, min: 1.0, max: 5.0, precision: 1 }}
            />
          )
        }}
      />

      <Controller
        control={form.control}
        name="ratingService"
        render={({ field, fieldState }) => {
          const { error } = fieldState
          return (
            <TextField
              {...field}
              label="サービス評価"
              type="number"
              helperText={error?.message}
              error={error?.message != null && !error.message}
              onChange={(e) =>
                field.onChange(parseFloatOrKeepOriginal(e.target.value))
              }
              inputProps={{ step: 0.1, min: 1.0, max: 5.0, precision: 1 }}
            />
          )
        }}
      />

      <Controller
        control={form.control}
        name="ratingAtmosphere"
        render={({ field, fieldState }) => {
          const { error } = fieldState
          return (
            <TextField
              {...field}
              label="雰囲気評価"
              type="number"
              helperText={error?.message}
              error={error?.message != null && !error.message}
              onChange={(e) =>
                field.onChange(parseFloatOrKeepOriginal(e.target.value))
              }
              inputProps={{ step: 0.1, min: 1.0, max: 5.0, precision: 1 }}
            />
          )
        }}
      />

      <Controller
        control={form.control}
        name="ratingCostPerformance"
        render={({ field, fieldState }) => {
          const { error } = fieldState
          return (
            <TextField
              {...field}
              label="コスパ評価"
              type="number"
              helperText={error?.message}
              error={error?.message != null && !error.message}
              onChange={(e) =>
                field.onChange(parseFloatOrKeepOriginal(e.target.value))
              }
              inputProps={{ step: 0.1, min: 1.0, max: 5.0, precision: 1 }}
            />
          )
        }}
      />

      <Button variant="contained" color="primary" type="submit">
        {review ? '更新する' : '追加する'}
      </Button>
    </Box>
  )
}

export default function ReviewFormWrap() {
  const { reviewId } = useParams<{ reviewId?: string }>()
  const params =
    reviewId === undefined
      ? undefined
      : {
          id: +reviewId,
        }
  const { data: review } = reviewService.useGet(params)

  const routes = review
    ? [
        {
          path: '/reviews',
          breadcrumbName: '口コミ一覧',
        },
        {
          path: `/reviews/${review.id}`,
          breadcrumbName: `${review.id}`,
        },
        {
          path: `/reviews/${review.id}/edit`,
          breadcrumbName: '編集',
        },
      ]
    : [
        {
          path: '/reviews',
          breadcrumbName: '口コミ一覧',
        },
        {
          path: `/reviews/new`,
          breadcrumbName: '追加',
        },
      ]

  if (reviewId !== undefined && review === undefined) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <CircularProgress />
      </Box>
    )
  }

  return (
    <>
      <Breadcrumb routes={routes} />
      <PageHeader title={review ? '編集' : '追加'} sx={{ marginTop: '32px' }}>
        <ReviewEditForm reviewId={reviewId} review={review} />
      </PageHeader>
    </>
  )
}
