import { UserResource } from '@hello-ai/proto/src/gen/auto_reserve/admin/user/user_resource'
import { ListFilters } from '@hello-ai/proto/src/gen/auto_reserve/admin/user/user_service'
import { StringValue } from '@hello-ai/proto/src/gen/google/protobuf/wrappers'
import {
  Avatar,
  Box,
  Button,
  FormGroup,
  TextField,
  Typography,
} from '@mui/material'
import foodieImg from 'assets/images/foodie.png'
import { FilterAction } from 'components/Filter/FilterAction'
import DataGrid, { usePagination } from 'components/Shared/DataGrid'
import PageHeader from 'components/Shared/PageHeader'
import { userService } from 'models/User'
import React, { useState } from 'react'
import { Image } from 'react-native'
import { useNavigate } from 'react-router'

function SubmitDetailPageButton({ user }: { user: UserResource }) {
  const navigate = useNavigate()

  return (
    <Button variant="outlined" onClick={() => navigate(`/users/${user.id}`)}>
      詳細
    </Button>
  )
}

type FilterProps = {
  onFilter: (value: StringValue | undefined) => void
  defaultValue: StringValue | undefined
}

function FilterEmailAddress({ onFilter, defaultValue }: FilterProps) {
  const [email, setEmail] = useState(defaultValue?.value ?? '')

  return (
    <FormGroup sx={{ p: 2 }}>
      <TextField
        sx={{ my: 1 }}
        size="small"
        label="メールアドレス"
        value={email}
        onChange={(event) => setEmail(event.target.value)}
      />
      <FilterAction
        onReset={() => {
          setEmail('')
        }}
        onSubmit={() => {
          if (email === '') {
            onFilter(undefined)
            return
          }
          const message = StringValue.create()
          message.value = email
          onFilter(message)
        }}
      />
    </FormGroup>
  )
}

function FilterPhoneNumber({ onFilter, defaultValue }: FilterProps) {
  const [phoneNumber, setPhonNumber] = useState(defaultValue?.value ?? '')

  return (
    <FormGroup sx={{ p: 2 }}>
      <TextField
        sx={{ my: 1 }}
        size="small"
        label="電話番号"
        value={phoneNumber}
        onChange={(event) => setPhonNumber(event.target.value)}
      />
      <FilterAction
        onReset={() => {
          setPhonNumber('')
        }}
        onSubmit={() => {
          if (phoneNumber === '') {
            onFilter(undefined)
            return
          }
          const message = StringValue.create()
          message.value = phoneNumber
          onFilter(message)
        }}
      />
    </FormGroup>
  )
}

export default function Users() {
  const pagination = usePagination()
  const [filters, setFilters] = useState<ListFilters>({
    phoneNumber: undefined,
    email: undefined,
  })
  const { data } = userService.useList({
    page: pagination.page,
    per: pagination.perPage,
    filters,
  })

  return (
    <PageHeader title="ユーザー一覧">
      <DataGrid
        sx={{ maxHeight: 400 }}
        data={data?.users}
        rowKey={(row) => `user-${row.id}`}
        columns={[
          {
            key: 'action',
            render: (row) => <SubmitDetailPageButton user={row} />,
          },
          {
            key: 'name',
            title: 'ユーザー',
            render: (row) => (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <Avatar
                  src={row.serializedImage?.sm}
                  sx={{ width: 40, height: 40 }}
                />
                <Typography sx={{ ml: 1 }} variant="body1">
                  {row.name}
                </Typography>
                {row.isFoodie && (
                  <Image
                    source={{
                      uri: foodieImg,
                      width: 20,
                      height: 20,
                    }}
                    style={{
                      marginLeft: 8,
                    }}
                  />
                )}
              </Box>
            ),
          },
          {
            key: 'username',
            title: 'ユーザーID',
            render: (row) => row.username,
          },
          {
            key: 'email',
            title: 'メールアドレス',
            render: (row) => row.email,
            filter: {
              key: 'email',
              FilterContent: FilterEmailAddress,
            },
          },
          {
            key: 'phoneNumber',
            title: '電話番号',
            render: (row) => row.phoneNumber,
            filter: {
              key: 'phoneNumber',
              FilterContent: FilterPhoneNumber,
            },
          },
        ]}
        loading={data === undefined}
        pagination={pagination}
        filterState={filters}
        onChangeFilterValue={(key, newValue) => {
          setFilters((prev) => {
            // FIXME: #6274 を参考にimmutable updaterに修正してください
            prev[key] = newValue
            return { ...prev }
          })
        }}
      />
    </PageHeader>
  )
}
