// @generated by protobuf-ts 2.8.1 with parameter long_type_number,generate_dependencies
// @generated from protobuf file "auto_reserve/admin/owner/owner_service.proto" (package "auto_reserve.admin.owner", syntax proto3)
// tslint:disable
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { Owner } from "./owner_service";
import type { GetRestaurantsResponse } from "./owner_service";
import type { OwnerResource } from "./owner_resource";
import type { GetRequest } from "./owner_service";
import type { ListResponse } from "./owner_service";
import type { ListRequest } from "./owner_service";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { AllResponse } from "./owner_service";
import type { AllRequest } from "./owner_service";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service auto_reserve.admin.owner.Owner
 */
export interface IOwnerClient {
  /**
   * @generated from protobuf rpc: All(auto_reserve.admin.owner.AllRequest) returns (auto_reserve.admin.owner.AllResponse);
   */
  all(
    input: AllRequest,
    options?: RpcOptions,
  ): UnaryCall<AllRequest, AllResponse>;
  /**
   * @generated from protobuf rpc: List(auto_reserve.admin.owner.ListRequest) returns (auto_reserve.admin.owner.ListResponse);
   */
  list(
    input: ListRequest,
    options?: RpcOptions,
  ): UnaryCall<ListRequest, ListResponse>;
  /**
   * @generated from protobuf rpc: Get(auto_reserve.admin.owner.GetRequest) returns (auto_reserve.admin.owner.OwnerResource);
   */
  get(
    input: GetRequest,
    options?: RpcOptions,
  ): UnaryCall<GetRequest, OwnerResource>;
  /**
   * @generated from protobuf rpc: GetRestaurants(auto_reserve.admin.owner.GetRequest) returns (auto_reserve.admin.owner.GetRestaurantsResponse);
   */
  getRestaurants(
    input: GetRequest,
    options?: RpcOptions,
  ): UnaryCall<GetRequest, GetRestaurantsResponse>;
}
/**
 * @generated from protobuf service auto_reserve.admin.owner.Owner
 */
export class OwnerClient implements IOwnerClient, ServiceInfo {
  typeName = Owner.typeName;
  methods = Owner.methods;
  options = Owner.options;
  constructor(private readonly _transport: RpcTransport) {}
  /**
   * @generated from protobuf rpc: All(auto_reserve.admin.owner.AllRequest) returns (auto_reserve.admin.owner.AllResponse);
   */
  all(
    input: AllRequest,
    options?: RpcOptions,
  ): UnaryCall<AllRequest, AllResponse> {
    const method = this.methods[0],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<AllRequest, AllResponse>(
      "unary",
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: List(auto_reserve.admin.owner.ListRequest) returns (auto_reserve.admin.owner.ListResponse);
   */
  list(
    input: ListRequest,
    options?: RpcOptions,
  ): UnaryCall<ListRequest, ListResponse> {
    const method = this.methods[1],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<ListRequest, ListResponse>(
      "unary",
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: Get(auto_reserve.admin.owner.GetRequest) returns (auto_reserve.admin.owner.OwnerResource);
   */
  get(
    input: GetRequest,
    options?: RpcOptions,
  ): UnaryCall<GetRequest, OwnerResource> {
    const method = this.methods[2],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<GetRequest, OwnerResource>(
      "unary",
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: GetRestaurants(auto_reserve.admin.owner.GetRequest) returns (auto_reserve.admin.owner.GetRestaurantsResponse);
   */
  getRestaurants(
    input: GetRequest,
    options?: RpcOptions,
  ): UnaryCall<GetRequest, GetRestaurantsResponse> {
    const method = this.methods[3],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<GetRequest, GetRestaurantsResponse>(
      "unary",
      this._transport,
      method,
      opt,
      input,
    );
  }
}
