import i18n, { InitOptions, TOptions } from 'i18next'
import { initReactI18next } from 'react-i18next'
import { SupportedLocale, initLocale } from '../locale'

import i18nextConfig from '@hello-ai/ar_config/i18next/base'

const fileMap: { [key: string]: string } = {
  ja: 'ja',
  en: 'en',
  'zh-cn': 'zh-CN',
  'zh-hk': 'zh-HK',
  'zh-tw': 'zh-TW',
  ko: 'ko',
  th: 'th',
}

function preprocess(data: Record<string, string>) {
  const result: Record<string, string> = {}
  Object.entries(data).forEach(([k, v]) => {
    if (v.length > 0) {
      result[k] = v
    }
  })
  return result
}

function loadLocale(locale: string) {
  const fileName = fileMap[locale.toLowerCase()]

  // 日本語の時は読み込まなくて良い
  if (fileName === 'ja') {
    return
  }

  // translationsはサイズ が大きいので必要な時に読み込む
  import(`@hello-ai/ar_i18n/src/translations/${fileName}.json`).then((v) => {
    i18n.addResourceBundle(
      locale,
      'translation',
      preprocess(v.default),
      true,
      true
    )
  })

  /*
  - namespace:  ' restaurant' の追加
  - 「レストランのinfo系に関する文言」を追加するためのnamespace
  */
  // 「日本語としては同じ文言でも翻訳した時に別のニュアンスにしたい」というcaseのためにnamespaceを追加
  import(`@hello-ai/ar_i18n/src/translations/restaurant/${fileName}.json`).then(
    (v) => {
      i18n.addResourceBundle(
        locale,
        'restaurant',
        preprocess(v.default),
        true,
        true
      )
    }
  )
}

/** call this function as early as possible (in index.js before App's initialization)  */
export function initI18n(locale: SupportedLocale) {
  initLocale(locale)
  i18n.use(initReactI18next).init({
    ...(i18nextConfig as Partial<InitOptions>),
    compatibilityJSON: 'v3',
    debug: false,
    lng: locale,
    fallbackLng: {
      zh: ['zh-CN', 'zh-TW', 'zh-HK', 'ja'],
      'zh-TW': ['zh-CN', 'ja'],
    },
    interpolation: {
      escapeValue: false,
    },
  })

  loadLocale(locale)
}

export const t: (key: string, options?: TOptions) => string = i18n.t.bind(i18n)
