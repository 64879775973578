import React from 'react'
import { Button, Box } from '@mui/material'

export function FilterAction({
  onReset,
  onSubmit,
}: {
  onReset: () => void
  onSubmit: () => void
}) {
  return (
    <Box sx={{ display: 'flex', columnGap: 2 }}>
      <Button size="small" onClick={onReset}>
        リセット
      </Button>
      <Button variant="contained" size="small" onClick={onSubmit}>
        OK
      </Button>
    </Box>
  )
}
