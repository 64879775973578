import { Button } from '@mui/material'
import DataGrid from 'components/Shared/DataGrid'
import PageHeader from 'components/Shared/PageHeader'
import { restaurantService } from 'models/Restaurant'
import dayjs from 'dayjs'
import React from 'react'
import { useNavigate } from 'react-router'

export function RestaurantOrderContracts({
  restaurantId,
}: {
  restaurantId?: string
}) {
  const navigate = useNavigate()
  const { data } = restaurantService.useGetOrderContracts({
    id: +restaurantId!,
  })

  return (
    <PageHeader
      title="セルフオーダー"
      extra={[
        <Button
          key="new"
          variant="contained"
          onClick={() =>
            navigate(
              `/restaurants/${restaurantId}/restaurant_order_contracts/new`
            )
          }
        >
          作成
        </Button>,
      ]}
    >
      <DataGrid
        data={data?.restaurantOrderContracts}
        columns={[
          {
            key: 'action',
            render: (restaurantOrderContract) => (
              <Button
                variant="outlined"
                onClick={() =>
                  navigate(
                    `/restaurant_order_contracts/${restaurantOrderContract.id}/edit`
                  )
                }
              >
                編集
              </Button>
            ),
          },
          {
            title: '期間',
            key: 'term',
            render: (restaurantOrderContract) =>
              `${
                restaurantOrderContract.startDate !== undefined
                  ? dayjs
                      .unix(restaurantOrderContract.startDate.seconds)
                      .format('YYYY/MM/DD')
                  : ''
              }~${
                restaurantOrderContract.endDate !== undefined
                  ? dayjs
                      .unix(restaurantOrderContract.endDate.seconds)
                      .format('YYYY/MM/DD')
                  : ''
              }`,
          },
          {
            title: '月額費用',
            key: 'monthlyFee',
            render: (restaurantOrderContract) =>
              `${restaurantOrderContract.monthlyFee}円`,
          },
        ]}
        loading={data === undefined}
        rowKey={(restaurantOrderContract) =>
          `orderContract${restaurantOrderContract.id}`
        }
        sx={{ minHeight: 400 }}
      />
    </PageHeader>
  )
}
