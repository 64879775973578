import React from 'react'
import {
  Image,
  ImageProps,
  StyleProp,
  StyleSheet,
  ViewStyle,
} from 'react-native'
import {
  ExtendedImageSourceProp,
  resolveImageSource,
} from '../modules/resolveImageSource'
import { AspectRatio } from './AspectRatio'

type AspectImageProps = Omit<ImageProps, 'style' | 'source'> & {
  style?: StyleProp<ViewStyle>
  source?: ExtendedImageSourceProp
}

export function AspectImage({ style, ...props }: AspectImageProps) {
  const { aspectRatio } = StyleSheet.flatten(style)

  const ratio = typeof aspectRatio === 'number' ? aspectRatio : 1

  const resolvedSource = resolveImageSource(props.source)

  return (
    <AspectRatio ratio={ratio} style={style}>
      <Image
        style={{
          width: '100%',
          height: '100%',
        }}
        {...props}
        source={resolvedSource}
      />
    </AspectRatio>
  )
}
