import React from 'react'
import { View } from 'react-native'
import { useNavigate } from 'react-router'
import BroadcastMessageList from 'components/BroadcastMessage/List'
import PageHeader from 'components/Shared/PageHeader'
import { Button } from '@mui/material'

export default function BroadcastMessages() {
  const navigate = useNavigate()
  return (
    <View>
      <PageHeader
        title="配信一覧"
        extra={[
          <Button
            key="create"
            color="primary"
            variant="contained"
            onClick={() => {
              navigate('/broadcast_messages/new')
            }}
          >
            メッセージを作成
          </Button>,
        ]}
      >
        <BroadcastMessageList />
      </PageHeader>
    </View>
  )
}
