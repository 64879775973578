import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { restaurantOrderContractService } from 'models/RestaurantOrderContract'
import dayjs from 'dayjs'
import PageHeader from 'components/Shared/PageHeader'
import DataGrid, { usePagination } from 'components/Shared/DataGrid'
import { Button } from '@mui/material'

export default function RestaurantOrderContracts() {
  const navigate = useNavigate()
  const pagination = usePagination()
  const { data } = restaurantOrderContractService.useList({
    page: pagination.page,
    per: pagination.perPage,
  })

  return (
    <PageHeader title="セルフオーダー契約一覧">
      <DataGrid
        data={data?.restaurantOrderContracts}
        pagination={pagination}
        loading={data === undefined}
        // totalCount={data?.totalCount ?? 0}
        rowKey={(record) => record.id}
        columns={[
          {
            key: 'action',
            render: (restaurantOrderContract) => (
              <Button
                variant="outlined"
                onClick={() =>
                  navigate(
                    `/restaurant_order_contracts/${restaurantOrderContract.id}/edit`
                  )
                }
              >
                編集
              </Button>
            ),
          },
          {
            title: 'レストラン',
            key: 'restaurant',
            render: (restaurantOrderContract) => (
              <Link
                to={`/restaurants/${restaurantOrderContract.restaurant?.id}`}
              >
                {restaurantOrderContract.restaurant?.name}
              </Link>
            ),
          },
          {
            title: '期間',
            key: 'term',
            render: (restaurantOrderContract) =>
              `${
                restaurantOrderContract.startDate !== undefined
                  ? dayjs
                      .unix(restaurantOrderContract.startDate.seconds)
                      .format('YYYY/MM/DD')
                  : ''
              }~${
                restaurantOrderContract.endDate !== undefined
                  ? dayjs
                      .unix(restaurantOrderContract.endDate.seconds)
                      .format('YYYY/MM/DD')
                  : ''
              }`,
          },
          {
            title: '月額費用',
            key: 'monthlyFee',
            render: (restaurantOrderContract) =>
              `${restaurantOrderContract.monthlyFee}円`,
          },
          {
            title: 'VISA,MASTER,AMEX決済手数料',
            key: 'majorCardBrandFeeRate',
            render: (restaurantOrderContract) =>
              restaurantOrderContract.majorCardBrandFeeRate,
          },
          {
            title: 'その他決済手数料',
            key: 'minorCardBrandFeeRate',
            render: (restaurantOrderContract) =>
              restaurantOrderContract.minorCardBrandFeeRate,
          },
          {
            title: '契約期間',
            key: 'contractPeriod',
            render: (restaurantOrderContract) =>
              `${restaurantOrderContract.contractPeriod}ヶ月`,
          },
          {
            title: 'メモ',
            key: 'memo',
            render: (restaurantOrderContract) => restaurantOrderContract.memo,
          },
          {
            title: '作成日時',
            key: 'memo',
            render: (restaurantOrderContract) =>
              restaurantOrderContract.createdAt !== undefined
                ? dayjs
                    .unix(restaurantOrderContract.createdAt.seconds)
                    .format('YYYY/MM/DD hh:mm')
                : '',
          },
        ]}
        sx={{ minHeight: 400 }}
      />
    </PageHeader>
  )
}
