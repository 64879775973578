import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material'
import React from 'react'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'

interface ConfirmModalProps {
  type: 'delete' | 'warning'
  isVisible: boolean
  title: string
  onClose: () => void
  onConfirm: () => Promise<void> | void
}

export default function ConfirmModal({
  type,
  isVisible,
  title,
  onClose,
  onConfirm,
}: ConfirmModalProps) {
  return (
    <Dialog open={isVisible} onClose={onClose}>
      <DialogTitle
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <WarningAmberIcon color="warning" sx={{ mr: 1 }} />
        {title}
      </DialogTitle>
      <DialogContent>
        <Typography>本当によろしいですか？</Typography>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={onClose}>
          いいえ
        </Button>
        <Button color={type} onClick={onConfirm}>
          はい
        </Button>
      </DialogActions>
    </Dialog>
  )
}
