import { OwnerInvoiceResource_Status } from '@hello-ai/proto/src/gen/auto_reserve/admin/owner_invoice/owner_invoice_resource'
import { OwnerInvoiceClient } from '@hello-ai/proto/src/gen/auto_reserve/admin/owner_invoice/owner_invoice_service.client'
import { assertNever } from 'modules/assert'
import { createRpcService } from 'modules/rpc'

export const ownerInvoiceService = createRpcService(OwnerInvoiceClient)
export const ownerInvoiceClient = ownerInvoiceService.client

export function getStatusName(value: OwnerInvoiceResource_Status) {
  switch (value) {
    case OwnerInvoiceResource_Status.DRAFT:
      return 'ドラフト'
    case OwnerInvoiceResource_Status.BILLED:
      return '請求済'
    case OwnerInvoiceResource_Status.PAID:
      return '支払い済'
    case OwnerInvoiceResource_Status.NOT_BILLED:
      return '請求なし'
    default: {
      assertNever(value)
    }
  }
}
