import { OwnerInvoiceResource_Status } from '@hello-ai/proto/src/gen/auto_reserve/admin/owner_invoice/owner_invoice_resource'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import Breadcrumb from 'components/Shared/Breadcrumb'
import PageHeader from 'components/Shared/PageHeader'
import { displayToastInfo } from 'components/Shared/Toast'
import dayjs from 'dayjs'
import { toInteger } from 'lodash'
import { onError, useToken } from 'models/Auth'
import { ownerService } from 'models/Owner'
import { getStatusName, ownerInvoiceService } from 'models/OwnerInvoice'
import React, { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { View } from 'react-native'
import { useNavigate, useParams } from 'react-router'
import { z } from 'zod'

const createStatusItem = (status: number) => ({
  label: getStatusName(status),
  value: status,
})
const STATUS_LIST = [
  createStatusItem(OwnerInvoiceResource_Status.DRAFT),
  createStatusItem(OwnerInvoiceResource_Status.BILLED),
  createStatusItem(OwnerInvoiceResource_Status.PAID),
  createStatusItem(OwnerInvoiceResource_Status.NOT_BILLED),
]

const schema = z.object({
  ownerId: z.number(),
  status: z.string(),
  due: z.date(),
  contents: z.string(),
  amount: z.preprocess(Number, z.number()),
})

type Schema = z.infer<typeof schema>

export default function ReviewForm() {
  const token = useToken()
  const navigate = useNavigate()
  const { ownerInvoiceId } = useParams<{ ownerInvoiceId?: string }>()
  const params =
    ownerInvoiceId === undefined
      ? undefined
      : {
          id: ownerInvoiceId,
        }
  const { data: ownerInvoice } = ownerInvoiceService.useGet(params)
  const { data } = ownerService.useAll({})

  const form = useForm<Schema>({
    mode: 'onChange',
    resolver: zodResolver(schema),
  })

  useEffect(() => {
    if (ownerInvoice != null) {
      if (ownerInvoice.owner?.id == null) return
      form.reset({
        ownerId: ownerInvoice.owner?.id,
        status: `${ownerInvoice.status}`,
        due: ownerInvoice.due ? new Date(ownerInvoice.due.nanos) : new Date(),
        contents: ownerInvoice.contents,
        amount: ownerInvoice.amount,
      })
    } else {
      form.reset({
        ownerId: data?.owners[0].id,
        status: `${OwnerInvoiceResource_Status.DRAFT}`,
        due: new Date(),
        contents: '',
        amount: 0,
      })
    }
  }, [form, ownerInvoice, data])

  const routes =
    ownerInvoice !== undefined
      ? [
          {
            path: '/owner_invoices',
            breadcrumbName: '請求一覧',
          },
          {
            path: `/owner_invoices/${ownerInvoice.id}`,
            breadcrumbName: `${ownerInvoice.id}`,
          },
          {
            path: `/owner_invoices/${ownerInvoice.id}/edit`,
            breadcrumbName: '編集',
          },
        ]
      : [
          {
            path: '/owner_invoices',
            breadcrumbName: '請求一覧',
          },
          {
            path: `/owner_invoices/new`,
            breadcrumbName: '追加',
          },
        ]

  if (ownerInvoiceId !== undefined && ownerInvoice === undefined) return null

  const onCreate = async (values: Schema) => {
    if (ownerInvoiceId !== undefined) return
    const { response, error } = await ownerInvoiceService.create(token, {
      ...values,
      due: dayjs(values.due).format('YYYY-MM-DD'),
      status: toInteger(values.status),
    })

    if (error != null) {
      onError(error)
      return
    }

    if (response === undefined) return null

    displayToastInfo('作成しました')

    navigate(`/owner_invoices/${response.id}`)
  }

  const onUpdate = async (values: Schema) => {
    if (ownerInvoiceId === undefined) return
    const { error } = await ownerInvoiceService.update(token, {
      ...values,
      id: ownerInvoiceId,
      due: dayjs(values.due).format('YYYY-MM-DD'),
      status: toInteger(values.status),
    })

    if (error != null) {
      onError(error)
      return
    }

    displayToastInfo('更新しました')

    navigate(`/owner_invoices/${ownerInvoiceId}`)
  }

  return (
    <View>
      <Breadcrumb routes={routes} />
      <PageHeader
        title={ownerInvoice !== undefined ? '編集' : '追加'}
        sx={{ mt: 4 }}
      >
        <Box
          component="form"
          sx={{ display: 'grid', flexDirection: 'column', rowGap: 2 }}
          onSubmit={form.handleSubmit((values) => {
            if (ownerInvoiceId !== undefined) {
              onUpdate(values)
            } else {
              onCreate(values)
            }
          })}
        >
          <Controller
            control={form.control}
            name="ownerId"
            render={({ field, fieldState }) => {
              const { error } = fieldState
              return (
                <TextField
                  {...field}
                  value={`${field.value}`}
                  select
                  label="オーナー"
                  autoFocus
                  helperText={error?.message}
                  error={error !== undefined && error.message !== ''}
                  fullWidth
                >
                  {data?.owners.map((owner) => (
                    <MenuItem key={owner.id} value={owner.id}>
                      {owner.name}
                    </MenuItem>
                  ))}
                </TextField>
              )
            }}
          />
          <Controller
            control={form.control}
            name="status"
            render={({ field, fieldState }) => {
              const { error } = fieldState
              return (
                <FormControl
                  error={error !== undefined && error.message !== ''}
                  fullWidth
                  sx={{ display: 'flex', columnGap: 2 }}
                >
                  <FormLabel id="owner_invoice_status">ステータス</FormLabel>
                  <RadioGroup
                    aria-labelledby="owner_invoice_status"
                    {...field}
                    sx={{ display: 'flex', flexDirection: 'row' }}
                    value={`${field.value}`}
                  >
                    {STATUS_LIST.map((status) => (
                      <FormControlLabel
                        {...status}
                        key={status.value}
                        control={<Radio />}
                      />
                    ))}
                  </RadioGroup>
                  <FormHelperText>{error?.message}</FormHelperText>
                </FormControl>
              )
            }}
          />
          <Box sx={{ display: 'flex', columnGap: 2 }}>
            <Controller
              control={form.control}
              name="due"
              render={({ field }) => {
                return (
                  <DatePicker
                    label="支払い期日"
                    value={field.value}
                    onChange={(value) => {
                      if (value == null) return
                      form.setValue('due', value)
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                )
              }}
            />
            <Controller
              control={form.control}
              name="amount"
              render={({ field, fieldState }) => {
                const { error } = fieldState
                return (
                  <TextField
                    {...field}
                    label="金額"
                    type="number"
                    helperText={error?.message}
                    error={error !== undefined && error.message !== ''}
                  />
                )
              }}
            />
          </Box>
          <Controller
            control={form.control}
            name="contents"
            render={({ field, fieldState }) => {
              const { error } = fieldState
              return (
                <TextField
                  {...field}
                  label="内容"
                  multiline
                  helperText={error?.message}
                  error={error !== undefined && error.message !== ''}
                />
              )
            }}
          />
          <Button
            type="submit"
            variant="contained"
            sx={{ justifySelf: 'flex-end', width: 'fit-content' }}
          >
            {ownerInvoice !== undefined ? '更新する' : '追加する'}
          </Button>
        </Box>
      </PageHeader>
    </View>
  )
}
