import {
  useRef,
  useCallback,
  useLayoutEffect,
  useState,
  useEffect,
} from 'react'

import { throttle, ThrottleSettings } from 'lodash-es'
import { useStable } from './useStable'

export function useThrottleEventCallback<T extends unknown[], R>(
  callback: (...args: T) => R,
  wait: number,
  /** optionsは後から変更できない */
  options?: ThrottleSettings
) {
  const stableOptions = useStable(() => options) // inlineでオブジェクトを渡せるよう最初の値を保存する
  const savedCallback = useRef<(...args: T) => R>()
  const savedThrottledCallback = useRef<(...args: T) => R | undefined>(() => {
    throw new Error('Cannot call an event handler while rendering.')
  })

  // Remember the latest callback.
  useLayoutEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // remember the latest throttled callback which calls the saved callback
  useLayoutEffect(() => {
    savedThrottledCallback.current = throttle(
      (...args: T) => savedCallback.current?.(...args),
      wait,
      { trailing: false, ...stableOptions }
    )
  }, [stableOptions, wait])

  return useCallback((...args: T) => {
    return savedThrottledCallback.current(...args)
  }, [])
}

export function useThrottleStateCallback<T extends unknown[], R>(
  func: (...args: T) => R,
  wait: number
) {
  const timer = useRef<NodeJS.Timeout>()
  const [enable, setEnable] = useState(true)

  const throttledCallback = useCallback(
    (...args: T) => {
      if (enable) {
        setEnable(false)
        timer.current = setTimeout(() => {
          setEnable(true)
        }, wait)
        return func(...args)
      }
    },
    [func, enable, wait]
  )

  useEffect(() => {
    return () => {
      if (timer.current != null) {
        clearTimeout(timer.current)
      }
    }
  }, [wait])

  return {
    throttledCallback,
    enable,
  }
}

export default useThrottleEventCallback
