// @generated by protobuf-ts 2.8.1 with parameter long_type_number,generate_dependencies
// @generated from protobuf file "auto_reserve/admin/review/review_service.proto" (package "auto_reserve.admin.review", syntax proto3)
// tslint:disable
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Any } from "../../../google/protobuf/any";
import { ReviewResource_Status } from "./review_resource";
import { FloatValue } from "../../../google/protobuf/wrappers";
import { ReviewResource } from "./review_resource";
/**
 * @generated from protobuf message auto_reserve.admin.review.ListRequest
 */
export interface ListRequest {
  /**
   * @generated from protobuf field: int64 page = 1;
   */
  page: number;
  /**
   * @generated from protobuf field: int64 per = 2;
   */
  per: number;
}
/**
 * @generated from protobuf message auto_reserve.admin.review.ListResponse
 */
export interface ListResponse {
  /**
   * @generated from protobuf field: repeated auto_reserve.admin.review.ReviewResource reviews = 1;
   */
  reviews: ReviewResource[];
  /**
   * @generated from protobuf field: int64 total_count = 2;
   */
  totalCount: number;
}
/**
 * @generated from protobuf message auto_reserve.admin.review.GetRequest
 */
export interface GetRequest {
  /**
   * @generated from protobuf field: int64 id = 1;
   */
  id: number;
}
/**
 * @generated from protobuf message auto_reserve.admin.review.UpdateRequest
 */
export interface UpdateRequest {
  /**
   * @generated from protobuf field: int64 id = 1;
   */
  id: number;
  /**
   * @generated from protobuf field: string content = 2;
   */
  content: string;
  /**
   * @generated from protobuf field: google.protobuf.FloatValue rating = 3;
   */
  rating?: FloatValue;
  /**
   * @generated from protobuf field: google.protobuf.FloatValue rating_atmosphere = 4;
   */
  ratingAtmosphere?: FloatValue;
  /**
   * @generated from protobuf field: google.protobuf.FloatValue rating_cost_performance = 5;
   */
  ratingCostPerformance?: FloatValue;
  /**
   * @generated from protobuf field: google.protobuf.FloatValue rating_food = 6;
   */
  ratingFood?: FloatValue;
  /**
   * @generated from protobuf field: google.protobuf.FloatValue rating_service = 7;
   */
  ratingService?: FloatValue;
  /**
   * @generated from protobuf field: auto_reserve.admin.review.ReviewResource.Status status = 8;
   */
  status: ReviewResource_Status;
}
/**
 * @generated from protobuf message auto_reserve.admin.review.DestroyRequest
 */
export interface DestroyRequest {
  /**
   * @generated from protobuf field: int64 id = 1;
   */
  id: number;
}
/**
 * @generated from protobuf message auto_reserve.admin.review.DestroyResponse
 */
export interface DestroyResponse {}
/**
 * @generated from protobuf message auto_reserve.admin.review.ImportRequest
 */
export interface ImportRequest {
  /**
   * @generated from protobuf field: google.protobuf.Any file = 1;
   */
  file?: Any;
}
/**
 * @generated from protobuf message auto_reserve.admin.review.ImportResponse
 */
export interface ImportResponse {}
// @generated message type with reflection information, may provide speed optimized methods
class ListRequest$Type extends MessageType<ListRequest> {
  constructor() {
    super("auto_reserve.admin.review.ListRequest", [
      {
        no: 1,
        name: "page",
        kind: "scalar",
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
      {
        no: 2,
        name: "per",
        kind: "scalar",
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
    ]);
  }
  create(value?: PartialMessage<ListRequest>): ListRequest {
    const message = { page: 0, per: 0 };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<ListRequest>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: ListRequest,
  ): ListRequest {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* int64 page */ 1:
          message.page = reader.int64().toNumber();
          break;
        case /* int64 per */ 2:
          message.per = reader.int64().toNumber();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: ListRequest,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* int64 page = 1; */
    if (message.page !== 0) writer.tag(1, WireType.Varint).int64(message.page);
    /* int64 per = 2; */
    if (message.per !== 0) writer.tag(2, WireType.Varint).int64(message.per);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.admin.review.ListRequest
 */
export const ListRequest = new ListRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListResponse$Type extends MessageType<ListResponse> {
  constructor() {
    super("auto_reserve.admin.review.ListResponse", [
      {
        no: 1,
        name: "reviews",
        kind: "message",
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => ReviewResource,
      },
      {
        no: 2,
        name: "total_count",
        kind: "scalar",
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
    ]);
  }
  create(value?: PartialMessage<ListResponse>): ListResponse {
    const message = { reviews: [], totalCount: 0 };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<ListResponse>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: ListResponse,
  ): ListResponse {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* repeated auto_reserve.admin.review.ReviewResource reviews */ 1:
          message.reviews.push(
            ReviewResource.internalBinaryRead(reader, reader.uint32(), options),
          );
          break;
        case /* int64 total_count */ 2:
          message.totalCount = reader.int64().toNumber();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: ListResponse,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* repeated auto_reserve.admin.review.ReviewResource reviews = 1; */
    for (let i = 0; i < message.reviews.length; i++)
      ReviewResource.internalBinaryWrite(
        message.reviews[i],
        writer.tag(1, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* int64 total_count = 2; */
    if (message.totalCount !== 0)
      writer.tag(2, WireType.Varint).int64(message.totalCount);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.admin.review.ListResponse
 */
export const ListResponse = new ListResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetRequest$Type extends MessageType<GetRequest> {
  constructor() {
    super("auto_reserve.admin.review.GetRequest", [
      {
        no: 1,
        name: "id",
        kind: "scalar",
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
    ]);
  }
  create(value?: PartialMessage<GetRequest>): GetRequest {
    const message = { id: 0 };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<GetRequest>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: GetRequest,
  ): GetRequest {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* int64 id */ 1:
          message.id = reader.int64().toNumber();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: GetRequest,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* int64 id = 1; */
    if (message.id !== 0) writer.tag(1, WireType.Varint).int64(message.id);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.admin.review.GetRequest
 */
export const GetRequest = new GetRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateRequest$Type extends MessageType<UpdateRequest> {
  constructor() {
    super("auto_reserve.admin.review.UpdateRequest", [
      {
        no: 1,
        name: "id",
        kind: "scalar",
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
      { no: 2, name: "content", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: "rating", kind: "message", T: () => FloatValue },
      {
        no: 4,
        name: "rating_atmosphere",
        kind: "message",
        T: () => FloatValue,
      },
      {
        no: 5,
        name: "rating_cost_performance",
        kind: "message",
        T: () => FloatValue,
      },
      { no: 6, name: "rating_food", kind: "message", T: () => FloatValue },
      { no: 7, name: "rating_service", kind: "message", T: () => FloatValue },
      {
        no: 8,
        name: "status",
        kind: "enum",
        T: () => [
          "auto_reserve.admin.review.ReviewResource.Status",
          ReviewResource_Status,
        ],
      },
    ]);
  }
  create(value?: PartialMessage<UpdateRequest>): UpdateRequest {
    const message = { id: 0, content: "", status: 0 };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<UpdateRequest>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: UpdateRequest,
  ): UpdateRequest {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* int64 id */ 1:
          message.id = reader.int64().toNumber();
          break;
        case /* string content */ 2:
          message.content = reader.string();
          break;
        case /* google.protobuf.FloatValue rating */ 3:
          message.rating = FloatValue.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.rating,
          );
          break;
        case /* google.protobuf.FloatValue rating_atmosphere */ 4:
          message.ratingAtmosphere = FloatValue.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.ratingAtmosphere,
          );
          break;
        case /* google.protobuf.FloatValue rating_cost_performance */ 5:
          message.ratingCostPerformance = FloatValue.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.ratingCostPerformance,
          );
          break;
        case /* google.protobuf.FloatValue rating_food */ 6:
          message.ratingFood = FloatValue.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.ratingFood,
          );
          break;
        case /* google.protobuf.FloatValue rating_service */ 7:
          message.ratingService = FloatValue.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.ratingService,
          );
          break;
        case /* auto_reserve.admin.review.ReviewResource.Status status */ 8:
          message.status = reader.int32();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: UpdateRequest,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* int64 id = 1; */
    if (message.id !== 0) writer.tag(1, WireType.Varint).int64(message.id);
    /* string content = 2; */
    if (message.content !== "")
      writer.tag(2, WireType.LengthDelimited).string(message.content);
    /* google.protobuf.FloatValue rating = 3; */
    if (message.rating)
      FloatValue.internalBinaryWrite(
        message.rating,
        writer.tag(3, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* google.protobuf.FloatValue rating_atmosphere = 4; */
    if (message.ratingAtmosphere)
      FloatValue.internalBinaryWrite(
        message.ratingAtmosphere,
        writer.tag(4, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* google.protobuf.FloatValue rating_cost_performance = 5; */
    if (message.ratingCostPerformance)
      FloatValue.internalBinaryWrite(
        message.ratingCostPerformance,
        writer.tag(5, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* google.protobuf.FloatValue rating_food = 6; */
    if (message.ratingFood)
      FloatValue.internalBinaryWrite(
        message.ratingFood,
        writer.tag(6, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* google.protobuf.FloatValue rating_service = 7; */
    if (message.ratingService)
      FloatValue.internalBinaryWrite(
        message.ratingService,
        writer.tag(7, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* auto_reserve.admin.review.ReviewResource.Status status = 8; */
    if (message.status !== 0)
      writer.tag(8, WireType.Varint).int32(message.status);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.admin.review.UpdateRequest
 */
export const UpdateRequest = new UpdateRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DestroyRequest$Type extends MessageType<DestroyRequest> {
  constructor() {
    super("auto_reserve.admin.review.DestroyRequest", [
      {
        no: 1,
        name: "id",
        kind: "scalar",
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
    ]);
  }
  create(value?: PartialMessage<DestroyRequest>): DestroyRequest {
    const message = { id: 0 };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<DestroyRequest>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: DestroyRequest,
  ): DestroyRequest {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* int64 id */ 1:
          message.id = reader.int64().toNumber();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: DestroyRequest,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* int64 id = 1; */
    if (message.id !== 0) writer.tag(1, WireType.Varint).int64(message.id);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.admin.review.DestroyRequest
 */
export const DestroyRequest = new DestroyRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DestroyResponse$Type extends MessageType<DestroyResponse> {
  constructor() {
    super("auto_reserve.admin.review.DestroyResponse", []);
  }
  create(value?: PartialMessage<DestroyResponse>): DestroyResponse {
    const message = {};
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<DestroyResponse>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: DestroyResponse,
  ): DestroyResponse {
    return target ?? this.create();
  }
  internalBinaryWrite(
    message: DestroyResponse,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.admin.review.DestroyResponse
 */
export const DestroyResponse = new DestroyResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ImportRequest$Type extends MessageType<ImportRequest> {
  constructor() {
    super("auto_reserve.admin.review.ImportRequest", [
      { no: 1, name: "file", kind: "message", T: () => Any },
    ]);
  }
  create(value?: PartialMessage<ImportRequest>): ImportRequest {
    const message = {};
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<ImportRequest>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: ImportRequest,
  ): ImportRequest {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* google.protobuf.Any file */ 1:
          message.file = Any.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.file,
          );
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: ImportRequest,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* google.protobuf.Any file = 1; */
    if (message.file)
      Any.internalBinaryWrite(
        message.file,
        writer.tag(1, WireType.LengthDelimited).fork(),
        options,
      ).join();
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.admin.review.ImportRequest
 */
export const ImportRequest = new ImportRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ImportResponse$Type extends MessageType<ImportResponse> {
  constructor() {
    super("auto_reserve.admin.review.ImportResponse", []);
  }
  create(value?: PartialMessage<ImportResponse>): ImportResponse {
    const message = {};
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<ImportResponse>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: ImportResponse,
  ): ImportResponse {
    return target ?? this.create();
  }
  internalBinaryWrite(
    message: ImportResponse,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.admin.review.ImportResponse
 */
export const ImportResponse = new ImportResponse$Type();
/**
 * @generated ServiceType for protobuf service auto_reserve.admin.review.Review
 */
export const Review = new ServiceType("auto_reserve.admin.review.Review", [
  { name: "List", options: {}, I: ListRequest, O: ListResponse },
  { name: "Get", options: {}, I: GetRequest, O: ReviewResource },
  { name: "Update", options: {}, I: UpdateRequest, O: ReviewResource },
  { name: "Destroy", options: {}, I: DestroyRequest, O: DestroyResponse },
  { name: "Import", options: {}, I: ImportRequest, O: ImportResponse },
]);
