// @generated by protobuf-ts 2.8.1 with parameter long_type_number,generate_dependencies
// @generated from protobuf file "auto_reserve/admin/owner_invoice/owner_invoice_service.proto" (package "auto_reserve.admin.owner_invoice", syntax proto3)
// tslint:disable
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { OwnerInvoice } from "./owner_invoice_service";
import type { DestroyResponse } from "./owner_invoice_service";
import type { DestroyRequest } from "./owner_invoice_service";
import type { UpsertResponse } from "./owner_invoice_service";
import type { UpsertRequest } from "./owner_invoice_service";
import type { UpdateStatusRequest } from "./owner_invoice_service";
import type { UpdateRequest } from "./owner_invoice_service";
import type { CreateRequest } from "./owner_invoice_service";
import type { OwnerInvoiceResource } from "./owner_invoice_resource";
import type { GetRequest } from "./owner_invoice_service";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { ListResponse } from "./owner_invoice_service";
import type { ListRequest } from "./owner_invoice_service";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service auto_reserve.admin.owner_invoice.OwnerInvoice
 */
export interface IOwnerInvoiceClient {
  /**
   * @generated from protobuf rpc: List(auto_reserve.admin.owner_invoice.ListRequest) returns (auto_reserve.admin.owner_invoice.ListResponse);
   */
  list(
    input: ListRequest,
    options?: RpcOptions,
  ): UnaryCall<ListRequest, ListResponse>;
  /**
   * @generated from protobuf rpc: Get(auto_reserve.admin.owner_invoice.GetRequest) returns (auto_reserve.admin.owner_invoice.OwnerInvoiceResource);
   */
  get(
    input: GetRequest,
    options?: RpcOptions,
  ): UnaryCall<GetRequest, OwnerInvoiceResource>;
  /**
   * @generated from protobuf rpc: Create(auto_reserve.admin.owner_invoice.CreateRequest) returns (auto_reserve.admin.owner_invoice.OwnerInvoiceResource);
   */
  create(
    input: CreateRequest,
    options?: RpcOptions,
  ): UnaryCall<CreateRequest, OwnerInvoiceResource>;
  /**
   * @generated from protobuf rpc: Update(auto_reserve.admin.owner_invoice.UpdateRequest) returns (auto_reserve.admin.owner_invoice.OwnerInvoiceResource);
   */
  update(
    input: UpdateRequest,
    options?: RpcOptions,
  ): UnaryCall<UpdateRequest, OwnerInvoiceResource>;
  /**
   * @generated from protobuf rpc: UpdateStatus(auto_reserve.admin.owner_invoice.UpdateStatusRequest) returns (auto_reserve.admin.owner_invoice.OwnerInvoiceResource);
   */
  updateStatus(
    input: UpdateStatusRequest,
    options?: RpcOptions,
  ): UnaryCall<UpdateStatusRequest, OwnerInvoiceResource>;
  /**
   * @generated from protobuf rpc: Upsert(auto_reserve.admin.owner_invoice.UpsertRequest) returns (auto_reserve.admin.owner_invoice.UpsertResponse);
   */
  upsert(
    input: UpsertRequest,
    options?: RpcOptions,
  ): UnaryCall<UpsertRequest, UpsertResponse>;
  /**
   * @generated from protobuf rpc: Destroy(auto_reserve.admin.owner_invoice.DestroyRequest) returns (auto_reserve.admin.owner_invoice.DestroyResponse);
   */
  destroy(
    input: DestroyRequest,
    options?: RpcOptions,
  ): UnaryCall<DestroyRequest, DestroyResponse>;
}
/**
 * @generated from protobuf service auto_reserve.admin.owner_invoice.OwnerInvoice
 */
export class OwnerInvoiceClient implements IOwnerInvoiceClient, ServiceInfo {
  typeName = OwnerInvoice.typeName;
  methods = OwnerInvoice.methods;
  options = OwnerInvoice.options;
  constructor(private readonly _transport: RpcTransport) {}
  /**
   * @generated from protobuf rpc: List(auto_reserve.admin.owner_invoice.ListRequest) returns (auto_reserve.admin.owner_invoice.ListResponse);
   */
  list(
    input: ListRequest,
    options?: RpcOptions,
  ): UnaryCall<ListRequest, ListResponse> {
    const method = this.methods[0],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<ListRequest, ListResponse>(
      "unary",
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: Get(auto_reserve.admin.owner_invoice.GetRequest) returns (auto_reserve.admin.owner_invoice.OwnerInvoiceResource);
   */
  get(
    input: GetRequest,
    options?: RpcOptions,
  ): UnaryCall<GetRequest, OwnerInvoiceResource> {
    const method = this.methods[1],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<GetRequest, OwnerInvoiceResource>(
      "unary",
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: Create(auto_reserve.admin.owner_invoice.CreateRequest) returns (auto_reserve.admin.owner_invoice.OwnerInvoiceResource);
   */
  create(
    input: CreateRequest,
    options?: RpcOptions,
  ): UnaryCall<CreateRequest, OwnerInvoiceResource> {
    const method = this.methods[2],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<CreateRequest, OwnerInvoiceResource>(
      "unary",
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: Update(auto_reserve.admin.owner_invoice.UpdateRequest) returns (auto_reserve.admin.owner_invoice.OwnerInvoiceResource);
   */
  update(
    input: UpdateRequest,
    options?: RpcOptions,
  ): UnaryCall<UpdateRequest, OwnerInvoiceResource> {
    const method = this.methods[3],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<UpdateRequest, OwnerInvoiceResource>(
      "unary",
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: UpdateStatus(auto_reserve.admin.owner_invoice.UpdateStatusRequest) returns (auto_reserve.admin.owner_invoice.OwnerInvoiceResource);
   */
  updateStatus(
    input: UpdateStatusRequest,
    options?: RpcOptions,
  ): UnaryCall<UpdateStatusRequest, OwnerInvoiceResource> {
    const method = this.methods[4],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<UpdateStatusRequest, OwnerInvoiceResource>(
      "unary",
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: Upsert(auto_reserve.admin.owner_invoice.UpsertRequest) returns (auto_reserve.admin.owner_invoice.UpsertResponse);
   */
  upsert(
    input: UpsertRequest,
    options?: RpcOptions,
  ): UnaryCall<UpsertRequest, UpsertResponse> {
    const method = this.methods[5],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<UpsertRequest, UpsertResponse>(
      "unary",
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: Destroy(auto_reserve.admin.owner_invoice.DestroyRequest) returns (auto_reserve.admin.owner_invoice.DestroyResponse);
   */
  destroy(
    input: DestroyRequest,
    options?: RpcOptions,
  ): UnaryCall<DestroyRequest, DestroyResponse> {
    const method = this.methods[6],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<DestroyRequest, DestroyResponse>(
      "unary",
      this._transport,
      method,
      opt,
      input,
    );
  }
}
