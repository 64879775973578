import { createBrowserHistory } from 'history'

const initialLocationKey = '__INITIAL_LOCATION__'

interface GoBackOptions {
  replace: boolean
}

function createHistory() {
  const history = createBrowserHistory()

  history.replace(
    {
      ...history.location,
    },
    {
      [initialLocationKey]: true,
    }
  )

  function canGoBack() {
    const state = history.location.state
    return state == null || !(initialLocationKey in (state as {}))
  }

  function goBack(
    fallback: string,
    options: GoBackOptions = { replace: true }
  ) {
    if (canGoBack()) {
      history.back()
      return
    }
    history[options.replace ? 'replace' : 'push'](fallback)
  }

  return { history, canGoBack, goBack }
}
export const { history, canGoBack, goBack } = createHistory()
