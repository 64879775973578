import { createTheme } from '@mui/material'
import { grey, red } from '@mui/material/colors'

export const spacing = 8

const theme = createTheme({
  spacing,
  palette: {
    secondary: {
      light: grey['400'],
      main: grey['700'],
      dark: grey['800'],
      contrastText: '#fff',
    },
    delete: {
      light: red['400'],
      main: red['700'],
      dark: red['800'],
      contrastText: '#fff',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
    },
  },
  components: {
    MuiTablePagination: {
      styleOverrides: {
        selectLabel: {
          marginBottom: 0,
        },
        displayedRows: {
          marginBottom: 0,
        },
      },
    },
  },
  typography: {
    fontSize: 14,
    h1: {
      fontSize: 30,
    },
    h2: {
      fontSize: 24,
    },
    h3: {
      fontSize: 20,
    },
    h4: {
      fontSize: 16,
    },
    h5: {
      fontSize: 14,
    },
  },
})

export default theme
