export function swrKey<T = Record<string, unknown>>(
  token: string | null,
  url: string,
  params?: T
): [string | null, string, string] {
  function replacer(_key: string, value: unknown) {
    if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
      // objectの場合、ソートしたキー順にassignしキー順序をstableにする
      return Object.keys(value)
        .sort()
        .reduce((sorted: any, key: string) => {
          sorted[key] = (value as any)[key]
          return sorted
        }, {})
    }
    return value
  }

  return [token, url, JSON.stringify(params ?? {}, replacer)]
}
