import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import {
  restaurantReservationBookContractService,
  getFeeStatusName,
  getUserFeeTypeName,
} from 'models/RestaurantReservationBookContract'
import dayjs from 'dayjs'
import PageHeader from 'components/Shared/PageHeader'
import DataGrid, { usePagination } from 'components/Shared/DataGrid'
import { Button } from '@mui/material'

export default function RestaurantReservationBookContracts() {
  const navigate = useNavigate()
  const pagination = usePagination()
  const { data } = restaurantReservationBookContractService.useList({
    page: pagination.page,
    per: pagination.perPage,
  })

  return (
    <PageHeader title="予約台帳契約一覧">
      <DataGrid
        data={data?.restaurantReservationBookContracts}
        rowKey={(record) => record.id}
        columns={[
          {
            key: 'action',
            render: (restaurantReservationBookContract) => (
              <Button
                variant="outlined"
                onClick={() =>
                  navigate(
                    `/restaurant_reservation_book_contracts/${restaurantReservationBookContract.id}/edit`
                  )
                }
              >
                編集
              </Button>
            ),
          },
          {
            title: 'レストラン',
            key: 'restaurant',
            render: (restaurantReservationBookContract) => (
              <Link
                to={`/restaurants/${restaurantReservationBookContract.restaurant?.id}`}
              >
                {restaurantReservationBookContract.restaurant?.name}
              </Link>
            ),
          },
          {
            key: 'reservationCount',
            render: (restaurantReservationBookContract) => (
              <a
                href={`https://redash.autoreserve.com/queries/460?p_restaurant_id=${restaurantReservationBookContract.restaurant?.id}`}
                target="_blank"
                rel="noreferrer"
              >
                予約回数
              </a>
            ),
          },
          {
            title: '契約タイプ',
            key: 'feeStatus',
            render: (restaurantReservationBookContract) =>
              getFeeStatusName(restaurantReservationBookContract.feeStatus),
          },
          {
            title: '期間',
            key: 'term',
            render: (restaurantReservationBookContract) =>
              `${
                restaurantReservationBookContract.startDate !== undefined
                  ? dayjs
                      .unix(restaurantReservationBookContract.startDate.seconds)
                      .format('YYYY/MM/DD')
                  : ''
              }~${
                restaurantReservationBookContract.endDate !== undefined
                  ? dayjs
                      .unix(restaurantReservationBookContract.endDate.seconds)
                      .format('YYYY/MM/DD')
                  : ''
              }`,
          },
          {
            title: '月額費用',
            key: 'monthlyFee',
            render: (restaurantReservationBookContract) =>
              `${restaurantReservationBookContract.monthlyFee}円`,
          },
          {
            title: 'ランチ送客手数料',
            key: 'lunchCommissionFee',
            render: (restaurantOrderContract) =>
              `${restaurantOrderContract.lunchCommissionFee}円`,
          },
          {
            title: 'ディナー送客手数料',
            key: 'dinnerCommissionFee',
            render: (restaurantOrderContract) =>
              `${restaurantOrderContract.dinnerCommissionFee}円`,
          },
          {
            title: 'ユーザー予約手数料タイプ',
            key: 'userFeeType',
            render: (restaurantOrderContract) =>
              `${getUserFeeTypeName(restaurantOrderContract.userFeeType)}`,
          },
          {
            title: 'ユーザー予約手数料',
            key: 'userFee',
            render: (restaurantOrderContract) =>
              `${restaurantOrderContract.userFee}円`,
          },
          {
            title: '契約期間',
            key: 'contractPeriod',
            render: (restaurantReservationBookContract) =>
              `${restaurantReservationBookContract.contractPeriod}ヶ月`,
          },
          {
            title: 'メモ',
            key: 'memo',
            render: (restaurantReservationBookContract) =>
              restaurantReservationBookContract.memo,
          },
          {
            title: '作成日時',
            key: 'memo',
            render: (restaurantReservationBookContract) =>
              restaurantReservationBookContract.createdAt !== undefined
                ? dayjs
                    .unix(restaurantReservationBookContract.createdAt.seconds)
                    .format('YYYY/MM/DD hh:mm')
                : '',
          },
        ]}
        loading={data === undefined}
        sx={{ minHeight: 400 }}
      />
    </PageHeader>
  )
}
