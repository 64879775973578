import React, { ReactNode } from 'react'
import { Box, Typography } from '@mui/material'
import { SystemStyleObject, Theme } from '@mui/system'

interface PageHeaderProps {
  title: string
  titleSx?: SystemStyleObject<Theme>
  prefixTitle?: ReactNode
  suffixTitle?: ReactNode
  sx?: SystemStyleObject<Theme>
  extra?: ReactNode[]
  children: ReactNode
}

export default function PageHeader({
  prefixTitle,
  title,
  titleSx,
  suffixTitle,
  children,
  sx,
  extra,
}: PageHeaderProps) {
  return (
    <Box sx={[{ py: 2, px: 3, backgroundColor: 'white' }, sx ?? null]}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          mb: 2,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyItems: 'center',
            columnGap: 2,
          }}
        >
          {prefixTitle}
          <Typography variant="h2" sx={titleSx}>
            {title}
          </Typography>
          {suffixTitle}
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            columnGap: 1,
          }}
        >
          {extra?.map((item, index) => (
            <Box key={`extra-item-${index}`} sx={{ height: 'fit-content' }}>
              {item}
            </Box>
          ))}
        </Box>
      </Box>
      {children}
    </Box>
  )
}
