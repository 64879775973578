import axios, { isAxiosError, setHeader } from 'modules/axios'
import { SWRConfiguration, Revalidator, RevalidatorOptions } from 'swr'
import { PublicConfiguration } from 'swr/_internal'
import { onError } from 'models/Auth'

export const swrDefaultConfig: SWRConfiguration = {
  onError,
  onErrorRetry,
  focusThrottleInterval: 0,
}

// Based on:
// https://github.com/vercel/swr/blob/5a05547df9aa700d634510f629d00c9f4cbdb9c7/src/config.ts#L12
function onErrorRetry<Data = any, Error = any>(
  error: unknown,
  __: string,
  config: Readonly<PublicConfiguration<Data, Error>>,
  revalidate: Revalidator,
  opts: Required<RevalidatorOptions>
): void {
  if (isAxiosError(error)) {
    const status = error.response?.status
    // 400系はリトライしない
    if (status !== undefined && status < 500) return
  }

  if (document.visibilityState === 'hidden') {
    return
  }

  if (
    typeof config.errorRetryCount === 'number' &&
    opts.retryCount > config.errorRetryCount
  ) {
    return
  }

  // exponential backoff
  const count = Math.min(opts.retryCount, 8)
  const timeout =
    ~~((Math.random() + 0.5) * (1 << count)) * config.errorRetryInterval
  setTimeout(() => {
    revalidate(opts)
  }, timeout)
}

export async function fetcher<T = any, P extends {} = {}>([
  token,
  url,
  params,
]: [token: string | null, url: string, params?: P]) {
  setHeader({ token })
  const response = await axios.get<T>(url, { params })
  return response.data
}
