// @generated by protobuf-ts 2.8.1 with parameter long_type_number,generate_dependencies
// @generated from protobuf file "auto_reserve/admin/broadcast_message/broadcast_message_service.proto" (package "auto_reserve.admin.broadcast_message", syntax proto3)
// tslint:disable
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { BroadcastMessage } from "./broadcast_message_service";
import type { NumberOfCustomersResponse } from "./broadcast_message_service";
import type { NumberOfCustomersRequest } from "./broadcast_message_service";
import type { PreviewResponse } from "./broadcast_message_service";
import type { PreviewRequest } from "./broadcast_message_service";
import type { Empty } from "../../../google/protobuf/empty";
import type { DestroyRequest } from "./broadcast_message_service";
import type { UpdateRequest } from "./broadcast_message_service";
import type { CreateRequest } from "./broadcast_message_service";
import type { BroadcastMessageResource } from "./broadcast_message_resource";
import type { GetRequest } from "./broadcast_message_service";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { ListResponse } from "./broadcast_message_service";
import type { ListRequest } from "./broadcast_message_service";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service auto_reserve.admin.broadcast_message.BroadcastMessage
 */
export interface IBroadcastMessageClient {
  /**
   * @generated from protobuf rpc: List(auto_reserve.admin.broadcast_message.ListRequest) returns (auto_reserve.admin.broadcast_message.ListResponse);
   */
  list(
    input: ListRequest,
    options?: RpcOptions,
  ): UnaryCall<ListRequest, ListResponse>;
  /**
   * @generated from protobuf rpc: Get(auto_reserve.admin.broadcast_message.GetRequest) returns (auto_reserve.admin.broadcast_message.BroadcastMessageResource);
   */
  get(
    input: GetRequest,
    options?: RpcOptions,
  ): UnaryCall<GetRequest, BroadcastMessageResource>;
  /**
   * @generated from protobuf rpc: Create(auto_reserve.admin.broadcast_message.CreateRequest) returns (auto_reserve.admin.broadcast_message.BroadcastMessageResource);
   */
  create(
    input: CreateRequest,
    options?: RpcOptions,
  ): UnaryCall<CreateRequest, BroadcastMessageResource>;
  /**
   * @generated from protobuf rpc: Update(auto_reserve.admin.broadcast_message.UpdateRequest) returns (auto_reserve.admin.broadcast_message.BroadcastMessageResource);
   */
  update(
    input: UpdateRequest,
    options?: RpcOptions,
  ): UnaryCall<UpdateRequest, BroadcastMessageResource>;
  /**
   * @generated from protobuf rpc: Destroy(auto_reserve.admin.broadcast_message.DestroyRequest) returns (google.protobuf.Empty);
   */
  destroy(
    input: DestroyRequest,
    options?: RpcOptions,
  ): UnaryCall<DestroyRequest, Empty>;
  /**
   * @generated from protobuf rpc: Preview(auto_reserve.admin.broadcast_message.PreviewRequest) returns (auto_reserve.admin.broadcast_message.PreviewResponse);
   */
  preview(
    input: PreviewRequest,
    options?: RpcOptions,
  ): UnaryCall<PreviewRequest, PreviewResponse>;
  /**
   * @generated from protobuf rpc: NumberOfCustomers(auto_reserve.admin.broadcast_message.NumberOfCustomersRequest) returns (auto_reserve.admin.broadcast_message.NumberOfCustomersResponse);
   */
  numberOfCustomers(
    input: NumberOfCustomersRequest,
    options?: RpcOptions,
  ): UnaryCall<NumberOfCustomersRequest, NumberOfCustomersResponse>;
}
/**
 * @generated from protobuf service auto_reserve.admin.broadcast_message.BroadcastMessage
 */
export class BroadcastMessageClient
  implements IBroadcastMessageClient, ServiceInfo
{
  typeName = BroadcastMessage.typeName;
  methods = BroadcastMessage.methods;
  options = BroadcastMessage.options;
  constructor(private readonly _transport: RpcTransport) {}
  /**
   * @generated from protobuf rpc: List(auto_reserve.admin.broadcast_message.ListRequest) returns (auto_reserve.admin.broadcast_message.ListResponse);
   */
  list(
    input: ListRequest,
    options?: RpcOptions,
  ): UnaryCall<ListRequest, ListResponse> {
    const method = this.methods[0],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<ListRequest, ListResponse>(
      "unary",
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: Get(auto_reserve.admin.broadcast_message.GetRequest) returns (auto_reserve.admin.broadcast_message.BroadcastMessageResource);
   */
  get(
    input: GetRequest,
    options?: RpcOptions,
  ): UnaryCall<GetRequest, BroadcastMessageResource> {
    const method = this.methods[1],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<GetRequest, BroadcastMessageResource>(
      "unary",
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: Create(auto_reserve.admin.broadcast_message.CreateRequest) returns (auto_reserve.admin.broadcast_message.BroadcastMessageResource);
   */
  create(
    input: CreateRequest,
    options?: RpcOptions,
  ): UnaryCall<CreateRequest, BroadcastMessageResource> {
    const method = this.methods[2],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<CreateRequest, BroadcastMessageResource>(
      "unary",
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: Update(auto_reserve.admin.broadcast_message.UpdateRequest) returns (auto_reserve.admin.broadcast_message.BroadcastMessageResource);
   */
  update(
    input: UpdateRequest,
    options?: RpcOptions,
  ): UnaryCall<UpdateRequest, BroadcastMessageResource> {
    const method = this.methods[3],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<UpdateRequest, BroadcastMessageResource>(
      "unary",
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: Destroy(auto_reserve.admin.broadcast_message.DestroyRequest) returns (google.protobuf.Empty);
   */
  destroy(
    input: DestroyRequest,
    options?: RpcOptions,
  ): UnaryCall<DestroyRequest, Empty> {
    const method = this.methods[4],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<DestroyRequest, Empty>(
      "unary",
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: Preview(auto_reserve.admin.broadcast_message.PreviewRequest) returns (auto_reserve.admin.broadcast_message.PreviewResponse);
   */
  preview(
    input: PreviewRequest,
    options?: RpcOptions,
  ): UnaryCall<PreviewRequest, PreviewResponse> {
    const method = this.methods[5],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<PreviewRequest, PreviewResponse>(
      "unary",
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: NumberOfCustomers(auto_reserve.admin.broadcast_message.NumberOfCustomersRequest) returns (auto_reserve.admin.broadcast_message.NumberOfCustomersResponse);
   */
  numberOfCustomers(
    input: NumberOfCustomersRequest,
    options?: RpcOptions,
  ): UnaryCall<NumberOfCustomersRequest, NumberOfCustomersResponse> {
    const method = this.methods[6],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<NumberOfCustomersRequest, NumberOfCustomersResponse>(
      "unary",
      this._transport,
      method,
      opt,
      input,
    );
  }
}
