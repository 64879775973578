// @generated by protobuf-ts 2.8.1 with parameter long_type_number,generate_dependencies
// @generated from protobuf file "auto_reserve/admin/restaurant/restaurant_resource.proto" (package "auto_reserve.admin.restaurant", syntax proto3)
// tslint:disable
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Timestamp } from "../../../google/protobuf/timestamp";
/**
 * @generated from protobuf message auto_reserve.admin.restaurant.RestaurantResource
 */
export interface RestaurantResource {
  /**
   * @generated from protobuf field: int64 id = 1;
   */
  id: number;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp created_at = 2;
   */
  createdAt?: Timestamp;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp updated_at = 3;
   */
  updatedAt?: Timestamp;
  /**
   * @generated from protobuf field: string name = 4;
   */
  name: string;
  /**
   * @generated from protobuf field: auto_reserve.admin.restaurant.RestaurantResource.Status status = 5;
   */
  status: RestaurantResource_Status;
  /**
   * @generated from protobuf field: auto_reserve.admin.restaurant.RestaurantResource.PublishedStatus published_status = 6;
   */
  publishedStatus: RestaurantResource_PublishedStatus;
}
/**
 * @generated from protobuf enum auto_reserve.admin.restaurant.RestaurantResource.Status
 */
export enum RestaurantResource_Status {
  /**
   * @generated from protobuf enum value: UNPUBLISHED = 0;
   */
  UNPUBLISHED = 0,
  /**
   * @generated from protobuf enum value: PUBLISHED = 1;
   */
  PUBLISHED = 1,
  /**
   * @generated from protobuf enum value: NOT_RESERVED = 2;
   */
  NOT_RESERVED = 2,
  /**
   * @generated from protobuf enum value: CLOSED = 3;
   */
  CLOSED = 3,
}
/**
 * @generated from protobuf enum auto_reserve.admin.restaurant.RestaurantResource.PublishedStatus
 */
export enum RestaurantResource_PublishedStatus {
  /**
   * @generated from protobuf enum value: AUTO = 0;
   */
  AUTO = 0,
  /**
   * @generated from protobuf enum value: MANUAL_CALL = 1;
   */
  MANUAL_CALL = 1,
  /**
   * @generated from protobuf enum value: ONLY_WEB = 2;
   */
  ONLY_WEB = 2,
}
// @generated message type with reflection information, may provide speed optimized methods
class RestaurantResource$Type extends MessageType<RestaurantResource> {
  constructor() {
    super("auto_reserve.admin.restaurant.RestaurantResource", [
      {
        no: 1,
        name: "id",
        kind: "scalar",
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
      { no: 2, name: "created_at", kind: "message", T: () => Timestamp },
      { no: 3, name: "updated_at", kind: "message", T: () => Timestamp },
      { no: 4, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
      {
        no: 5,
        name: "status",
        kind: "enum",
        T: () => [
          "auto_reserve.admin.restaurant.RestaurantResource.Status",
          RestaurantResource_Status,
        ],
      },
      {
        no: 6,
        name: "published_status",
        kind: "enum",
        T: () => [
          "auto_reserve.admin.restaurant.RestaurantResource.PublishedStatus",
          RestaurantResource_PublishedStatus,
        ],
      },
    ]);
  }
  create(value?: PartialMessage<RestaurantResource>): RestaurantResource {
    const message = { id: 0, name: "", status: 0, publishedStatus: 0 };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<RestaurantResource>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: RestaurantResource,
  ): RestaurantResource {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* int64 id */ 1:
          message.id = reader.int64().toNumber();
          break;
        case /* google.protobuf.Timestamp created_at */ 2:
          message.createdAt = Timestamp.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.createdAt,
          );
          break;
        case /* google.protobuf.Timestamp updated_at */ 3:
          message.updatedAt = Timestamp.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.updatedAt,
          );
          break;
        case /* string name */ 4:
          message.name = reader.string();
          break;
        case /* auto_reserve.admin.restaurant.RestaurantResource.Status status */ 5:
          message.status = reader.int32();
          break;
        case /* auto_reserve.admin.restaurant.RestaurantResource.PublishedStatus published_status */ 6:
          message.publishedStatus = reader.int32();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: RestaurantResource,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* int64 id = 1; */
    if (message.id !== 0) writer.tag(1, WireType.Varint).int64(message.id);
    /* google.protobuf.Timestamp created_at = 2; */
    if (message.createdAt)
      Timestamp.internalBinaryWrite(
        message.createdAt,
        writer.tag(2, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* google.protobuf.Timestamp updated_at = 3; */
    if (message.updatedAt)
      Timestamp.internalBinaryWrite(
        message.updatedAt,
        writer.tag(3, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* string name = 4; */
    if (message.name !== "")
      writer.tag(4, WireType.LengthDelimited).string(message.name);
    /* auto_reserve.admin.restaurant.RestaurantResource.Status status = 5; */
    if (message.status !== 0)
      writer.tag(5, WireType.Varint).int32(message.status);
    /* auto_reserve.admin.restaurant.RestaurantResource.PublishedStatus published_status = 6; */
    if (message.publishedStatus !== 0)
      writer.tag(6, WireType.Varint).int32(message.publishedStatus);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.admin.restaurant.RestaurantResource
 */
export const RestaurantResource = new RestaurantResource$Type();
