import { RestaurantResource_PublishedStatus } from '@hello-ai/proto/src/gen/auto_reserve/admin/restaurant/restaurant_resource'
import { ListFilters } from '@hello-ai/proto/src/gen/auto_reserve/admin/restaurant/restaurant_service'
import { StringValue } from '@hello-ai/proto/src/gen/google/protobuf/wrappers'
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  TextField,
} from '@mui/material'
import { FilterAction } from 'components/Filter/FilterAction'
import { FilterStatus } from 'components/Filter/FilterStatus'
import DataGrid, { usePagination } from 'components/Shared/DataGrid'
import PageHeader from 'components/Shared/PageHeader'
import {
  getPublishedStatusName,
  getStatusName,
  restaurantService,
} from 'models/Restaurant'
import React, { useState } from 'react'
import { useNavigate } from 'react-router'

function FilterPublishedStatus({
  onFilter,
  defaultValue,
}: {
  onFilter: (value: number[]) => void
  defaultValue: number[]
}) {
  const [newSelectedStatus, setNewSelectedStatus] = useState(defaultValue)

  return (
    <FormGroup sx={{ p: 2 }}>
      {[
        {
          text: getPublishedStatusName(RestaurantResource_PublishedStatus.AUTO),
          value: RestaurantResource_PublishedStatus.AUTO,
        },
        {
          text: getPublishedStatusName(
            RestaurantResource_PublishedStatus.MANUAL_CALL
          ),
          value: RestaurantResource_PublishedStatus.MANUAL_CALL,
        },
        {
          text: getPublishedStatusName(
            RestaurantResource_PublishedStatus.ONLY_WEB
          ),
          value: RestaurantResource_PublishedStatus.ONLY_WEB,
        },
      ].map((option) => {
        const checked = newSelectedStatus.includes(option.value)

        return (
          <FormControlLabel
            key={option.text}
            control={<Checkbox />}
            label={option.text}
            checked={checked}
            onChange={(_, newChecked) => {
              if (newChecked === checked) return
              setNewSelectedStatus((prev) => {
                if (newChecked) {
                  return [...prev, option.value]
                } else {
                  return prev.filter(
                    (selectedStatus) => selectedStatus !== option.value
                  )
                }
              })
            }}
          />
        )
      })}
      <FilterAction
        onReset={() => {
          setNewSelectedStatus(defaultValue)
        }}
        onSubmit={() => {
          onFilter(newSelectedStatus)
        }}
      />
    </FormGroup>
  )
}

function FilterName({
  onFilter,
  defaultValue,
}: {
  onFilter: (value: StringValue | undefined) => void
  defaultValue: StringValue | undefined
}) {
  const [name, setName] = useState(defaultValue?.value ?? '')

  return (
    <FormGroup sx={{ p: 2 }}>
      <TextField
        sx={{ my: 1 }}
        size="small"
        label="名前"
        value={name}
        onChange={(event) => setName(event.target.value)}
      />
      <FilterAction
        onReset={() => {
          setName('')
        }}
        onSubmit={() => {
          if (name === '') {
            onFilter(undefined)
            return
          }
          const message = StringValue.create()
          message.value = name
          onFilter(message)
        }}
      />
    </FormGroup>
  )
}

export default function Restaurants() {
  const navigate = useNavigate()
  const pagination = usePagination()
  const [filters, setFilters] = useState<ListFilters>({
    name: undefined,
    statuses: [],
    publishedStatuses: [],
  })
  const { data } = restaurantService.useList({
    page: pagination.page,
    per: pagination.perPage,
    filters,
  })

  return (
    <PageHeader title="レストラン一覧">
      <DataGrid
        sx={{ minHeight: 400 }}
        totalCount={data?.totalCount ?? 0}
        data={data?.restaurants}
        rowKey={(row) => `restaurant-${row.id}`}
        columns={[
          {
            key: 'action',
            render: (restaurant) => (
              <Button
                variant="outlined"
                onClick={() => navigate(`/restaurants/${restaurant.id}`)}
              >
                詳細
              </Button>
            ),
          },
          {
            title: 'ステータス',
            key: 'status',
            filter: {
              key: 'statuses',
              FilterContent: FilterStatus,
            },
            render: (restaurant) => getStatusName(restaurant.status),
          },
          {
            title: '公開ステータス',
            key: 'publishedStatus',
            filter: {
              key: 'publishedStatuses',
              FilterContent: FilterPublishedStatus,
            },
            render: (restaurant) =>
              getPublishedStatusName(restaurant.publishedStatus),
          },
          {
            title: '名前',
            key: 'name',
            filter: {
              key: 'name',
              FilterContent: FilterName,
            },
            render: (restaurant) => restaurant.name,
          },
          {
            title: '住所',
            key: 'address',
            render: (restaurant) => restaurant.address,
          },
        ]}
        loading={data === undefined}
        pagination={pagination}
        filterState={filters}
        onChangeFilterValue={(key, newValue) => {
          setFilters((prev) => {
            // FIXME: #6274 を参考にimmutable updaterに修正してください
            prev[key] = newValue
            return { ...prev }
          })
        }}
      />
    </PageHeader>
  )
}
