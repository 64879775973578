import { Loading } from '@hello-ai/ar_shared/src/components/Loading'
import {
  PreviewRequest,
  PreviewResponse,
} from '@hello-ai/proto/src/gen/auto_reserve/admin/broadcast_message/broadcast_message_service'
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material'
import { Segmented } from 'components/Shared/Segmented'
import { onError, useToken } from 'models/Auth'
import { broadcastMessageService } from 'models/BroadcastMessage'
import React, { useEffect, useState } from 'react'
import MessagePreviewContent from './MessagePreviewContent'

interface LocalPhotoMessageItem {
  type: 'local_photo'
  value: File
}
interface RemotePhotoMessageItem {
  type: 'remote_photo'
  value: string
}
interface TextMessageItem {
  type: 'text'
  value: string
}
interface ButtonMessageItem {
  type: 'button'
  value: {
    text: string | null
    url: string | null
  } | null
}
export type MessageItem =
  | LocalPhotoMessageItem
  | RemotePhotoMessageItem
  | TextMessageItem
  | ButtonMessageItem

const blobOrFileToBase64 = (src: Blob | File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result as string)
    reader.onerror = () => reject(reader.error)
    reader.readAsDataURL(src)
  })
}

export const convertImageWithinMessageList = (list: MessageItem[]) =>
  Promise.all(
    list.map(async (i) => {
      switch (i.type) {
        case 'local_photo': {
          const image = await blobOrFileToBase64(i.value)
          return {
            body: '',
            image,
          }
        }
        case 'remote_photo': {
          const blob = await fetch(i.value).then((e) => e.blob())
          const image = await blobOrFileToBase64(blob)
          return {
            body: '',
            image,
          }
        }
        case 'button': {
          return {
            body: '',
            image: '',
            button: {
              text: i.value?.text ?? '',
              url: i.value?.url ?? '',
            },
          }
        }
        case 'text': {
          return {
            body: i.value,
            image: '',
          }
        }
      }
    })
  )
interface PreviewData {
  deliveryAt: string
  messageItems: MessageItem[]
}

function MailPreviewContent({ data }: { data: PreviewData }) {
  const token = useToken()
  const [preview, setPreview] = useState<PreviewResponse | null>(null)

  useEffect(() => {
    let canceled = true
    ;(async () => {
      const contents = await convertImageWithinMessageList(
        data.messageItems
      ).catch(onError)
      if (contents == null) return

      const previewRequest: PreviewRequest = {
        deliveryAt: data.deliveryAt,
        contents,
      }

      const { response, error } = await broadcastMessageService.preview(
        token,
        previewRequest
      )
      if (error != null) {
        onError(error)
        return
      }
      if (canceled && error == null && response != null) {
        setPreview(response)
      }
    })()
    return () => {
      canceled = false
    }
  }, [data, token])

  if (preview == null) {
    return <Loading />
  }

  return (
    <Box>
      <Typography sx={{ marginBottom: 2 }}>件名</Typography>
      <Typography variant="h4" sx={{ marginBottom: 2 }}>
        {preview.title}
      </Typography>
      <Typography sx={{ marginBottom: 2 }}>本文</Typography>
      <Box
        sx={{ overflow: 'auto' }}
        dangerouslySetInnerHTML={{ __html: preview.bodyHtml }}
      />
    </Box>
  )
}

interface PreviewModalProps {
  data: PreviewData
  isOpen: boolean
  onClose: () => void
}

export default function BroadcastMessagePreviewModal({
  data,
  isOpen,
  onClose,
}: PreviewModalProps) {
  const [mode, setMode] = useState<'message' | 'mail'>('message')

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>プレビュー</DialogTitle>
      <DialogContent sx={{ justifyContent: 'center' }}>
        <Box sx={{ marginBottom: '24px', alignContent: 'center' }}>
          <Segmented
            options={
              [
                {
                  label: 'メッセージ',
                  value: 'message',
                },
                { label: 'メール', value: 'mail' },
              ] as const
            }
            value={mode}
            onChange={setMode}
          />
        </Box>
        <Box>
          {mode === 'message' && (
            <MessagePreviewContent
              // TODO: APIから受け取るなりする
              restaurant={{
                name: 'AutoReserve',
                logo: {
                  xl: 'https://i.autoreserve.com/thumb/240x240/user/image/000/000/222/222/014fd67f-1190-42cb-b5f7-ddcf5e0f9194.jpg',
                  lg: 'https://i.autoreserve.com/thumb/240x240/user/image/000/000/222/222/014fd67f-1190-42cb-b5f7-ddcf5e0f9194.jpg',
                  md: 'https://i.autoreserve.com/thumb/240x240/user/image/000/000/222/222/014fd67f-1190-42cb-b5f7-ddcf5e0f9194.jpg',
                  sm: 'https://i.autoreserve.com/thumb/240x240/user/image/000/000/222/222/014fd67f-1190-42cb-b5f7-ddcf5e0f9194.jpg',
                },
              }}
              previewData={data.messageItems.map((i) => {
                switch (i.type) {
                  case 'button': {
                    return {
                      type: 'button',
                      value: i.value,
                    }
                  }
                  default: {
                    return {
                      type:
                        i.type === 'local_photo' || i.type === 'remote_photo'
                          ? 'photo'
                          : i.type,
                      value:
                        i.type === 'local_photo'
                          ? URL.createObjectURL(i.value)
                          : i.value,
                    }
                  }
                }
              })}
            />
          )}
          {mode === 'mail' && <MailPreviewContent data={data} />}
        </Box>
      </DialogContent>
    </Dialog>
  )
}
