import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
} from '@mui/material'
import { OwnerInvoiceResource_Status } from '@hello-ai/proto/src/gen/auto_reserve/admin/owner_invoice/owner_invoice_resource'
import React, { useState } from 'react'
import { FilterAction } from 'components/Filter/FilterAction'
import { getStatusName } from 'models/OwnerInvoice'

const STATUSES = [
  OwnerInvoiceResource_Status.DRAFT,
  OwnerInvoiceResource_Status.BILLED,
  OwnerInvoiceResource_Status.PAID,
  OwnerInvoiceResource_Status.NOT_BILLED,
].map((status) => ({ value: status, text: getStatusName(status) }))

// FIXME: #6274 を参考に `FilterSingleStatus` と同じように `value` props を受け取り正しいreactive renderかつformのresetができ、number型前提ではないように修正してください
export function FilterStatus({
  onFilter,
  defaultValue,
}: {
  onFilter: (value: number[]) => void
  defaultValue: number[]
}) {
  const [newSelectedStatus, setNewSelectedStatus] = useState(defaultValue)

  return (
    <FormGroup sx={{ p: 2 }}>
      {STATUSES.map((option) => {
        const checked = newSelectedStatus.includes(option.value)

        return (
          <FormControlLabel
            key={option.text}
            control={<Checkbox />}
            label={option.text}
            checked={checked}
            onChange={(_, newChecked) => {
              if (newChecked === checked) return
              setNewSelectedStatus((prev) => {
                if (newChecked) {
                  return [...prev, option.value]
                } else {
                  return prev.filter(
                    (selectedStatus) => selectedStatus !== option.value
                  )
                }
              })
            }}
          />
        )
      })}
      <FilterAction
        onReset={() => {
          setNewSelectedStatus(defaultValue)
        }}
        onSubmit={() => {
          onFilter(newSelectedStatus)
        }}
      />
    </FormGroup>
  )
}

export function FilterSingleStatus<Value>({
  onFilter,
  defaultValue,
  value,
  options,
}: {
  onFilter: (value: Value) => void
  defaultValue: Value
  value: Value
  options: {
    value: Value
    text: string
  }[]
}) {
  const [newSelectedStatus, setNewSelectedStatus] = useState(
    value ?? defaultValue
  )

  return (
    // NOTE: uncontrolledにするためにあえてRadioGroupにvalueを設定しない（DOMを通ることでstringでないvalueの型が強制的にstringにキャストされないようにするため）
    <RadioGroup sx={{ p: 2 }} name="filter-status-single-select-group">
      {options.map((option) => {
        const checked = newSelectedStatus === option.value
        return (
          <FormControlLabel
            key={option.text}
            control={<Radio />}
            value={option.value}
            label={option.text}
            checked={checked}
            onChange={() => {
              setNewSelectedStatus(option.value)
            }}
          />
        )
      })}
      <FilterAction
        onReset={() => {
          setNewSelectedStatus(defaultValue)
        }}
        onSubmit={() => {
          onFilter(newSelectedStatus)
        }}
      />
    </RadioGroup>
  )
}
