import { AxiosError } from 'axios'
import { isAxiosError } from './axios'

export function getErrorMessage(error: Error | AxiosError | unknown) {
  if (isAxiosError(error)) {
    if (error.response != null) {
      return error.response?.data?.error ?? 'エラーが発生しました'
    }
    if (error.request != null) {
      return 'サーバーへの接続に失敗しました'
    }
  }
  if (error instanceof Error) {
    return error.message
  }

  return 'エラーが発生しました'
}
