import React from 'react'
import {
  FontAwesomeIcon as FontAwesomeIconRaw,
  Props,
} from '@fortawesome/react-native-fontawesome'
import { Colors } from '../constants/Colors'

function FontAwesomeIcon({ color, ...props }: Props) {
  return <FontAwesomeIconRaw {...props} color={color ?? Colors.black} />
}

const MemoizedFontAwesomeIcon = React.memo(FontAwesomeIcon)

export { MemoizedFontAwesomeIcon as FontAwesomeIcon }
