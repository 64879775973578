import React from 'react'
import { useNavigate } from 'react-router-dom'
import { signIn } from 'models/Auth'
import { z } from 'zod'
import { Controller, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { TextField, Button, Typography } from '@mui/material'
import { Box } from '@mui/system'

const schema = z.object({
  email: z
    .string({
      required_error: 'メールアドレスを入力してください',
    })
    .email({ message: '有効なメールアドレスを入力してください' }),
  password: z
    .string({
      required_error: 'パスワードを入力してください',
    })
    .min(1, 'パスワードを入力してください'),
})

type Schema = z.infer<typeof schema>

export default function SignIn() {
  const navigate = useNavigate()
  const form = useForm({
    mode: 'onChange',
    resolver: zodResolver(schema),
    defaultValues: {
      email: '',
      password: '',
    },
  })

  const onFinish = async (params: Schema) => {
    const { error } = await signIn(params.email, params.password)
    if (error != null) return
    navigate('/')
  }

  const { isDirty, isValid, isSubmitting } = form.formState

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
      }}
    >
      <Box
        sx={{
          width: 480,
          p: 3,
        }}
      >
        <Typography
          variant="h1"
          sx={{ textAlign: 'center', fontWeight: '600', fontSize: 32, mb: 3 }}
        >
          AR Admin
        </Typography>
        <Box
          component="form"
          sx={{ display: 'flex', flexDirection: 'column', rowGap: 2 }}
          onSubmit={form.handleSubmit(onFinish)}
        >
          <Controller
            control={form.control}
            name="email"
            render={({ field, fieldState }) => {
              const { error } = fieldState
              return (
                <TextField
                  {...field}
                  label="メールアドレス"
                  autoFocus
                  helperText={error?.message}
                  error={error?.message != null && !error.message}
                />
              )
            }}
          />
          <Controller
            control={form.control}
            name="password"
            render={({ field, fieldState }) => {
              const { error } = fieldState
              return (
                <TextField
                  {...field}
                  type="password"
                  label="パスワード"
                  helperText={error?.message}
                  error={error?.message != null && !error.message}
                />
              )
            }}
          />
          <Button
            type="submit"
            disabled={(!isDirty && !isValid) || isSubmitting}
            fullWidth
            variant="contained"
          >
            ログイン
          </Button>
        </Box>
      </Box>
    </Box>
  )
}
