import { ImageRequireSource, ImageURISource } from 'react-native'

import { NextStaticImageData } from '../types/NextStaticImageData'

type NextStaticRequireImage = {
  default: NextStaticImageData
}

type ExtendedImageURISource = Omit<ImageURISource, 'uri'> & {
  uri: string | NextStaticImageData | undefined | null
}

const resolve = (
  source:
    | ExtendedImageURISource
    | ImageRequireSource
    | string
    | NextStaticImageData
    | NextStaticRequireImage
): string | number | ImageURISource | ImageURISource[] | undefined => {
  // legacyでsource={importedImagePath}
  if (source == null || typeof source === 'string') {
    return source as undefined | string
  }

  if (typeof source === 'number') {
    return source as ImageRequireSource
  }

  // source={importedPath} on nextjs
  if ('src' in source) {
    return {
      ...source,
      uri: source.src,
    }
  }

  // const img = require('./assets/US.png')
  // source={img}
  if ('default' in source) {
    return {
      ...source.default,
      uri: source.default.src,
    }
  }

  // source={{ uri: importedPath }} on nextjs
  if (typeof source.uri === 'object') {
    return {
      ...source,
      uri: source.uri?.src,
    }
  }
  return source as ImageURISource
}

export type ExtendedImageSourceProp =
  | ExtendedImageURISource
  | ExtendedImageURISource[]
  | string
  | NextStaticImageData
  | ImageRequireSource

export function resolveImageSource(
  source: ExtendedImageSourceProp | undefined
): string | number | ImageURISource | ImageURISource[] | undefined {
  if (source == null) {
    return undefined
  }

  if (Array.isArray(source)) {
    return source.map((value) => resolve(value)) as ImageURISource[]
  }

  return resolve(source)
}
