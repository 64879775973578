import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import dayjs from '@hello-ai/ar_shared/src/modules/dayjs'
import {
  BroadcastMessageResource,
  BroadcastMessageResource_Status,
} from '@hello-ai/proto/src/gen/auto_reserve/admin/broadcast_message/broadcast_message_resource'
import { MessageContentResource } from '@hello-ai/proto/src/gen/auto_reserve/admin/message_content/message_content_resource'
import { Timestamp } from '@hello-ai/proto/src/gen/google/protobuf/timestamp'
import { Box, Button, CircularProgress, Typography } from '@mui/material'
import Breadcrumb from 'components/Shared/Breadcrumb'
import ConfirmModal from 'components/Shared/ConfirmModal'
import { DescriptionItem, Descriptions } from 'components/Shared/Descriptions'
import PageHeader from 'components/Shared/PageHeader'
import { displayToastError, displayToastInfo } from 'components/Shared/Toast'
import { useToken } from 'models/Auth'
import { broadcastMessageService } from 'models/BroadcastMessage'
import React, { useCallback, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import {
  getStatusDisplayLabel,
  getStatusTextStyle,
} from '../../components/BroadcastMessage/Table'

export const createBreadcrumbs = (
  broadcastMessage: BroadcastMessageResource
) => [
  {
    path: '/broadcast_messages',
    breadcrumbName: '配信一覧',
  },
  {
    path: `/broadcast_messages/${broadcastMessage.id}`,
    breadcrumbName: `${(
      broadcastMessage.messageContents.find((i) => i.image == null)?.body ??
      'メッセージなし'
    ).substring(0, 7)}...`,
  },
]

function MessageContentViewer({
  messageContent,
}: {
  messageContent: MessageContentResource
}) {
  if (messageContent.image != null) {
    return <img width={200} src={messageContent.image.url} />
  }
  if (messageContent.button) {
    return (
      <Typography color={Colors.primary}>
        {messageContent.button.text}
      </Typography>
    )
  }
  return (
    <Typography variant="body1" sx={{ whiteSpace: 'pre-line' }}>
      {messageContent.body}
    </Typography>
  )
}

export default function BroadcastMessage() {
  const navigate = useNavigate()
  const token = useToken()

  const { broadcastMessageId } = useParams<{ broadcastMessageId: string }>()
  const { data: broadcastMessage } = broadcastMessageService.useGet({
    id: broadcastMessageId!,
  })

  const [isDeleteConfirmModalOpen, setIsDeleteConfirmModalOpen] =
    useState(false)

  const onPressDelete = useCallback(async () => {
    const { error } = await broadcastMessageService.destroy(token, {
      id: broadcastMessageId!,
    })
    if (error) {
      displayToastError(error.message)
    } else {
      displayToastInfo('削除しました')
    }
    navigate('/broadcast_messages', { replace: true })
  }, [broadcastMessageId, navigate, token])

  if (broadcastMessage == null) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <CircularProgress />
      </Box>
    )
  }

  const disableUpdateOrDeleteButton =
    broadcastMessage.status !== BroadcastMessageResource_Status.PENDING

  return (
    <>
      <Breadcrumb routes={createBreadcrumbs(broadcastMessage)} />
      <ConfirmModal
        type="delete"
        title="配信を削除します"
        isVisible={isDeleteConfirmModalOpen}
        onClose={() => setIsDeleteConfirmModalOpen(false)}
        onConfirm={onPressDelete}
      />
      <PageHeader
        sx={{
          marginTop: '32px',
        }}
        titleSx={getStatusTextStyle(broadcastMessage.status)}
        title={getStatusDisplayLabel(broadcastMessage.status)}
        extra={[
          <Button
            key="delete"
            variant="outlined"
            color="delete"
            disabled={disableUpdateOrDeleteButton}
            onClick={() => setIsDeleteConfirmModalOpen(true)}
          >
            削除
          </Button>,
          <Button
            key="edit"
            variant="outlined"
            disabled={disableUpdateOrDeleteButton}
            onClick={() =>
              navigate(`/broadcast_messages/${broadcastMessage.id}/edit`)
            }
          >
            編集
          </Button>,
        ]}
      >
        <Descriptions>
          <DescriptionItem label="配信日時">
            {broadcastMessage.deliveryAt &&
              dayjs(Timestamp.toDate(broadcastMessage.deliveryAt)).format(
                'YYYY年M月D日 HH:mm'
              )}
          </DescriptionItem>
          <DescriptionItem label="配信対象">
            {Number(broadcastMessage.numberOfSent).toLocaleString()}人
            {broadcastMessage.testDelivery && '(テストユーザーのみ)'}
          </DescriptionItem>
          <DescriptionItem label="配信人数">
            {Number(broadcastMessage.successCount).toLocaleString()}人
          </DescriptionItem>
        </Descriptions>
        <Box sx={{ mt: 2 }}>
          <Typography>件名: {broadcastMessage.subject}</Typography>
        </Box>
        <Box sx={{ marginTop: '16px' }}>
          <Typography variant="h3" gutterBottom>
            メッセージ
          </Typography>
          {broadcastMessage.messageContents.map((content) => (
            <Box key={content.id} style={{ marginBottom: 24 }}>
              <MessageContentViewer messageContent={content} />
            </Box>
          ))}
        </Box>
      </PageHeader>
    </>
  )
}
