import {
  RpcError,
  RpcMetadata,
  RpcOptions,
  UnaryCall,
} from '@protobuf-ts/runtime-rpc'
import { TwirpFetchTransport } from '@protobuf-ts/twirp-transport'
import Config from 'constants/Config'

export function rpcTransport() {
  return new TwirpFetchTransport({
    baseUrl: Config.apiUrl + '/twirp',
    sendJson: true,
  })
}

export function rpcHeaders(token: string): RpcMetadata {
  return {
    Authorization: `Bearer ${token}`,
    'X-AR-Token': token,
  }
}

export function rpcOptions({ token }: { token: string | null }): RpcOptions {
  return {
    jsonOptions: { ignoreUnknownFields: true },
    ...(token != null && { meta: rpcHeaders(token) }),
  }
}

export interface WrappedResponse<T> {
  response?: T
  error?: RpcError
}

export function callRpc<T extends object>(
  call: UnaryCall<object, T>
): Promise<WrappedResponse<T>> {
  return call.response
    .then((response) => ({ response }))
    .catch((error: RpcError) => ({ error }))
}
