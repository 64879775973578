import React from 'react'
import { Typography } from '@mui/material'
import { Route } from 'components/Shared/Breadcrumb'
import Link from 'components/Shared/Link'

export function itemRender(
  route: Route,
  params: any,
  routes: Array<Route>,
  paths: Array<string>
) {
  const last = routes.indexOf(route) === routes.length - 1
  return last ? (
    <Typography variant="body1">{route.breadcrumbName}</Typography>
  ) : (
    <Link to={route.path} underline="none">
      {route.breadcrumbName}
    </Link>
  )
}
