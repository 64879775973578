import { DestroyRequest as RestaurantImageDestroyRequest } from '@hello-ai/proto/src/gen/auto_reserve/admin/restaurant_image/restaurant_image_service'
import { DestroyRequest as ReviewDestroyRequest } from '@hello-ai/proto/src/gen/auto_reserve/admin/review/review_service'
import { Box, Button, CircularProgress, Grid, Typography } from '@mui/material'
import Breadcrumb from 'components/Shared/Breadcrumb'
import ConfirmModal from 'components/Shared/ConfirmModal'
import { DescriptionItem, Descriptions } from 'components/Shared/Descriptions'
import PageHeader from 'components/Shared/PageHeader'
import { displayToastInfo } from 'components/Shared/Toast'
import dayjs from 'dayjs'
import { onError, useToken } from 'models/Auth'
import { restaurantImageClient } from 'models/RestaurantImage'
import { getStatusName, reviewClient, reviewService } from 'models/Review'
import { callRpc, rpcOptions } from 'modules/rpc'
import React from 'react'
import { View } from 'react-native'
import { useNavigate, useParams } from 'react-router'
import { Link } from 'react-router-dom'

export default function Review() {
  const navigate = useNavigate()
  const token = useToken()
  const { reviewId } = useParams<{ reviewId: string }>()
  const { data: review, mutate } = reviewService.useGet({ id: +reviewId! })

  const [reviewDestroyRequest, setReviewDestroyRequest] =
    React.useState<ReviewDestroyRequest | null>(null)
  const [restaurantImageDestroyRequest, setRestaurantImageDestroyRequest] =
    React.useState<RestaurantImageDestroyRequest | null>(null)

  if (review === undefined) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <CircularProgress />
      </Box>
    )
  }

  const { user, restaurant, restaurantImages } = review

  return (
    <View>
      <Breadcrumb
        routes={[
          {
            path: '/reviews',
            breadcrumbName: '口コミ一覧',
          },
          {
            path: `/reviews/${review.id}`,
            breadcrumbName: `${review.id}`,
          },
        ]}
      />
      <PageHeader
        title={`口コミ #${review.id}`}
        sx={{ marginTop: '32px' }}
        extra={[
          <Button
            key="edit"
            variant="contained"
            onClick={() => navigate(`/reviews/${review.id}/edit`)}
          >
            編集
          </Button>,
          <Button
            key="destroy"
            variant="contained"
            color="delete"
            onClick={() =>
              setReviewDestroyRequest({
                id: review.id,
              })
            }
          >
            削除
          </Button>,
        ]}
      >
        <Descriptions>
          <DescriptionItem label="ステータス">
            {getStatusName(review.status)}
          </DescriptionItem>
          <DescriptionItem label="ユーザー">
            <Link to={`/users/${user?.id}`}>{user?.name}</Link>
          </DescriptionItem>
          <DescriptionItem label="レストラン">
            <Link to={`/restaurants/${restaurant?.id}`}>
              {restaurant?.name}
            </Link>
          </DescriptionItem>
        </Descriptions>
        <Descriptions>
          <DescriptionItem label="内容">
            <Typography
              variant="body2"
              sx={{ whiteSpace: 'pre-wrap', marginTop: '8px' }}
            >
              {review.content}
            </Typography>
          </DescriptionItem>
        </Descriptions>
        <Descriptions>
          <DescriptionItem label="料金">{review.price?.value}</DescriptionItem>
          <DescriptionItem label="予約時間">
            {review.visitedAt !== undefined
              ? dayjs.unix(review.visitedAt.seconds).format('YYYY/MM/DD hh:mm')
              : ''}
          </DescriptionItem>
        </Descriptions>
        <Descriptions title="評価">
          <DescriptionItem label="総合">
            {review.rating?.value.toFixed(1)}
          </DescriptionItem>
          <DescriptionItem label="料理">
            {review.ratingFood?.value.toFixed(1)}
          </DescriptionItem>
          <DescriptionItem label="サービス">
            {review.ratingService?.value.toFixed(1)}
          </DescriptionItem>
          <DescriptionItem label="雰囲気">
            {review.ratingAtmosphere?.value.toFixed(1)}
          </DescriptionItem>
          <DescriptionItem label="コスパ">
            {review.ratingCostPerformance?.value.toFixed(1)}
          </DescriptionItem>
        </Descriptions>
        <Descriptions title="投稿">
          <DescriptionItem label="いいね数">
            {review.likesCount}
          </DescriptionItem>
          <DescriptionItem label="コメント数">
            {review.commentsCount}
          </DescriptionItem>
        </Descriptions>
      </PageHeader>
      <ConfirmModal
        type="delete"
        title="削除します"
        isVisible={reviewDestroyRequest !== null}
        onClose={() => setReviewDestroyRequest(null)}
        onConfirm={async () => {
          if (!reviewDestroyRequest) {
            return
          }
          const { error } = await callRpc(
            reviewClient.destroy(reviewDestroyRequest, rpcOptions({ token }))
          )

          if (error != null) {
            onError(error)
            return
          }

          displayToastInfo('削除しました')

          setReviewDestroyRequest(null)

          navigate('/reviews')
        }}
      />
      <PageHeader title="画像" sx={{ marginTop: '32px' }}>
        <Grid container gap={1}>
          {restaurantImages.map((restaurantImage) => {
            return (
              <Grid item xs={3} key={restaurantImage.id}>
                <img
                  src={restaurantImage.serializedImage?.sm}
                  style={{ objectFit: 'cover', width: '100%' }}
                />
                <Button
                  color="delete"
                  variant="outlined"
                  sx={{ marginTop: '16px' }}
                  onClick={() =>
                    setRestaurantImageDestroyRequest({
                      id: restaurantImage.id,
                    })
                  }
                >
                  削除
                </Button>
              </Grid>
            )
          })}
        </Grid>
      </PageHeader>
      <ConfirmModal
        type="delete"
        title="削除します"
        isVisible={restaurantImageDestroyRequest !== null}
        onClose={() => setRestaurantImageDestroyRequest(null)}
        onConfirm={async () => {
          if (!restaurantImageDestroyRequest) {
            return
          }
          const { error } = await callRpc(
            restaurantImageClient.destroy(
              restaurantImageDestroyRequest,
              rpcOptions({ token })
            )
          )

          if (error != null) {
            onError(error)
            return
          }

          mutate()

          displayToastInfo('削除しました')

          setRestaurantImageDestroyRequest(null)
        }}
      />
    </View>
  )
}
