// @generated by protobuf-ts 2.8.1 with parameter long_type_number,generate_dependencies
// @generated from protobuf file "auto_reserve/admin/restaurant_order_contract/restaurant_order_contract_service.proto" (package "auto_reserve.admin.restaurant_order_contract", syntax proto3)
// tslint:disable
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { RestaurantOrderContract } from "./restaurant_order_contract_service";
import type { UpdateRequest } from "./restaurant_order_contract_service";
import type { CreateRequest } from "./restaurant_order_contract_service";
import type { RestaurantOrderContractResource } from "./restaurant_order_contract_resource";
import type { GetRequest } from "./restaurant_order_contract_service";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { ListResponse } from "./restaurant_order_contract_service";
import type { ListRequest } from "./restaurant_order_contract_service";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service auto_reserve.admin.restaurant_order_contract.RestaurantOrderContract
 */
export interface IRestaurantOrderContractClient {
  /**
   * @generated from protobuf rpc: List(auto_reserve.admin.restaurant_order_contract.ListRequest) returns (auto_reserve.admin.restaurant_order_contract.ListResponse);
   */
  list(
    input: ListRequest,
    options?: RpcOptions,
  ): UnaryCall<ListRequest, ListResponse>;
  /**
   * @generated from protobuf rpc: Get(auto_reserve.admin.restaurant_order_contract.GetRequest) returns (auto_reserve.admin.restaurant_order_contract.RestaurantOrderContractResource);
   */
  get(
    input: GetRequest,
    options?: RpcOptions,
  ): UnaryCall<GetRequest, RestaurantOrderContractResource>;
  /**
   * @generated from protobuf rpc: Create(auto_reserve.admin.restaurant_order_contract.CreateRequest) returns (auto_reserve.admin.restaurant_order_contract.RestaurantOrderContractResource);
   */
  create(
    input: CreateRequest,
    options?: RpcOptions,
  ): UnaryCall<CreateRequest, RestaurantOrderContractResource>;
  /**
   * @generated from protobuf rpc: Update(auto_reserve.admin.restaurant_order_contract.UpdateRequest) returns (auto_reserve.admin.restaurant_order_contract.RestaurantOrderContractResource);
   */
  update(
    input: UpdateRequest,
    options?: RpcOptions,
  ): UnaryCall<UpdateRequest, RestaurantOrderContractResource>;
}
/**
 * @generated from protobuf service auto_reserve.admin.restaurant_order_contract.RestaurantOrderContract
 */
export class RestaurantOrderContractClient
  implements IRestaurantOrderContractClient, ServiceInfo
{
  typeName = RestaurantOrderContract.typeName;
  methods = RestaurantOrderContract.methods;
  options = RestaurantOrderContract.options;
  constructor(private readonly _transport: RpcTransport) {}
  /**
   * @generated from protobuf rpc: List(auto_reserve.admin.restaurant_order_contract.ListRequest) returns (auto_reserve.admin.restaurant_order_contract.ListResponse);
   */
  list(
    input: ListRequest,
    options?: RpcOptions,
  ): UnaryCall<ListRequest, ListResponse> {
    const method = this.methods[0],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<ListRequest, ListResponse>(
      "unary",
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: Get(auto_reserve.admin.restaurant_order_contract.GetRequest) returns (auto_reserve.admin.restaurant_order_contract.RestaurantOrderContractResource);
   */
  get(
    input: GetRequest,
    options?: RpcOptions,
  ): UnaryCall<GetRequest, RestaurantOrderContractResource> {
    const method = this.methods[1],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<GetRequest, RestaurantOrderContractResource>(
      "unary",
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: Create(auto_reserve.admin.restaurant_order_contract.CreateRequest) returns (auto_reserve.admin.restaurant_order_contract.RestaurantOrderContractResource);
   */
  create(
    input: CreateRequest,
    options?: RpcOptions,
  ): UnaryCall<CreateRequest, RestaurantOrderContractResource> {
    const method = this.methods[2],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<CreateRequest, RestaurantOrderContractResource>(
      "unary",
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: Update(auto_reserve.admin.restaurant_order_contract.UpdateRequest) returns (auto_reserve.admin.restaurant_order_contract.RestaurantOrderContractResource);
   */
  update(
    input: UpdateRequest,
    options?: RpcOptions,
  ): UnaryCall<UpdateRequest, RestaurantOrderContractResource> {
    const method = this.methods[3],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<UpdateRequest, RestaurantOrderContractResource>(
      "unary",
      this._transport,
      method,
      opt,
      input,
    );
  }
}
