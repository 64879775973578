import { StyleSheet, View } from 'react-native'
import React from 'react'
import { AspectRatioProps } from './types'

export * from './types'

const styles = StyleSheet.create({
  root: {
    overflow: 'hidden',
  },
  ratio: {
    width: '100%',
  },
  content: {
    bottom: 0,
    height: '100%',
    left: 0,
    position: 'absolute',
    top: 0,
    width: '100%',
  },
})

export function AspectRatio({
  children,
  onLayout,
  ratio,
  style,
}: AspectRatioProps) {
  return (
    <View onLayout={onLayout} style={[styles.root, style]}>
      <View
        style={[
          styles.ratio,
          {
            aspectRatio: ratio,
          },
        ]}
      />
      <View style={styles.content}>{children}</View>
    </View>
  )
}
