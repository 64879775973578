export const oneDecimalRefine = (number: number | undefined) =>
  number === undefined ||
  number.toString().match(/^\d\.\d$/) ||
  number.toString().match(/^\d$/)

export const parseFloatOrKeepOriginal = (value: string) => {
  const parsed = parseFloat(value)
  return isNaN(parsed) ? value : parsed
}

export const parseIntOrKeepOriginal = (value: string) => {
  if (value === '') return value
  const parsed = Number(value)
  return isNaN(parsed) ? value : parsed
}
