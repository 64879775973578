import React from 'react'
import { View, Image } from 'react-native'
import { useParams, useNavigate } from 'react-router'
import { useUser, getStatusName, getRoleName } from 'models/User'
import foodieImg from 'assets/images/foodie.png'
import Breadcrumb from 'components/Shared/Breadcrumb'
import PageHeader from 'components/Shared/PageHeader'
import { Avatar, Button, Typography } from '@mui/material'
import { Descriptions, DescriptionItem } from 'components/Shared/Descriptions'

export default function User() {
  const { userId } = useParams<{ userId: string }>()
  const { user } = useUser({ id: +userId! })
  const navigate = useNavigate()

  if (user === undefined) return null

  const { instagramUser } = user

  return (
    <View>
      <Breadcrumb
        routes={[
          {
            path: '/users',
            breadcrumbName: 'ユーザー一覧',
          },
          {
            path: `/users/${user.id}`,
            breadcrumbName: `${user.name}`,
          },
        ]}
      />
      <PageHeader
        prefixTitle={
          <Avatar
            src={user.serializedImage?.sm}
            sx={{ width: 64, height: 64 }}
          />
        }
        title={user.name}
        suffixTitle={
          user.isFoodie && (
            <Image
              source={{
                uri: foodieImg,
                width: 24,
                height: 24,
              }}
            />
          )
        }
        sx={{ mt: 4 }}
        extra={[
          <Button
            variant="outlined"
            key="edit"
            onClick={() => navigate(`/users/${user.id}/edit`)}
          >
            編集
          </Button>,
        ]}
      >
        <Descriptions>
          <DescriptionItem label="ユーザーID">{user.username}</DescriptionItem>
          <DescriptionItem label="表示名">{user.name}</DescriptionItem>
          <DescriptionItem label="メールアドレス">{user.email}</DescriptionItem>
          <DescriptionItem label="電話番号">{user.phoneNumber}</DescriptionItem>
          <DescriptionItem label="ロール">
            {getRoleName(user.role)}
          </DescriptionItem>
          <DescriptionItem label="ステータス">
            {getStatusName(user.status)}
          </DescriptionItem>
          <DescriptionItem label="フーディー">
            {user.isFoodie === true ? 'フーディー' : '一般'}
          </DescriptionItem>
        </Descriptions>
        {instagramUser !== undefined && (
          <>
            <Typography variant="h6" sx={{ py: 2 }}>
              Instagram
            </Typography>
            <Descriptions>
              <DescriptionItem label="ユーザーID">
                <a
                  href={`https://instagram.com/${instagramUser.instagramUsername}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {instagramUser.instagramUsername}
                </a>
              </DescriptionItem>
              <DescriptionItem label="同期">
                {instagramUser.syncEnabled === true ? 'する' : 'しない'}
              </DescriptionItem>
              <DescriptionItem label="投稿数">
                {instagramUser.instagramPostsCount}
              </DescriptionItem>
              <DescriptionItem label="フォロワー数">
                {instagramUser.instagramFollowersCount}
              </DescriptionItem>
            </Descriptions>
          </>
        )}
      </PageHeader>
    </View>
  )
}
