import useSWR from 'modules/swr'
import { createAspidaSWRParameters } from '@hello-ai/ar_shared/src/modules/aspida-swr'
import client from 'modules/api'
import { setHeader } from 'modules/axios'
import { useToken } from 'models/Auth'

export function usePrefectures() {
  const token = useToken()
  setHeader({ token })
  return useSWR(...createAspidaSWRParameters(client.prefectures))
}
