import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import {
  Box,
  Button,
  TextField,
  ToggleButtonGroup,
  ToggleButton,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Divider,
  Typography,
  DialogActions,
} from '@mui/material'
import {
  TextFields as TextFieldsIcon,
  Image as ImageIcon,
  AddCircle as AddCircleIcon,
  Delete as DeleteIcon,
  Clear as ClearIcon,
} from '@mui/icons-material'
import React, { useMemo, useRef, useState } from 'react'
import { z } from 'zod'
import { UseFormReturn, useFieldArray } from 'react-hook-form'
import { APP_MESSAGE_BUTTON_STYLE } from './MessagePreviewContent'
import { Button as ArButton } from '@hello-ai/ar_shared/src/components/Button'

type MessageButtonValue = {
  text: string
  url: string
} | null

export function MessageButtonPart({
  defaultValue,
  onChange,
}: {
  defaultValue: MessageButtonValue
  onChange: (value: NonNullable<MessageButtonValue>) => void
}) {
  const [isOpen, setIsOpen] = useState(false)
  const [value, setValue] = useState(defaultValue)

  return (
    <>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {value ? (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Box sx={{ display: 'flex', width: '100%', p: 2 }}>
              <ArButton
                variant="secondary"
                style={[APP_MESSAGE_BUTTON_STYLE, { width: 100, flex: 1 }]}
              >
                {value.text}
              </ArButton>
              <Divider
                orientation="vertical"
                flexItem
                variant="middle"
                sx={{ mx: 2 }}
              />
              <ArButton variant="primary" style={[{ width: 100, flex: 1 }]}>
                {value.text}
              </ArButton>
            </Box>
            <Box>
              <Button
                variant="text"
                color="primary"
                onClick={() => setIsOpen(true)}
              >
                編集
              </Button>
              <Button
                variant="text"
                color="primary"
                onClick={() => setValue(null)}
              >
                削除
              </Button>
            </Box>
          </Box>
        ) : (
          <Box
            sx={{
              flex: 1,
              height: 100,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Button
              variant="text"
              color="primary"
              onClick={() => setIsOpen(true)}
            >
              ボタンを作成
            </Button>
          </Box>
        )}
      </Box>
      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle
          sx={{
            display: 'fex',
            justifyContent: 'center',
            alignItems: 'center',
            borderBottom: 1,
            borderColor: Colors.border,
          }}
        >
          <IconButton
            sx={{ position: 'absolute', left: 0 }}
            onClick={() => setIsOpen(false)}
          >
            <ClearIcon />
          </IconButton>
          <Typography color="primary">ボタンを作成</Typography>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex' }}>
            <Box sx={{ display: 'flex', width: '100%', p: 2 }}>
              <ArButton
                variant="secondary"
                style={[APP_MESSAGE_BUTTON_STYLE, { width: 100, flex: 1 }]}
              >
                {value?.text}
              </ArButton>
              <Divider
                orientation="vertical"
                flexItem
                variant="middle"
                sx={{ mx: 2 }}
              />
              <ArButton variant="primary" style={[{ width: 100, flex: 1 }]}>
                {value?.text}
              </ArButton>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: 2 }}>
            <TextField
              label="ボタンテキスト"
              value={value?.text}
              onChange={(e) => {
                setValue((prev) => ({
                  text: e.target.value,
                  url: prev?.url ?? '',
                }))
              }}
              helperText="12文字以内で入力してください"
            />
            <TextField
              label="URL"
              value={value?.url}
              onChange={(e) => {
                setValue((prev) => ({
                  text: prev?.text ?? '',
                  url: e.target.value,
                }))
              }}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={value == null}
            onClick={() => {
              if (value != null) {
                onChange(value)
                setIsOpen(false)
              }
            }}
          >
            保存する
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export function MessageTextPart({
  onBlur,
  value: _value,
}: {
  onBlur: React.FocusEventHandler<HTMLTextAreaElement>
  value: string
}) {
  const [value, setValue] = useState(_value)

  return (
    <Box
      sx={{ width: '100%', height: 100, p: 0, border: 0 }}
      component="textarea"
      value={value}
      onChange={(e) => {
        setValue(e.target.value)
      }}
      onBlur={onBlur}
    />
  )
}

export function MessageImagePart({
  src,
  onChangeFile,
}: {
  src: string | null
  onChangeFile: (src: File) => void
}) {
  const inputFileRef = useRef<HTMLInputElement>(null)
  const [_src, setSrc] = useState<typeof src>(src)
  return (
    <>
      {_src != null ? (
        <Box sx={{ width: 400, position: 'relative' }}>
          <img style={{ width: '100%' }} src={_src} />
          <IconButton
            onClick={() => setSrc(null)}
            sx={{ position: 'absolute', top: -12, right: -12 }}
          >
            <ClearIcon />
          </IconButton>
        </Box>
      ) : (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: 100,
          }}
        >
          <Button
            variant="text"
            onClick={() => {
              inputFileRef.current?.click()
            }}
          >
            画像をアップロードする
          </Button>
        </Box>
      )}
      <input
        ref={inputFileRef}
        type="file"
        style={{ display: 'none' }}
        onChange={(e) => {
          const file = e.target.files?.[0]
          if (file != null) {
            onChangeFile(file)
          }
        }}
      />
    </>
  )
}

export const messageEditorSchema = z.object({
  messageContents: z.array(
    z.union([
      z.object({
        type: z.literal('text'),
        value: z.string(),
      }),
      z.object({
        type: z.literal('local_photo'),
        value: z.custom<File>(),
      }),
      z.object({
        type: z.literal('remote_photo'),
        value: z.string(),
      }),
      z.object({
        type: z.literal('button'),
        value: z.object({
          text: z.string(),
          url: z.string(),
        }),
      }),
    ])
  ),
})

type Schema = z.infer<typeof messageEditorSchema>

function MessageEditContainer({
  value,
  onChange,
  onPressDelete,
}: {
  value: Schema['messageContents'][0]
  onChange: (value: Schema['messageContents'][0]) => void
  onPressDelete: () => void
}) {
  const [type, setType] = useState(value.type)

  const editor = useMemo(() => {
    switch (type) {
      case 'text': {
        return (
          <MessageTextPart
            value={value.type === 'text' ? value.value : ''}
            onBlur={(e) => {
              onChange({
                type: 'text',
                value: e.target.value,
              })
            }}
          />
        )
      }
      case 'local_photo': {
        return (
          <MessageImagePart
            src={
              value.type === 'remote_photo'
                ? value.value
                : value.type === 'local_photo'
                ? URL.createObjectURL(value.value)
                : null
            }
            onChangeFile={(file) => {
              onChange({
                type: 'local_photo',
                value: file,
              })
            }}
          />
        )
      }
      case 'remote_photo': {
        return (
          <MessageImagePart
            src={value.type === 'remote_photo' ? value.value : ''}
            onChangeFile={(file) => {
              onChange({
                type: 'local_photo',
                value: file,
              })
            }}
          />
        )
      }
      case 'button': {
        return (
          <MessageButtonPart
            defaultValue={value.type === 'button' ? value.value : null}
            onChange={(value) => {
              onChange({
                type: 'button',
                value,
              })
            }}
          />
        )
      }
      default: {
        throw new Error('未定義のメッセージタイプです')
      }
    }
  }, [type, onChange, value])

  return (
    <Box sx={{ display: 'flex', flex: 1 }}>
      <Box sx={{ border: 1, borderColor: Colors.border, flex: 1 }}>
        <Box
          sx={{
            display: 'flex',
            flex: 1,
            borderBottom: 1,
            borderColor: Colors.border,
          }}
        >
          <ToggleButtonGroup
            exclusive
            value={type}
            onChange={(_, newType) => {
              // NOTE: 同じアイコンをクリックするとnullが返ってくる
              if (newType == null) return
              setType(newType)
            }}
          >
            <ToggleButton value="text">
              <TextFieldsIcon />
            </ToggleButton>
            <ToggleButton
              value={type === 'remote_photo' ? 'remote_photo' : 'local_photo'}
            >
              <ImageIcon />
            </ToggleButton>
            <ToggleButton value="button">
              <AddCircleIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
        <Box sx={{ backgroundColor: Colors.bgBlack }}>{editor}</Box>
      </Box>
      <IconButton onClick={onPressDelete}>
        <DeleteIcon />
      </IconButton>
    </Box>
  )
}

export function MessageEditor({ form }: { form: UseFormReturn<Schema> }) {
  const { fields, append, remove, update } = useFieldArray({
    control: form.control,
    name: 'messageContents',
  })

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
        width: '80%',
      }}
    >
      {fields.map((field, index) => {
        return (
          <MessageEditContainer
            key={field.id}
            value={field}
            onChange={(value) => {
              update(index, value)
            }}
            onPressDelete={() => {
              remove(index)
            }}
          />
        )
      })}
      <Button
        variant="outlined"
        onClick={() => {
          append({
            type: 'text',
            value: '',
          })
        }}
      >
        追加する
      </Button>
    </Box>
  )
}
