import React from 'react'
import PageHeader from 'components/Shared/PageHeader'
import { Button } from '@mui/material'
import { useNavigate } from 'react-router'
import DataGrid from 'components/Shared/DataGrid'

const dummy: { id: string; term: string; monthly_fee: string }[] = []

export function RestaurantTakeoutContracts() {
  const navigate = useNavigate()

  return (
    <PageHeader
      title="テイクアウト"
      extra={[
        <Button
          key="new"
          variant="contained"
          onClick={() => navigate(`/takeout_contracts/new`)}
          disabled
        >
          作成
        </Button>,
      ]}
    >
      <DataGrid
        rowKey={(takeout) => takeout.id}
        columns={[
          {
            key: 'action',
            render: (takeout) => (
              <Button
                variant="outlined"
                onClick={() => navigate(`/takeout_contracts/${takeout.id}`)}
              >
                詳細
              </Button>
            ),
          },
          {
            title: '期間',
            key: 'term',
            render: (takeout) => takeout.term,
          },
          {
            title: '月額費用',
            key: 'monthly_fee',
            render: (takeout) => takeout.monthly_fee,
          },
        ]}
        // TODO: Replace with real data
        data={dummy}
        sx={{ minHeight: 400 }}
      />
    </PageHeader>
  )
}
