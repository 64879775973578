import { RestaurantResource } from '@hello-ai/proto/src/gen/auto_reserve/admin/review/review_resource'
import { Box, Button, CircularProgress, Typography } from '@mui/material'
import Breadcrumb from 'components/Shared/Breadcrumb'
import DataGrid from 'components/Shared/DataGrid'
import { DescriptionItem, Descriptions } from 'components/Shared/Descriptions'
import PageHeader from 'components/Shared/PageHeader'
import { getPayoutIntervalTypeName, ownerService } from 'models/Owner'
import React from 'react'
import { useNavigate, useParams } from 'react-router'

function SubmitDetailPageButton({
  restaurant,
}: {
  restaurant: RestaurantResource
}) {
  const navigate = useNavigate()

  return (
    <Button
      variant="outlined"
      onClick={() => navigate(`/restaurants/${restaurant.id}`)}
    >
      詳細
    </Button>
  )
}

export default function Owner() {
  const navigate = useNavigate()
  const { ownerId } = useParams<{ ownerId: string }>()
  const { data: owner } = ownerService.useGet({
    id: +ownerId!,
  })
  const { data: restaurantData } = ownerService.useGetRestaurants({
    id: +ownerId!,
  })

  if (owner === undefined)
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <CircularProgress />
      </Box>
    )

  return (
    <div>
      <Breadcrumb
        routes={[
          {
            path: '/owners',
            breadcrumbName: 'オーナー一覧',
          },
          {
            path: `/owners/${owner.id}`,
            breadcrumbName: `${owner.name}`,
          },
        ]}
      />
      <PageHeader
        title={owner.name}
        sx={{ mt: 4 }}
        extra={[
          <Button
            key="invoices"
            variant="outlined"
            onClick={() => navigate(`/owner_invoices?owner_ids=${owner.id}`)}
          >
            請求一覧
          </Button>,
          <Button
            key="edit"
            variant="outlined"
            onClick={() => navigate(`/owners/${owner.id}/edit`)}
          >
            編集
          </Button>,
        ]}
      >
        <Descriptions>
          <DescriptionItem label="オーナーID">{owner.username}</DescriptionItem>
          <DescriptionItem label="オーナー名">{owner.name}</DescriptionItem>
        </Descriptions>
        <Typography variant="h6" sx={{ py: 2 }}>
          振込・請求
        </Typography>
        <Descriptions>
          <DescriptionItem label="口座振替書類">
            {owner.hasBankAccountTransferDocument === true
              ? '取得済'
              : '未取得'}
          </DescriptionItem>
          <DescriptionItem label="ロボペイ口振顧客番号">
            {owner.robopayCustomerId}
          </DescriptionItem>
          <DescriptionItem label="振込期間">
            {getPayoutIntervalTypeName(owner.payoutIntervalType)}
          </DescriptionItem>
        </Descriptions>
      </PageHeader>
      <PageHeader title="レストラン一覧" sx={{ mt: 4 }}>
        <DataGrid
          sx={{ maxHeight: 400 }}
          data={restaurantData?.restaurants}
          columns={[
            {
              key: 'action',
              render: (row) => <SubmitDetailPageButton restaurant={row} />,
            },
            {
              key: 'name',
              title: 'レストラン名',
              render: (row) => row.name,
            },
          ]}
          totalCount={restaurantData?.restaurants.length}
          loading={restaurantData === undefined}
          rowKey={(row) => `restaurant-${row.id}`}
        />
      </PageHeader>
    </div>
  )
}
