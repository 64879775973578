import {
  RestaurantReservationBookContractResource_FeeStatus,
  RestaurantReservationBookContractResource_UserFeeType,
} from '@hello-ai/proto/src/gen/auto_reserve/admin/restaurant_reservation_book_contract/restaurant_reservation_book_contract_resource'
import { RestaurantReservationBookContractClient } from '@hello-ai/proto/src/gen/auto_reserve/admin/restaurant_reservation_book_contract/restaurant_reservation_book_contract_service.client'
import { assertNever } from 'modules/assert'
import { createRpcService } from 'modules/rpc'

export const restaurantReservationBookContractService = createRpcService(
  RestaurantReservationBookContractClient
)
export const restaurantReservationBookContract =
  restaurantReservationBookContractService.client

export function getFeeStatusName(
  value: RestaurantReservationBookContractResource_FeeStatus
) {
  switch (value) {
    case RestaurantReservationBookContractResource_FeeStatus.COMMISSION:
      return '従量課金'
    case RestaurantReservationBookContractResource_FeeStatus.MONTHLY:
      return '月額課金'
    case RestaurantReservationBookContractResource_FeeStatus.USER:
      return 'ユーザー課金'
    default: {
      assertNever(value)
    }
  }
}

export function getUserFeeTypeName(
  value: RestaurantReservationBookContractResource_UserFeeType
) {
  switch (value) {
    case RestaurantReservationBookContractResource_UserFeeType.DEFAULT:
      return 'デフォルト'
    case RestaurantReservationBookContractResource_UserFeeType.CUSTOM:
      return 'カスタム'
    default: {
      assertNever(value)
    }
  }
}
