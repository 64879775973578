import React from 'react'
import { useParams, useNavigate } from 'react-router'
import { restaurantService } from 'models/Restaurant'
import { RestaurantsReservationBookContracts } from 'components/Restaurant/ReservationBookContracts'
import { RestaurantOrderContracts } from 'components/Restaurant/OrderContracts'
import { RestaurantTakeoutContracts } from 'components/Restaurant/TakeoutContracts'
import Breadcrumb from 'components/Shared/Breadcrumb'
import PageHeader from 'components/Shared/PageHeader'
import { Box, Button, Grid, Typography } from '@mui/material'
import { DescriptionItem, Descriptions } from 'components/Shared/Descriptions'

export default function Restaurant() {
  const navigate = useNavigate()
  const { restaurantId } = useParams<{ restaurantId: string }>()
  const { data: restaurant } = restaurantService.useGet({
    id: +restaurantId!,
  })

  if (restaurant === undefined) {
    return <></>
  }

  return (
    <Box>
      <Breadcrumb
        routes={[
          {
            path: '/restaurants',
            breadcrumbName: 'レストラン一覧',
          },
          {
            path: `/restaurants/${restaurant.id}`,
            breadcrumbName: restaurant.name,
          },
        ]}
      />
      <PageHeader
        sx={{ marginTop: '16px' }}
        title={restaurant.name}
        extra={[
          <Button
            key="edit"
            variant="outlined"
            onClick={() => navigate(`/restaurants/${restaurant.id}/edit`)}
          >
            編集
          </Button>,
        ]}
      >
        <Descriptions>
          <DescriptionItem label="レストラン名">
            {restaurant.name}
          </DescriptionItem>
        </Descriptions>
      </PageHeader>
      <Box style={{ marginTop: 24 }}>
        <Typography variant="h2" gutterBottom>
          契約
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <RestaurantsReservationBookContracts restaurantId={restaurantId} />
          </Grid>
          <Grid item xs={6}>
            <RestaurantOrderContracts restaurantId={restaurantId} />
          </Grid>
          <Grid item xs={6}>
            <RestaurantTakeoutContracts />
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}
