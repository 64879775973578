import { Button } from '@mui/material'
import { BaseTableRows, BasicTableCell } from 'components/Sales/BaseTableRows'
import type { Report } from 'models/Report'
import React from 'react'

type ConnectCollectionTransfer = Report['connect_collection_transfer']

const SUB_CATEGORY_ROW_SPAN = 2
const OTHERS_ROW_SPAN = SUB_CATEGORY_ROW_SPAN
const REPORTING_CATEGORY_ROW_SPAN = OTHERS_ROW_SPAN

export function ConnectCollectionTransferTableRows({
  data,
  onClickReportingCategory,
}: {
  data: ConnectCollectionTransfer
  onClickReportingCategory: () => void
}) {
  return (
    <>
      <>
        <BaseTableRows
          reportingCategoryCell={
            <BasicTableCell rowSpan={REPORTING_CATEGORY_ROW_SPAN}>
              <Button variant="text" onClick={onClickReportingCategory}>
                Connect 取立の送金
              </Button>
            </BasicTableCell>
          }
          categoryCell={
            <BasicTableCell rowSpan={OTHERS_ROW_SPAN} colSpan={2}>
              その他
            </BasicTableCell>
          }
          items={[
            {
              label: '件数',
              value: data.others?.others?.transaction_count,
            },
            {
              label: '総額',
              value: data.others?.others?.total_amount,
              type: 'currency',
            },
          ]}
        />
      </>
    </>
  )
}
