// @generated by protobuf-ts 2.8.1 with parameter long_type_number,generate_dependencies
// @generated from protobuf file "auto_reserve/admin/restaurant_reservation_book_contract/restaurant_reservation_book_contract_service.proto" (package "auto_reserve.admin.restaurant_reservation_book_contract", syntax proto3)
// tslint:disable
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { RestaurantReservationBookContractResource_UserFeeType } from "./restaurant_reservation_book_contract_resource";
import { RestaurantReservationBookContractResource_FeeStatus } from "./restaurant_reservation_book_contract_resource";
import { StringValue } from "../../../google/protobuf/wrappers";
import { RestaurantReservationBookContractResource } from "./restaurant_reservation_book_contract_resource";
/**
 * @generated from protobuf message auto_reserve.admin.restaurant_reservation_book_contract.ListRequest
 */
export interface ListRequest {}
/**
 * @generated from protobuf message auto_reserve.admin.restaurant_reservation_book_contract.ListResponse
 */
export interface ListResponse {
  /**
   * @generated from protobuf field: repeated auto_reserve.admin.restaurant_reservation_book_contract.RestaurantReservationBookContractResource restaurant_reservation_book_contracts = 1;
   */
  restaurantReservationBookContracts: RestaurantReservationBookContractResource[];
}
/**
 * @generated from protobuf message auto_reserve.admin.restaurant_reservation_book_contract.GetRequest
 */
export interface GetRequest {
  /**
   * @generated from protobuf field: string id = 1;
   */
  id: string;
}
/**
 * @generated from protobuf message auto_reserve.admin.restaurant_reservation_book_contract.CreateRequest
 */
export interface CreateRequest {
  /**
   * @generated from protobuf field: int64 restaurant_id = 1;
   */
  restaurantId: number;
  /**
   * @generated from protobuf field: string start_date = 2;
   */
  startDate: string;
  /**
   * @generated from protobuf field: google.protobuf.StringValue end_date = 3;
   */
  endDate?: StringValue;
  /**
   * @generated from protobuf field: int64 monthly_fee = 4;
   */
  monthlyFee: number;
  /**
   * @generated from protobuf field: int64 contract_period = 5;
   */
  contractPeriod: number;
  /**
   * @generated from protobuf field: int64 lunch_commission_fee = 6;
   */
  lunchCommissionFee: number;
  /**
   * @generated from protobuf field: int64 dinner_commission_fee = 7;
   */
  dinnerCommissionFee: number;
  /**
   * @generated from protobuf field: google.protobuf.StringValue memo = 8;
   */
  memo?: StringValue;
  /**
   * @generated from protobuf field: auto_reserve.admin.restaurant_reservation_book_contract.RestaurantReservationBookContractResource.FeeStatus fee_status = 9;
   */
  feeStatus: RestaurantReservationBookContractResource_FeeStatus;
  /**
   * @generated from protobuf field: auto_reserve.admin.restaurant_reservation_book_contract.RestaurantReservationBookContractResource.UserFeeType user_fee_type = 10;
   */
  userFeeType: RestaurantReservationBookContractResource_UserFeeType;
  /**
   * @generated from protobuf field: int64 user_fee = 11;
   */
  userFee: number;
  /**
   * @generated from protobuf field: google.protobuf.StringValue restaurant_reservation_book_plan_id = 12;
   */
  restaurantReservationBookPlanId?: StringValue;
}
/**
 * @generated from protobuf message auto_reserve.admin.restaurant_reservation_book_contract.UpdateRequest
 */
export interface UpdateRequest {
  /**
   * @generated from protobuf field: string id = 1;
   */
  id: string;
  /**
   * @generated from protobuf field: string start_date = 2;
   */
  startDate: string;
  /**
   * @generated from protobuf field: google.protobuf.StringValue end_date = 3;
   */
  endDate?: StringValue;
  /**
   * @generated from protobuf field: int64 monthly_fee = 4;
   */
  monthlyFee: number;
  /**
   * @generated from protobuf field: int64 contract_period = 5;
   */
  contractPeriod: number;
  /**
   * @generated from protobuf field: int64 lunch_commission_fee = 6;
   */
  lunchCommissionFee: number;
  /**
   * @generated from protobuf field: int64 dinner_commission_fee = 7;
   */
  dinnerCommissionFee: number;
  /**
   * @generated from protobuf field: google.protobuf.StringValue memo = 8;
   */
  memo?: StringValue;
  /**
   * @generated from protobuf field: auto_reserve.admin.restaurant_reservation_book_contract.RestaurantReservationBookContractResource.FeeStatus fee_status = 9;
   */
  feeStatus: RestaurantReservationBookContractResource_FeeStatus;
  /**
   * @generated from protobuf field: auto_reserve.admin.restaurant_reservation_book_contract.RestaurantReservationBookContractResource.UserFeeType user_fee_type = 10;
   */
  userFeeType: RestaurantReservationBookContractResource_UserFeeType;
  /**
   * @generated from protobuf field: int64 user_fee = 11;
   */
  userFee: number;
  /**
   * @generated from protobuf field: google.protobuf.StringValue restaurant_reservation_book_plan_id = 12;
   */
  restaurantReservationBookPlanId?: StringValue;
}
// @generated message type with reflection information, may provide speed optimized methods
class ListRequest$Type extends MessageType<ListRequest> {
  constructor() {
    super(
      "auto_reserve.admin.restaurant_reservation_book_contract.ListRequest",
      [],
    );
  }
  create(value?: PartialMessage<ListRequest>): ListRequest {
    const message = {};
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<ListRequest>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: ListRequest,
  ): ListRequest {
    return target ?? this.create();
  }
  internalBinaryWrite(
    message: ListRequest,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.admin.restaurant_reservation_book_contract.ListRequest
 */
export const ListRequest = new ListRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListResponse$Type extends MessageType<ListResponse> {
  constructor() {
    super(
      "auto_reserve.admin.restaurant_reservation_book_contract.ListResponse",
      [
        {
          no: 1,
          name: "restaurant_reservation_book_contracts",
          kind: "message",
          repeat: 1 /*RepeatType.PACKED*/,
          T: () => RestaurantReservationBookContractResource,
        },
      ],
    );
  }
  create(value?: PartialMessage<ListResponse>): ListResponse {
    const message = { restaurantReservationBookContracts: [] };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<ListResponse>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: ListResponse,
  ): ListResponse {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* repeated auto_reserve.admin.restaurant_reservation_book_contract.RestaurantReservationBookContractResource restaurant_reservation_book_contracts */ 1:
          message.restaurantReservationBookContracts.push(
            RestaurantReservationBookContractResource.internalBinaryRead(
              reader,
              reader.uint32(),
              options,
            ),
          );
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: ListResponse,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* repeated auto_reserve.admin.restaurant_reservation_book_contract.RestaurantReservationBookContractResource restaurant_reservation_book_contracts = 1; */
    for (let i = 0; i < message.restaurantReservationBookContracts.length; i++)
      RestaurantReservationBookContractResource.internalBinaryWrite(
        message.restaurantReservationBookContracts[i],
        writer.tag(1, WireType.LengthDelimited).fork(),
        options,
      ).join();
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.admin.restaurant_reservation_book_contract.ListResponse
 */
export const ListResponse = new ListResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetRequest$Type extends MessageType<GetRequest> {
  constructor() {
    super(
      "auto_reserve.admin.restaurant_reservation_book_contract.GetRequest",
      [{ no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }],
    );
  }
  create(value?: PartialMessage<GetRequest>): GetRequest {
    const message = { id: "" };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<GetRequest>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: GetRequest,
  ): GetRequest {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* string id */ 1:
          message.id = reader.string();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: GetRequest,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* string id = 1; */
    if (message.id !== "")
      writer.tag(1, WireType.LengthDelimited).string(message.id);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.admin.restaurant_reservation_book_contract.GetRequest
 */
export const GetRequest = new GetRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateRequest$Type extends MessageType<CreateRequest> {
  constructor() {
    super(
      "auto_reserve.admin.restaurant_reservation_book_contract.CreateRequest",
      [
        {
          no: 1,
          name: "restaurant_id",
          kind: "scalar",
          T: 3 /*ScalarType.INT64*/,
          L: 2 /*LongType.NUMBER*/,
        },
        {
          no: 2,
          name: "start_date",
          kind: "scalar",
          T: 9 /*ScalarType.STRING*/,
        },
        { no: 3, name: "end_date", kind: "message", T: () => StringValue },
        {
          no: 4,
          name: "monthly_fee",
          kind: "scalar",
          T: 3 /*ScalarType.INT64*/,
          L: 2 /*LongType.NUMBER*/,
        },
        {
          no: 5,
          name: "contract_period",
          kind: "scalar",
          T: 3 /*ScalarType.INT64*/,
          L: 2 /*LongType.NUMBER*/,
        },
        {
          no: 6,
          name: "lunch_commission_fee",
          kind: "scalar",
          T: 3 /*ScalarType.INT64*/,
          L: 2 /*LongType.NUMBER*/,
        },
        {
          no: 7,
          name: "dinner_commission_fee",
          kind: "scalar",
          T: 3 /*ScalarType.INT64*/,
          L: 2 /*LongType.NUMBER*/,
        },
        { no: 8, name: "memo", kind: "message", T: () => StringValue },
        {
          no: 9,
          name: "fee_status",
          kind: "enum",
          T: () => [
            "auto_reserve.admin.restaurant_reservation_book_contract.RestaurantReservationBookContractResource.FeeStatus",
            RestaurantReservationBookContractResource_FeeStatus,
          ],
        },
        {
          no: 10,
          name: "user_fee_type",
          kind: "enum",
          T: () => [
            "auto_reserve.admin.restaurant_reservation_book_contract.RestaurantReservationBookContractResource.UserFeeType",
            RestaurantReservationBookContractResource_UserFeeType,
          ],
        },
        {
          no: 11,
          name: "user_fee",
          kind: "scalar",
          T: 3 /*ScalarType.INT64*/,
          L: 2 /*LongType.NUMBER*/,
        },
        {
          no: 12,
          name: "restaurant_reservation_book_plan_id",
          kind: "message",
          T: () => StringValue,
        },
      ],
    );
  }
  create(value?: PartialMessage<CreateRequest>): CreateRequest {
    const message = {
      restaurantId: 0,
      startDate: "",
      monthlyFee: 0,
      contractPeriod: 0,
      lunchCommissionFee: 0,
      dinnerCommissionFee: 0,
      feeStatus: 0,
      userFeeType: 0,
      userFee: 0,
    };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<CreateRequest>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: CreateRequest,
  ): CreateRequest {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* int64 restaurant_id */ 1:
          message.restaurantId = reader.int64().toNumber();
          break;
        case /* string start_date */ 2:
          message.startDate = reader.string();
          break;
        case /* google.protobuf.StringValue end_date */ 3:
          message.endDate = StringValue.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.endDate,
          );
          break;
        case /* int64 monthly_fee */ 4:
          message.monthlyFee = reader.int64().toNumber();
          break;
        case /* int64 contract_period */ 5:
          message.contractPeriod = reader.int64().toNumber();
          break;
        case /* int64 lunch_commission_fee */ 6:
          message.lunchCommissionFee = reader.int64().toNumber();
          break;
        case /* int64 dinner_commission_fee */ 7:
          message.dinnerCommissionFee = reader.int64().toNumber();
          break;
        case /* google.protobuf.StringValue memo */ 8:
          message.memo = StringValue.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.memo,
          );
          break;
        case /* auto_reserve.admin.restaurant_reservation_book_contract.RestaurantReservationBookContractResource.FeeStatus fee_status */ 9:
          message.feeStatus = reader.int32();
          break;
        case /* auto_reserve.admin.restaurant_reservation_book_contract.RestaurantReservationBookContractResource.UserFeeType user_fee_type */ 10:
          message.userFeeType = reader.int32();
          break;
        case /* int64 user_fee */ 11:
          message.userFee = reader.int64().toNumber();
          break;
        case /* google.protobuf.StringValue restaurant_reservation_book_plan_id */ 12:
          message.restaurantReservationBookPlanId =
            StringValue.internalBinaryRead(
              reader,
              reader.uint32(),
              options,
              message.restaurantReservationBookPlanId,
            );
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: CreateRequest,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* int64 restaurant_id = 1; */
    if (message.restaurantId !== 0)
      writer.tag(1, WireType.Varint).int64(message.restaurantId);
    /* string start_date = 2; */
    if (message.startDate !== "")
      writer.tag(2, WireType.LengthDelimited).string(message.startDate);
    /* google.protobuf.StringValue end_date = 3; */
    if (message.endDate)
      StringValue.internalBinaryWrite(
        message.endDate,
        writer.tag(3, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* int64 monthly_fee = 4; */
    if (message.monthlyFee !== 0)
      writer.tag(4, WireType.Varint).int64(message.monthlyFee);
    /* int64 contract_period = 5; */
    if (message.contractPeriod !== 0)
      writer.tag(5, WireType.Varint).int64(message.contractPeriod);
    /* int64 lunch_commission_fee = 6; */
    if (message.lunchCommissionFee !== 0)
      writer.tag(6, WireType.Varint).int64(message.lunchCommissionFee);
    /* int64 dinner_commission_fee = 7; */
    if (message.dinnerCommissionFee !== 0)
      writer.tag(7, WireType.Varint).int64(message.dinnerCommissionFee);
    /* google.protobuf.StringValue memo = 8; */
    if (message.memo)
      StringValue.internalBinaryWrite(
        message.memo,
        writer.tag(8, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* auto_reserve.admin.restaurant_reservation_book_contract.RestaurantReservationBookContractResource.FeeStatus fee_status = 9; */
    if (message.feeStatus !== 0)
      writer.tag(9, WireType.Varint).int32(message.feeStatus);
    /* auto_reserve.admin.restaurant_reservation_book_contract.RestaurantReservationBookContractResource.UserFeeType user_fee_type = 10; */
    if (message.userFeeType !== 0)
      writer.tag(10, WireType.Varint).int32(message.userFeeType);
    /* int64 user_fee = 11; */
    if (message.userFee !== 0)
      writer.tag(11, WireType.Varint).int64(message.userFee);
    /* google.protobuf.StringValue restaurant_reservation_book_plan_id = 12; */
    if (message.restaurantReservationBookPlanId)
      StringValue.internalBinaryWrite(
        message.restaurantReservationBookPlanId,
        writer.tag(12, WireType.LengthDelimited).fork(),
        options,
      ).join();
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.admin.restaurant_reservation_book_contract.CreateRequest
 */
export const CreateRequest = new CreateRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateRequest$Type extends MessageType<UpdateRequest> {
  constructor() {
    super(
      "auto_reserve.admin.restaurant_reservation_book_contract.UpdateRequest",
      [
        { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
        {
          no: 2,
          name: "start_date",
          kind: "scalar",
          T: 9 /*ScalarType.STRING*/,
        },
        { no: 3, name: "end_date", kind: "message", T: () => StringValue },
        {
          no: 4,
          name: "monthly_fee",
          kind: "scalar",
          T: 3 /*ScalarType.INT64*/,
          L: 2 /*LongType.NUMBER*/,
        },
        {
          no: 5,
          name: "contract_period",
          kind: "scalar",
          T: 3 /*ScalarType.INT64*/,
          L: 2 /*LongType.NUMBER*/,
        },
        {
          no: 6,
          name: "lunch_commission_fee",
          kind: "scalar",
          T: 3 /*ScalarType.INT64*/,
          L: 2 /*LongType.NUMBER*/,
        },
        {
          no: 7,
          name: "dinner_commission_fee",
          kind: "scalar",
          T: 3 /*ScalarType.INT64*/,
          L: 2 /*LongType.NUMBER*/,
        },
        { no: 8, name: "memo", kind: "message", T: () => StringValue },
        {
          no: 9,
          name: "fee_status",
          kind: "enum",
          T: () => [
            "auto_reserve.admin.restaurant_reservation_book_contract.RestaurantReservationBookContractResource.FeeStatus",
            RestaurantReservationBookContractResource_FeeStatus,
          ],
        },
        {
          no: 10,
          name: "user_fee_type",
          kind: "enum",
          T: () => [
            "auto_reserve.admin.restaurant_reservation_book_contract.RestaurantReservationBookContractResource.UserFeeType",
            RestaurantReservationBookContractResource_UserFeeType,
          ],
        },
        {
          no: 11,
          name: "user_fee",
          kind: "scalar",
          T: 3 /*ScalarType.INT64*/,
          L: 2 /*LongType.NUMBER*/,
        },
        {
          no: 12,
          name: "restaurant_reservation_book_plan_id",
          kind: "message",
          T: () => StringValue,
        },
      ],
    );
  }
  create(value?: PartialMessage<UpdateRequest>): UpdateRequest {
    const message = {
      id: "",
      startDate: "",
      monthlyFee: 0,
      contractPeriod: 0,
      lunchCommissionFee: 0,
      dinnerCommissionFee: 0,
      feeStatus: 0,
      userFeeType: 0,
      userFee: 0,
    };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<UpdateRequest>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: UpdateRequest,
  ): UpdateRequest {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* string id */ 1:
          message.id = reader.string();
          break;
        case /* string start_date */ 2:
          message.startDate = reader.string();
          break;
        case /* google.protobuf.StringValue end_date */ 3:
          message.endDate = StringValue.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.endDate,
          );
          break;
        case /* int64 monthly_fee */ 4:
          message.monthlyFee = reader.int64().toNumber();
          break;
        case /* int64 contract_period */ 5:
          message.contractPeriod = reader.int64().toNumber();
          break;
        case /* int64 lunch_commission_fee */ 6:
          message.lunchCommissionFee = reader.int64().toNumber();
          break;
        case /* int64 dinner_commission_fee */ 7:
          message.dinnerCommissionFee = reader.int64().toNumber();
          break;
        case /* google.protobuf.StringValue memo */ 8:
          message.memo = StringValue.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.memo,
          );
          break;
        case /* auto_reserve.admin.restaurant_reservation_book_contract.RestaurantReservationBookContractResource.FeeStatus fee_status */ 9:
          message.feeStatus = reader.int32();
          break;
        case /* auto_reserve.admin.restaurant_reservation_book_contract.RestaurantReservationBookContractResource.UserFeeType user_fee_type */ 10:
          message.userFeeType = reader.int32();
          break;
        case /* int64 user_fee */ 11:
          message.userFee = reader.int64().toNumber();
          break;
        case /* google.protobuf.StringValue restaurant_reservation_book_plan_id */ 12:
          message.restaurantReservationBookPlanId =
            StringValue.internalBinaryRead(
              reader,
              reader.uint32(),
              options,
              message.restaurantReservationBookPlanId,
            );
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: UpdateRequest,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* string id = 1; */
    if (message.id !== "")
      writer.tag(1, WireType.LengthDelimited).string(message.id);
    /* string start_date = 2; */
    if (message.startDate !== "")
      writer.tag(2, WireType.LengthDelimited).string(message.startDate);
    /* google.protobuf.StringValue end_date = 3; */
    if (message.endDate)
      StringValue.internalBinaryWrite(
        message.endDate,
        writer.tag(3, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* int64 monthly_fee = 4; */
    if (message.monthlyFee !== 0)
      writer.tag(4, WireType.Varint).int64(message.monthlyFee);
    /* int64 contract_period = 5; */
    if (message.contractPeriod !== 0)
      writer.tag(5, WireType.Varint).int64(message.contractPeriod);
    /* int64 lunch_commission_fee = 6; */
    if (message.lunchCommissionFee !== 0)
      writer.tag(6, WireType.Varint).int64(message.lunchCommissionFee);
    /* int64 dinner_commission_fee = 7; */
    if (message.dinnerCommissionFee !== 0)
      writer.tag(7, WireType.Varint).int64(message.dinnerCommissionFee);
    /* google.protobuf.StringValue memo = 8; */
    if (message.memo)
      StringValue.internalBinaryWrite(
        message.memo,
        writer.tag(8, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* auto_reserve.admin.restaurant_reservation_book_contract.RestaurantReservationBookContractResource.FeeStatus fee_status = 9; */
    if (message.feeStatus !== 0)
      writer.tag(9, WireType.Varint).int32(message.feeStatus);
    /* auto_reserve.admin.restaurant_reservation_book_contract.RestaurantReservationBookContractResource.UserFeeType user_fee_type = 10; */
    if (message.userFeeType !== 0)
      writer.tag(10, WireType.Varint).int32(message.userFeeType);
    /* int64 user_fee = 11; */
    if (message.userFee !== 0)
      writer.tag(11, WireType.Varint).int64(message.userFee);
    /* google.protobuf.StringValue restaurant_reservation_book_plan_id = 12; */
    if (message.restaurantReservationBookPlanId)
      StringValue.internalBinaryWrite(
        message.restaurantReservationBookPlanId,
        writer.tag(12, WireType.LengthDelimited).fork(),
        options,
      ).join();
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.admin.restaurant_reservation_book_contract.UpdateRequest
 */
export const UpdateRequest = new UpdateRequest$Type();
/**
 * @generated ServiceType for protobuf service auto_reserve.admin.restaurant_reservation_book_contract.RestaurantReservationBookContract
 */
export const RestaurantReservationBookContract = new ServiceType(
  "auto_reserve.admin.restaurant_reservation_book_contract.RestaurantReservationBookContract",
  [
    { name: "List", options: {}, I: ListRequest, O: ListResponse },
    {
      name: "Get",
      options: {},
      I: GetRequest,
      O: RestaurantReservationBookContractResource,
    },
    {
      name: "Create",
      options: {},
      I: CreateRequest,
      O: RestaurantReservationBookContractResource,
    },
    {
      name: "Update",
      options: {},
      I: UpdateRequest,
      O: RestaurantReservationBookContractResource,
    },
  ],
);
