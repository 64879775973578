// @generated by protobuf-ts 2.8.1 with parameter long_type_number,generate_dependencies
// @generated from protobuf file "auto_reserve/admin/medium/medium_resource.proto" (package "auto_reserve.admin.medium", syntax proto3)
// tslint:disable
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Timestamp } from "../../../google/protobuf/timestamp";
/**
 * @generated from protobuf message auto_reserve.admin.medium.UserResource
 */
export interface UserResource {
  /**
   * @generated from protobuf field: int64 id = 1;
   */
  id: number;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp created_at = 2;
   */
  createdAt?: Timestamp;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp updated_at = 3;
   */
  updatedAt?: Timestamp;
  /**
   * @generated from protobuf field: string name = 4;
   */
  name: string;
  /**
   * @generated from protobuf field: string email = 5;
   */
  email: string;
}
/**
 * @generated from protobuf message auto_reserve.admin.medium.MediumResource
 */
export interface MediumResource {
  /**
   * @generated from protobuf field: string id = 1;
   */
  id: string;
  /**
   * @generated from protobuf field: string name = 2;
   */
  name: string;
  /**
   * @generated from protobuf field: string domain = 3;
   */
  domain: string;
  /**
   * @generated from protobuf field: string slug = 4;
   */
  slug: string;
  /**
   * @generated from protobuf field: auto_reserve.admin.medium.MediumResource.Status status = 5;
   */
  status: MediumResource_Status;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp created_at = 6;
   */
  createdAt?: Timestamp;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp updated_at = 7;
   */
  updatedAt?: Timestamp;
  /**
   * @generated from protobuf field: repeated auto_reserve.admin.medium.UserResource users = 8;
   */
  users: UserResource[];
  /**
   * @generated from protobuf field: auto_reserve.admin.medium.MediumResource.AffiliateType affiliate_type = 9;
   */
  affiliateType: MediumResource_AffiliateType;
  /**
   * @generated from protobuf field: string url = 10;
   */
  url: string;
}
/**
 * @generated from protobuf enum auto_reserve.admin.medium.MediumResource.Status
 */
export enum MediumResource_Status {
  /**
   * @generated from protobuf enum value: UNDER_REVIEW = 0;
   */
  UNDER_REVIEW = 0,
  /**
   * @generated from protobuf enum value: CERTIFIED = 1;
   */
  CERTIFIED = 1,
  /**
   * @generated from protobuf enum value: NOT_CERTIFIED = 2;
   */
  NOT_CERTIFIED = 2,
}
/**
 * @generated from protobuf enum auto_reserve.admin.medium.MediumResource.AffiliateType
 */
export enum MediumResource_AffiliateType {
  /**
   * @generated from protobuf enum value: EMBEDDED = 0;
   */
  EMBEDDED = 0,
  /**
   * @generated from protobuf enum value: LINK = 1;
   */
  LINK = 1,
}
// @generated message type with reflection information, may provide speed optimized methods
class UserResource$Type extends MessageType<UserResource> {
  constructor() {
    super("auto_reserve.admin.medium.UserResource", [
      {
        no: 1,
        name: "id",
        kind: "scalar",
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
      { no: 2, name: "created_at", kind: "message", T: () => Timestamp },
      { no: 3, name: "updated_at", kind: "message", T: () => Timestamp },
      { no: 4, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
      { no: 5, name: "email", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
    ]);
  }
  create(value?: PartialMessage<UserResource>): UserResource {
    const message = { id: 0, name: "", email: "" };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<UserResource>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: UserResource,
  ): UserResource {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* int64 id */ 1:
          message.id = reader.int64().toNumber();
          break;
        case /* google.protobuf.Timestamp created_at */ 2:
          message.createdAt = Timestamp.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.createdAt,
          );
          break;
        case /* google.protobuf.Timestamp updated_at */ 3:
          message.updatedAt = Timestamp.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.updatedAt,
          );
          break;
        case /* string name */ 4:
          message.name = reader.string();
          break;
        case /* string email */ 5:
          message.email = reader.string();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: UserResource,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* int64 id = 1; */
    if (message.id !== 0) writer.tag(1, WireType.Varint).int64(message.id);
    /* google.protobuf.Timestamp created_at = 2; */
    if (message.createdAt)
      Timestamp.internalBinaryWrite(
        message.createdAt,
        writer.tag(2, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* google.protobuf.Timestamp updated_at = 3; */
    if (message.updatedAt)
      Timestamp.internalBinaryWrite(
        message.updatedAt,
        writer.tag(3, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* string name = 4; */
    if (message.name !== "")
      writer.tag(4, WireType.LengthDelimited).string(message.name);
    /* string email = 5; */
    if (message.email !== "")
      writer.tag(5, WireType.LengthDelimited).string(message.email);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.admin.medium.UserResource
 */
export const UserResource = new UserResource$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MediumResource$Type extends MessageType<MediumResource> {
  constructor() {
    super("auto_reserve.admin.medium.MediumResource", [
      { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: "domain", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
      { no: 4, name: "slug", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
      {
        no: 5,
        name: "status",
        kind: "enum",
        T: () => [
          "auto_reserve.admin.medium.MediumResource.Status",
          MediumResource_Status,
        ],
      },
      { no: 6, name: "created_at", kind: "message", T: () => Timestamp },
      { no: 7, name: "updated_at", kind: "message", T: () => Timestamp },
      {
        no: 8,
        name: "users",
        kind: "message",
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => UserResource,
      },
      {
        no: 9,
        name: "affiliate_type",
        kind: "enum",
        T: () => [
          "auto_reserve.admin.medium.MediumResource.AffiliateType",
          MediumResource_AffiliateType,
        ],
      },
      { no: 10, name: "url", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
    ]);
  }
  create(value?: PartialMessage<MediumResource>): MediumResource {
    const message = {
      id: "",
      name: "",
      domain: "",
      slug: "",
      status: 0,
      users: [],
      affiliateType: 0,
      url: "",
    };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<MediumResource>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: MediumResource,
  ): MediumResource {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* string id */ 1:
          message.id = reader.string();
          break;
        case /* string name */ 2:
          message.name = reader.string();
          break;
        case /* string domain */ 3:
          message.domain = reader.string();
          break;
        case /* string slug */ 4:
          message.slug = reader.string();
          break;
        case /* auto_reserve.admin.medium.MediumResource.Status status */ 5:
          message.status = reader.int32();
          break;
        case /* google.protobuf.Timestamp created_at */ 6:
          message.createdAt = Timestamp.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.createdAt,
          );
          break;
        case /* google.protobuf.Timestamp updated_at */ 7:
          message.updatedAt = Timestamp.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.updatedAt,
          );
          break;
        case /* repeated auto_reserve.admin.medium.UserResource users */ 8:
          message.users.push(
            UserResource.internalBinaryRead(reader, reader.uint32(), options),
          );
          break;
        case /* auto_reserve.admin.medium.MediumResource.AffiliateType affiliate_type */ 9:
          message.affiliateType = reader.int32();
          break;
        case /* string url */ 10:
          message.url = reader.string();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: MediumResource,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* string id = 1; */
    if (message.id !== "")
      writer.tag(1, WireType.LengthDelimited).string(message.id);
    /* string name = 2; */
    if (message.name !== "")
      writer.tag(2, WireType.LengthDelimited).string(message.name);
    /* string domain = 3; */
    if (message.domain !== "")
      writer.tag(3, WireType.LengthDelimited).string(message.domain);
    /* string slug = 4; */
    if (message.slug !== "")
      writer.tag(4, WireType.LengthDelimited).string(message.slug);
    /* auto_reserve.admin.medium.MediumResource.Status status = 5; */
    if (message.status !== 0)
      writer.tag(5, WireType.Varint).int32(message.status);
    /* google.protobuf.Timestamp created_at = 6; */
    if (message.createdAt)
      Timestamp.internalBinaryWrite(
        message.createdAt,
        writer.tag(6, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* google.protobuf.Timestamp updated_at = 7; */
    if (message.updatedAt)
      Timestamp.internalBinaryWrite(
        message.updatedAt,
        writer.tag(7, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* repeated auto_reserve.admin.medium.UserResource users = 8; */
    for (let i = 0; i < message.users.length; i++)
      UserResource.internalBinaryWrite(
        message.users[i],
        writer.tag(8, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* auto_reserve.admin.medium.MediumResource.AffiliateType affiliate_type = 9; */
    if (message.affiliateType !== 0)
      writer.tag(9, WireType.Varint).int32(message.affiliateType);
    /* string url = 10; */
    if (message.url !== "")
      writer.tag(10, WireType.LengthDelimited).string(message.url);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.admin.medium.MediumResource
 */
export const MediumResource = new MediumResource$Type();
