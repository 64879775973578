// @generated by protobuf-ts 2.8.1 with parameter long_type_number,generate_dependencies
// @generated from protobuf file "auto_reserve/admin/restaurant/restaurant_service.proto" (package "auto_reserve.admin.restaurant", syntax proto3)
// tslint:disable
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { RestaurantReservationBookContractResource } from "../restaurant_reservation_book_contract/restaurant_reservation_book_contract_resource";
import { RestaurantOrderContractResource } from "../restaurant_order_contract/restaurant_order_contract_resource";
import { RestaurantResource_PublishedStatus } from "./restaurant_resource";
import { RestaurantResource_Status } from "./restaurant_resource";
import { StringValue } from "../../../google/protobuf/wrappers";
/**
 * @generated from protobuf message auto_reserve.admin.restaurant.ListFilters
 */
export interface ListFilters {
  /**
   * @generated from protobuf field: google.protobuf.StringValue name = 1;
   */
  name?: StringValue;
  /**
   * @generated from protobuf field: repeated int64 statuses = 2;
   */
  statuses: number[];
  /**
   * @generated from protobuf field: repeated int64 published_statuses = 3;
   */
  publishedStatuses: number[];
}
/**
 * @generated from protobuf message auto_reserve.admin.restaurant.ListRequest
 */
export interface ListRequest {
  /**
   * @generated from protobuf field: int64 page = 1;
   */
  page: number;
  /**
   * @generated from protobuf field: int64 per = 2;
   */
  per: number;
  /**
   * @generated from protobuf field: auto_reserve.admin.restaurant.ListFilters filters = 3;
   */
  filters?: ListFilters;
}
/**
 * @generated from protobuf message auto_reserve.admin.restaurant.ListRestaurantResource
 */
export interface ListRestaurantResource {
  /**
   * @generated from protobuf field: int64 id = 1;
   */
  id: number;
  /**
   * @generated from protobuf field: string name = 2;
   */
  name: string;
  /**
   * @generated from protobuf field: string address = 3;
   */
  address: string;
  /**
   * @generated from protobuf field: auto_reserve.admin.restaurant.RestaurantResource.Status status = 4;
   */
  status: RestaurantResource_Status;
  /**
   * @generated from protobuf field: auto_reserve.admin.restaurant.RestaurantResource.PublishedStatus published_status = 5;
   */
  publishedStatus: RestaurantResource_PublishedStatus;
}
/**
 * @generated from protobuf message auto_reserve.admin.restaurant.ListResponse
 */
export interface ListResponse {
  /**
   * @generated from protobuf field: repeated auto_reserve.admin.restaurant.ListRestaurantResource restaurants = 1;
   */
  restaurants: ListRestaurantResource[];
  /**
   * @generated from protobuf field: int64 total_count = 2;
   */
  totalCount: number;
}
/**
 * @generated from protobuf message auto_reserve.admin.restaurant.GetRequest
 */
export interface GetRequest {
  /**
   * @generated from protobuf field: int64 id = 1;
   */
  id: number;
}
/**
 * @generated from protobuf message auto_reserve.admin.restaurant.GetResponse
 */
export interface GetResponse {
  /**
   * @generated from protobuf field: int64 id = 1;
   */
  id: number;
  /**
   * @generated from protobuf field: string name = 2;
   */
  name: string;
}
/**
 * @generated from protobuf message auto_reserve.admin.restaurant.GetOrderContractsResponse
 */
export interface GetOrderContractsResponse {
  /**
   * @generated from protobuf field: int64 id = 1;
   */
  id: number;
  /**
   * @generated from protobuf field: repeated auto_reserve.admin.restaurant_order_contract.RestaurantOrderContractResource restaurant_order_contracts = 2;
   */
  restaurantOrderContracts: RestaurantOrderContractResource[];
}
/**
 * @generated from protobuf message auto_reserve.admin.restaurant.GetReservationBookContractsResponse
 */
export interface GetReservationBookContractsResponse {
  /**
   * @generated from protobuf field: int64 id = 1;
   */
  id: number;
  /**
   * @generated from protobuf field: repeated auto_reserve.admin.restaurant_reservation_book_contract.RestaurantReservationBookContractResource restaurant_reservation_book_contracts = 2;
   */
  restaurantReservationBookContracts: RestaurantReservationBookContractResource[];
}
// @generated message type with reflection information, may provide speed optimized methods
class ListFilters$Type extends MessageType<ListFilters> {
  constructor() {
    super("auto_reserve.admin.restaurant.ListFilters", [
      { no: 1, name: "name", kind: "message", T: () => StringValue },
      {
        no: 2,
        name: "statuses",
        kind: "scalar",
        repeat: 1 /*RepeatType.PACKED*/,
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
      {
        no: 3,
        name: "published_statuses",
        kind: "scalar",
        repeat: 1 /*RepeatType.PACKED*/,
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
    ]);
  }
  create(value?: PartialMessage<ListFilters>): ListFilters {
    const message = { statuses: [], publishedStatuses: [] };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<ListFilters>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: ListFilters,
  ): ListFilters {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* google.protobuf.StringValue name */ 1:
          message.name = StringValue.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.name,
          );
          break;
        case /* repeated int64 statuses */ 2:
          if (wireType === WireType.LengthDelimited)
            for (let e = reader.int32() + reader.pos; reader.pos < e; )
              message.statuses.push(reader.int64().toNumber());
          else message.statuses.push(reader.int64().toNumber());
          break;
        case /* repeated int64 published_statuses */ 3:
          if (wireType === WireType.LengthDelimited)
            for (let e = reader.int32() + reader.pos; reader.pos < e; )
              message.publishedStatuses.push(reader.int64().toNumber());
          else message.publishedStatuses.push(reader.int64().toNumber());
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: ListFilters,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* google.protobuf.StringValue name = 1; */
    if (message.name)
      StringValue.internalBinaryWrite(
        message.name,
        writer.tag(1, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* repeated int64 statuses = 2; */
    if (message.statuses.length) {
      writer.tag(2, WireType.LengthDelimited).fork();
      for (let i = 0; i < message.statuses.length; i++)
        writer.int64(message.statuses[i]);
      writer.join();
    }
    /* repeated int64 published_statuses = 3; */
    if (message.publishedStatuses.length) {
      writer.tag(3, WireType.LengthDelimited).fork();
      for (let i = 0; i < message.publishedStatuses.length; i++)
        writer.int64(message.publishedStatuses[i]);
      writer.join();
    }
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.admin.restaurant.ListFilters
 */
export const ListFilters = new ListFilters$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListRequest$Type extends MessageType<ListRequest> {
  constructor() {
    super("auto_reserve.admin.restaurant.ListRequest", [
      {
        no: 1,
        name: "page",
        kind: "scalar",
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
      {
        no: 2,
        name: "per",
        kind: "scalar",
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
      { no: 3, name: "filters", kind: "message", T: () => ListFilters },
    ]);
  }
  create(value?: PartialMessage<ListRequest>): ListRequest {
    const message = { page: 0, per: 0 };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<ListRequest>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: ListRequest,
  ): ListRequest {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* int64 page */ 1:
          message.page = reader.int64().toNumber();
          break;
        case /* int64 per */ 2:
          message.per = reader.int64().toNumber();
          break;
        case /* auto_reserve.admin.restaurant.ListFilters filters */ 3:
          message.filters = ListFilters.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.filters,
          );
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: ListRequest,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* int64 page = 1; */
    if (message.page !== 0) writer.tag(1, WireType.Varint).int64(message.page);
    /* int64 per = 2; */
    if (message.per !== 0) writer.tag(2, WireType.Varint).int64(message.per);
    /* auto_reserve.admin.restaurant.ListFilters filters = 3; */
    if (message.filters)
      ListFilters.internalBinaryWrite(
        message.filters,
        writer.tag(3, WireType.LengthDelimited).fork(),
        options,
      ).join();
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.admin.restaurant.ListRequest
 */
export const ListRequest = new ListRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListRestaurantResource$Type extends MessageType<ListRestaurantResource> {
  constructor() {
    super("auto_reserve.admin.restaurant.ListRestaurantResource", [
      {
        no: 1,
        name: "id",
        kind: "scalar",
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
      { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: "address", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
      {
        no: 4,
        name: "status",
        kind: "enum",
        T: () => [
          "auto_reserve.admin.restaurant.RestaurantResource.Status",
          RestaurantResource_Status,
        ],
      },
      {
        no: 5,
        name: "published_status",
        kind: "enum",
        T: () => [
          "auto_reserve.admin.restaurant.RestaurantResource.PublishedStatus",
          RestaurantResource_PublishedStatus,
        ],
      },
    ]);
  }
  create(
    value?: PartialMessage<ListRestaurantResource>,
  ): ListRestaurantResource {
    const message = {
      id: 0,
      name: "",
      address: "",
      status: 0,
      publishedStatus: 0,
    };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<ListRestaurantResource>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: ListRestaurantResource,
  ): ListRestaurantResource {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* int64 id */ 1:
          message.id = reader.int64().toNumber();
          break;
        case /* string name */ 2:
          message.name = reader.string();
          break;
        case /* string address */ 3:
          message.address = reader.string();
          break;
        case /* auto_reserve.admin.restaurant.RestaurantResource.Status status */ 4:
          message.status = reader.int32();
          break;
        case /* auto_reserve.admin.restaurant.RestaurantResource.PublishedStatus published_status */ 5:
          message.publishedStatus = reader.int32();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: ListRestaurantResource,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* int64 id = 1; */
    if (message.id !== 0) writer.tag(1, WireType.Varint).int64(message.id);
    /* string name = 2; */
    if (message.name !== "")
      writer.tag(2, WireType.LengthDelimited).string(message.name);
    /* string address = 3; */
    if (message.address !== "")
      writer.tag(3, WireType.LengthDelimited).string(message.address);
    /* auto_reserve.admin.restaurant.RestaurantResource.Status status = 4; */
    if (message.status !== 0)
      writer.tag(4, WireType.Varint).int32(message.status);
    /* auto_reserve.admin.restaurant.RestaurantResource.PublishedStatus published_status = 5; */
    if (message.publishedStatus !== 0)
      writer.tag(5, WireType.Varint).int32(message.publishedStatus);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.admin.restaurant.ListRestaurantResource
 */
export const ListRestaurantResource = new ListRestaurantResource$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListResponse$Type extends MessageType<ListResponse> {
  constructor() {
    super("auto_reserve.admin.restaurant.ListResponse", [
      {
        no: 1,
        name: "restaurants",
        kind: "message",
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => ListRestaurantResource,
      },
      {
        no: 2,
        name: "total_count",
        kind: "scalar",
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
    ]);
  }
  create(value?: PartialMessage<ListResponse>): ListResponse {
    const message = { restaurants: [], totalCount: 0 };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<ListResponse>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: ListResponse,
  ): ListResponse {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* repeated auto_reserve.admin.restaurant.ListRestaurantResource restaurants */ 1:
          message.restaurants.push(
            ListRestaurantResource.internalBinaryRead(
              reader,
              reader.uint32(),
              options,
            ),
          );
          break;
        case /* int64 total_count */ 2:
          message.totalCount = reader.int64().toNumber();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: ListResponse,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* repeated auto_reserve.admin.restaurant.ListRestaurantResource restaurants = 1; */
    for (let i = 0; i < message.restaurants.length; i++)
      ListRestaurantResource.internalBinaryWrite(
        message.restaurants[i],
        writer.tag(1, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* int64 total_count = 2; */
    if (message.totalCount !== 0)
      writer.tag(2, WireType.Varint).int64(message.totalCount);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.admin.restaurant.ListResponse
 */
export const ListResponse = new ListResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetRequest$Type extends MessageType<GetRequest> {
  constructor() {
    super("auto_reserve.admin.restaurant.GetRequest", [
      {
        no: 1,
        name: "id",
        kind: "scalar",
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
    ]);
  }
  create(value?: PartialMessage<GetRequest>): GetRequest {
    const message = { id: 0 };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<GetRequest>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: GetRequest,
  ): GetRequest {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* int64 id */ 1:
          message.id = reader.int64().toNumber();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: GetRequest,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* int64 id = 1; */
    if (message.id !== 0) writer.tag(1, WireType.Varint).int64(message.id);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.admin.restaurant.GetRequest
 */
export const GetRequest = new GetRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetResponse$Type extends MessageType<GetResponse> {
  constructor() {
    super("auto_reserve.admin.restaurant.GetResponse", [
      {
        no: 1,
        name: "id",
        kind: "scalar",
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
      { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
    ]);
  }
  create(value?: PartialMessage<GetResponse>): GetResponse {
    const message = { id: 0, name: "" };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<GetResponse>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: GetResponse,
  ): GetResponse {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* int64 id */ 1:
          message.id = reader.int64().toNumber();
          break;
        case /* string name */ 2:
          message.name = reader.string();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: GetResponse,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* int64 id = 1; */
    if (message.id !== 0) writer.tag(1, WireType.Varint).int64(message.id);
    /* string name = 2; */
    if (message.name !== "")
      writer.tag(2, WireType.LengthDelimited).string(message.name);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.admin.restaurant.GetResponse
 */
export const GetResponse = new GetResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetOrderContractsResponse$Type extends MessageType<GetOrderContractsResponse> {
  constructor() {
    super("auto_reserve.admin.restaurant.GetOrderContractsResponse", [
      {
        no: 1,
        name: "id",
        kind: "scalar",
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
      {
        no: 2,
        name: "restaurant_order_contracts",
        kind: "message",
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => RestaurantOrderContractResource,
      },
    ]);
  }
  create(
    value?: PartialMessage<GetOrderContractsResponse>,
  ): GetOrderContractsResponse {
    const message = { id: 0, restaurantOrderContracts: [] };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<GetOrderContractsResponse>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: GetOrderContractsResponse,
  ): GetOrderContractsResponse {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* int64 id */ 1:
          message.id = reader.int64().toNumber();
          break;
        case /* repeated auto_reserve.admin.restaurant_order_contract.RestaurantOrderContractResource restaurant_order_contracts */ 2:
          message.restaurantOrderContracts.push(
            RestaurantOrderContractResource.internalBinaryRead(
              reader,
              reader.uint32(),
              options,
            ),
          );
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: GetOrderContractsResponse,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* int64 id = 1; */
    if (message.id !== 0) writer.tag(1, WireType.Varint).int64(message.id);
    /* repeated auto_reserve.admin.restaurant_order_contract.RestaurantOrderContractResource restaurant_order_contracts = 2; */
    for (let i = 0; i < message.restaurantOrderContracts.length; i++)
      RestaurantOrderContractResource.internalBinaryWrite(
        message.restaurantOrderContracts[i],
        writer.tag(2, WireType.LengthDelimited).fork(),
        options,
      ).join();
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.admin.restaurant.GetOrderContractsResponse
 */
export const GetOrderContractsResponse = new GetOrderContractsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetReservationBookContractsResponse$Type extends MessageType<GetReservationBookContractsResponse> {
  constructor() {
    super("auto_reserve.admin.restaurant.GetReservationBookContractsResponse", [
      {
        no: 1,
        name: "id",
        kind: "scalar",
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
      {
        no: 2,
        name: "restaurant_reservation_book_contracts",
        kind: "message",
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => RestaurantReservationBookContractResource,
      },
    ]);
  }
  create(
    value?: PartialMessage<GetReservationBookContractsResponse>,
  ): GetReservationBookContractsResponse {
    const message = { id: 0, restaurantReservationBookContracts: [] };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<GetReservationBookContractsResponse>(
        this,
        message,
        value,
      );
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: GetReservationBookContractsResponse,
  ): GetReservationBookContractsResponse {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* int64 id */ 1:
          message.id = reader.int64().toNumber();
          break;
        case /* repeated auto_reserve.admin.restaurant_reservation_book_contract.RestaurantReservationBookContractResource restaurant_reservation_book_contracts */ 2:
          message.restaurantReservationBookContracts.push(
            RestaurantReservationBookContractResource.internalBinaryRead(
              reader,
              reader.uint32(),
              options,
            ),
          );
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: GetReservationBookContractsResponse,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* int64 id = 1; */
    if (message.id !== 0) writer.tag(1, WireType.Varint).int64(message.id);
    /* repeated auto_reserve.admin.restaurant_reservation_book_contract.RestaurantReservationBookContractResource restaurant_reservation_book_contracts = 2; */
    for (let i = 0; i < message.restaurantReservationBookContracts.length; i++)
      RestaurantReservationBookContractResource.internalBinaryWrite(
        message.restaurantReservationBookContracts[i],
        writer.tag(2, WireType.LengthDelimited).fork(),
        options,
      ).join();
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.admin.restaurant.GetReservationBookContractsResponse
 */
export const GetReservationBookContractsResponse =
  new GetReservationBookContractsResponse$Type();
/**
 * @generated ServiceType for protobuf service auto_reserve.admin.restaurant.Restaurant
 */
export const Restaurant = new ServiceType(
  "auto_reserve.admin.restaurant.Restaurant",
  [
    { name: "List", options: {}, I: ListRequest, O: ListResponse },
    { name: "Get", options: {}, I: GetRequest, O: GetResponse },
    {
      name: "GetOrderContracts",
      options: {},
      I: GetRequest,
      O: GetOrderContractsResponse,
    },
    {
      name: "GetReservationBookContracts",
      options: {},
      I: GetRequest,
      O: GetReservationBookContractsResponse,
    },
  ],
);
