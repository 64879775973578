// @generated by protobuf-ts 2.8.1 with parameter long_type_number,generate_dependencies
// @generated from protobuf file "auto_reserve/admin/review/review_resource.proto" (package "auto_reserve.admin.review", syntax proto3)
// tslint:disable
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Int64Value } from "../../../google/protobuf/wrappers";
import { FloatValue } from "../../../google/protobuf/wrappers";
import { RestaurantImageResource } from "../restaurant_image/restaurant_image_resource";
import { Timestamp } from "../../../google/protobuf/timestamp";
/**
 * @generated from protobuf message auto_reserve.admin.review.UserResource
 */
export interface UserResource {
  /**
   * @generated from protobuf field: int64 id = 1;
   */
  id: number;
  /**
   * @generated from protobuf field: string name = 2;
   */
  name: string;
  /**
   * @generated from protobuf field: string default_image_url = 3;
   */
  defaultImageUrl: string;
}
/**
 * @generated from protobuf message auto_reserve.admin.review.RestaurantResource
 */
export interface RestaurantResource {
  /**
   * @generated from protobuf field: int64 id = 1;
   */
  id: number;
  /**
   * @generated from protobuf field: string name = 2;
   */
  name: string;
}
/**
 * @generated from protobuf message auto_reserve.admin.review.ReviewResource
 */
export interface ReviewResource {
  /**
   * @generated from protobuf field: int64 id = 1;
   */
  id: number;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp created_at = 2;
   */
  createdAt?: Timestamp;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp updated_at = 3;
   */
  updatedAt?: Timestamp;
  /**
   * @generated from protobuf field: string content = 4;
   */
  content: string;
  /**
   * @generated from protobuf field: auto_reserve.admin.review.UserResource user = 5;
   */
  user?: UserResource;
  /**
   * @generated from protobuf field: auto_reserve.admin.review.RestaurantResource restaurant = 6;
   */
  restaurant?: RestaurantResource;
  /**
   * @generated from protobuf field: repeated auto_reserve.admin.restaurant_image.RestaurantImageResource restaurant_images = 7;
   */
  restaurantImages: RestaurantImageResource[];
  /**
   * @generated from protobuf field: google.protobuf.FloatValue rating = 8;
   */
  rating?: FloatValue;
  /**
   * @generated from protobuf field: google.protobuf.FloatValue rating_atmosphere = 9;
   */
  ratingAtmosphere?: FloatValue;
  /**
   * @generated from protobuf field: google.protobuf.FloatValue rating_cost_performance = 10;
   */
  ratingCostPerformance?: FloatValue;
  /**
   * @generated from protobuf field: google.protobuf.FloatValue rating_food = 11;
   */
  ratingFood?: FloatValue;
  /**
   * @generated from protobuf field: google.protobuf.FloatValue rating_service = 12;
   */
  ratingService?: FloatValue;
  /**
   * @generated from protobuf field: int64 comments_count = 13;
   */
  commentsCount: number;
  /**
   * @generated from protobuf field: int64 likes_count = 14;
   */
  likesCount: number;
  /**
   * @generated from protobuf field: google.protobuf.Int64Value price = 15;
   */
  price?: Int64Value;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp visited_at = 16;
   */
  visitedAt?: Timestamp;
  /**
   * @generated from protobuf field: auto_reserve.admin.review.ReviewResource.Status status = 17;
   */
  status: ReviewResource_Status;
}
/**
 * @generated from protobuf enum auto_reserve.admin.review.ReviewResource.Status
 */
export enum ReviewResource_Status {
  /**
   * @generated from protobuf enum value: JUDGING = 0;
   */
  JUDGING = 0,
  /**
   * @generated from protobuf enum value: PUBLISHED = 1;
   */
  PUBLISHED = 1,
  /**
   * @generated from protobuf enum value: UNPUBLISHED = 2;
   */
  UNPUBLISHED = 2,
  /**
   * @generated from protobuf enum value: ANONYMOUS = 3;
   */
  ANONYMOUS = 3,
}
// @generated message type with reflection information, may provide speed optimized methods
class UserResource$Type extends MessageType<UserResource> {
  constructor() {
    super("auto_reserve.admin.review.UserResource", [
      {
        no: 1,
        name: "id",
        kind: "scalar",
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
      { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
      {
        no: 3,
        name: "default_image_url",
        kind: "scalar",
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
  create(value?: PartialMessage<UserResource>): UserResource {
    const message = { id: 0, name: "", defaultImageUrl: "" };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<UserResource>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: UserResource,
  ): UserResource {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* int64 id */ 1:
          message.id = reader.int64().toNumber();
          break;
        case /* string name */ 2:
          message.name = reader.string();
          break;
        case /* string default_image_url */ 3:
          message.defaultImageUrl = reader.string();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: UserResource,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* int64 id = 1; */
    if (message.id !== 0) writer.tag(1, WireType.Varint).int64(message.id);
    /* string name = 2; */
    if (message.name !== "")
      writer.tag(2, WireType.LengthDelimited).string(message.name);
    /* string default_image_url = 3; */
    if (message.defaultImageUrl !== "")
      writer.tag(3, WireType.LengthDelimited).string(message.defaultImageUrl);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.admin.review.UserResource
 */
export const UserResource = new UserResource$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RestaurantResource$Type extends MessageType<RestaurantResource> {
  constructor() {
    super("auto_reserve.admin.review.RestaurantResource", [
      {
        no: 1,
        name: "id",
        kind: "scalar",
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
      { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
    ]);
  }
  create(value?: PartialMessage<RestaurantResource>): RestaurantResource {
    const message = { id: 0, name: "" };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<RestaurantResource>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: RestaurantResource,
  ): RestaurantResource {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* int64 id */ 1:
          message.id = reader.int64().toNumber();
          break;
        case /* string name */ 2:
          message.name = reader.string();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: RestaurantResource,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* int64 id = 1; */
    if (message.id !== 0) writer.tag(1, WireType.Varint).int64(message.id);
    /* string name = 2; */
    if (message.name !== "")
      writer.tag(2, WireType.LengthDelimited).string(message.name);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.admin.review.RestaurantResource
 */
export const RestaurantResource = new RestaurantResource$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ReviewResource$Type extends MessageType<ReviewResource> {
  constructor() {
    super("auto_reserve.admin.review.ReviewResource", [
      {
        no: 1,
        name: "id",
        kind: "scalar",
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
      { no: 2, name: "created_at", kind: "message", T: () => Timestamp },
      { no: 3, name: "updated_at", kind: "message", T: () => Timestamp },
      { no: 4, name: "content", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
      { no: 5, name: "user", kind: "message", T: () => UserResource },
      {
        no: 6,
        name: "restaurant",
        kind: "message",
        T: () => RestaurantResource,
      },
      {
        no: 7,
        name: "restaurant_images",
        kind: "message",
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => RestaurantImageResource,
      },
      { no: 8, name: "rating", kind: "message", T: () => FloatValue },
      {
        no: 9,
        name: "rating_atmosphere",
        kind: "message",
        T: () => FloatValue,
      },
      {
        no: 10,
        name: "rating_cost_performance",
        kind: "message",
        T: () => FloatValue,
      },
      { no: 11, name: "rating_food", kind: "message", T: () => FloatValue },
      { no: 12, name: "rating_service", kind: "message", T: () => FloatValue },
      {
        no: 13,
        name: "comments_count",
        kind: "scalar",
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
      {
        no: 14,
        name: "likes_count",
        kind: "scalar",
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
      { no: 15, name: "price", kind: "message", T: () => Int64Value },
      { no: 16, name: "visited_at", kind: "message", T: () => Timestamp },
      {
        no: 17,
        name: "status",
        kind: "enum",
        T: () => [
          "auto_reserve.admin.review.ReviewResource.Status",
          ReviewResource_Status,
        ],
      },
    ]);
  }
  create(value?: PartialMessage<ReviewResource>): ReviewResource {
    const message = {
      id: 0,
      content: "",
      restaurantImages: [],
      commentsCount: 0,
      likesCount: 0,
      status: 0,
    };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<ReviewResource>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: ReviewResource,
  ): ReviewResource {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* int64 id */ 1:
          message.id = reader.int64().toNumber();
          break;
        case /* google.protobuf.Timestamp created_at */ 2:
          message.createdAt = Timestamp.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.createdAt,
          );
          break;
        case /* google.protobuf.Timestamp updated_at */ 3:
          message.updatedAt = Timestamp.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.updatedAt,
          );
          break;
        case /* string content */ 4:
          message.content = reader.string();
          break;
        case /* auto_reserve.admin.review.UserResource user */ 5:
          message.user = UserResource.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.user,
          );
          break;
        case /* auto_reserve.admin.review.RestaurantResource restaurant */ 6:
          message.restaurant = RestaurantResource.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.restaurant,
          );
          break;
        case /* repeated auto_reserve.admin.restaurant_image.RestaurantImageResource restaurant_images */ 7:
          message.restaurantImages.push(
            RestaurantImageResource.internalBinaryRead(
              reader,
              reader.uint32(),
              options,
            ),
          );
          break;
        case /* google.protobuf.FloatValue rating */ 8:
          message.rating = FloatValue.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.rating,
          );
          break;
        case /* google.protobuf.FloatValue rating_atmosphere */ 9:
          message.ratingAtmosphere = FloatValue.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.ratingAtmosphere,
          );
          break;
        case /* google.protobuf.FloatValue rating_cost_performance */ 10:
          message.ratingCostPerformance = FloatValue.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.ratingCostPerformance,
          );
          break;
        case /* google.protobuf.FloatValue rating_food */ 11:
          message.ratingFood = FloatValue.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.ratingFood,
          );
          break;
        case /* google.protobuf.FloatValue rating_service */ 12:
          message.ratingService = FloatValue.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.ratingService,
          );
          break;
        case /* int64 comments_count */ 13:
          message.commentsCount = reader.int64().toNumber();
          break;
        case /* int64 likes_count */ 14:
          message.likesCount = reader.int64().toNumber();
          break;
        case /* google.protobuf.Int64Value price */ 15:
          message.price = Int64Value.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.price,
          );
          break;
        case /* google.protobuf.Timestamp visited_at */ 16:
          message.visitedAt = Timestamp.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.visitedAt,
          );
          break;
        case /* auto_reserve.admin.review.ReviewResource.Status status */ 17:
          message.status = reader.int32();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: ReviewResource,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* int64 id = 1; */
    if (message.id !== 0) writer.tag(1, WireType.Varint).int64(message.id);
    /* google.protobuf.Timestamp created_at = 2; */
    if (message.createdAt)
      Timestamp.internalBinaryWrite(
        message.createdAt,
        writer.tag(2, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* google.protobuf.Timestamp updated_at = 3; */
    if (message.updatedAt)
      Timestamp.internalBinaryWrite(
        message.updatedAt,
        writer.tag(3, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* string content = 4; */
    if (message.content !== "")
      writer.tag(4, WireType.LengthDelimited).string(message.content);
    /* auto_reserve.admin.review.UserResource user = 5; */
    if (message.user)
      UserResource.internalBinaryWrite(
        message.user,
        writer.tag(5, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* auto_reserve.admin.review.RestaurantResource restaurant = 6; */
    if (message.restaurant)
      RestaurantResource.internalBinaryWrite(
        message.restaurant,
        writer.tag(6, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* repeated auto_reserve.admin.restaurant_image.RestaurantImageResource restaurant_images = 7; */
    for (let i = 0; i < message.restaurantImages.length; i++)
      RestaurantImageResource.internalBinaryWrite(
        message.restaurantImages[i],
        writer.tag(7, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* google.protobuf.FloatValue rating = 8; */
    if (message.rating)
      FloatValue.internalBinaryWrite(
        message.rating,
        writer.tag(8, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* google.protobuf.FloatValue rating_atmosphere = 9; */
    if (message.ratingAtmosphere)
      FloatValue.internalBinaryWrite(
        message.ratingAtmosphere,
        writer.tag(9, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* google.protobuf.FloatValue rating_cost_performance = 10; */
    if (message.ratingCostPerformance)
      FloatValue.internalBinaryWrite(
        message.ratingCostPerformance,
        writer.tag(10, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* google.protobuf.FloatValue rating_food = 11; */
    if (message.ratingFood)
      FloatValue.internalBinaryWrite(
        message.ratingFood,
        writer.tag(11, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* google.protobuf.FloatValue rating_service = 12; */
    if (message.ratingService)
      FloatValue.internalBinaryWrite(
        message.ratingService,
        writer.tag(12, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* int64 comments_count = 13; */
    if (message.commentsCount !== 0)
      writer.tag(13, WireType.Varint).int64(message.commentsCount);
    /* int64 likes_count = 14; */
    if (message.likesCount !== 0)
      writer.tag(14, WireType.Varint).int64(message.likesCount);
    /* google.protobuf.Int64Value price = 15; */
    if (message.price)
      Int64Value.internalBinaryWrite(
        message.price,
        writer.tag(15, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* google.protobuf.Timestamp visited_at = 16; */
    if (message.visitedAt)
      Timestamp.internalBinaryWrite(
        message.visitedAt,
        writer.tag(16, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* auto_reserve.admin.review.ReviewResource.Status status = 17; */
    if (message.status !== 0)
      writer.tag(17, WireType.Varint).int32(message.status);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.admin.review.ReviewResource
 */
export const ReviewResource = new ReviewResource$Type();
