import { Breadcrumbs } from '@mui/material'
import { itemRender } from 'modules/itemRender'
import React from 'react'

export interface Route {
  path: string
  breadcrumbName: string
}

interface BreadcrumbProps {
  routes: Route[]
}

export default function Breadcrumb({ routes }: BreadcrumbProps) {
  return (
    <Breadcrumbs>
      {routes.map((route) => (
        <div key={route.path}>{itemRender(route, null, routes, [])}</div>
      ))}
    </Breadcrumbs>
  )
}
