import { Text } from '@hello-ai/ar_shared/src/components/Text'
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import { BaseTableRows, BasicTableCell } from 'components/Sales/BaseTableRows'
import { ChargeTableRows } from 'components/Sales/ChargeTableRows'
import { ConnectCollectionTransferTableRows } from 'components/Sales/ConnectCollectionCountTableRows'
import { DisputeTableRows } from 'components/Sales/DisputeTableRows'
import { PartialCaptureReversalTableRows } from 'components/Sales/PartialCaptureReversalTableRows'
import { PlatformEarningTableRows } from 'components/Sales/PlatformEarningTableRows'
import { RefundTableRows } from 'components/Sales/RefundTableRows'
import { TransferTableRows } from 'components/Sales/TransferTableRows'
import { displayToastError, displayToastInfo } from 'components/Shared/Toast'
import { useReportBalanceActivities, useReportBalanceCsvs } from 'models/Report'
import { isAxiosError } from 'modules/axios'
import React, { useState } from 'react'

export function SalesTable({ year, month }: { year: number; month: number }) {
  const [isOpenDialog, setIsOpenDialog] = useState(false)
  const [reportingCategory, setReportingCategory] = useState<string | null>(
    null
  )
  const {
    data: balanceActivities,
    isLoading: isLoadingBalanceActivities,
    error: balanceActivitiesError,
  } = useReportBalanceActivities({
    year,
    month,
  })

  const { trigger: triggerBalanceCsvs } = useReportBalanceCsvs(
    reportingCategory ?? ''
  )

  const onOpenDialog = (reportingCategory: string) => {
    setIsOpenDialog(true)
    setReportingCategory(reportingCategory)
  }

  const onCloseDialog = () => {
    setIsOpenDialog(false)
    setReportingCategory(null)
  }

  const onDownload = async () => {
    const data = await triggerBalanceCsvs(
      {
        year,
        month,
      },
      {
        onError(error) {
          if (isAxiosError(error) && error.response?.status === 404) {
            displayToastInfo('データがありません')
          } else {
            displayToastError('ダウンロードに失敗しました')
          }
        },
      }
    )
    downloadBlob(data, `${year}-${month}-${reportingCategory}.csv`)
  }

  function downloadBlob(blob: Blob, filename: string): void {
    const url = window.URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.download = filename
    link.style.display = 'none'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    window.URL.revokeObjectURL(url)
  }

  if (balanceActivitiesError) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '70vh',
        }}
      >
        {isAxiosError(balanceActivitiesError) &&
        balanceActivitiesError.response?.status === 404 ? (
          <Alert severity="info">データがありません</Alert>
        ) : (
          <Alert severity="error">エラーが発生しました</Alert>
        )}
      </Box>
    )
  }

  if (isLoadingBalanceActivities) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '70vh',
        }}
      >
        <CircularProgress />
      </Box>
    )
  }

  if (!balanceActivities) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '70vh',
        }}
      >
        <Alert severity="info">データがありません</Alert>
      </Box>
    )
  }

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Reporting Category</TableCell>
              <TableCell>Category</TableCell>
              <TableCell>Sub Category</TableCell>
              <TableCell />
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            <ChargeTableRows
              data={balanceActivities.report?.charge}
              onClickReportingCategory={() => onOpenDialog('charge')}
            />
            <RefundTableRows
              name="返金"
              data={balanceActivities.report?.refund}
              onClickReportingCategory={() => onOpenDialog('refund')}
            />
            <RefundTableRows
              name="返金の失敗"
              data={balanceActivities.report?.refund_failure}
              onClickReportingCategory={() => onOpenDialog('refund_failure')}
            />
            <PartialCaptureReversalTableRows
              data={balanceActivities.report?.partial_capture_reversal}
              onClickReportingCategory={() =>
                onOpenDialog('partial_capture_reversal')
              }
            />
            <DisputeTableRows
              name="不審請求"
              data={balanceActivities.report?.dispute}
              onClickReportingCategory={() => onOpenDialog('dispute')}
            />
            <DisputeTableRows
              name="不審請求の差戻し"
              data={balanceActivities.report?.dispute_reversal}
              onClickReportingCategory={() => onOpenDialog('dispute_reversal')}
            />
            <TransferTableRows
              name="送金"
              data={balanceActivities.report?.transfer}
              onClickReportingCategory={() => onOpenDialog('transfer')}
            />
            <TransferTableRows
              name="送金の差戻し"
              data={balanceActivities.report?.transfer_reversal}
              onClickReportingCategory={() => onOpenDialog('transfer_reversal')}
            />
            <PlatformEarningTableRows
              name="プラットフォームの手数料収入"
              data={balanceActivities.report?.platform_earning}
              onClickReportingCategory={() => onOpenDialog('platform_earning')}
            />
            <PlatformEarningTableRows
              name="プラットフォームの手数料収入の返金"
              data={balanceActivities.report?.platform_earning_refund}
              onClickReportingCategory={() =>
                onOpenDialog('platform_earning_refund')
              }
            />
            <ConnectCollectionTransferTableRows
              data={balanceActivities.report?.connect_collection_transfer}
              onClickReportingCategory={() =>
                onOpenDialog('connect_collection_transfer')
              }
            />
            <BaseTableRows
              reportingCategoryCell={
                <BasicTableCell rowSpan={3} colSpan={3}>
                  <Button
                    variant="text"
                    onClick={() => onOpenDialog('stripe_fee')}
                  >
                    Stripe 追加料金
                  </Button>
                </BasicTableCell>
              }
              items={[
                {
                  label: '件数',
                  value:
                    balanceActivities.report?.stripe_fee?.transaction_count,
                },
                {
                  label: '総額',
                  value: balanceActivities.report?.stripe_fee?.total_amount,
                  type: 'currency',
                },
                {
                  label: '税',
                  value: balanceActivities.report?.stripe_fee?.tax_amount,
                  type: 'currency',
                },
              ]}
            />
            <BaseTableRows
              reportingCategoryCell={
                <BasicTableCell rowSpan={2} colSpan={3}>
                  <Button variant="text" onClick={() => onOpenDialog('topup')}>
                    トップアップ
                  </Button>
                </BasicTableCell>
              }
              items={[
                {
                  label: '件数',
                  value: balanceActivities.report?.topup?.transaction_count,
                },
                {
                  label: '総額',
                  value: balanceActivities.report?.topup?.total_amount,
                  type: 'currency',
                },
              ]}
            />
            <BaseTableRows
              reportingCategoryCell={
                <BasicTableCell colSpan={3}>
                  <Box sx={{ p: 1 }}>
                    <Text>アクティビティによる残高変更</Text>
                  </Box>
                </BasicTableCell>
              }
              items={[
                {
                  label: '',
                  value: balanceActivities.balance_change_from_activity,
                  type: 'currency',
                },
              ]}
            />
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog open={isOpenDialog} onClose={onCloseDialog}>
        <DialogTitle>CSVをダウンロードしますか？</DialogTitle>
        <DialogActions>
          <Button color="secondary" onClick={onCloseDialog}>
            いいえ
          </Button>
          <Button
            onClick={() => {
              onDownload()
              onCloseDialog()
            }}
            autoFocus
          >
            はい
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
