import { OwnerResource } from '@hello-ai/proto/src/gen/auto_reserve/admin/owner/owner_resource'
import { Box, Button } from '@mui/material'
import DataGrid, { usePagination } from 'components/Shared/DataGrid'
import PageHeader from 'components/Shared/PageHeader'
import { ownerService } from 'models/Owner'
import React from 'react'
import { useNavigate } from 'react-router'

function SubmitDetailPageButton({ owner }: { owner: OwnerResource }) {
  const navigate = useNavigate()

  return (
    <Button variant="outlined" onClick={() => navigate(`/owners/${owner.id}`)}>
      詳細
    </Button>
  )
}

export default function Owners() {
  const pagination = usePagination()
  const { data } = ownerService.useList({
    page: pagination.page,
    per: pagination.perPage,
  })

  return (
    <Box sx={{ flex: 1 }}>
      <PageHeader title="オーナー一覧">
        <DataGrid
          sx={{ width: '100%', height: 400 }}
          data={data?.owners}
          rowKey={(row) => `owner-${row.id}`}
          columns={[
            {
              key: 'action',
              render: (row) => <SubmitDetailPageButton owner={row} />,
            },
            {
              key: 'username',
              title: 'オーナーID',
              render: (row) => row.username,
            },
            { key: 'name', title: 'オーナー名', render: (row) => row.name },
          ]}
          totalCount={data?.totalCount}
          loading={data === undefined}
          pagination={pagination}
        />
      </PageHeader>
    </Box>
  )
}
