import React, { useState } from 'react'
import { Box, Button, Dialog, DialogContent, DialogTitle } from '@mui/material'
import PageHeader from 'components/Shared/PageHeader'

export default function Growth() {
  const [isReviewCsvModalVisible, setIsReviewCsvModalVisible] = useState(false)

  return (
    <Box>
      <PageHeader
        title="グロース"
        extra={[
          <Button
            type="button"
            variant="outlined"
            key="csv_review"
            onClick={() => setIsReviewCsvModalVisible(true)}
          >
            CSVレビューインポート
          </Button>,
          <Button type="button" variant="outlined" key="csv_description">
            CSV概要インポート
          </Button>,
        ]}
      >
        <Dialog
          open={isReviewCsvModalVisible}
          onClose={() => setIsReviewCsvModalVisible(false)}
        >
          <DialogTitle>CSVレビューインポート</DialogTitle>
          <DialogContent>
            <Button component="label" variant="outlined">
              ファイルを選択
              <input
                hidden
                accept="text/csv"
                multiple
                type="file"
                onChange={(e) => {
                  const file = e.target.files?.[0]
                  if (file != null) {
                    const reader = new FileReader()
                    reader.onload = (e) => {
                      console.log(e.target?.result)
                    }
                    reader.readAsBinaryString(file)
                    return false
                  }
                }}
              />
            </Button>
          </DialogContent>
        </Dialog>
      </PageHeader>
    </Box>
  )
}
