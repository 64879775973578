import React, { useState } from 'react'
import { View } from 'react-native'
import { FilterState, useMedia } from 'models/Medium'
import { MediumList } from 'components/Medium/List'
import PageHeader from 'components/Shared/PageHeader'
import { MediumResource_Status } from '@hello-ai/proto/src/gen/auto_reserve/admin/medium/medium_resource'

export default function Affiliate() {
  const [filters, setFilters] = useState<FilterState>({
    statuses: MediumResource_Status.UNDER_REVIEW,
  })

  const { media, mutate } = useMedia({
    status:
      filters.statuses !== undefined ? { value: filters.statuses } : undefined,
  })

  return (
    <View>
      {media && media.length > 0 && (
        <PageHeader title="承認待ちメディア">
          <MediumList
            media={media}
            onMediumUpdated={mutate}
            filters={filters}
            setFilters={setFilters}
            disableFilters={{ statuses: true }}
          />
        </PageHeader>
      )}
    </View>
  )
}
