import React, { useLayoutEffect, useRef, useState } from 'react'
import { onError, useToken } from 'models/Auth'

import { ResponsiveProvider } from '@hello-ai/ar_shared/src/modules/useResponsive'
import {
  Config as ArSharedConfig,
  ConfigProvider as ArSharedConfigProvider,
} from '@hello-ai/ar_shared/src/components/Config'

import { initAuthConfig } from '@hello-ai/ar_shared/src/modules/auth'
import { Navigate, Routes, Route, Router } from 'react-router-dom'
import { history } from 'modules/history'
import { Modal } from 'components/Shared/Modal'
import { ToastProvider, setToastRef, Toast } from 'components/Shared/Toast'
import { Pages } from 'pages'
import './App.css'
import { ThemeProvider } from '@mui/material'
import theme from './theme'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

const config: ArSharedConfig = {
  Text: {
    fontSize: 14,
    fontFamily: '"Hiragino Sans", sans-serif',
  },
  TextInput: {
    fontSize: 14,
    height: 64,
  },
  ModalComponent: Modal,
}

initAuthConfig({
  useToken,
  onError,
})

interface PrivateRouteProps {
  location: Location
  children: React.ReactElement | null
}

function PrivateRoute({ location, children }: PrivateRouteProps) {
  const token = useToken()

  return token != null ? (
    children
  ) : (
    <Navigate
      to={{
        pathname: '/sign_in',
      }}
      state={{ from: location }}
    />
  )
}

function Container() {
  const historyRef = useRef<typeof history>()

  if (historyRef.current == null) {
    historyRef.current = history
  }

  const currentHistory = historyRef.current
  const [state, setState] = useState({
    action: currentHistory.action,
    location: currentHistory.location,
  })
  useLayoutEffect(() => history.listen(setState), [currentHistory])

  return (
    <Router
      location={state.location}
      navigationType={state.action}
      navigator={currentHistory}
    >
      <Routes>
        <Route path="/sign_in" element={<Pages.SignIn />} />
        <Route
          path="/*"
          element={
            <PrivateRoute location={location}>
              <Pages.Dashboard />
            </PrivateRoute>
          }
        />
        <Route element={<Pages.NotFound />} />
      </Routes>
    </Router>
  )
}

export default function App() {
  return (
    <ArSharedConfigProvider value={config}>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <ResponsiveProvider>
            <ToastProvider>
              <Container />
              <Toast
                ref={(ref) => {
                  setToastRef(ref)
                }}
              />
            </ToastProvider>
          </ResponsiveProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </ArSharedConfigProvider>
  )
}
