import React, { useContext, useMemo } from 'react'
import { Breakpoints } from '../constants/Breakpoints'
import { useMediaQuery } from './useMediaQuery'
import invariant from 'tiny-invariant'

import { ImageStyle, TextStyle, ViewStyle, StyleSheet } from 'react-native'

function lessThan(bp: number) {
  return `(max-width: ${bp - 0.02}px)`
}

function isEqual(bp: number) {
  return `(min-width: ${bp - 0.02}px) and (max-width: ${bp}px)`
}

interface ResponsiveResult {
  width: number
  xs: 1
  sm: 3
  md: 5
  lg: 7
}

export const ResponsiveContext = React.createContext<
  ResponsiveResult | undefined
>(undefined)

export const ResponsiveProvider = React.memo(function ResponsiveProvider({
  children,
}: {
  children?: React.ReactNode
}) {
  const ltXs = useMediaQuery(lessThan(Breakpoints.xs)) // 0
  const isXs = useMediaQuery(isEqual(Breakpoints.xs)) // 1

  const ltSm = useMediaQuery(lessThan(Breakpoints.sm)) // 2
  const isSm = useMediaQuery(isEqual(Breakpoints.sm)) // 3

  const ltMd = useMediaQuery(lessThan(Breakpoints.md)) // 4
  const isMd = useMediaQuery(isEqual(Breakpoints.md)) // 5

  const ltLg = useMediaQuery(lessThan(Breakpoints.lg)) // 6
  const isLg = useMediaQuery(isEqual(Breakpoints.lg)) // 7

  let width: number

  if (ltXs) {
    width = 0
  } else if (isXs) {
    width = 1
  } else if (ltSm) {
    width = 2
  } else if (isSm) {
    width = 3
  } else if (ltMd) {
    width = 4
  } else if (isMd) {
    width = 5
  } else if (ltLg) {
    width = 6
  } else if (isLg) {
    width = 7
  } else {
    width = 8
  }

  const value: ResponsiveResult = useMemo(
    () => ({
      width,
      xs: 1,
      sm: 3,
      md: 5,
      lg: 7,
    }),
    [width]
  )

  return (
    <ResponsiveContext.Provider value={value}>
      {children}
    </ResponsiveContext.Provider>
  )
})

export function useResponsive() {
  const value = useContext(ResponsiveContext)
  invariant(
    value !== undefined,
    'useResponsive() must be called inside <ResponsiveProvider  />'
  )
  return value
}

type NamedStyles<T> = { [P in keyof T]: ViewStyle | TextStyle | ImageStyle }

export function createResponsiveSheet<
  T extends NamedStyles<T> | NamedStyles<any>
>(styles: (responsive: ResponsiveResult) => T) {
  return styles
}

export function useResponsiveStyle<T extends NamedStyles<T> | NamedStyles<any>>(
  styles: (responsive: ResponsiveResult) => T
) {
  const responsive = useResponsive()
  return StyleSheet.create(styles(responsive))
}
