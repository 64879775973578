import { ReviewResource_Status } from '@hello-ai/proto/src/gen/auto_reserve/admin/review/review_resource'
import { ReviewClient } from '@hello-ai/proto/src/gen/auto_reserve/admin/review/review_service.client'
import { assertNever } from 'modules/assert'
import { createRpcService } from 'modules/rpc'

export const reviewService = createRpcService(ReviewClient)
export const reviewClient = reviewService.client

export function getStatusName(value: ReviewResource_Status) {
  switch (value) {
    case ReviewResource_Status.JUDGING:
      return '審査中'
    case ReviewResource_Status.PUBLISHED:
      return '公開'
    case ReviewResource_Status.UNPUBLISHED:
      return '非公開'
    case ReviewResource_Status.ANONYMOUS:
      return '匿名'
    default: {
      assertNever(value)
    }
  }
}
