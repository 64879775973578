import React from 'react'
import { View } from 'react-native'
import { Text } from '@hello-ai/ar_shared/src/components/Text'

export default function Dashboard() {
  return (
    <View>
      <Text>dashboard</Text>
    </View>
  )
}
