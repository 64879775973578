import React from 'react'
import { Text, View } from 'react-native'
import { getStatusName, reviewService } from 'models/Review'
import PageHeader from 'components/Shared/PageHeader'
import DataGrid, { usePagination } from 'components/Shared/DataGrid'
import { Button, Grid } from '@mui/material'
import { useNavigate, Link } from 'react-router-dom'
import { Image } from '@hello-ai/ar_shared/src/components/Image'
import dayjs from 'dayjs'

export default function Reviews() {
  const navigate = useNavigate()
  const pagination = usePagination()
  const { data } = reviewService.useList({
    page: pagination.page,
    per: pagination.perPage,
  })

  return (
    <PageHeader title="口コミ一覧">
      <DataGrid
        data={data?.reviews}
        totalCount={data?.totalCount ?? 0}
        rowKey={(record) => record.id}
        columns={[
          {
            key: 'action',
            render: (review) => (
              <Button
                variant="outlined"
                onClick={() => navigate(`/reviews/${review.id}`)}
              >
                詳細
              </Button>
            ),
          },
          {
            title: 'ステータス',
            key: 'status',
            render: (review) => getStatusName(review.status),
          },
          {
            title: 'ユーザー',
            key: 'user',
            render: (review) => {
              const { user } = review
              return (
                <Link to={`/users/${user?.id}`}>
                  <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    <Image
                      source={{
                        uri: user?.defaultImageUrl,
                        width: 32,
                        height: 32,
                      }}
                      resizeMode="cover"
                    />
                    <Text style={{ marginLeft: 4 }}>{user?.name}</Text>
                  </View>
                </Link>
              )
            },
          },
          {
            title: 'レストラン',
            key: 'user',
            render: (review) => {
              const { restaurant } = review
              return (
                <Link to={`/restaurants/${restaurant?.id}`}>
                  {restaurant?.name}
                </Link>
              )
            },
          },
          {
            title: '評価',
            key: 'rating',
            render: (review) => {
              return (
                <View>
                  <Text numberOfLines={1}>
                    総合: {review.rating?.value.toFixed(1)}
                  </Text>
                  <Text numberOfLines={1}>
                    料理: {review.ratingFood?.value.toFixed(1)}
                  </Text>
                  <Text numberOfLines={1}>
                    サービス: {review.ratingService?.value.toFixed(1)}
                  </Text>
                  <Text numberOfLines={1}>
                    雰囲気: {review.ratingAtmosphere?.value.toFixed(1)}
                  </Text>
                  <Text numberOfLines={1}>
                    コスパ: {review.ratingCostPerformance?.value.toFixed(1)}
                  </Text>
                </View>
              )
            },
          },
          {
            title: '内容',
            key: 'content',
            render: (review) => review.content,
          },
          {
            title: '画像',
            key: 'images',
            render: (review) => (
              <Grid container spacing={2} sx={{ flex: 1 }} columns={2}>
                {review.restaurantImages.map((restaurantImage) => {
                  return (
                    <Grid key={restaurantImage.id} item>
                      <img
                        src={restaurantImage.serializedImage?.sm}
                        style={{
                          objectFit: 'cover',
                          width: 80,
                        }}
                      />
                    </Grid>
                  )
                })}
              </Grid>
            ),
          },
          {
            title: '作成日',
            key: 'createdAt',
            render: (review) =>
              review.createdAt
                ? dayjs
                    .unix(review.createdAt?.seconds)
                    .format('YYYY/MM/DD hh:mm')
                : '',
          },
        ]}
        loading={data === undefined}
        sx={{ minHeight: 400 }}
      />
    </PageHeader>
  )
}
