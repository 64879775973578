// @generated by protobuf-ts 2.8.1 with parameter long_type_number,generate_dependencies
// @generated from protobuf file "auto_reserve/admin/restaurant_image/restaurant_image_resource.proto" (package "auto_reserve.admin.restaurant_image", syntax proto3)
// tslint:disable
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Timestamp } from "../../../google/protobuf/timestamp";
/**
 * @generated from protobuf message auto_reserve.admin.restaurant_image.SerializedImage
 */
export interface SerializedImage {
  /**
   * @generated from protobuf field: string xl = 1;
   */
  xl: string;
  /**
   * @generated from protobuf field: string lg = 2;
   */
  lg: string;
  /**
   * @generated from protobuf field: string md = 3;
   */
  md: string;
  /**
   * @generated from protobuf field: string sm = 4;
   */
  sm: string;
}
/**
 * @generated from protobuf message auto_reserve.admin.restaurant_image.RestaurantImageResource
 */
export interface RestaurantImageResource {
  /**
   * @generated from protobuf field: int64 id = 1;
   */
  id: number;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp created_at = 2;
   */
  createdAt?: Timestamp;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp updated_at = 3;
   */
  updatedAt?: Timestamp;
  /**
   * @generated from protobuf field: auto_reserve.admin.restaurant_image.SerializedImage serialized_image = 4;
   */
  serializedImage?: SerializedImage;
}
// @generated message type with reflection information, may provide speed optimized methods
class SerializedImage$Type extends MessageType<SerializedImage> {
  constructor() {
    super("auto_reserve.admin.restaurant_image.SerializedImage", [
      { no: 1, name: "xl", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: "lg", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: "md", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
      { no: 4, name: "sm", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
    ]);
  }
  create(value?: PartialMessage<SerializedImage>): SerializedImage {
    const message = { xl: "", lg: "", md: "", sm: "" };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<SerializedImage>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: SerializedImage,
  ): SerializedImage {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* string xl */ 1:
          message.xl = reader.string();
          break;
        case /* string lg */ 2:
          message.lg = reader.string();
          break;
        case /* string md */ 3:
          message.md = reader.string();
          break;
        case /* string sm */ 4:
          message.sm = reader.string();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: SerializedImage,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* string xl = 1; */
    if (message.xl !== "")
      writer.tag(1, WireType.LengthDelimited).string(message.xl);
    /* string lg = 2; */
    if (message.lg !== "")
      writer.tag(2, WireType.LengthDelimited).string(message.lg);
    /* string md = 3; */
    if (message.md !== "")
      writer.tag(3, WireType.LengthDelimited).string(message.md);
    /* string sm = 4; */
    if (message.sm !== "")
      writer.tag(4, WireType.LengthDelimited).string(message.sm);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.admin.restaurant_image.SerializedImage
 */
export const SerializedImage = new SerializedImage$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RestaurantImageResource$Type extends MessageType<RestaurantImageResource> {
  constructor() {
    super("auto_reserve.admin.restaurant_image.RestaurantImageResource", [
      {
        no: 1,
        name: "id",
        kind: "scalar",
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
      { no: 2, name: "created_at", kind: "message", T: () => Timestamp },
      { no: 3, name: "updated_at", kind: "message", T: () => Timestamp },
      {
        no: 4,
        name: "serialized_image",
        kind: "message",
        T: () => SerializedImage,
      },
    ]);
  }
  create(
    value?: PartialMessage<RestaurantImageResource>,
  ): RestaurantImageResource {
    const message = { id: 0 };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined)
      reflectionMergePartial<RestaurantImageResource>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: RestaurantImageResource,
  ): RestaurantImageResource {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* int64 id */ 1:
          message.id = reader.int64().toNumber();
          break;
        case /* google.protobuf.Timestamp created_at */ 2:
          message.createdAt = Timestamp.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.createdAt,
          );
          break;
        case /* google.protobuf.Timestamp updated_at */ 3:
          message.updatedAt = Timestamp.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.updatedAt,
          );
          break;
        case /* auto_reserve.admin.restaurant_image.SerializedImage serialized_image */ 4:
          message.serializedImage = SerializedImage.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.serializedImage,
          );
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: RestaurantImageResource,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* int64 id = 1; */
    if (message.id !== 0) writer.tag(1, WireType.Varint).int64(message.id);
    /* google.protobuf.Timestamp created_at = 2; */
    if (message.createdAt)
      Timestamp.internalBinaryWrite(
        message.createdAt,
        writer.tag(2, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* google.protobuf.Timestamp updated_at = 3; */
    if (message.updatedAt)
      Timestamp.internalBinaryWrite(
        message.updatedAt,
        writer.tag(3, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* auto_reserve.admin.restaurant_image.SerializedImage serialized_image = 4; */
    if (message.serializedImage)
      SerializedImage.internalBinaryWrite(
        message.serializedImage,
        writer.tag(4, WireType.LengthDelimited).fork(),
        options,
      ).join();
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message auto_reserve.admin.restaurant_image.RestaurantImageResource
 */
export const RestaurantImageResource = new RestaurantImageResource$Type();
