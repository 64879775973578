// @generated by protobuf-ts 2.8.1 with parameter long_type_number,generate_dependencies
// @generated from protobuf file "auto_reserve/admin/restaurant/restaurant_service.proto" (package "auto_reserve.admin.restaurant", syntax proto3)
// tslint:disable
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { Restaurant } from "./restaurant_service";
import type { GetReservationBookContractsResponse } from "./restaurant_service";
import type { GetOrderContractsResponse } from "./restaurant_service";
import type { GetResponse } from "./restaurant_service";
import type { GetRequest } from "./restaurant_service";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { ListResponse } from "./restaurant_service";
import type { ListRequest } from "./restaurant_service";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service auto_reserve.admin.restaurant.Restaurant
 */
export interface IRestaurantClient {
  /**
   * @generated from protobuf rpc: List(auto_reserve.admin.restaurant.ListRequest) returns (auto_reserve.admin.restaurant.ListResponse);
   */
  list(
    input: ListRequest,
    options?: RpcOptions,
  ): UnaryCall<ListRequest, ListResponse>;
  /**
   * @generated from protobuf rpc: Get(auto_reserve.admin.restaurant.GetRequest) returns (auto_reserve.admin.restaurant.GetResponse);
   */
  get(
    input: GetRequest,
    options?: RpcOptions,
  ): UnaryCall<GetRequest, GetResponse>;
  /**
   * @generated from protobuf rpc: GetOrderContracts(auto_reserve.admin.restaurant.GetRequest) returns (auto_reserve.admin.restaurant.GetOrderContractsResponse);
   */
  getOrderContracts(
    input: GetRequest,
    options?: RpcOptions,
  ): UnaryCall<GetRequest, GetOrderContractsResponse>;
  /**
   * @generated from protobuf rpc: GetReservationBookContracts(auto_reserve.admin.restaurant.GetRequest) returns (auto_reserve.admin.restaurant.GetReservationBookContractsResponse);
   */
  getReservationBookContracts(
    input: GetRequest,
    options?: RpcOptions,
  ): UnaryCall<GetRequest, GetReservationBookContractsResponse>;
}
/**
 * @generated from protobuf service auto_reserve.admin.restaurant.Restaurant
 */
export class RestaurantClient implements IRestaurantClient, ServiceInfo {
  typeName = Restaurant.typeName;
  methods = Restaurant.methods;
  options = Restaurant.options;
  constructor(private readonly _transport: RpcTransport) {}
  /**
   * @generated from protobuf rpc: List(auto_reserve.admin.restaurant.ListRequest) returns (auto_reserve.admin.restaurant.ListResponse);
   */
  list(
    input: ListRequest,
    options?: RpcOptions,
  ): UnaryCall<ListRequest, ListResponse> {
    const method = this.methods[0],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<ListRequest, ListResponse>(
      "unary",
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: Get(auto_reserve.admin.restaurant.GetRequest) returns (auto_reserve.admin.restaurant.GetResponse);
   */
  get(
    input: GetRequest,
    options?: RpcOptions,
  ): UnaryCall<GetRequest, GetResponse> {
    const method = this.methods[1],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<GetRequest, GetResponse>(
      "unary",
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: GetOrderContracts(auto_reserve.admin.restaurant.GetRequest) returns (auto_reserve.admin.restaurant.GetOrderContractsResponse);
   */
  getOrderContracts(
    input: GetRequest,
    options?: RpcOptions,
  ): UnaryCall<GetRequest, GetOrderContractsResponse> {
    const method = this.methods[2],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<GetRequest, GetOrderContractsResponse>(
      "unary",
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: GetReservationBookContracts(auto_reserve.admin.restaurant.GetRequest) returns (auto_reserve.admin.restaurant.GetReservationBookContractsResponse);
   */
  getReservationBookContracts(
    input: GetRequest,
    options?: RpcOptions,
  ): UnaryCall<GetRequest, GetReservationBookContractsResponse> {
    const method = this.methods[3],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<GetRequest, GetReservationBookContractsResponse>(
      "unary",
      this._transport,
      method,
      opt,
      input,
    );
  }
}
