import React from 'react'
import { Image as RNImage, ImageProps as RNImageProps } from 'react-native'

import {
  ExtendedImageSourceProp,
  resolveImageSource,
} from '../../modules/resolveImageSource'

export * from './types'

export type ImageProps = Omit<RNImageProps, 'source'> & {
  source: ExtendedImageSourceProp
}

export function Image(props: ImageProps) {
  const resolvedSource = resolveImageSource(props.source)

  return <RNImage {...props} source={resolvedSource} />
}
