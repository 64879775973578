import { FormControlLabel, Radio, RadioGroup } from '@mui/material'
import React from 'react'

export function Segmented<T>({
  options,
  value,
  onChange,
}: {
  options: readonly { value: T; label: string }[]
  value: T
  onChange: React.Dispatch<React.SetStateAction<T>>
}) {
  return (
    <RadioGroup
      name="justify"
      value={value}
      onChange={(event) => onChange(event.target.value as T)}
      row
    >
      {options.map(({ value, label }, index) => (
        <FormControlLabel
          key={index}
          value={value}
          control={<Radio />}
          label={label}
        />
      ))}
    </RadioGroup>
  )
}
