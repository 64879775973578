import { TableCell, TableRow, styled } from '@mui/material'
import React, { ReactNode } from 'react'

export const BasicTableCell = styled(TableCell)(() => ({
  fontSize: 12,
  paddingTop: '8px',
  paddingBottom: '8px',
  paddingLeft: '8px',
  paddingRight: '8px',
}))

export function BaseTableRows({
  items,
  reportingCategoryCell,
  categoryCell,
  subCategoryCell,
}: {
  items: {
    label: string
    value: string | number | undefined
    type?: 'currency'
  }[]
  reportingCategoryCell?: ReactNode
  categoryCell?: ReactNode
  subCategoryCell?: ReactNode
}) {
  return (
    <>
      {items.map((item, index) => (
        <TableRow key={item.label}>
          {index === 0 && (
            <>
              {reportingCategoryCell}
              {categoryCell}
              {subCategoryCell}
            </>
          )}
          <BasicTableCell>{item.label}</BasicTableCell>
          <BasicTableCell sx={{ textAlign: 'right' }}>
            {item.type === 'currency'
              ? item.value?.toLocaleString('ja-JP', {
                  style: 'currency',
                  currency: 'JPY',
                })
              : item.value}
          </BasicTableCell>
        </TableRow>
      ))}
    </>
  )
}
