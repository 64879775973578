import React from 'react'
import {
  ActivityIndicator,
  GestureResponderEvent,
  StyleProp,
  TextStyle,
  ViewStyle,
} from 'react-native'
import { Text } from './Text'
import { Colors } from '../constants/Colors'

import { TouchableOpacity } from './Touchables'

interface ModeVariant {
  contained: 'primary' | 'secondary' | 'danger'
  outline:
    | 'primary'
    | 'primary-secondary'
    | 'secondary'
    | 'danger'
    | 'danger-secondary'
  text: 'primary' | 'secondary' | 'danger'
}

type ButtonStyle = ViewStyle

interface ButtonProps<T extends keyof ModeVariant> {
  mode?: T
  variant?: ModeVariant[T]
  disabled?: boolean
  onPress?: (event: GestureResponderEvent) => void
  style?: StyleProp<ButtonStyle>
  children:
    | React.ReactNode
    | (({ textStyle }: { textStyle: StyleProp<TextStyle> }) => React.ReactNode)
  width?: number | string
  height?: number | string
  roundness?: number
  textBold?: boolean
  textPrimary?: boolean
  textStyle?: StyleProp<TextStyle>

  href?: string
  hrefAttrs?: {
    target?: string
    rel?: string
  }
  testID?: string
  loading?: boolean
}

type ModeStyles = {
  [mode in keyof ModeVariant]: {
    [variant in ModeVariant[mode]]: {
      style: ViewStyle
      textStyle: TextStyle
    }
  }
}

const styles: ModeStyles = {
  contained: {
    primary: {
      style: {
        borderWidth: 1,
        borderColor: Colors.primary,
        backgroundColor: Colors.primary,
      },
      textStyle: {
        color: 'white',
        fontWeight: '600',
      },
    },
    secondary: {
      style: {
        borderWidth: 1,
        borderColor: 'white',
        backgroundColor: 'white',
      },
      textStyle: {
        color: Colors.primary,
      },
    },
    danger: {
      style: {
        backgroundColor: Colors.caution,
      },
      textStyle: {
        color: Colors.white,
      },
    },
  },

  outline: {
    primary: {
      style: {
        borderWidth: 1,
        borderColor: Colors.primary,
        backgroundColor: 'white',
      },
      textStyle: {
        color: Colors.primary,
      },
    },
    'primary-secondary': {
      style: {
        borderWidth: 1,
        borderColor: Colors.primary,
        backgroundColor: 'transparent',
      },
      textStyle: {
        color: Colors.primary,
      },
    },
    secondary: {
      style: {
        borderWidth: 1,
        borderColor: Colors.darkOverlay20,
        backgroundColor: 'white',
      },
      textStyle: {
        color: Colors.black,
      },
    },
    danger: {
      style: {
        borderWidth: 1,
        borderColor: Colors.caution40,
        backgroundColor: 'white',
      },
      textStyle: {
        color: Colors.caution,
      },
    },
    'danger-secondary': {
      style: {
        borderWidth: 1,
        borderColor: Colors.caution40,
        backgroundColor: 'transparent',
      },
      textStyle: {
        color: Colors.caution,
      },
    },
  },
  text: {
    primary: {
      style: {},
      textStyle: {
        color: Colors.primary,
      },
    },
    secondary: {
      style: {},
      textStyle: {
        color: Colors.black,
      },
    },
    danger: {
      style: {},
      textStyle: {
        color: Colors.caution,
      },
    },
  },
}

export function Button<T extends keyof ModeVariant>({
  disabled = false,
  mode = 'contained' as T,
  variant = 'primary',
  roundness = 4,
  onPress,
  style,
  children,
  width,
  height = 40,
  textStyle,
  textBold = false,
  textPrimary = false,
  href,
  hrefAttrs,
  testID,
  loading = false,
}: ButtonProps<T>) {
  const buttonStyles: {
    style: ViewStyle
    textStyle: TextStyle
  } = (styles as any)[mode][variant]

  function renderChildren() {
    const textStyle_: StyleProp<TextStyle> = [
      textBold && {
        fontWeight: '600',
      },
      textPrimary && {
        color: Colors.primary,
      },

      buttonStyles.textStyle,
      textStyle,
    ]
    if (typeof children === 'string' || typeof children === 'number') {
      return <Text style={textStyle_}>{children}</Text>
    } else if (typeof children === 'function') {
      return children({ textStyle: textStyle_ })
    }

    return children
  }

  return (
    <TouchableOpacity
      onPress={onPress}
      href={href}
      hrefAttrs={hrefAttrs}
      disabled={disabled}
      style={[
        {
          borderRadius: roundness,
          paddingHorizontal: 24,
          width,
          height,
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
        },
        disabled && {
          opacity: 0.4,
        },
        buttonStyles.style,
        style,
      ]}
      testID={testID}
    >
      {renderChildren()}
      {loading && (
        <ActivityIndicator
          size="small"
          color={mode === 'outline' ? 'gray' : 'white'}
        />
      )}
    </TouchableOpacity>
  )
}
