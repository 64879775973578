import { useRef, useEffect } from 'react'

export function usePrevious<T>(value: T, initialValue?: T): T | undefined {
  const ref = useRef(initialValue)

  useEffect(() => {
    ref.current = value
  }, [value])

  return ref.current
}
