import {
  RestaurantResource_PublishedStatus,
  RestaurantResource_Status,
} from '@hello-ai/proto/src/gen/auto_reserve/admin/restaurant/restaurant_resource'
import { RestaurantClient } from '@hello-ai/proto/src/gen/auto_reserve/admin/restaurant/restaurant_service.client'
import { assertNever } from 'modules/assert'
import { createRpcService } from 'modules/rpc'

export const restaurantService = createRpcService(RestaurantClient)
export const restaurantClient = restaurantService.client

export function getStatusName(value: RestaurantResource_Status) {
  switch (value) {
    case RestaurantResource_Status.UNPUBLISHED:
      return '非公開'
    case RestaurantResource_Status.PUBLISHED:
      return '公開'
    case RestaurantResource_Status.CLOSED:
      return '閉店'
    case RestaurantResource_Status.NOT_RESERVED:
      return '予約不可'
    default: {
      assertNever(value)
    }
  }
}

export function getPublishedStatusName(
  value: RestaurantResource_PublishedStatus
) {
  switch (value) {
    case RestaurantResource_PublishedStatus.AUTO:
      return '自動予約'
    case RestaurantResource_PublishedStatus.MANUAL_CALL:
      return '手動予約'
    case RestaurantResource_PublishedStatus.ONLY_WEB:
      return '代行不可'
    default: {
      assertNever(value)
    }
  }
}
