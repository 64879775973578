import React from 'react'
import ServerError from 'pages/Error/ServerError'

interface ErrorBoundaryProps {
  hasError?: boolean
  children: React.ReactNode
  fallback: (error: unknown) => React.ReactNode
}

interface ErrorBoundaryState {
  hasError: boolean
  error?: unknown
}

class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  state: ErrorBoundaryState = {
    hasError: false,
  }

  static getDerivedStateFromError(error: unknown): ErrorBoundaryState {
    return { hasError: true, error }
  }

  componentDidCatch(error: unknown, errorInfo: unknown) {
    console.error(error, errorInfo)
  }

  render() {
    if (this.props.hasError != null && this.props.hasError) {
      return this.props.fallback(this.state.error)
    }

    if (this.state.hasError) {
      return this.props.fallback(this.state.error)
    }
    return this.props.children
  }
}

const defaultFallback = () => <ServerError />

export function withErrorBoundary<P extends {}>(
  Component: React.ComponentType<React.PropsWithChildren<P>>,
  fallback: () => React.ReactNode = defaultFallback
) {
  return function WithErrorBoundary(props: P) {
    return (
      <ErrorBoundary fallback={fallback}>
        <Component {...props} />
      </ErrorBoundary>
    )
  }
}
