import {
  UserResource_Role,
  UserResource_Status,
} from '@hello-ai/proto/src/gen/auto_reserve/admin/user/user_resource'
import {
  GetRequest,
  ListRequest,
} from '@hello-ai/proto/src/gen/auto_reserve/admin/user/user_service'
import { UserClient } from '@hello-ai/proto/src/gen/auto_reserve/admin/user/user_service.client'
import { assertNever } from 'modules/assert'
import { createRpcService, rpcOptions } from 'modules/rpc'
import useSWR, { swrKey } from 'modules/swr'
import invariant from 'tiny-invariant'
import { useToken } from './Auth'

export const userService = createRpcService(UserClient)
export const userClient = userService.client

export function getStatusName(value: UserResource_Status) {
  switch (value) {
    case UserResource_Status.NORMAL:
      return '通常'
    case UserResource_Status.SUSPENDED:
      return '凍結'
    case UserResource_Status.DISCARDED:
      return '削除'
    default: {
      assertNever(value)
    }
  }
}

export function getRoleName(value: UserResource_Role) {
  switch (value) {
    case UserResource_Role.GENERAL:
      return '一般'
    case UserResource_Role.ADMIN:
      return '管理者'
    case UserResource_Role.DEVELOPER:
      return '開発者'
    case UserResource_Role.CS:
      return 'CS'
    case UserResource_Role.INTERNAL:
      return 'INTERNAL'
    default: {
      assertNever(value)
    }
  }
}

export function useUsers(params: ListRequest) {
  const token = useToken()

  const { data, mutate, error } = useSWR(
    swrKey(token, `User/List`, params),
    () => userClient.list(params, rpcOptions({ token })).response
  )

  return {
    data,
    mutate,
    error,
  }
}

export function useUser(params: GetRequest | undefined) {
  const token = useToken()

  const { data, mutate, error } = useSWR(
    params === undefined ? null : swrKey(token, 'User/Get', params),
    () => {
      invariant(params !== undefined, 'params cannot be undefined')
      return userClient.get(params, rpcOptions({ token })).response
    }
  )

  return {
    user: data,
    mutate,
    error,
  }
}
